import React from "react";
import styled from "styled-components";
import { Navbar, DropdownButton, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { notificationsActions } from "_actions";

const StyledBadge = styled.div`
  position: absolute;
  top: -6px;
  left: 18px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 10px;
  background-color: #dc3545;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
`;

const StyledBellContainer = styled.div`
  position: relative;
  margin-right: 32px;
  cursor: pointer;

  :hover {
    svg {
      path {
        fill: #007bff;
      }
    }
  }
`;

const BellSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.02 2.91003C8.70997 2.91003 6.01997 5.60003 6.01997 8.91003V11.8C6.01997 12.41 5.75997 13.34 5.44997 13.86L4.29997 15.77C3.58997 16.95 4.07997 18.26 5.37997 18.7C9.68997 20.14 14.34 20.14 18.65 18.7C19.86 18.3 20.39 16.87 19.73 15.77L18.58 13.86C18.28 13.34 18.02 12.41 18.02 11.8V8.91003C18.02 5.61003 15.32 2.91003 12.02 2.91003Z"
      stroke="#007bff"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M13.87 3.19994C13.56 3.10994 13.24 3.03994 12.91 2.99994C11.95 2.87994 11.03 2.94994 10.17 3.19994C10.46 2.45994 11.18 1.93994 12.02 1.93994C12.86 1.93994 13.58 2.45994 13.87 3.19994Z"
      stroke="#007bff"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601"
      stroke="#007bff"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
);

export const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.authentication.user.username);
  const { countOfUnread } = useSelector((state) => state.notifications);

  const handleNavBar = (e) => {
    e.stopPropagation();
    props.handleNavBar();
  };

  const handleShowNotifications = () => {
    dispatch(notificationsActions.showNotifications());
  };

  return (
    <Navbar expand="lg" className="navbar-default">
      <Navbar.Toggle onClick={handleNavBar} />
      <Navbar.Brand>
        <a href="#">{props.brandText}</a>
      </Navbar.Brand>

      <Navbar.Collapse className="d-flex justify-content-end show">
        <StyledBellContainer onClick={handleShowNotifications}>
          <BellSvg />
          {countOfUnread > 0 && <StyledBadge>{countOfUnread > 99 ? "99+" : countOfUnread}</StyledBadge>}
        </StyledBellContainer>
        <DropdownButton menuAlign="right" title={username} variant="outline-primary">
          <Link to="/admin/profile" className="dropdown-item">
            <i className="fa fa-user"></i> Profile
          </Link>
          <Dropdown.Divider />
          <Link to="/login" className="dropdown-item">
            <i className="fa fa-sign-out"></i> Logout
          </Link>
        </DropdownButton>
      </Navbar.Collapse>
    </Navbar>
  );
};
