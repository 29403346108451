import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { adminActions } from "_actions";
import { adminService } from "_services/admin.service";

import Spinner from "components/Spinner";
import Upselloffer from "./Upselloffer";
import UpcomingDates from "./UpcomingDates";

export default function EinOptions() {
  const defaultCat = localStorage.getItem("@EINOption_Status/category") || "EIN";

  const dispatch = useDispatch();
  const availableCategories = useSelector(({ authentication: { user } }) => user.availableCategories);
  const storeEINOptions = useSelector(({ einoptions: { options } }) => options);

  const [activeTab, setActiveTab] = useState("schedule");
  const [category, setCategory] = useState(availableCategories.includes(defaultCat) ? defaultCat : "EIN");
  const [ein_option_Object, setProcessingOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const availability = {
    MONDAY: "Mo",
    TUESDAY: "Tu",
    WEDNESDAY: "We",
    THURSDAY: "Th",
    FRIDAY: "Fri",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };

  const [einOptions, setEINOptions] = useState(storeEINOptions);

  const handleTab = (key) => setActiveTab(key);

  const changeOptionStatus = (idx, { target: { checked } }) => {
    const options = [...einOptions];

    options[idx].disabled = !checked;
    setEINOptions(options);
    dispatch(adminActions.saveoptions(options[idx]));
  };

  const changeSchedule = (idx, dayIdx, timeIdx, { target: { name, value } }) => {
    const options = [...einOptions];
    const timeData = options[idx].enableSchedule[dayIdx][name].split(":");

    timeData[timeIdx] = value;
    options[idx].enableSchedule[dayIdx][name] = timeData.join(":");

    setEINOptions(options);
    dispatch(adminActions.saveoptions(options[idx]));
  };

  const removeActivity = (day, idx, isActive) => {
    const newSchedule = {
      dayOfWeek: day,
      finishTime: "23:59:00",
      startTime: "00:00:00",
    };

    const options = [...einOptions];
    const schedule = options[idx].enableSchedule;

    const weekdays = isActive
      ? schedule.filter(({ dayOfWeek }) => dayOfWeek !== day)
      : sortactivity([...schedule, newSchedule]);

    options[idx].enableSchedule = weekdays;

    setEINOptions(options);
    dispatch(adminActions.saveoptions(options[idx]));
  };

  const sortactivity = (schedule) => {
    schedule.sort(function (a, b) {
      return Object.keys(availability).indexOf(a.dayOfWeek) - Object.keys(availability).indexOf(b.dayOfWeek);
    });

    return schedule;
  };

  const handleChangeCategory = ({ target: { value } }) => {
    setCategory(value);

    localStorage.setItem("@EINOption_Status/category", value);

    setLoading(true);

    adminService.getProcessingOptions(value, false).then((options) => {
      setLoading(false);
      setProcessingOptions(
        options.values.reduce((am, currentVal) => {
          return { ...am, [currentVal.code]: `${currentVal.value} Processing Speed` };
        }, {})
      );
      dispatch(adminActions.getEinOptions(value));
    });
  };

  useEffect(() => {
    setLoading(true);
    adminService.getProcessingOptions(category, false).then((options) => {
      setLoading(false);
      setProcessingOptions(
        options.values.reduce(
          (am, currentVal) => ({ ...am, [currentVal.code]: `${currentVal.value} Processing Speed` }),
          {}
        )
      );
      dispatch(adminActions.getEinOptions(category));
    });
  }, []);

  useEffect(() => {
    setLoading(false);
    setEINOptions(storeEINOptions);
  }, [storeEINOptions]);

  const availableDayRenderer = (key, idx) => {
    const isActive = einOptions[idx].enableSchedule.some(({ dayOfWeek }) => key === dayOfWeek);

    return (
      <div
        key={`available-${key}-${idx}`}
        className={`upselloffer-availablity${isActive ? " active" : ""}`}
        onClick={() => removeActivity(key, idx, isActive)}
      >
        <span>{availability[key]}</span>
      </div>
    );
  };

  return (
    <Container fluid className="main-content ein-options">
      <Tabs defaultActiveKey="upsell" transition={false} activeKey={activeTab} onSelect={handleTab}>
        <Tab eventKey="schedule" title="Status">
          {!!availableCategories.length && (
            <Row className="option-wrapper">
              <Form.Group as={Col} lg="4">
                <Form.Label>Category</Form.Label>
                <Form.Control size="sm" as="select" value={category} onChange={handleChangeCategory}>
                  {availableCategories.map((cat, key) => (
                    <option value={cat} key={key}>
                      {cat}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>
          )}

          <Row>
            {loading && (
              <Col lg="12 d-flex justify-content-center">
                <Spinner />
              </Col>
            )}

            {!!(!loading && !einOptions.length) && (
              <Col lg="12 d-flex justify-content-center">
                <h4>No processing options available.</h4>
              </Col>
            )}

            {!!(!loading && einOptions.length) && (
              <>
                {einOptions.map((option, index) => (
                  <Col lg="6" key={index}>
                    <div className="option-wrapper">
                      <Form.Check
                        type="checkbox"
                        label={ein_option_Object[option.option] || option.option}
                        checked={!option.disabled}
                        name="disabled"
                        onChange={(e) => changeOptionStatus(index, e)}
                      />
                      Availability:
                      {Object.keys(availability).map((key) => availableDayRenderer(key, index))}
                      {option.enableSchedule.map((schedule, key1) => (
                        <div className="schedule-form" key={key1}>
                          <Form inline>
                            <Col sm="3">{schedule.dayOfWeek}:</Col>
                            <Form.Group xs={3} sm={3} md={3} lg={3} xl={3}>
                              <Form.Control
                                as="select"
                                size="sm"
                                value={schedule.startTime.split(":")[0]}
                                name="startTime"
                                onChange={(e) => changeSchedule(index, key1, 0, e)}
                                disabled={option.disabled}
                              >
                                {Array.from(Array(24), (e, i) => (
                                  <React.Fragment key={i}>
                                    {i < 10 ? <option>0{i}</option> : <option>{i}</option>}
                                  </React.Fragment>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            :
                            <Form.Group xs={3} sm={3} md={3} lg={3} xl={3}>
                              <Form.Control
                                as="select"
                                size="sm"
                                value={schedule.startTime.split(":")[1]}
                                name="startTime"
                                onChange={(e) => changeSchedule(index, key1, 1, e)}
                                disabled={option.disabled}
                              >
                                {Array.from(Array(60), (e, i) => (
                                  <React.Fragment key={i}>
                                    {i < 10 ? <option>0{i}</option> : <option>{i}</option>}
                                  </React.Fragment>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            <span>To</span>
                            <Form.Group xs={3} sm={3} md={3} lg={3} xl={3}>
                              <Form.Control
                                as="select"
                                size="sm"
                                value={schedule.finishTime.split(":")[0]}
                                name="finishTime"
                                onChange={(e) => changeSchedule(index, key1, 0, e)}
                                disabled={option.disabled}
                              >
                                {Array.from(Array(24), (e, i) => (
                                  <React.Fragment key={i}>
                                    {i < 10 ? <option>0{i}</option> : <option>{i}</option>}
                                  </React.Fragment>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            :
                            <Form.Group xs={3} sm={3} md={3} lg={3} xl={3}>
                              <Form.Control
                                as="select"
                                size="sm"
                                value={schedule.finishTime.split(":")[1]}
                                name="finishTime"
                                onChange={(e) => changeSchedule(index, key1, 1, e)}
                                disabled={option.disabled}
                              >
                                {Array.from(Array(60), (e, i) => (
                                  <React.Fragment key={i}>
                                    {i < 10 ? <option>0{i}</option> : <option>{i}</option>}
                                  </React.Fragment>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Form>
                        </div>
                      ))}
                    </div>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </Tab>
        <Tab eventKey="upsell" title="Upsell">
          <Upselloffer />
        </Tab>
        <Tab eventKey="upcomingDates" title="Upcoming Dates">
          <UpcomingDates />
        </Tab>
      </Tabs>
    </Container>
  );
}
