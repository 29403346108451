import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { accountingActions } from "_actions";

import Table from "components/Table";
import ConfirmationModal from "components/ConfirmationModal";

const getColumns = ({ posting, deleting, productCodes, newUpcomingDate, handleChange, handleAdd, handleDelete }) => {
  return [
    {
      dataIndex: "productCode",
      title: <b>Product Code</b>,
      width: "20%",
      breakWords: true,
      render: (row) => {
        if (row?.id) {
          return <span>{row?.productCode?.code}</span>;
        }

        return (
          <Form.Control
            as="select"
            size="sm"
            name="productCode"
            value={newUpcomingDate?.productCode?.code}
            onChange={handleChange}
          >
            <option value="">Select Product Type...</option>
            {productCodes?.map(({ code }) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </Form.Control>
        );
      },
    },
    {
      dataIndex: "description",
      title: <b>Description</b>,
      width: "40%",
      breakWords: true,
      render: (row) => {
        if (row?.id) {
          return <span>{row?.description}</span>;
        }

        return (
          <Form.Control
            size="sm"
            name="description"
            placeholder="Provide description"
            value={newUpcomingDate?.description}
            onChange={handleChange}
          />
        );
      },
    },
    {
      dataIndex: "month",
      title: <b>Month</b>,
      width: "15%",
      render: (row) => {
        const monthsArr = moment.months();

        if (row.id) {
          return <span>{monthsArr[row?.month - 1] || row?.month}</span>;
        }

        return (
          <Form.Control as="select" size="sm" name="month" value={newUpcomingDate?.month} onChange={handleChange}>
            <option value="">Select Month...</option>
            {monthsArr?.map((month, idx) => (
              <option key={month} value={idx + 1}>
                {month}
              </option>
            ))}
          </Form.Control>
        );
      },
    },
    {
      dataIndex: "day",
      title: <b>Day</b>,
      width: "15%",
      render: (row) => {
        if (row.id) {
          return <span>{row?.day}</span>;
        }

        return (
          <Form.Control as="select" size="sm" name="day" value={newUpcomingDate?.day} onChange={handleChange}>
            <option value="">Select Day...</option>
            {Array.from({
              length: moment()
                .set("month", (newUpcomingDate?.month || 1) - 1)
                .daysInMonth(),
            }).map((_, idx) => (
              <option key={idx} value={idx + 1}>
                {idx + 1}
              </option>
            ))}
          </Form.Control>
        );
      },
    },
    {
      dataIndex: "action",
      title: <b>Action</b>,
      width: "10%",
      render: (row) => {
        if (row?.id) {
          return (
            <Button
              variant="outline-danger"
              disabled={deleting}
              onClick={() => {
                handleDelete(row?.id);
              }}
            >
              Delete
            </Button>
          );
        }

        const disabled = Object.keys(newUpcomingDate).some((key) => key !== "id" && !newUpcomingDate[key]) || posting;

        return (
          <Button variant="primary" disabled={disabled} onClick={handleAdd}>
            + Add
          </Button>
        );
      },
    },
  ];
};

const initialUpcomingDate = { productCode: "", description: "", month: "", day: "" };

export default function UpcomingDates() {
  const dispatch = useDispatch();
  const { upcomingDates } = useSelector(({ accounting }) => accounting);
  const { data, posting, deleting } = upcomingDates || {};

  const productCodes = useSelector(({ variants: { productCodes } }) => productCodes);

  const [newUpcomingDate, setNewUpcomingDate] = useState(initialUpcomingDate);
  const [deleteUpcomingDate, setDeleteUpcomingDate] = useState(null);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target || {};
    setNewUpcomingDate((prev) => ({ ...prev, [name]: value }));
  };

  const handleAdd = () => {
    dispatch(
      accountingActions.postUpcomingDate({
        body: newUpcomingDate,
        onSuccess: () => {
          setNewUpcomingDate(initialUpcomingDate);
        },
      })
    );
  };

  const handleDeleteInit = (id) => {
    setDeleteUpcomingDate(data?.find((item) => item.id === id));
    setConfirmationModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(
      accountingActions.deleteUpcomingDate({
        id: deleteUpcomingDate?.id,
        onSuccess: () => {
          setDeleteUpcomingDate(null);
          setConfirmationModalVisible(false);
        },
      })
    );
  };

  const handleConfirmationModalClose = () => {
    setDeleteUpcomingDate(null);
    setConfirmationModalVisible(false);
  };

  useEffect(() => {
    dispatch(accountingActions.getUpcomingDates());
  }, []);

  return (
    <>
      <Table
        columns={getColumns({
          posting,
          deleting,
          productCodes,
          newUpcomingDate,
          handleChange,
          handleAdd,
          handleDelete: handleDeleteInit,
        })}
        rows={[...data, newUpcomingDate]}
      />
      <ConfirmationModal
        show={confirmationModalVisible}
        body={
          <div>
            <div style={{ paddingBottom: 8 }}>Are you sure you want to delete this record?</div>
            <div>
              <b>Product Type:</b>&nbsp;
              {deleteUpcomingDate?.productCode?.code}
            </div>
            <div>
              <b>Description:</b>&nbsp;
              {deleteUpcomingDate?.description}
            </div>
          </div>
        }
        handleConfirm={handleDeleteConfirm}
        handleClose={handleConfirmationModalClose}
      />
    </>
  );
}
