import { config } from "_config";
import { authHeader, mappingQueryParams, handleResponse, saveFile } from "_helpers";

export const accountingService = {
  getFiles,
  uploadFile,
  downloadFile,
  deleteFile,
  getStatements,
  createStatement,
  uploadStatement,
  updateStatement,
  downloadStatement,
  deleteStatement,
  requestStatement,
  getFileTypes,
  getChannel,
  getUpcomingDates,
  postUpcomingDate,
  deleteUpcomingDate,
};

function getFiles(options = {}) {
  const { customerId, productId, type, year, page, count } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/files?page=${page}&count=${count}`;

  if (type) {
    url += `&type=${type}`;
  }

  if (year) {
    url += `&year=${year}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function uploadFile({ customerId, productId, body, startDate, endDate, description, type, year }) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body,
  };

  const url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/files${mappingQueryParams({
    startDate,
    endDate,
    description,
    type,
    year,
  })}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function downloadFile({ customerId, productId, fileId, fileName, checkMime = false }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/files/${fileId}`;

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 200) {
      saveFile(response, fileName, true, checkMime);
    } else {
      return handleResponse(response);
    }
  });
}

function deleteFile({ customerId, productId, fileId }) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/files/${fileId}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getStatements(options = {}) {
  const { customerId, productId, type, status, year, page, count } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/statements?page=${page}&count=${count}`;

  if (type) {
    url += `&type=${type}`;
  }

  if (status) {
    url += `&status=${status}`;
  }

  if (year) {
    url += `&year=${year}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function createStatement({ customerId, productId, type, status, body, startDate, endDate, description, year }) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body,
  };

  const url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/statements${mappingQueryParams({
    type,
    status,
    startDate,
    endDate,
    description,
    year,
  })}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function uploadStatement({ customerId, productId, statementId, body }) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body,
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/statements/${statementId}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function updateStatement({ customerId, productId, statementId, body }) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/statements/${statementId}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function downloadStatement({ customerId, productId, statementId, fileName, checkMime = false }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/statements/${statementId}`;

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 200) {
      saveFile(response, fileName, true, checkMime);
    } else {
      return handleResponse(response);
    }
  });
}

function deleteStatement({ customerId, productId, statementId }) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/statements/${statementId}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function requestStatement({ customerId, productId, body }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/statements/request`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getFileTypes(options = {}) {
  const { customerId, productId } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/file-types`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getChannel(options = {}) {
  const { customerId, productId } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/customers/${customerId}/products/${productId}/get-message-channel`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getUpcomingDates(options = {}) {
  const { code } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/accounting/upcoming-dates`;

  if (code) {
    url += `&code=${code}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function postUpcomingDate({ body }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  let url = `${config.accountingUrl}/accounting/upcoming-dates`;

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteUpcomingDate({ id }) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  let url = `${config.accountingUrl}/accounting/upcoming-dates/${id}`;

  return fetch(url, requestOptions).then(handleResponse);
}
