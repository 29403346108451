import { productTasksConstants } from "_constants";
import { handleError } from "_helpers";
import { customerService } from "_services";
import { alertActions } from "./";

export const productTasksActions = {
  refreshProductTasksFilters,
  getProductTasks,
  createProductTask,
  updateProductTask,
  deleteProductTask,
};

function refreshProductTasksFilters(payload) {
  return { type: productTasksConstants.REFRESH_PRODUCT_TASKS_FILTERS, payload };
}

function getProductTasks({ orderId, productId, filters = {} }) {
  const { page, count, type, status } = filters || {};

  return (dispatch) => {
    dispatch(request());

    customerService.getProductTasks({ orderId, productId, page, count, status, type }).then(
      (tasks) => dispatch(success(tasks)),
      (error) => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Loading tasks error"
          )
        );
      }
    );
  };

  function request() {
    return { type: productTasksConstants.GET_PRODUCT_TASKS_REQUEST };
  }
  function success(payload) {
    return { type: productTasksConstants.GET_PRODUCT_TASKS_SUCCESS, payload };
  }
  function failure() {
    return { type: productTasksConstants.GET_PRODUCT_TASKS_FAILURE };
  }
}

function createProductTask({ orderId, productId, body, filters = {} }) {
  return (dispatch) => {
    dispatch(request());
    console.log({ filters });
    customerService.createProductTask({ orderId, productId, body }).then(
      () => {
        dispatch(getProductTasks({ orderId, productId, filters }));

        dispatch(alertActions.success("Task created successfully."));
        dispatch(success());
      },
      (error) => {
        alertActions.error(handleError(error)?.message || error?.message || error?.toString() || "Creating task error");
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: productTasksConstants.CREATE_PRODUCT_TASK_REQUEST };
  }
  function success() {
    return { type: productTasksConstants.CREATE_PRODUCT_TASK_SUCCESS };
  }
  function failure() {
    return { type: productTasksConstants.CREATE_PRODUCT_TASK_FAILURE };
  }
}

function updateProductTask({ orderId, productId, taskId, body, filters = {} }) {
  return (dispatch) => {
    dispatch(request());

    customerService.updateProductTask({ orderId, productId, taskId, body }).then(
      () => {
        dispatch(getProductTasks({ orderId, productId, filters }));

        dispatch(alertActions.success("Task updated successfully."));
        dispatch(success());
      },
      (error) => {
        alertActions.error(handleError(error)?.message || error?.message || error?.toString() || "Updating task error");
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: productTasksConstants.UPDATE_PRODUCT_TASK_REQUEST };
  }
  function success() {
    return { type: productTasksConstants.UPDATE_PRODUCT_TASK_SUCCESS };
  }
  function failure() {
    return { type: productTasksConstants.UPDATE_PRODUCT_TASK_FAILURE };
  }
}

function deleteProductTask({ orderId, productId, taskId, filters = {} }) {
  return (dispatch) => {
    dispatch(request());

    customerService.deleteProductTask({ orderId, productId, taskId }).then(
      () => {
        dispatch(getProductTasks({ orderId, productId, filters }));

        dispatch(alertActions.success("Task deleted successfully."));
        dispatch(success());
      },
      (error) => {
        alertActions.error(handleError(error)?.message || error?.message || error?.toString() || "Deleting task error");
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: productTasksConstants.DELETE_PRODUCT_TASK_REQUEST };
  }
  function success() {
    return { type: productTasksConstants.DELETE_PRODUCT_TASK_SUCCESS };
  }
  function failure() {
    return { type: productTasksConstants.DELETE_PRODUCT_TASK_FAILURE };
  }
}
