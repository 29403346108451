import { userConstants } from "../_constants";

const initialState = {
  items: {},
  user: {},
  error: {},
  loading: 0,
};

export function users(state = { ...initialState }, action) {
  const { type, payload } = action;

  switch (type) {
    // GET ALL USERS
    case userConstants.GETALL_REQUEST:
      return { ...state, loading: state.loading + 1, items: initialState.items, error: initialState.error };
    case userConstants.GETALL_SUCCESS:
      return { ...state, loading: state.loading - 1, items: payload };
    case userConstants.GETALL_FAILURE:
      return { ...state, loading: state.loading - 1, error: payload };

    // GET USER
    case userConstants.GET_USER_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case userConstants.GET_USER_SUCCESS:
      return { ...state, loading: state.loading - 1, user: payload };
    case userConstants.GET_USER_FAILURE:
      return { ...state, loading: state.loading - 1, error: payload };

    // REGISTER USER
    case userConstants.REGISTER_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case userConstants.REGISTER_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case userConstants.REGISTER_FAILURE:
      return { ...state, loading: state.loading - 1, error: payload };

    // UPDATE USER
    case userConstants.UPDATE_USER_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case userConstants.UPDATE_USER_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case userConstants.UPDATE_USER_FAILURE:
      return { ...state, loading: state.loading - 1, error: payload };

    // DELETE USER
    case userConstants.DELETE_USER_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case userConstants.DELETE_USER_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case userConstants.DELETE_USER_FAILURE:
      return { ...state, loading: state.loading - 1, error: payload };

    case userConstants.GETME_SUCCESS:
      return { items: action.user };

    default:
      return state;
  }
}
