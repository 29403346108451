import { Row, Form } from "react-bootstrap";

import { country_obj } from "variables/Variables";
import { phoneFormatter } from "_helpers";

import IndividualAddressView from "../IndividualAddress/View";

export default function Individual({ individual, isOrganization, isForceShowing = false }) {
  if (!isForceShowing && !individual) return null;

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.firstName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional)
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.middleName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.lastName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional)
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.suffixName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone Number
        </Form.Label>
        <Form.Label column sm={7}>
          {phoneFormatter(individual?.phone) || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Email Address
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.email || "–"}
        </Form.Label>
      </Form.Group>

      {!isOrganization && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Country Of Citizenship
          </Form.Label>
          <Form.Label column sm={7}>
            {country_obj[individual?.countryOfCitizenship] || individual?.countryOfCitizenship || "–"}
          </Form.Label>
        </Form.Group>
      )}

      <IndividualAddressView isForceShowing={isForceShowing} address={individual?.address} />
    </>
  );
}
