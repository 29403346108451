import { config } from "_config";
import { getFileExtension } from "_helpers/parser";

export function validatePassword(password) {
  const checkLength = new RegExp(/^.{8,}$/);
  const hasDigit = new RegExp(/^(?=.*\d).*$/);
  const hasUpperLetter = new RegExp(/^(?=.*[A-Z]).*$/);
  const hasLowerLetter = new RegExp(/^(?=.*[a-z]).*$/);
  const hasSpecialSymbol = new RegExp(/^(?=.*[!@#$%^&*])(?!.*[ ]).*$/);
  const complexCheck = new RegExp(/^[a-zA-Z0-9!@#$%^&*]+$/);

  const c1 = checkLength.test(password);
  const c2 = hasDigit.test(password);
  const c3 = hasUpperLetter.test(password);
  const c4 = hasLowerLetter.test(password);
  const c5 = hasSpecialSymbol.test(password);
  const c6 = complexCheck.test(password);

  return c1 && c2 && c3 && c4 && c5 && c6;
}

export function getFileExtensionsString(arr) {
  if (Array.isArray(arr) && !arr.includes("*")) {
    return arr.map((ext) => "." + ext).join(", ");
  }

  return "";
}

export function checkIfFileAllowed(ext, allowedExt) {
  if (!Array.isArray(allowedExt) || allowedExt === undefined || allowedExt.includes("*") || allowedExt.length === 0) {
    return true;
  }

  return allowedExt.includes(ext);
}

export function sanitizeFileName(fileName) {
  if (fileName) {
    const extension = getFileExtension(fileName, true);
    const name = fileName.slice(0, fileName.length - extension.length);
    const result = name.replace(/[^a-zA-Z0-9\.\_\-]/g, "") + extension;

    return result;
  }

  return;
}

export function validateUrl(url) {
  try {
    const validUrl = new URL(url);

    return validUrl ? true : false;
  } catch (_) {
    return false;
  }
}

export function checkIfTestCard(cardNumber) {
  const testNumbers = ["0000000000000000"];

  if (["dev", "stage"].includes(config.envName)) {
    testNumbers.push("7000000000000001");
  }

  return testNumbers.includes(cardNumber);
}

export function getUUID() {
  return Math.floor(Math.random() * Date.now()).toString(16);
}

export function multiplyPriceToNumber(price, multiplier) {
  return (parseFloat(price) * multiplier).toFixed(2);
}
