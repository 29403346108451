import React from "react";
import styled from "styled-components";

import { hexToRGBA } from "_helpers/theme";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledIndicator = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #641ec9;
  color: #641ec9;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #641ec9;
    color: #641ec9;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #641ec9;
    color: #641ec9;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #641ec9;
    }
    50%,
    100% {
      background-color: ${hexToRGBA("#641EC9", 0.2)};
    }
  }
`;

const Loader = () => (
  <StyledContainer>
    <StyledIndicator />
  </StyledContainer>
);

export default Loader;
