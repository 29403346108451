import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusView from "../Status/View";

export default function FormTrademarkUSPTOFilingFee({ orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">USPTO FILING FEE PRODUCT TRADEMARK</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />
    </div>
  );
}
