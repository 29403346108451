import { Row, Col, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { trademarkUseStatus } from "constants/trademarks.constants";

import IndividualEdit from "../Individual/Edit";
import TrademarkClassesListEdit from "../TrademarkClassesList/EditAsync";
import NewAddressEdit from "../NewAddress/Edit";
import { initAddress } from "../IndividualAddress/Edit";

import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

export default function FormTrademarkRenewal({
  validated,
  orderDetails,
  selectedProduct,
  onError,
  onChange,
  onSelectedProduct,
  ...props
}) {
  const orderId = orderDetails.order.id;
  const productDetails = orderDetails.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    if (name === "newSpecimenWasUploaded") exFields = { [name]: value === "yes" };

    if (name === "ownerChanged") {
      if (value === "no") {
        if (productDetails.newFirstName || productDetails.newLastName) {
          exFields = { newFirstName: productDetails.newFirstName, newLastName: productDetails.newLastName };
        } else {
          exFields = { newFirstName: "", newLastName: "" };
        }
      } else {
        exFields = { newFirstName: null, newLastName: null };
      }
    }

    if (name === "addressChanged") {
      if (value === "no") {
        if (productDetails.newAddress) {
          exFields = { newAddress: { ...productDetails.newAddress } };
        } else {
          exFields = { newAddress: { ...initAddress } };
        }
      } else {
        exFields = { newAddress: null };
      }
    }

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handleChangeProductStillInUse = (value) =>
    handleProductChange({ target: { name: "goodsAndServicesStillInUse", value } });
  const handleChangeProductNoLongerInUse = (value) =>
    handleProductChange({ target: { name: "goodsAndServicesNoLongerInUse", value } });
  const handleProductTrademarkOwnerChange = (value) =>
    handleProductChange({ target: { name: "trademarkOwner", value } });
  const handleProductNewAddressChange = (value) => handleProductChange({ target: { name: "newAddress", value } });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">RENEWAL TRADEMARK</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
        onSelectedProduct={onSelectedProduct}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial Number:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="serialNumber"
            value={productDetails.serialNumber || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="trademarkName"
            value={productDetails.trademarkName || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          In use status:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            name="inUseStatus"
            value={productDetails.inUseStatus || ""}
            onChange={handleProductChange}
          >
            {Object.keys(trademarkUseStatus).map((key) => (
              <option key={key} value={key}>
                {trademarkUseStatus[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          New specimen was uploaded?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="newSpecimenWasUploaded_r_no"
            className="mr-3"
            type="radio"
            label="no"
            name="newSpecimenWasUploaded"
            value="no"
            defaultChecked={!productDetails.newSpecimenWasUploaded}
            onChange={handleProductChange}
          />
          <Form.Check
            id="newSpecimenWasUploaded_r_yes"
            type="radio"
            label="yes"
            name="newSpecimenWasUploaded"
            value="yes"
            defaultChecked={productDetails.newSpecimenWasUploaded}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Specimen description:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="specimenDescription"
            value={productDetails.specimenDescription || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />

      {/* Replace for readonly displaying */}
      {/* <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is the Owner the same?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.newFirstName || productDetails.newLastName ? "No" : "Yes"}
        </Form.Label>
      </Form.Group> */}
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is the Owner the same?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="ownerChanged_r_no"
            className="mr-3"
            type="radio"
            label="no"
            name="ownerChanged"
            value="no"
            checked={productDetails.newFirstName !== null || productDetails.newLastName !== null}
            onChange={handleProductChange}
          />
          <Form.Check
            id="ownerChanged_r_yes"
            type="radio"
            label="yes"
            name="ownerChanged"
            value="yes"
            checked={productDetails.newFirstName === null && productDetails.newLastName === null}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      {(productDetails.newFirstName !== null || productDetails.newLastName !== null) && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              <h6 className="font-weight-bold">New Owner</h6>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              New First Name:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="newFirstName"
                value={productDetails.newFirstName || ""}
                onChange={handleProductChange}
                onInvalid={() => handleAddError("New First Name")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              New Last Name:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="newLastName"
                value={productDetails.newLastName || ""}
                onChange={handleProductChange}
                onInvalid={() => handleAddError("New Last Name")}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <hr />

      {/* ## Replace for readonly displaying */}
      {/* <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is the Address the same?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.newAddress ? "No" : "Yes"}
        </Form.Label>
      </Form.Group> */}
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is the Address the same?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="addressChanged_r_no"
            className="mr-3"
            type="radio"
            label="no"
            name="addressChanged"
            value="no"
            checked={!!productDetails.newAddress}
            onChange={handleProductChange}
          />
          <Form.Check
            id="addressChanged_r_yes"
            type="radio"
            label="yes"
            name="addressChanged"
            value="yes"
            checked={!productDetails.newAddress}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      {productDetails.newAddress && (
        <NewAddressEdit
          withoutButton
          label="New Address"
          newAddress={productDetails.newAddress}
          onError={handleAddError}
          onChange={handleProductNewAddressChange}
        />
      )}
      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Website URL:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="websiteURL"
            value={productDetails.websiteURL || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />
      <IndividualEdit
        label="Trademark Owner"
        individual={productDetails.trademarkOwner}
        onError={handleAddError}
        onChange={handleProductTrademarkOwnerChange}
      />

      <TrademarkClassesListEdit
        id="goodsAndServicesStillInUse"
        validated={validated}
        label="Goods and Services still in use"
        classes={productDetails.goodsAndServicesStillInUse}
        onError={handleAddError}
        onChange={handleChangeProductStillInUse}
      />

      <TrademarkClassesListEdit
        id="goodsAndServicesNoLongerInUse"
        validated={validated}
        label="Goods and Services not longer in use"
        classes={productDetails.goodsAndServicesNoLongerInUse}
        onError={handleAddError}
        onChange={handleChangeProductNoLongerInUse}
      />
    </div>
  );
}
