import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { isMobile } from "react-device-detect";
import isEqual from "lodash.isequal";

import { usePrevious } from "_hooks";
import { chatsActions } from "_actions";

import ChannelListItem from "./ChannelListItem";
import Loader from "../../Loader";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  scrollbar-color: #dee2e6 #fff;
  border-bottom: 1px solid #edeefd;

  ${({ height }) =>
    height &&
    css`
      max-height: ${height};
      min-height: ${height};
    `}

  @media (max-width: 767px) {
    min-height: unset;
    min-width: unset;
    width: unset;
  }
`;

const StyledLoaderContainer = styled.div`
  padding-top: 8px;
`;

export default function ChannelListView({ channelListFiltersRef, currentChannel, onSelect }) {
  const infiniteLoaderContainerRef = useRef(null);
  const infiniteLoaderRef = useRef(null);
  const hasMountedRef = useRef(false);
  const dispatch = useDispatch();
  const { channels } = useSelector((state) => state.chats);
  const filters = channels?.filters || {};
  const prevFilters = usePrevious(filters);
  const loading = channels?.loading;
  const orders = channels?.orders || {};
  const data = channels?.data || {};
  const prevData = usePrevious(data);

  const isParamsChanged = useMemo(
    () => prevData?.userId !== data?.userId || !isEqual(prevData?.statuses, data?.statuses),
    [prevData, data]
  );

  const loadNextPage = () => {
    dispatch(chatsActions.refreshAllChannelsFilters({ page: filters.page + 1 }));
  };

  const itemCount = data?.totalPages > data?.page ? data?.elements?.length + 1 : data?.elements?.length;

  const loadMoreItems = loading ? () => {} : loadNextPage;

  const isItemLoaded = (index) => index < data.elements.length;

  useEffect(() => {
    if (!isEqual(prevFilters, filters) && filters?.userId && !loading) {
      dispatch(chatsActions.getAllChannels({ ...filters }));
    }
  }, [prevFilters, filters, loading]);

  useEffect(() => {
    if (hasMountedRef.current && isParamsChanged) {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache();
      }
    }
    hasMountedRef.current = true;
  }, [isParamsChanged]);

  if (loading && !channels?.data?.elements?.length) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  return (
    <StyledContainer
      ref={infiniteLoaderContainerRef}
      height={`calc(100vh - 67px - ${channelListFiltersRef?.current?.getBoundingClientRect().height}px)`}
    >
      <InfiniteLoader
        ref={infiniteLoaderRef}
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
      >
        {({ onItemsRendered, ref }) => (
          <List
            ref={(list) => {
              ref(list);
              infiniteLoaderRef.current = list;
            }}
            height={isMobile ? 260 : infiniteLoaderContainerRef.current?.getBoundingClientRect().height || 400}
            width="100%"
            itemCount={itemCount}
            itemSize={128}
            itemData={{
              orders,
              elements: data?.elements,
              currentChannel,
              onSelect,
              isItemLoaded,
            }}
            onItemsRendered={onItemsRendered}
          >
            {ChannelListItem}
          </List>
        )}
      </InfiniteLoader>
    </StyledContainer>
  );
}
