export const notificationsConstants = {
  SHOW_NOTIFICATIONS: "NOTIFICATIONS_SHOW_NOTIFICATIONS",
  HIDE_NOTIFICATIONS: "NOTIFICATIONS_HIDE_NOTIFICATIONS",

  REFRESH_CHANNELS_FILTERS: "NOTIFICATIONS_REFRESH_CHANNELS_FILTERS",
  GET_CHANNELS_REQUEST: "NOTIFICATIONS_GET_CHANNELS_REQUEST",
  GET_CHANNELS_SUCCESS: "NOTIFICATIONS_GET_CHANNELS_SUCCESS",
  GET_CHANNELS_FAILURE: "NOTIFICATIONS_GET_CHANNELS_FAILURE",

  REFRESH_CLOSED_CHANNELS_FILTERS: "NOTIFICATIONS_REFRESH_CLOSED_CHANNELS_FILTERS",
  GET_CLOSED_CHANNELS_REQUEST: "NOTIFICATIONS_GET_CLOSED_CHANNELS_REQUEST",
  GET_CLOSED_CHANNELS_SUCCESS: "NOTIFICATIONS_GET_CLOSED_CHANNELS_SUCCESS",
  GET_CLOSED_CHANNELS_FAILURE: "NOTIFICATIONS_GET_CLOSED_CHANNELS_FAILURE",

  GET_COUNT_OF_UNREAD_REQUEST: "NOTIFICATIONS_GET_COUNT_OF_UNREAD_REQUEST",
  GET_COUNT_OF_UNREAD_SUCCESS: "NOTIFICATIONS_GET_COUNT_OF_UNREAD_SUCCESS",
  GET_COUNT_OF_UNREAD_FAILURE: "NOTIFICATIONS_GET_COUNT_OF_UNREAD_FAILURE",

  CLOSE_CHANNEL_REQUEST: "NOTIFICATIONS_CLOSE_CHANNEL_REQUEST",
  CLOSE_CHANNEL_SUCCESS: "NOTIFICATIONS_CLOSE_CHANNEL_SUCCESS",
  CLOSE_CHANNEL_FAILURE: "NOTIFICATIONS_CLOSE_CHANNEL_FAILURE",

  JOIN_CHANNEL_REQUEST: "NOTIFICATIONS_JOIN_CHANNEL_REQUEST",
  JOIN_CHANNEL_SUCCESS: "NOTIFICATIONS_JOIN_CHANNEL_SUCCESS",
  JOIN_CHANNEL_FAILURE: "NOTIFICATIONS_JOIN_CHANNEL_FAILURE",

  LEAVE_CHANNEL_REQUEST: "NOTIFICATIONS_LEAVE_CHANNEL_REQUEST",
  LEAVE_CHANNEL_SUCCESS: "NOTIFICATIONS_LEAVE_CHANNEL_SUCCESS",
  LEAVE_CHANNEL_FAILURE: "NOTIFICATIONS_LEAVE_CHANNEL_FAILURE",
};

export const NOTIFICATION_STATUSES = {
  new: "New",
  active: "Active",
  pendingCustomerResponse: "PendingCustomerResponse",
  pendingAgentResponse: "PendingAgentResponse",
  idle: "Idle",
};
