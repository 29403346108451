import React from "react";
import { Form, Row } from "react-bootstrap";
import { getMainProductDetails } from "_helpers";
import StatusView from "../Status/View";
import Address from "./Address/View";

import { state_array } from "variables/Variables";
import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

function FormRegisteredAgent({ parentOrderDetails, orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const productDetails = orderDetails?.products[selectedProduct];
  const { mainProductDetails } = getMainProductDetails(orderDetails, parentOrderDetails);

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Registered Agent</h4>
      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />
      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {mainProductDetails?.owner?.ssn || productDetails?.ssn || "N/A"}
        </Form.Label>
      </Form.Group>
      {/* <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Webfile Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.webFileNumber || "—"}
        </Form.Label>
      </Form.Group> */}
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Company Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.processingResult?.companyName || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Email:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.processingResult?.email || "—"}
        </Form.Label>
      </Form.Group>
      <FormPDF productDetails={productDetails} />
      <hr />
      <h6 className="font-weight-bold mt-4">State</h6>
      {state_array[productDetails.state] || "—"}
      <hr />
      <h6 className="font-weight-bold mt-4">Billed (Annually)</h6>${parseFloat(productDetails?.price || "0").toFixed(2)}
      <hr />
      <h6 className="font-weight-bold mt-4">Registered Agent Address</h6>
      <Address address={productDetails.processingResult.raAddress} />
    </div>
  );
}

export default FormRegisteredAgent;
