import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";

import { getMainProductDetails } from "_helpers";
import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusEdit from "../../Status/Edit";

function FormAnnualReport({
  parentOrderDetails,
  orderDetails,
  selectedProduct,
  onParentChange,
  onChange,
  onSelectedProduct,
}) {
  const productDetails = orderDetails?.products[selectedProduct];
  const processingResult = productDetails?.processingResult;
  const { mainOrderDetails, mainProductIndex, mainProductDetails, isParent } = getMainProductDetails(
    orderDetails,
    parentOrderDetails
  );

  const handleProductChange = ({ target: { name, value } }) =>
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: value } : product
      ),
    });

  const handleMainProductChange = ({ target: { name, value } }) => {
    const exFields = {};

    if (name === "ssn") {
      exFields.owner = {
        ...mainProductDetails?.owner,
        ssn: value || null,
      };
    }

    const onEvent = isParent ? onParentChange : onChange;

    onEvent({
      ...mainOrderDetails,
      products: mainOrderDetails?.products.map((product, idx) =>
        idx === mainProductIndex ? { ...product, ...exFields } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Annual Report</h4>
      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onSelectedProduct={onSelectedProduct}
      />

      {productDetails.webFileNumber !== undefined && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Webfile Number:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              // required={productDetails?.state === "TX" && processingResult?.status !== "MissingWebFile"}
              size="sm"
              type="text"
              name="webFileNumber"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={productDetails?.webFileNumber || ""}
              onChange={handleProductChange}
            />
          </Col>
        </Form.Group>
      )}

      {mainProductDetails && (
        <>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Social Security Number:
            </Form.Label>
            <Col sm={7}>
              <InputMask
                className="form-control form-control-sm"
                mask="999-99-9999"
                maskChar=" "
                name="ssn"
                placeholder="999-99-9999"
                pattern="^\d{3}-\d{2}-\d{4}$"
                value={mainProductDetails?.owner?.ssn || ""}
                onChange={handleMainProductChange}
                onInvalid={() => handleAddError("Social Security Number")}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <FormPDF productDetails={productDetails} />
    </div>
  );
}

export default FormAnnualReport;
