import React, { useState } from "react";
import styled from "styled-components";

import ChannelList from "./ChannelList";
import Channel from "./Channel";

const StyledContainer = styled.div`
  display: flex;
  padding-top: 67px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export default function Chats() {
  const [currentChannel, setCurrentChannel] = useState(null);

  const handleChannelSelect = (channel) => {
    setCurrentChannel({ ...channel });
  };

  return (
    <StyledContainer>
      <ChannelList currentChannel={currentChannel} onSelect={handleChannelSelect} />
      <Channel channel={currentChannel} />
    </StyledContainer>
  );
}
