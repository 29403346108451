import { notificationsConstants } from "_constants";
import { handleError } from "_helpers";
import { customerService, sendbirdService } from "_services";
import { alertActions, customerActions } from "./";

const sleep = async (ms) => new Promise((r) => setTimeout(r, ms));

export const notificationsActions = {
  showNotifications,
  hideNotifications,
  refreshChannelsFilters,
  getChannels,
  refreshClosedChannelsFilters,
  getClosedChannels,
  getCountOfUnread,
  pollCountOfUnread,
  closeChannel,
  joinChannel,
  leaveChannel,
};

function showNotifications() {
  return { type: notificationsConstants.SHOW_NOTIFICATIONS };
}

function hideNotifications() {
  return { type: notificationsConstants.HIDE_NOTIFICATIONS };
}

function refreshChannelsFilters(payload) {
  return { type: notificationsConstants.REFRESH_CHANNELS_FILTERS, payload };
}

function getChannels({ userId, orderUid, statuses }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const data = await sendbirdService.getChannels({ userId, orderUid, statuses });

      const rawOrderIds = Object.keys(data)
        ?.reduce((res, key) => {
          return [...res, ...data[key]];
        }, [])
        .map(({ orderId }) => orderId);

      const orderIds = Array.from(new Set(rawOrderIds));

      const rawOrders = await Promise.all(orderIds.map((orderId) => customerService.getOrder(orderId)));

      const orders = rawOrders?.reduce((result, item) => {
        result[item?.order?.id] = item;
        return result;
      }, {});

      dispatch(success({ data, orders }));
    } catch (error) {
      dispatch(failure(error));
      dispatch(
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Loading channels error"
        )
      );
    }
  };

  function request() {
    return { type: notificationsConstants.GET_CHANNELS_REQUEST };
  }
  function success(payload) {
    return { type: notificationsConstants.GET_CHANNELS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: notificationsConstants.GET_CHANNELS_FAILURE, payload };
  }
}

function refreshClosedChannelsFilters(payload) {
  return { type: notificationsConstants.REFRESH_CLOSED_CHANNELS_FILTERS, payload };
}

function getClosedChannels({ userId, orderUid, page, count }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const data = await sendbirdService.getClosedChannels({ userId, orderUid, page, count });

      const orderIds = data?.elements?.map(({ orderId }) => orderId);

      const rawOrders = await Promise.all(orderIds.map((orderId) => customerService.getOrder(orderId)));

      const orders = rawOrders?.reduce((result, item) => {
        result[item?.order?.id] = item;
        return result;
      }, {});

      dispatch(success({ data, orders }));
    } catch (error) {
      dispatch(failure(error));
      dispatch(
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Loading closed channels error"
        )
      );
    }
  };

  function request() {
    return { type: notificationsConstants.GET_CLOSED_CHANNELS_REQUEST };
  }
  function success(payload) {
    return { type: notificationsConstants.GET_CLOSED_CHANNELS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: notificationsConstants.GET_CLOSED_CHANNELS_FAILURE, payload };
  }
}

function getCountOfUnread({ userId }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const data = await sendbirdService.getCountOfUnread({ userId });
      dispatch(success(data));
    } catch (error) {
      dispatch(failure());
      dispatch(
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Loading notifications error"
        )
      );
    }
  };

  function request() {
    return { type: notificationsConstants.GET_COUNT_OF_UNREAD_REQUEST };
  }
  function success(payload) {
    return { type: notificationsConstants.GET_COUNT_OF_UNREAD_SUCCESS, payload };
  }
  function failure() {
    return { type: notificationsConstants.GET_COUNT_OF_UNREAD_FAILURE };
  }
}

function pollCountOfUnread({ userId }) {
  return async (dispatch) => {
    while (true) {
      dispatch(getCountOfUnread({ userId }));
      await sleep(30 * 60 * 1000);
    }
  };
}

function closeChannel({ userId, orderId, productId, cb }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      await sendbirdService.closeChannel({ orderId, productId });
      dispatch(success());
      dispatch(alertActions.success("Channel was closed successfully"));
      dispatch(getClosedChannels({ userId, orderUid: orderId, page: 1, count: 1 }));

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(failure());
      dispatch(
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Channel closing failed"
        )
      );
    }
  };

  function request() {
    return { type: notificationsConstants.CLOSE_CHANNEL_REQUEST };
  }
  function success() {
    return { type: notificationsConstants.CLOSE_CHANNEL_SUCCESS };
  }
  function failure() {
    return { type: notificationsConstants.CLOSE_CHANNEL_FAILURE };
  }
}

function joinChannel({ userId, orderId, channelUrl }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      await sendbirdService.joinChannel({ userId, channelUrl });
      dispatch(success());
      dispatch(alertActions.success("Joined channel successfully"));
      dispatch(getChannels({ userId, orderUid: orderId }));
      dispatch(customerActions.getOrder(orderId));
    } catch (error) {
      dispatch(failure());
      dispatch(
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Joining channel failed"
        )
      );
    }
  };

  function request() {
    return { type: notificationsConstants.JOIN_CHANNEL_REQUEST };
  }
  function success() {
    return { type: notificationsConstants.JOIN_CHANNEL_SUCCESS };
  }
  function failure() {
    return { type: notificationsConstants.JOIN_CHANNEL_FAILURE };
  }
}

function leaveChannel({ userId, orderId, channelUrl, cb }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      await sendbirdService.leaveChannel({ userId, channelUrl });
      dispatch(success());
      dispatch(alertActions.success("Left channel successfully"));
      dispatch(getChannels({ userId, orderUid: orderId }));
      dispatch(customerActions.getOrder(orderId));

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(failure());
      dispatch(
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Leaving channel failed"
        )
      );
    }
  };

  function request() {
    return { type: notificationsConstants.LEAVE_CHANNEL_REQUEST };
  }
  function success() {
    return { type: notificationsConstants.LEAVE_CHANNEL_SUCCESS };
  }
  function failure() {
    return { type: notificationsConstants.LEAVE_CHANNEL_FAILURE };
  }
}
