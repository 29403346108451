import { useRef, useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

import { PRODUCT_TASK_STATUS, PRODUCT_TASK_TYPE } from "variables/Variables";

const initialDetails = {
  type: PRODUCT_TASK_TYPE.textRequest,
  status: PRODUCT_TASK_STATUS.new,
  subject: "",
  message: "",
};

export default function TaskDetailsModal({ show, initialData, handleCancel = () => {}, handleConfirm = () => {} }) {
  const formEl = useRef(null);

  const [details, setDetails] = useState(initialDetails);
  const [validated, setValidated] = useState(false);

  const handleChange = ({ target: { value, name } }) => {
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    const form = formEl.current;
    setValidated(true);

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      handleConfirm({ ...details });
      setDetails(initialDetails);
      setValidated(false);
    }
  };

  const handleClose = () => {
    setDetails(initialDetails);
    handleCancel();
  };

  useEffect(() => {
    if (initialData) {
      setDetails((prevDetails) => ({ ...prevDetails, ...initialData }));
    }
  }, [initialData]);

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <b>Task Details</b>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} noValidate ref={formEl}>
          <Row>
            <Form.Group as={Col} sm={12}>
              <Form.Label>TYPE:</Form.Label>
              <Form.Control as="select" name="type" value={details?.type || ""} onChange={handleChange}>
                {Object.values(PRODUCT_TASK_TYPE).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please, select Type</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={12}>
              <Form.Label>STATUS:</Form.Label>
              <Form.Control as="select" name="status" value={details?.status || ""} onChange={handleChange}>
                {Object.values(PRODUCT_TASK_STATUS).map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please, select Status</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={12}>
              <Form.Label>SUBJECT:</Form.Label>
              <Form.Control
                required
                name="subject"
                placeholder="Enter subject"
                value={details?.subject || ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">Please, provide Subject</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={12}>
              <Form.Label>MESSAGE:</Form.Label>
              <Form.Control
                required
                name="message"
                placeholder="Enter message"
                value={details?.message || ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">Please, provide Message</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
