import StatusView from "../Status/View";
import { Form, Row } from "react-bootstrap";

import { getMainProductDetails } from "_helpers";
import Address from "components/OrderDetails/ProductDetails/Incorporation/Address/View";

export default function FormIncorpifyBusinessAddressAmendment({
  parentOrderDetails,
  orderDetails,
  selectedProduct,
  onUpdateOrderStatus,
  onChange,
}) {
  const productDetails = orderDetails?.products[selectedProduct];
  const { mainProductDetails } = getMainProductDetails(orderDetails, parentOrderDetails);

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {mainProductDetails?.owner?.ssn || "N/A"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold mb-4">Business Address Amendment Details</h6>

      <h6 className="font-weight-bold">New Address</h6>
      <Address address={productDetails?.newAddress} />
    </div>
  );
}
