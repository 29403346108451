import { Form, Row } from "react-bootstrap";

import { getMainProductDetails } from "_helpers";
import StatusView from "../Status/View";

export default function FormIncorpifyBusinessNameAmendment({
  parentOrderDetails,
  orderDetails,
  selectedProduct,
  onUpdateOrderStatus,
  onChange,
}) {
  const productDetails = orderDetails?.products[selectedProduct];
  const { mainProductDetails } = getMainProductDetails(orderDetails, parentOrderDetails);

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {mainProductDetails?.owner?.ssn || "N/A"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold mb-4">Business Name Amendment Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          New Business Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.newCompanyName || "–"}
        </Form.Label>
      </Form.Group>
    </div>
  );
}
