import { GroupChannelProvider } from "@sendbird/uikit-react/GroupChannel/context";
import React from "react";

import CustomGroupChannelView from "./CustomGroupChannelView";

const CustomGroupChannel = ({ productDetails, currentChannelUrl, currentMessage, setSearchVisible }) => {
  return (
    <GroupChannelProvider channelUrl={currentChannelUrl || productDetails?.messageChannelUrl}>
      <CustomGroupChannelView
        productDetails={productDetails}
        currentMessage={currentMessage}
        setSearchVisible={setSearchVisible}
      />
    </GroupChannelProvider>
  );
};

export default CustomGroupChannel;
