export const productTasksConstants = {
  GET_PRODUCT_TASKS_REQUEST: "GET_PRODUCT_TASKS_REQUEST",
  GET_PRODUCT_TASKS_SUCCESS: "GET_PRODUCT_TASKS_SUCCESS",
  GET_PRODUCT_TASKS_FAILURE: "GET_PRODUCT_TASKS_FAILURE",

  CREATE_PRODUCT_TASK_REQUEST: "CREATE_PRODUCT_TASK_REQUEST",
  CREATE_PRODUCT_TASK_SUCCESS: "CREATE_PRODUCT_TASK_SUCCESS",
  CREATE_PRODUCT_TASK_FAILURE: "CREATE_PRODUCT_TASK_FAILURE",

  UPDATE_PRODUCT_TASK_REQUEST: "UPDATE_PRODUCT_TASK_REQUEST",
  UPDATE_PRODUCT_TASK_SUCCESS: "UPDATE_PRODUCT_TASK_SUCCESS",
  UPDATE_PRODUCT_TASK_FAILURE: "UPDATE_PRODUCT_TASK_FAILURE",

  DELETE_PRODUCT_TASK_REQUEST: "DELETE_PRODUCT_TASK_REQUEST",
  DELETE_PRODUCT_TASK_SUCCESS: "DELETE_PRODUCT_TASK_SUCCESS",
  DELETE_PRODUCT_TASK_FAILURE: "DELETE_PRODUCT_TASK_FAILURE",

  REFRESH_PRODUCT_TASKS_FILTERS: "REFRESH_PRODUCT_TASKS_FILTERS",
};
