import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupChannelHandler from "@sendbird/uikit-react/handlers/GroupChannelHandler";
import { sendbirdSelectors, useSendbirdStateContext } from "@sendbird/uikit-react";

import { config } from "_config";
import { chatsActions, notificationsActions } from "_actions";

export default function SendbirdSubscription() {
  const dispatch = useDispatch();
  const { channels } = useSelector((state) => state.chats);
  const context = useSendbirdStateContext();
  const sdk = sendbirdSelectors.getSdk(context);

  useEffect(() => {
    if (sdk && sdk.groupChannel?.addGroupChannelHandler && channels?.data?.elements?.length) {
      const channelHandler = new GroupChannelHandler();

      channelHandler.onChannelChanged = (changedChannel) => {
        const channel = channels?.data?.elements?.find(({ channelUrl }) => channelUrl === changedChannel?.url);

        if (channel) {
          dispatch(notificationsActions.getCountOfUnread({ userId: channel.params?.userId }));
          dispatch(chatsActions.refetchChannel({ url: channel.channelUrl, userIdForStatuses: channel.params?.userId }));
        }
      };

      sdk.groupChannel.addGroupChannelHandler(config.sendbirdAppId, channelHandler);
    }

    return () => {
      if (sdk && sdk.groupChannel?.removeGroupChannelHandler) {
        sdk.groupChannel.removeGroupChannelHandler(config.sendbirdAppId);
      }
    };
  }, [sdk, channels]);

  return null;
}
