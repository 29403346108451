import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import IndividualView from "./Individual/View";
import NewAddressView from "./NewAddress/View";

import StatusView from "../Status/View";

export default function FormTrademarkExtension({ orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">EXTENSION TRADEMARK</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Extension Reason</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Extension Reason:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.extensionReason || "N/A"}
        </Form.Label>
      </Form.Group>

      {productDetails.extensionReason === "Other" && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Other Extenstion Reason:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.extensionReasonOther || "N/A"}
          </Form.Label>
        </Form.Group>
      )}

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.serialNumber}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.trademarkName}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Trademark Owner</h6>
      <IndividualView individual={productDetails.trademarkOwner} />

      <hr />
      <h6 className="font-weight-bold">New Address</h6>
      <NewAddressView newAddress={productDetails.newAddress} />

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Changes to drawing:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.changesToDrawing}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Changes to Owner</h6>
      <IndividualView individual={productDetails.changesToOwner} />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Missed goods or services:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.missedGoodsOrServices}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Times deadline was extanded:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.timesDeadlineWasExtended}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Owner</h6>
      <IndividualView individual={productDetails.owner} />
    </div>
  );
}
