import StatusView from "../Status/View";

export default function FormTrademarkKeepAlive({ orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">KEEP TRADEMARK ALIVE</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />
    </div>
  );
}
