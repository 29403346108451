import React from "react";
import Select from "react-select";
import chroma from "chroma-js";

import { CHANNEL_STATUSES, CHANNEL_STATUS_TITLES, CHANNEL_STATUS_COLORS } from "_constants";

export const statusOptions = [
  {
    value: CHANNEL_STATUSES.new,
    label: CHANNEL_STATUS_TITLES[CHANNEL_STATUSES.new],
    color: CHANNEL_STATUS_COLORS[CHANNEL_STATUSES.new],
  },
  {
    value: CHANNEL_STATUSES.active,
    label: CHANNEL_STATUS_TITLES[CHANNEL_STATUSES.active],
    color: CHANNEL_STATUS_COLORS[CHANNEL_STATUSES.active],
  },
  {
    value: CHANNEL_STATUSES.idle,
    label: CHANNEL_STATUS_TITLES[CHANNEL_STATUSES.idle],
    color: CHANNEL_STATUS_COLORS[CHANNEL_STATUSES.idle],
  },
  {
    value: CHANNEL_STATUSES.pendingAgentResponse,
    label: CHANNEL_STATUS_TITLES[CHANNEL_STATUSES.pendingAgentResponse],
    color: CHANNEL_STATUS_COLORS[CHANNEL_STATUSES.pendingAgentResponse],
  },
  {
    value: CHANNEL_STATUSES.pendingCustomerResponse,
    label: CHANNEL_STATUS_TITLES[CHANNEL_STATUSES.pendingCustomerResponse],
    color: CHANNEL_STATUS_COLORS[CHANNEL_STATUSES.pendingCustomerResponse],
  },
  {
    value: CHANNEL_STATUSES.closed,
    label: CHANNEL_STATUS_TITLES[CHANNEL_STATUSES.closed],
    color: CHANNEL_STATUS_COLORS[CHANNEL_STATUSES.closed],
  },
];

const getActiveOptions = ({ activeStatuses = [] }) => {
  return statusOptions.filter(({ value }) => activeStatuses.includes(value));
};

const colorStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled ? "#ccc" : isSelected ? (chroma.contrast(color, "white") > 2 ? "white" : "black") : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled ? (isSelected ? data.color : color.alpha(0.3).css()) : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
};

export default function StatusFilter({ activeStatuses = [], onSelect }) {
  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      styles={colorStyles}
      placeholder="Select statuses..."
      options={statusOptions}
      value={getActiveOptions({ activeStatuses })}
      onChange={onSelect}
    />
  );
}
