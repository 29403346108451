import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FixedSizeList as List } from "react-window";
import Avatar from "@sendbird/uikit-react/ui/Avatar";

import { parseChannelData } from "views/Chats/helpers";
import monexaLogoSrc from "assets/img/monexa-logo.png";

const StyledContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  scrollbar-color: #dee2e6 #fff;

  @media (max-width: 767px) {
  }
`;

const StyledListToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
`;

const StyledBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #e4e5e5;
  color: #606071;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
`;

const StyledToggleLabel = styled.div``;

const StyledLeftIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 48px;
`;

const StyledRightIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 48px;

  svg {
    transform: rotate(0deg);
    transition: all 0.2s ease-out;
  }

  ${({ show }) =>
    show &&
    css`
      svg {
        transform: rotate(90deg);
        transition: all 0.2s ease-out;
      }
    `}
`;

const StyledListBody = styled.div``;

const StyledItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  padding-right: 8px;
`;

const StyledAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;

  border: 1px solid #dee3e6;
  border-radius: 50%;
  background-color: #fff;

  .sendbird-avatar {
    .sendbird-avatar-img--default {
      background-color: #fff;

      svg {
        path {
          fill: #641ec9;
        }
      }
    }
  }
`;

const StyledMemberName = styled.div`
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
`;

const LeftIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z"
      stroke="#641ec9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11"
      stroke="#641ec9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.15997 14.56C1.73997 16.18 1.73997 18.82 4.15997 20.43C6.90997 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.91997 12.73 4.15997 14.56Z"
      stroke="#641ec9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3401 20C19.0601 19.85 19.7401 19.56 20.3001 19.13C21.8601 17.96 21.8601 16.03 20.3001 14.86C19.7501 14.44 19.0801 14.16 18.3701 14"
      stroke="#641ec9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const RightIcon = () => (
  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.43379 8.17219C8.82432 7.78167 8.82432 7.14851 8.43379 6.75798L2.06983 0.39402C1.67931 0.00349569 1.04614 0.00349569 0.655617 0.39402C0.265092 0.784544 0.265092 1.41771 0.655617 1.80823L6.31247 7.46509L0.655617 13.1219C0.265092 13.5125 0.265092 14.1456 0.655617 14.5362C1.04614 14.9267 1.67931 14.9267 2.06983 14.5362L8.43379 8.17219ZM6.72668 8.46509H7.72668V6.46509H6.72668V8.46509Z"
      fill="black"
    />
  </svg>
);

function ChannelListItem({ index, style, data }) {
  const { channel } = data || {};
  const { customerId } = parseChannelData(channel?.data) || {};
  const { userId, plainProfileUrl, nickname } = channel?.members[index] || {};

  return (
    <StyledItemContainer style={{ ...style }}>
      <StyledAvatarContainer>
        {userId !== customerId && <img src={monexaLogoSrc} alt="Monexa Logo" />}
        {userId === customerId && <Avatar src={plainProfileUrl} alt="Avatar" width={47} height={47} />}
      </StyledAvatarContainer>
      <StyledMemberName>{nickname}</StyledMemberName>
    </StyledItemContainer>
  );
}

export default function MembersInfo({ channel }) {
  const [show, setShow] = useState(false);

  const handleListToggle = () => {
    setShow((prev) => !prev);
  };

  return (
    <StyledContainer>
      <StyledListToggle onClick={handleListToggle}>
        <StyledLeftIconContainer>
          <LeftIcon />
        </StyledLeftIconContainer>
        <StyledToggleLabel>Members</StyledToggleLabel>
        <StyledBadge>{channel?.memberCount}</StyledBadge>
        <StyledRightIconContainer show={show}>
          <RightIcon />
        </StyledRightIconContainer>
      </StyledListToggle>
      <StyledListBody>
        {show && (
          <List
            height={channel?.memberCount > 3 ? 192 : channel?.memberCount * 64}
            width="100%"
            itemCount={channel?.memberCount}
            itemSize={64}
            itemData={{ channel }}
          >
            {ChannelListItem}
          </List>
        )}
      </StyledListBody>
    </StyledContainer>
  );
}
