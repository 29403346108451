import { Col, Form, Row } from "react-bootstrap";

import StatusEdit from "../../Status/Edit";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { TAB_CODES, LETTERS_TYPE_OPTIONS, LOGO_CATEGORY_OPTIONS, LOGO_USE_OPTIONS } from "variables/Variables";

export default function FormTrademarkLogoDesignService({
  orderDetails,
  selectedProduct,
  onChange,
  onError,
  onSelectedProduct,
  ...props
}) {
  const productDetails = orderDetails.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    const exFields = { [name]: value };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">LOGO DESIGN SERVICE</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
        onSelectedProduct={onSelectedProduct}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <h6 className="font-weight-bold">Business Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Exact Logo Wording/Business Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            name="logoName"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            onChange={handleProductChange}
            value={productDetails?.logoName || ""}
            onInvalid={() => handleAddError("Exact Logo Wording")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Tagline/Slogan <span style={{ color: "gray" }}>(optional)</span>:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            name="slogan"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            onChange={handleProductChange}
            value={productDetails?.slogan || ""}
            onInvalid={() => handleAddError("Tagline/Slogan")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Business Industry:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            name="businessIndustry"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            onChange={handleProductChange}
            value={productDetails?.businessIndustry || ""}
            onInvalid={() => handleAddError("Business Industry")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Business Description:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            name="businessDescription"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            onChange={handleProductChange}
            value={productDetails?.businessDescription || ""}
            onInvalid={() => handleAddError("Business Description")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Business Products/Services:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            name="businessProducts"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            onChange={handleProductChange}
            value={productDetails?.businessProducts || ""}
            onInvalid={() => handleAddError("Business Products/Services")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Target Audience for Customers:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            name="targetAudience"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            onChange={handleProductChange}
            value={productDetails?.targetAudience || ""}
            onInvalid={() => handleAddError("Target Audience")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Business Website <span style={{ color: "gray" }}>(optional)</span>:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            name="businessWebsite"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            onChange={handleProductChange}
            value={productDetails?.businessWebsite || ""}
            onInvalid={() => handleAddError("Business Website")}
          />
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Logo Design</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Letters Type:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            as="select"
            name="lettersType"
            onChange={handleProductChange}
            value={productDetails?.lettersType || ""}
            onInvalid={() => handleAddError("Letters Type")}
          >
            <option value="">Select a type</option>
            {LETTERS_TYPE_OPTIONS.map(({ value, label }) => (
              <option key={`letter-type-${value}`} value={value}>
                {label}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Customer's Conception:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            name="customersConception"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            onChange={handleProductChange}
            value={productDetails?.customersConception || ""}
            onInvalid={() => handleAddError("Customer's Conception")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Logo Category:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            as="select"
            name="logoCategory"
            onChange={handleProductChange}
            value={productDetails?.logoCategory || ""}
            onInvalid={() => handleAddError("Logo Category")}
          >
            <option value="">Select a category</option>
            {LOGO_CATEGORY_OPTIONS.map(({ value, label }) => (
              <option key={`logo-category-${value}`} value={value}>
                {label}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <br />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Where the logo will be used:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            as="select"
            name="logoUse"
            onChange={handleProductChange}
            value={productDetails?.logoUse || ""}
            onInvalid={() => handleAddError("Logo Use")}
          >
            <option value="">Select a category</option>
            {LOGO_USE_OPTIONS.map(({ value, label }) => (
              <option key={`logo-use-${value}`} value={value}>
                {label}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      {productDetails?.logoUse === "OTHER" && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Customer's description of use:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              required
              name="logoUseDescription"
              // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              onChange={handleProductChange}
              value={productDetails?.logoUseDescription || ""}
              onInvalid={() => handleAddError("Customer's description of use")}
            />
          </Col>
        </Form.Group>
      )}
    </div>
  );
}
