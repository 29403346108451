import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

import FormContainer from "components/OrderDetails/CreateOrder/FormComponents/FormContainer";
import Individual from "components/OrderDetails/ProductDetails/TrademarkForms/Individual/Edit";

const initProduct = {
  trademarkName: "",
  owner: null,
};

const ExtraFieldsContainer = styled.div`
  background-color: #ededed;
  border-left: 2px solid lightgray;
  margin-bottom: 1rem;
  padding: 12px;
`;

const TrademarkMonitoring = (props) => {
  const { orderDetails, productDetails, validated, onChange, onError } = props;

  const handleProductChange = ({ target: { name, value, values } }) => {
    const newObj = { ...productDetails };

    let exFields = { [name]: value };

    onChange({
      ...newObj,
      ...exFields,
    });
  };

  const handleProductOwnerChange = (value) => {
    handleProductChange({ target: { name: "owner", value } });
  };

  function handleAddError(error) {
    onError(error);
  }

  return (
    <FormContainer>
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group>
        <Form.Label>Trademark Name:</Form.Label>
        <Form.Control
          required
          size="sm"
          type="text"
          name="trademarkName"
          value={productDetails.trademarkName || ""}
          onChange={handleProductChange}
          onInvalid={() => handleAddError("Trademark Name")}
        />
      </Form.Group>

      <ExtraFieldsContainer>
        <Individual
          label="Owner Information"
          individual={productDetails?.owner}
          onError={handleAddError}
          onChange={handleProductOwnerChange}
        />
      </ExtraFieldsContainer>
    </FormContainer>
  );
};

export default TrademarkMonitoring;
