import { useRef, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import moment from "moment";

const initDetails = {
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
  description: "",
  year: "",
};

const getYearOptions = () => {
  const startYear = new Date().getFullYear();

  return Array.from(new Array(30), (_, i) => {
    return { value: `${startYear - i}`, label: `${startYear - i}` };
  });
};

const checkStartDate = (startDate, endDate) => {
  const parsedStartDate = moment(startDate, "YYYY-MM-DD");
  const parsedEndDate = moment(endDate, "YYYY-MM-DD");

  const startDateMsValue = parsedStartDate.valueOf();
  const endDateMsValue = parsedEndDate.valueOf();

  if (startDateMsValue > endDateMsValue) {
    return true;
  }

  return false;
};

const checkEndDate = (startDate, endDate) => {
  const parsedStartDate = moment(startDate, "YYYY-MM-DD");
  const parsedEndDate = moment(endDate, "YYYY-MM-DD");

  const startDateMsValue = parsedStartDate.valueOf();
  const endDateMsValue = parsedEndDate.valueOf();

  if (startDateMsValue > endDateMsValue) {
    return true;
  }

  return false;
};

export default function DocumentDetailsModal({ show, handleCancel = () => {}, handleConfirm = () => {} }) {
  const formEl = useRef(null);

  const [details, setDetails] = useState(initDetails);
  const [validated, setValidated] = useState(false);

  const isStartDateInvalid = checkStartDate(details?.startDate, details?.endDate);
  const isEndDateInvalid = checkEndDate(details?.startDate, details?.endDate);

  const handleChange = ({ target: { value, name } }) => {
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    const form = formEl.current;
    setValidated(true);

    if (form.checkValidity() === false || isStartDateInvalid || isEndDateInvalid) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      handleConfirm({ ...details });
      setDetails(initDetails);
      setValidated(false);
    }
  };

  const handleClose = () => {
    setDetails(initDetails);
    handleCancel();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <b>Document Details</b>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} noValidate ref={formEl}>
          <Row>
            <Form.Group as={Col} sm={12} md={6}>
              <Form.Label>START DATE:</Form.Label>
              <Form.Control
                required
                type="date"
                name="startDate"
                value={details?.startDate || ""}
                isInvalid={isStartDateInvalid}
                onChange={handleChange}
              />
              {isStartDateInvalid && (
                <Form.Control.Feedback type="invalid">Start Date should be before End Date</Form.Control.Feedback>
              )}
              {!isStartDateInvalid && (
                <Form.Control.Feedback type="invalid">Please, select Start Date</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} sm={12} md={6}>
              <Form.Label>END DATE:</Form.Label>
              <Form.Control
                required
                type="date"
                name="endDate"
                value={details?.endDate || ""}
                isInvalid={isEndDateInvalid}
                onChange={handleChange}
              />
              {isEndDateInvalid && (
                <Form.Control.Feedback type="invalid">End Date should be after Start Date</Form.Control.Feedback>
              )}
              {!isEndDateInvalid && (
                <Form.Control.Feedback type="invalid">Please, select End Date</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} sm={12}>
              <Form.Label>DESCRIPTION:</Form.Label>
              <Form.Control
                required
                name="description"
                placeholder="Provide Description"
                value={details?.description || ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">Please, provide Description</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={12}>
              <Form.Label>Tax Year</Form.Label>
              <Form.Control
                required
                as="select"
                name="year"
                placeholder="Year"
                value={details?.year || ""}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                {getYearOptions().map(({ label, value }) => (
                  <option key={`year-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Required Year</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
