import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { alertActions } from "_actions";

import useSidebarLogic from "./hooks/useSidebarLogic.hook";
import useClickOutside from "./hooks/useClickOutside.hook";

import SidebarHeader from "./SidebarHeader";
import NavigationList from "./NavigationList";
import SummaryOrders from "./SummaryOrders";

export const Sidebar = (props) => {
  const dispatch = useDispatch();

  const { routes, image, color, hasImage, handleNavBar, isOpen, handleClick } = props;

  const { authentication, alert: alertProp } = useSelector((state) => state);

  const sidebarRef = useRef(null);
  const { expandedRoutes, expandRoute } = useSidebarLogic(routes, authentication);

  useClickOutside(
    sidebarRef,
    () => {
      if (isOpen) handleNavBar(false);
    },
    [isOpen]
  );

  useEffect(() => {
    if (alertProp.message) {
      handleClick("tr", alertProp.type, alertProp.message);
      dispatch(alertActions.clear());
    }
  }, [dispatch, alertProp, handleClick]);

  return (
    <div ref={sidebarRef} id="sidebar" className="sidebar" data-color={color} data-image={image}>
      <SidebarHeader image={image} hasImage={hasImage} />

      <div className="sidebar-wrapper">
        <NavigationList routes={routes} expandedRoutes={expandedRoutes} onExpandRoute={expandRoute} />
        <hr />
        <SummaryOrders />
      </div>
    </div>
  );
};
