import { userConstants } from "_constants";
import { userService } from "_services";
import { history, getRedirectLocation } from "_helpers";

import { alertActions } from "./";
import { config } from "_config";

export const userActions = {
  login,
  logout,
  saveuser,
  updateuser,
  getAll,
  getById,
  delete: _delete,
  getme,
  updateprofile,
  updateUserDetails,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    const url2FA = `${window.location.origin}${config.publicUrl}/2fa`;

    userService.login(username, password, url2FA).then(
      (user) => {
        const { requires2FA } = user;
        const { duoAuthURL, ...saveuser } = user;

        localStorage.setItem("user", JSON.stringify(saveuser));

        if (requires2FA) {
          window.location.href = duoAuthURL;
          return;
        }

        userService.getme(user).then((userdetail) => {
          const redirectLocation = getRedirectLocation(userdetail);

          dispatch(success(userdetail));
          history.push(redirectLocation);
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function getme() {
  return (dispatch) => {
    userService.getme().then(
      (user) => {
        dispatch(success(user));
      },
      () => {}
    );
  };

  function success(user) {
    return { type: userConstants.GETME_SUCCESS, user };
  }
}

function updateUserDetails() {
  return (dispatch) => {
    try {
      const storedUserJSON = localStorage.getItem("user");
      const storedUser = JSON.parse(storedUserJSON);
      userService.getme(storedUser).then(
        (user) => {
          dispatch(refreshUserDetails(user));
        },
        () => {}
      );
    } catch (e) {}
  };

  function refreshUserDetails(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
}

function logout(onlyRemoveUser) {
  if (onlyRemoveUser) {
    localStorage.removeItem("user");
    localStorage.removeItem("zauth");
  } else {
    userService.logout();
  }

  return { type: userConstants.LOGOUT };
}

function saveuser({ user, filters }) {
  const { pagecount, searchByName, searchByRole, includeDisabled, onlyAccountManager } = filters;

  return (dispatch) => {
    dispatch(request());

    userService.saveuser(user).then(
      (userdata) => {
        dispatch(success());
        dispatch(alertActions.success("Registration successful"));
        dispatch(
          userActions.getAll({
            page: 1,
            count: pagecount,
            search: searchByName,
            role: searchByRole,
            accountManager: onlyAccountManager,
            includeDisabled: includeDisabled,
          })
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }

  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, payload: error };
  }
}

function getAll(params) {
  return (dispatch) => {
    dispatch(request());

    userService.getAll(params).then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }

  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, payload: users };
  }

  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, payload: error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request());

    userService.getById(id).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }
  function success(payload) {
    return { type: userConstants.GET_USER_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: userConstants.GET_USER_FAILURE, payload };
  }
}

function _delete({ user, filters }) {
  const { pagecount, searchByName, searchByRole, includeDisabled, onlyAccountManager } = filters;

  return (dispatch) => {
    dispatch(request());

    userService.delete(user).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success("Deleted successful"));
        dispatch(
          userActions.getAll({
            page: 1,
            count: pagecount,
            search: searchByName,
            role: searchByRole,
            accountManager: onlyAccountManager,
            includeDisabled: includeDisabled,
          })
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.DELETE_USER_REQUEST };
  }

  function success() {
    return { type: userConstants.DELETE_USER_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.DELETE_USER_FAILURE, payload: error };
  }
}

function updateuser({ user, filters }) {
  const { pagecount, searchByName, searchByRole, includeDisabled, onlyAccountManager } = filters;

  return (dispatch) => {
    dispatch(request());

    userService.update(user).then(
      (userdata) => {
        dispatch(success(userdata));
        dispatch(alertActions.success("Updated successful"));
        dispatch(
          userActions.getAll({
            page: 1,
            count: pagecount,
            search: searchByName,
            role: searchByRole,
            accountManager: onlyAccountManager,
            includeDisabled: includeDisabled,
          })
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UPDATE_USER_REQUEST };
  }
  function success() {
    return { type: userConstants.UPDATE_USER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, payload: error };
  }
}

function updateprofile(user) {
  return (dispatch) => {
    userService.update(user).then(
      (user) => {
        let olduser = JSON.parse(localStorage.getItem("user"));
        olduser = {
          ...olduser,
          username: user.username,
        };
        localStorage.setItem("user", JSON.stringify(olduser));
        dispatch(success(olduser));
        dispatch(alertActions.success("Profile updated"));
      },
      (error) => {
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function success(user) {
    return { type: userConstants.PROFILE_UPDATED, user };
  }
}
