import { createBrowserHistory } from "history";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const categoriesArr = ["ein", "ein_inc", "inc", "inc_states", "vital", "trademarks", "accounting", "passport"];

export function getAvailableCategory({ availableCategories }) {
  let resultCategory = "";

  availableCategories?.forEach((cat) => {
    const category = cat.toLowerCase();

    if (resultCategory) {
      const curIdx = categoriesArr.findIndex((s) => s === resultCategory);
      const nextIdx = categoriesArr.findIndex((s) => s === category);

      if (nextIdx < curIdx) resultCategory = category;
    } else {
      resultCategory = category;
    }
  });

  if (!categoriesArr.includes(resultCategory)) {
    resultCategory = "";
  }

  return resultCategory;
}

export function getRedirectLocation(user) {
  if (user) {
    if (["ADMIN", "DEVELOPER"].includes(user?.role)) {
      return "/admin/dashboard";
    }

    const categoryToRedirect = getAvailableCategory(user);

    if (categoryToRedirect) {
      return `/admin/orderlists/${categoryToRedirect}`;
    }

    return "/admin/orderlists/all";
  }

  return "/login";
}
