import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const TWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ${({ noHeader }) =>
    noHeader &&
    css`
      border-top: 1px solid #eaecf0;
      border-bottom: 1px solid #eaecf0;
    `}

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`;

const THead = styled.div`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  justify-content: space-between;
  top: 0;
  position: sticky;
`;

const TBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const TRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:hover {
    background: rgba(219, 228, 240, 0.25);
  }
`;

const TCol = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 1px solid #eaecf0;
  min-height: 44px;
  padding: 4px;

  ${({ breakWords }) =>
    breakWords &&
    css`
      word-break: break-all;
      white-space: normal;
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}
`;

const Table = ({ hiddenHeader = false, columns = [], rows = [], height, maxHeight }) => {
  return (
    <TWrapper height={height} maxHeight={maxHeight} noHeader={hiddenHeader}>
      <THead hidden={hiddenHeader}>
        {columns.map(({ dataIndex, title, width, minWidth }) => (
          <TCol header key={dataIndex} width={width} minWidth={minWidth}>
            {title}
          </TCol>
        ))}
      </THead>
      <TBody>
        {rows.map((row, idx) => (
          <TRow key={idx} className={row.classes || ""}>
            {columns.map(({ dataIndex, width, minWidth, breakWords, render }) => (
              <TCol key={`${dataIndex}-${idx}`} width={width} minWidth={minWidth} breakWords={breakWords}>
                {render ? render(row, idx) : row[dataIndex]}
              </TCol>
            ))}
          </TRow>
        ))}
      </TBody>
    </TWrapper>
  );
};

Table.propTypes = {
  hiddenHeader: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      width: PropTypes.string,
      render: PropTypes.func,
    })
  ),
  rows: PropTypes.array,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default Table;
