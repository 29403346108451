import { Form, Row } from "react-bootstrap";

import { phoneFormatter } from "_helpers";

import StatusView from "../Status/View";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

export default function FormPrivateContactInfo({ orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">PRIVATE CONTACT INFORMATION</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Private Info</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Private Phone Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {phoneFormatter(productDetails?.phoneNumber) || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Private Email:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.email || "—"}
        </Form.Label>
      </Form.Group>
    </div>
  );
}
