export const customerConstants = {
  GET_ORDERS_REQUEST: "CUSTOMERS_GET_ORDERS_REQUEST",
  GET_ORDERS_SUCCESS: "CUSTOMERS_GET_ORDERS_SUCCESS",
  GET_ORDERS_FAILURE: "CUSTOMERS_GET_ORDERS_FAILURE",

  GET_ORDER_REQUEST: "GET_ORDER_REQUEST",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_FAILURE: "GET_ORDER_FAILURE",

  DELETE_ORDER_REQUEST: "DELETE_ORDER_REQUEST",
  DELETE_ORDER_SUCCESS: "DELETE_ORDER_SUCCESS",
  DELETE_ORDER_FAILURE: "DELETE_ORDER_FAILURE",

  POST_NOTE_REQUEST: "POST_NOTE_REQUEST",
  POST_NOTE_SUCCESS: "POST_NOTE_SUCCESS",
  POST_NOTE_FAILURE: "POST_NOTE_FAILURE",

  GET_AVAILABLE_STATUSES_REQUEST: "GET_AVAILABLE_STATUSES_REQUEST",
  GET_AVAILABLE_STATUSES_SUCCESS: "GET_AVAILABLE_STATUSES_SUCCESS",
  GET_AVAILABLE_STATUSES_FAILURE: "GET_AVAILABLE_STATUSES_FAILURE",

  GET_RELATED_ORDERS_REQUEST: "GET_RELATED_ORDERS_REQUEST",
  GET_RELATED_ORDERS_SUCCESS: "GET_RELATED_ORDERS_SUCCESS",
  GET_RELATED_ORDERS_FAILURE: "GET_RELATED_ORDERS_FAILURE",

  ADD_RELATED_ORDER_REQUEST: "ADD_RELATED_ORDER_REQUEST",
  ADD_RELATED_ORDER_SUCCESS: "ADD_RELATED_ORDER_SUCCESS",
  ADD_RELATED_ORDER_FAILURE: "ADD_RELATED_ORDER_FAILURE",

  PUT_ORDER_REQUEST: "PUT_ORDER_REQUEST",
  PUT_ORDER_SUCCESS: "PUT_ORDER_SUCCESS",
  PUT_ORDER_FAILURE: "PUT_ORDER_FAILURE",

  POST_ORDER_FILE_REQUEST: "POST_ORDER_FILE_REQUEST",
  POST_ORDER_FILE_SUCCESS: "POST_ORDER_FILE_SUCCESS",
  POST_ORDER_FILE_FAILURE: "POST_ORDER_FILE_FAILURE",

  DELETE_ORDER_FILE_REQUEST: "DELETE_ORDER_FILE_REQUEST",
  DELETE_ORDER_FILE_SUCCESS: "DELETE_ORDER_FILE_SUCCESS",
  DELETE_ORDER_FILE_FAILURE: "DELETE_ORDER_FILE_FAILURE",

  GETPRIMARYACTIVITY_SUCCESS: "PRIMARY_ACTIVITY_SUCCESS",

  GET_SUMMARY_REQUEST: "GET_SUMMARY_REQUEST",
  GET_SUMMARY_SUCCESS: "GET_SUMMARY_SUCCESS",
  GET_SUMMARY_FAILED: "GET_SUMMARY_FAILED",

  GET_DASHBOARD_REPORT_REQUEST: "GET_DASHBOARD_REPORT_REQUEST",
  GET_DASHBOARD_REPORT_SUCCESS: "GET_DASHBOARD_REPORT_SUCCESS",
  GET_DASHBOARD_REPORT_FAILED: "GET_DASHBOARD_REPORT_FAILED",

  GET_TRACKING_REQUEST: "GET_TRACKING_REQUEST",
  GET_TRACKING_SUCCESS: "GET_TRACKING_SUCCESS",
  GET_TRACKING_FAILED: "GET_TRACKING_FAILED",

  UNSUBSCRIBE_REQUEST: "UNSUBSCRIBE_REQUEST",
  UNSUBSCRIBE_SUCCESS: "UNSUBSCRIBE_SUCCESS",
  UNSUBSCRIBE_FAILED: "UNSUBSCRIBE_FAILED",

  GET_SUBSCRIPTION_DETAILS_REQUEST: "GET_SUBSCRIPTION_DETAILS_REQUEST",
  GET_SUBSCRIPTION_DETAILS_SUCCESS: "GET_SUBSCRIPTION_DETAILS_SUCCESS",
  GET_SUBSCRIPTION_DETAILS_FAILED: "GET_SUBSCRIPTION_DETAILS_FAILED",

  RERUN_SUBSCRIPTION_REQUEST: "RERUN_SUBSCRIPTION_REQUEST",
  RERUN_SUBSCRIPTION_SUCCESS: "RERUN_SUBSCRIPTION_SUCCESS",
  RERUN_SUBSCRIPTION_FAILED: "RERUN_SUBSCRIPTION_FAILED",

  SET_COLLAPSE_FEDERAL_SEARCH: "SET_COLLAPSE_FEDERAL_SEARCH",

  SET_COLLAPSE_STATE_SEARCH: "SET_COLLAPSE_STATE_SEARCH",

  SET_COLLAPSE_MONITORING_REPORT: "SET_COLLAPSE_MONITORING_REPORT",

  GET_PAYMENT_METHODS_REQUEST: "GET_PAYMENT_METHODS_REQUEST",
  GET_PAYMENT_METHODS_SUCCESS: "GET_PAYMENT_METHODS_SUCCESS",
  GET_PAYMENT_METHODS_FAILED: "GET_PAYMENT_METHODS_FAILED",

  POST_PAYMENT_METHOD_REQUEST: "POST_PAYMENT_METHOD_REQUEST",
  POST_PAYMENT_METHOD_SUCCESS: "POST_PAYMENT_METHOD_SUCCESS",
  POST_PAYMENT_METHOD_FAILED: "POST_PAYMENT_METHOD_FAILED",

  POST_PAYMENT_DETAILS_REQUEST: "POST_PAYMENT_DETAILS_REQUEST",
  POST_PAYMENT_DETAILS_SUCCESS: "POST_PAYMENT_DETAILS_SUCCESS",
  POST_PAYMENT_DETAILS_FAILED: "POST_PAYMENT_DETAILS_FAILED",

  CHARGE_ANNUAL_COMPLIANCE_REPORT_REQUEST: "CHARGE_ANNUAL_COMPLIANCE_REPORT_REQUEST",
  CHARGE_ANNUAL_COMPLIANCE_REPORT_SUCCESS: "CHARGE_ANNUAL_COMPLIANCE_REPORT_SUCCESS",
  CHARGE_ANNUAL_COMPLIANCE_REPORT_FAILURE: "CHARGE_ANNUAL_COMPLIANCE_REPORT_FAILURE",

  CHARGE_SOU_FEE_REQUEST: "CHARGE_SOU_FEE_REQUEST",
  CHARGE_SOU_FEE_SUCCESS: "CHARGE_SOU_FEE_SUCCESS",
  CHARGE_SOU_FEE_FAILURE: "CHARGE_SOU_FEE_FAILURE",

  CHARGE_EXTENSION_FEE_REQUEST: "CHARGE_EXTENSION_FEE_REQUEST",
  CHARGE_EXTENSION_FEE_SUCCESS: "CHARGE_EXTENSION_FEE_SUCCESS",
  CHARGE_EXTENSION_FEE_FAILURE: "CHARGE_EXTENSION_FEE_FAILURE",

  CHARGE_OFFICE_ACTION_REQUEST: "CHARGE_OFFICE_ACTION_REQUEST",
  CHARGE_OFFICE_ACTION_SUCCESS: "CHARGE_OFFICE_ACTION_SUCCESS",
  CHARGE_OFFICE_ACTION_FAILURE: "CHARGE_OFFICE_ACTION_FAILURE",

  GET_EXPORT_ORDERS_REQUEST: "GET_EXPORT_ORDERS_REQUEST",
  GET_EXPORT_ORDERS_SUCCESS: "GET_EXPORT_ORDERS_SUCCESS",
  GET_EXPORT_ORDERS_FAILURE: "GET_EXPORT_ORDERS_FAILURE",

  CHARGE_REVIVAL_FROM_ABANDOMENT_REQUEST: "CHARGE_REVIVAL_FROM_ABANDOMENT_REQUEST",
  CHARGE_REVIVAL_FROM_ABANDOMENT_SUCCESS: "CHARGE_REVIVAL_FROM_ABANDOMENT_SUCCESS",
  CHARGE_REVIVAL_FROM_ABANDOMENT_FAILURE: "CHARGE_REVIVAL_FROM_ABANDOMENT_FAILURE",

  CHARGE_SALES_TAX_PERMIT_STATE_FEE_REQUEST: "CHARGE_SALES_TAX_PERMIT_STATE_FEE_REQUEST",
  CHARGE_SALES_TAX_PERMIT_STATE_FEE_SUCCESS: "CHARGE_SALES_TAX_PERMIT_STATE_FEE_SUCCESS",
  CHARGE_SALES_TAX_PERMIT_STATE_FEE_FAILURE: "CHARGE_SALES_TAX_PERMIT_STATE_FEE_FAILURE",

  DISSOLVE_ORDER_REQUEST: "DISSOLVE_ORDER_REQUEST",
  DISSOLVE_ORDER_SUCCESS: "DISSOLVE_ORDER_SUCCESS",
  DISSOLVE_ORDER_FAILURE: "DISSOLVE_ORDER_FAILURE",

  CLEAR_CUSTOMER_INFO_REQUEST: "CLEAR_CUSTOMER_INFO_REQUEST",
  CLEAR_CUSTOMER_INFO_SUCCESS: "CLEAR_CUSTOMER_INFO_SUCCESS",
  CLEAR_CUSTOMER_INFO_FAILURE: "CLEAR_CUSTOMER_INFO_FAILURE",

  CREATE_DELAYED_UPSELL_REQUEST: "CREATE_DELAYED_UPSELL_REQUEST",
  CREATE_DELAYED_UPSELL_SUCCESS: "CREATE_DELAYED_UPSELL_SUCCESS",
  CREATE_DELAYED_UPSELL_FAILURE: "CREATE_DELAYED_UPSELL_FAILURE",

  SET_COLLAPSE_MONITORING_REPORT: "SET_COLLAPSE_MONITORING_REPORT",

  UNLOCK_AUTOMATION_REQUEST: "UNLOCK_AUTOMATION_REQUEST",
  UNLOCK_AUTOMATION_SUCCESS: "UNLOCK_AUTOMATION_SUCCESS",
  UNLOCK_AUTOMATION_FAILURE: "UNLOCK_AUTOMATION_FAILURE",

  CHANGE_EMAIL_REQUEST: "CHANGE_EMAIL_REQUEST",
  CHANGE_EMAIL_SUCCESS: "CHANGE_EMAIL_SUCCESS",
  CHANGE_EMAIL_FAILURE: "CHANGE_EMAIL_FAILURE",

  GET_PARENT_ORDER_REQUEST: "GET_PARENT_ORDER_REQUEST",
  GET_PARENT_ORDER_SUCCESS: "GET_PARENT_ORDER_SUCCESS",
  GET_PARENT_ORDER_FAILURE: "GET_PARENT_ORDER_FAILURE",

  PUT_PARENT_ORDER_REQUEST: "PUT_PARENT_ORDER_REQUEST",
  PUT_PARENT_ORDER_SUCCESS: "PUT_PARENT_ORDER_SUCCESS",
  PUT_PARENT_ORDER_FAILURE: "PUT_PARENT_ORDER_FAILURE",
};
