import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import { Row, Form } from "react-bootstrap";

import { getMainProductDetails } from "_helpers";
import StatusView from "./Status/View";

function FormDefault({ parentOrderDetails, orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const productDetails = orderDetails?.products[selectedProduct];
  const { mainProductDetails } = getMainProductDetails(orderDetails, parentOrderDetails);

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">{productDetails?.code?.customerName}</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {mainProductDetails?.owner?.ssn || "N/A"}
        </Form.Label>
      </Form.Group>

      <FormPDF productDetails={productDetails} />
    </div>
  );
}

export default FormDefault;
