import { GroupChannel } from "@sendbird/uikit-react/GroupChannel";
import MessageList from "@sendbird/uikit-react/GroupChannel/components/MessageList";
import { useGroupChannelContext } from "@sendbird/uikit-react/GroupChannel/context";
import React from "react";

import CustomGroupChannelHeader from "./CustomGroupChannelHeader";
import CustomMessageContent from "./CustomMessageContent";

const CustomGroupChannelView = ({ productDetails, currentMessage, setSearchVisible }) => {
  const { currentChannel, showSearchIcon } = useGroupChannelContext();

  const handleSearchClick = () => {
    setSearchVisible && setSearchVisible((prevState) => !prevState);
  };

  return (
    <GroupChannel
      channelUrl={currentChannel?.url}
      renderChannelHeader={() => (
        <CustomGroupChannelHeader
          productDetails={productDetails}
          currentChannel={currentChannel}
          showSearchIcon={showSearchIcon}
          onSearchClick={handleSearchClick}
        />
      )}
      renderMessageContent={(props) => <CustomMessageContent currentMessage={currentMessage} {...props} />}
      renderMessageList={(props) => <MessageList {...props} />}
    />
  );
};

export default CustomGroupChannelView;
