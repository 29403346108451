import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { config } from "_config";
import { usePrevious } from "_hooks";
import { notificationsActions } from "_actions";

import Button from "../ui/Button";
import Spinner from "components/Spinner";
import CustomGroupChannel from "./CustomGroupChannel";
import CustomSearchPannel from "./CustomSearchPannel";
import CustomChannelSettings from "./CustomChannelSettings";
import ChannelPriorityCard from "./ChannelPriorityCard";
import SendbirdSubscription from "./SendbirdSubscription";

const StyledContainer = styled.div`
  flex-grow: 1;
  padding: 16px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const StyledActions = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 767px) {
    padding-top: 16px;
  }
`;

const StyledSBContainer = styled.div`
  @media (min-width: 767px) {
    .col-md-4 {
      padding-right: 7px;
    }

    .col-md-8 {
      padding-left: 8px;
    }
  }

  .sendbird-channel-list {
    width: 100%;
  }

  .sendbird-conversation {
    flex-grow: 1;
    border: 1px solid #dee2e6;
    scrollbar-color: #dee2e6 #fff;

    .sendbird-iconbutton--pressed {
      background-color: unset;
    }

    .sendbird-conversation__messages {
      .sendbird-conversation__scroll-container {
        .sendbird-conversation__messages-padding {
          padding-left: 16px;
          padding-right: 16px;
        }
      }

      .sendbird-separator {
        padding-top: 16px;
        padding-bottom: 16px;

        .sendbird-separator__text {
          .sendbird-label.sendbird-label--caption-2.sendbird-label--color-onbackground-2 {
            font-weight: 400;
          }
        }
      }

      .sendbird-admin-message {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .sendbird-msg-hoc {
        .sendbird-avatar-img--default {
          background-color: #641ec9;
        }

        .sendbird-message-content {
          padding-top: 8px;
          padding-bottom: 8px;

          &.selected-search-message {
            background-color: var(--sendbird-highlight-100);
          }
        }

        .sendbird-message-content.outgoing {
          .sendbird-text-message-item-body {
            .sendbird-label {
              color: #ffffff;
            }
          }

          .sendbird-voice-message-item-body {
            .sendbird-voice-message-item-body__status-button {
              .sendbird-icon {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .sendbird-message-content-reactions {
          .sendbird-context-menu {
            .sendbird-emoji-reactions__add-reaction-badge {
              .sendbird-icon {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          .sendbird-reaction-badge__inner__count {
            color: #ffffff;
          }
        }

        &:hover {
          .sendbird-message-content.outgoing {
            .sendbird-voice-message-item-body {
              .sendbird-progress-bar.sendbird-voice-message-item-body__progress-bar.progress-bar-color--primary {
                background-color: #641ec9;
              }
            }
          }
        }
      }
    }

    .sendbird-conversation__footer {
      position: sticky;
      bottom: 0;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      background-color: #ffffff;

      .sendbird-message-input-wrapper {
        .sendbird-message-input-wrapper__message-input {
          padding-left: 16px;
          padding-right: 16px;
        }

        #sendbird-message-input-text-field {
          /* border-radius: 12px; */
        }

        .sendbird-message-input {
          .sendbird-message-input--textarea {
            overflow-y: auto;
            border-width: 2px;

            &::-webkit-scrollbar {
              width: 12px;
            }

            &::-webkit-scrollbar-track {
              background: #dee2e6 0% 0% no-repeat padding-box;
              border-radius: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background: #606071 0% 0% no-repeat padding-box;
              border-radius: 6px;
            }
          }

          .sendbird-message-input--placeholder {
            top: 20px;
          }

          .sendbird-iconbutton {
            .sendbird-icon {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .sendbird-mention-suggest-list {
          width: auto;
          bottom: -12px;
          margin-left: 16px;
          margin-right: 16px;
          padding: 16px;
          overflow: auto;
          border: 1px solid #606071;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;

          .sendbird-mention-suggest-list__user-item {
            cursor: pointer;

            .sendbird-avatar-img--default {
              background-color: #641ec9;
            }

            .focused {
              background-color: #dee2e6;
            }

            * {
              font-weight: 400;
            }
          }
        }
      }

      .sendbird-message-input-wrapper--voice-message {
        .sendbird-voice-message-input {
          padding-left: 16px;
          padding-right: 16px;

          .sendbird-voice-message-input__indicator {
            .sendbird-voice-message-input__indicator__progress-bar {
              .sendbird-progress-bar.sendbird-voice-message-input__indicator__progress-bar__bar.progress-bar-color--primary {
                background-color: #606071;

                .sendbird-progress-bar__fill {
                  background-color: #641ec9;
                }
              }
            }

            .sendbird-voice-message-input__indicator__on-rec {
              margin-right: 4px;
            }

            .sendbird-voice-message-input__indicator__playback-time {
              display: flex;
              align-items: center;
              top: 0;
              bottom: 0;

              .sendbird-label {
                color: #ffffff;
              }
            }
          }

          .sendbird-voice-message-input__controler {
            .sendbird-icon {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    ${({ height }) =>
      height &&
      css`
        height: ${height};
      `}
  }

  .sendbird-message-search-pannel {
    min-width: 300px;
    border: 1px solid #dee2e6;

    .sendbird-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sendbird-ui-header {
      &.sendbird-message-search-pannel__header {
        height: auto;
        min-height: unset;
        padding: 8px;

        .sendbird-ui-header__middle__title.sendbird-label {
          &.sendbird-label--h-2 {
            &.sendbird-label--color-onbackground-1 {
              color: #641ec9;
            }
          }
        }

        .sendbird-icon {
          &.sendbird-icon-close {
            &.sendbird-icon-color--on-background-1 {
              svg {
                path {
                  fill: #606071;
                }
              }
            }
          }
        }
      }
    }

    .sendbird-message-search-pannel__input__container {
      border: 2px solid #606071 !important;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

      .sendbird-message-search-pannel__input__container__search-icon {
        left: 8px !important;
      }

      .sendbird-message-search-pannel__input__container__input-area {
        top: 10px;
        left: 38px;
        width: calc(100% - 80px);
      }

      .sendbird-message-search-pannel__input__container__reset-input-button {
        top: 10px;
        right: 10px !important;
      }

      .sendbird-message-search-pannel__input__container__spinner {
        top: 10px;
        right: 10px !important;
      }
    }

    .sendbird-message-search {
      overflow-y: auto;

      .sendbird-message-search-item {
        &.sendbird-message-search-item--selected {
          background-color: #edeefd;
        }

        .sendbird-message-search-item__left {
          border-inline-start: none;

          .sendbird-avatar-img--default {
            background-color: #641ec9;
          }
        }
      }
    }
  }

  .sendbird-channel-settings {
    min-width: 300px;
    background-color: #ffffff;
    border: 1px solid #dee2e6;

    .sendbird-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sendbird-ui-header {
      &.sendbird-channel-settings__header {
        height: auto;
        min-height: unset;
        padding: 8px;

        .sendbird-iconbutton--pressed {
          background-color: unset !important;
        }

        .sendbird-icon {
          &.sendbird-icon-close {
            &.sendbird-icon-color--on-background-1 {
              svg {
                path {
                  fill: #606071;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .sendbird-conversation {
      height: unset;
    }

    .sendbird-message-search-pannel {
      width: 100%;
    }

    .sendbird-channel-settings {
      width: 100%;
    }
  }
`;

const StyledChannelContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

const customColorSet = {
  "--sendbird-light-onlight-01": "#212529",
  "--sendbird-light-onlight-02": "#212529",
  "--sendbird-light-onlight-03": "#606071",
  "--sendbird-light-onlight-04": "#dee2e6",
  "--sendbird-light-background-100": "#f5f6f7",
  "--sendbird-light-background-200": "#f5f6f7",
  "--sendbird-light-background-300": "#f5f6f7",
  "--sendbird-light-primary-100": "#641ec9",
  "--sendbird-light-primary-300": "#641ec9",
  "--sendbird-light-primary-400": "#641ec9",
  "--sendbird-highlight-100": "#fff2b6",
  "--sendbird-message-input-border-active": "#641ec9",
  "--sendbird-light-shadow-message-input": "unset",
};

const Channel = ({ channel }) => {
  const headerRef = useRef(null);
  const dispatch = useDispatch();
  const { channelUrl, orderId } = channel || {};
  const prevChannelUrl = usePrevious(channelUrl);
  const user = useSelector((state) => state.authentication.user);
  const { channels: allChannels } = useSelector((state) => state.chats);
  const { joiningChannel, channels } = useSelector((state) => state.notifications);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);

  const orders = allChannels?.orders || {};
  const orderData = orders[orderId] || {};

  const isSpinner = Boolean(joiningChannel);

  const isUserJoined =
    user.id === allChannels?.filters?.userId ||
    Object.keys(channels?.data || {}).some((key) => {
      return Boolean((channels?.data || {})[key]?.length);
    });

  const handleJoin = () => {
    dispatch(
      notificationsActions.joinChannel({
        userId: user?.id,
        orderId,
        channelUrl,
      })
    );
  };

  useEffect(() => {
    if (prevChannelUrl !== channelUrl && channelUrl) {
      dispatch(notificationsActions.getChannels({ userId: user?.id, orderUid: orderId }));
      dispatch(notificationsActions.getClosedChannels({ userId: user?.id, orderUid: orderId, page: 1, count: 1 }));
    }
  }, [prevChannelUrl, channelUrl]);

  return (
    <StyledContainer>
      <StyledHeader ref={headerRef}>
        <ChannelPriorityCard channel={channel} orderData={orderData} />
        <StyledActions>
          <Button color="#641ec9" disabled={isUserJoined || !channelUrl} onClick={handleJoin}>
            Join
          </Button>
        </StyledActions>
      </StyledHeader>
      {isSpinner && <Spinner />}
      {!isSpinner && (
        <StyledSBContainer
          height={`calc(100vh - 67px - 48px - ${headerRef?.current?.getBoundingClientRect().height}px)`}
        >
          <SendbirdProvider
            appId={config.sendbirdAppId}
            userId={user?.id}
            accessToken={user?.sendBirdAccessToken}
            colorSet={customColorSet}
            uikitOptions={{
              groupChannel: { enableMention: true, enableTypingIndicator: true, enableVoiceMessage: true },
              groupChannelSettings: { enableMessageSearch: true },
            }}
            sdkInitParams={{ localCacheEnabled: false }}
          >
            <StyledChannelContainer>
              <CustomGroupChannel
                channelUrl={channelUrl}
                currentMessage={currentMessage}
                setSettingsVisible={setSettingsVisible}
                setSearchVisible={setSearchVisible}
              />
              {searchVisible && (
                <CustomSearchPannel
                  channelUrl={channelUrl}
                  setSearchVisible={setSearchVisible}
                  setCurrentMessage={setCurrentMessage}
                />
              )}
              {settingsVisible && (
                <CustomChannelSettings channelUrl={channelUrl} setSettingsVisible={setSettingsVisible} />
              )}
              <SendbirdSubscription />
            </StyledChannelContainer>
          </SendbirdProvider>
        </StyledSBContainer>
      )}
    </StyledContainer>
  );
};

export default Channel;
