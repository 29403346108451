import { Row, Col, Form } from "react-bootstrap";
import InputMask from "react-input-mask";

import { TAB_CODES } from "variables/Variables";
import { getMainProductDetails } from "_helpers";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import DateOfBirth from "components/OrderDetails/ProductDetails/DateOfBirth/Edit";

import StatusEdit from "../../Status/Edit";

function FormBoi({
  parentOrderDetails,
  orderDetails,
  selectedProduct,
  onParentChange,
  onChange,
  onError,
  onSelectedProduct,
  ...props
}) {
  const productDetails = orderDetails?.products[selectedProduct];
  const { mainOrderDetails, mainProductIndex, mainProductDetails, isParent } = getMainProductDetails(
    orderDetails,
    parentOrderDetails
  );

  const handleAddError = (field) => onError(TAB_CODES.boiInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    const exFields = { [name]: value || null };

    if (value === "Invalid date") {
      exFields[name] = null;
    }

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handleMainProductChange = ({ target: { name, value } }) => {
    const exFields = {};

    if (name === "ssn") {
      exFields.owner = {
        ...mainProductDetails?.owner,
        ssn: value || null,
      };
    }

    const onEvent = isParent ? onParentChange : onChange;

    onEvent({
      ...mainOrderDetails,
      products: mainOrderDetails?.products.map((product, idx) =>
        idx === mainProductIndex ? { ...product, ...exFields } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Beneficial Ownership Information Report</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
        onSelectedProduct={onSelectedProduct}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <DateOfBirth productDetails={productDetails} onChange={handleProductChange} />

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className="form-control form-control-sm"
            mask="999-99-9999"
            maskChar=" "
            name="ssn"
            placeholder="999-99-9999"
            pattern="^\d{3}-\d{2}-\d{4}$"
            value={mainProductDetails ? mainProductDetails.owner?.ssn || "" : productDetails?.ssn || ""}
            onChange={mainOrderDetails ? handleMainProductChange : handleProductChange}
            onInvalid={() => handleAddError("Social Security Number")}
          />
        </Col>
      </Form.Group>

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Driver's License or ID Number:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="driverLicenceOrID"
            value={productDetails?.driverLicenceOrID || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>
    </div>
  );
}

export default FormBoi;
