import clsx from "clsx";
import { useState } from "react";
import InputMask from "react-input-mask";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import styled, { css } from "styled-components";

import { phoneCleaner, phoneNumRegexp } from "_helpers";

import { suffixArray, state_array } from "variables/Variables";

export const initCoOwner = {
  firstName: "",
  middleName: null,
  lastName: "",
  suffixName: null,
  countryOfCitizenship: null,
  email: "",
  phone: "",
  address: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    county: null,
  },
};

const StyledCard = styled(Card)`
  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: red;
      color: red;
    `}

  padding-top: 8px;
  padding-bottom: 8px;

  .form-group:not(:last-child) {
    margin-bottom: 12px;
  }

  .form-label {
    display: flex;
    align-items: center;
    color: black;
    margin: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    text-transform: unset;
    font-size: 0.875rem;
  }

  & > hr {
    margin: 1rem 0;
  }
`;

const InfoText = styled.span`
  color: gray;
`;

export default function CoOwners({ hidden, coOwners, isCompact, onError, onChange }) {
  const [collapsedRows, setCollapsedRows] = useState([]);
  const [errorRows, setErrorRows] = useState([]);

  const handleCollapse = (rowId) => {
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((id) => rowId !== id));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  const isRowCollapsed = (rowId) => {
    return collapsedRows.includes(rowId);
  };

  const isRowError = (rowId) => {
    return errorRows.includes(rowId);
  };

  const handleAddCoOwner = () => {
    onChange([...coOwners, { ...initCoOwner }]);
    setCollapsedRows((prev) =>
      prev.map((item, idx) => {
        if (idx > 0) {
          return item + 1;
        }
      })
    );
    setErrorRows((prev) =>
      prev.map((item, idx) => {
        if (idx > 0) {
          return item + 1;
        }
      })
    );
  };

  const handleDeleteCoOwner = (deleteIdx) => {
    onChange(coOwners.filter((o, _idx) => deleteIdx !== _idx));
    setCollapsedRows((prev) =>
      prev.filter((item) => item !== deleteIdx).map((item) => (item > deleteIdx ? item - 1 : item))
    );
    setErrorRows((prev) =>
      prev.filter((item) => item !== deleteIdx).map((item) => (item > deleteIdx ? item - 1 : item))
    );
  };

  const handleCoOwnerChange = ({ target: { name, value } }, idx) => {
    let newData = value;

    setErrorRows((prev) => prev.filter((id) => idx !== id));

    if (name === "phone") {
      newData = phoneCleaner(newData);
    }

    if (value === "") {
      newData = null;
    }

    onChange(coOwners.map((owner, ownerIdx) => (ownerIdx === idx ? { ...owner, [name]: newData } : owner)));
  };

  const handleCoOwnerAddressChange = ({ target: { name, value } }, idx) => {
    let newData = value;

    setErrorRows((prev) => prev.filter((id) => idx !== id));

    onChange(
      coOwners.map((owner, ownerIdx) =>
        ownerIdx === idx ? { ...owner, address: { ...owner.address, [name]: newData } } : owner
      )
    );
  };

  const handleOnError = (idx, field) => {
    if (!errorRows.includes(idx)) {
      setErrorRows((prev) => [...prev, idx]);
    }

    onError(`Co-Owners (${idx + 1}) - ${field}`);
  };

  if (hidden) return null;

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">
            Co-Owners <InfoText>({coOwners?.length || "0"})</InfoText>
          </h6>
        </Form.Label>
        <Col sm={7}>
          <Button size="sm" onClick={handleAddCoOwner}>
            Add Owner
          </Button>
        </Col>
      </Form.Group>

      {coOwners.map((coOwner, idx) => (
        <StyledCard
          key={`coowners-card-${idx}`}
          className={clsx({
            "mb-3": true,
            "px-2": !isCompact,
            "px-4": isCompact,
          })}
          $hasError={isRowError(idx)}
        >
          <Form.Group as={Row} className="mb-0">
            <Form.Label
              column
              xs={isCompact ? 12 : 5}
              className="align-items-start"
              onClick={() => handleCollapse(idx)}
            >
              <i
                className={clsx("fa", { "fa-caret-up": isRowCollapsed(idx), "fa-caret-down": !isRowCollapsed(idx) })}
              />
              &nbsp;
              <InfoText className="mr-2">#{idx + 1}</InfoText>&nbsp;
              {coOwner.firstName || coOwner.lastName ? (
                <span>{`${coOwner.firstName} ${coOwner.lastName}`}</span>
              ) : (
                <span style={{ color: "gray" }}>{"<new co-owner>"}</span>
              )}
            </Form.Label>
            <Col xs={isCompact ? 12 : 5} className={clsx({ "align-items-start": true, "mx-2": isCompact })}>
              <Button size="sm" variant="danger" onClick={() => handleDeleteCoOwner(idx)}>
                Remove
              </Button>
            </Col>
          </Form.Group>

          <div style={{ display: isRowCollapsed(idx) ? "block" : "none" }}>
            <hr />
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                First Name
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={coOwner.firstName || ""}
                  onChange={(e) => handleCoOwnerChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "First Name")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Middle Name
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="middleName"
                  placeholder="Middle Name (optional)"
                  value={coOwner.middleName || ""}
                  onChange={(e) => handleCoOwnerChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "Middle Name")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Last Name
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={coOwner.lastName || ""}
                  onChange={(e) => handleCoOwnerChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "Last Name")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Suffix Name
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  as="select"
                  size="sm"
                  name="suffixName"
                  value={coOwner.suffixName || ""}
                  onChange={(e) => handleCoOwnerChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "Suffix Name")}
                >
                  <option value="">Please select (optional)</option>
                  {suffixArray.map((prop, key) => (
                    <option key={key} value={prop}>
                      {prop}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Email
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="email"
                  name="email"
                  placeholder="example@mail.com"
                  value={coOwner.email || ""}
                  onChange={(e) => handleCoOwnerChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "Email")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Phone
              </Form.Label>
              <Col sm={7}>
                <InputMask
                  required
                  className={clsx("form-control", "form-control-sm", {
                    "is-invalid": coOwner.phone && !phoneNumRegexp.test(coOwner.phone),
                  })}
                  mask="(999) 999-9999"
                  maskChar=" "
                  name="phone"
                  placeholder="(999) 999-9999"
                  pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                  value={coOwner.phone || ""}
                  onChange={(e) => handleCoOwnerChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "Phone")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Address 1
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  name="address1"
                  placeholder="Address 1"
                  value={coOwner.address?.address1 || ""}
                  onChange={(e) => handleCoOwnerAddressChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "Address 1")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Address 2
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="address2"
                  placeholder="Address 2 (optional)"
                  value={coOwner.address?.address2 || ""}
                  onChange={(e) => handleCoOwnerAddressChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "Address 2")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                City
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  name="city"
                  placeholder="City"
                  value={coOwner.address?.city || ""}
                  onChange={(e) => handleCoOwnerAddressChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "City")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                State
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  as="select"
                  size="sm"
                  name="state"
                  value={coOwner.address?.state || ""}
                  onChange={(e) => handleCoOwnerAddressChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "State")}
                >
                  <option value={null}>Select a state</option>
                  {Object.keys(state_array).map((key) => (
                    <option key={key} value={key}>
                      {state_array[key]}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                ZIP
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  placeholder="12345"
                  name="zip"
                  maxLength={5}
                  pattern="^\d{5}$"
                  value={coOwner.address?.zip || ""}
                  onChange={(e) => handleCoOwnerAddressChange(e, idx)}
                  onInvalid={() => handleOnError(idx, "ZIP")}
                />
              </Col>
            </Form.Group>
          </div>
        </StyledCard>
      ))}
    </>
  );
}
