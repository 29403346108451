import { useState } from "react";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import InputMask from "react-input-mask";

import { getMainProductDetails } from "_helpers";
import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import EditLicenseModal from "components/EditLicenseModal";
import CustomExternalLink from "components/CustomExternalLink";

import StatusEdit from "../../Status/Edit";

function FormBusinessLicenses({
  parentOrderDetails,
  orderDetails,
  initOrderDetails,
  selectedProduct,
  onParentChange,
  onChange,
  onSelectedProduct,
}) {
  const [mode, setMode] = useState(null);
  const [selectedLicenseIdx, setSelectedLicenseIdx] = useState(null);

  const productDetails = orderDetails?.products[selectedProduct];
  const { mainOrderDetails, mainProductIndex, mainProductDetails, isParent } = getMainProductDetails(
    orderDetails,
    parentOrderDetails
  );
  const licenses = productDetails?.processingResult?.licenses || [];

  const handleMainProductChange = ({ target: { name, value } }) => {
    const exFields = {};

    if (name === "ssn") {
      exFields.owner = {
        ...mainProductDetails?.owner,
        ssn: value || null,
      };
    }

    const onEvent = isParent ? onParentChange : onChange;

    onEvent({
      ...mainOrderDetails,
      products: mainOrderDetails?.products.map((product, idx) =>
        idx === mainProductIndex ? { ...product, ...exFields } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Business Licenses</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onSelectedProduct={onSelectedProduct}
      />

      {mainProductDetails && (
        <>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Social Security Number:
            </Form.Label>
            <Col sm={7}>
              <InputMask
                className="form-control form-control-sm"
                mask="999-99-9999"
                maskChar=" "
                name="ssn"
                placeholder="999-99-9999"
                pattern="^\d{3}-\d{2}-\d{4}$"
                value={mainProductDetails?.owner?.ssn || ""}
                onChange={handleMainProductChange}
                onInvalid={() => handleAddError("Social Security Number")}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Licenses and Permits</h6>

      <Row className="mb-4">
        <Col lg md={12} className="mb-3">
          <Table responsive className="orders-table">
            <thead>
              <tr className="info-row">
                <th style={{ width: "15%" }}>Name</th>
                <th style={{ width: "15%" }}>Authority</th>
                <th style={{ width: "20%" }}>Link</th>
                <th style={{ width: "20%" }}>Description</th>
                <th style={{ width: "10%" }}>Applies to</th>
                <th style={{ width: "10%" }}>Includes</th>
                <th style={{ width: "10%", minWidth: "130px" }}>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => {
                      setMode("add");
                      setSelectedLicenseIdx(null);
                    }}
                  >
                    Add
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {licenses?.map((item, idx) => (
                <tr key={`${idx}-${item?.licenseName}`} className="info-row">
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.licenseName}</td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.authority}</td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top", maxWidth: "180px" }}>
                    <CustomExternalLink short href={item?.url || "#"} target="_blank">
                      {item?.url}
                    </CustomExternalLink>
                  </td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.description}</td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.appliesTo}</td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.includes || "–"}</td>
                  <td style={{ verticalAlign: "top" }}>
                    <Button
                      size="sm"
                      variant="warning"
                      className="mb-2 mr-2"
                      onClick={() => {
                        setMode("edit");
                        setSelectedLicenseIdx(idx);
                      }}
                    >
                      Edit
                    </Button>

                    <Button
                      size="sm"
                      variant="danger"
                      className="mb-2"
                      onClick={() => {
                        setMode("delete");
                        setSelectedLicenseIdx(idx);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <EditLicenseModal
        show={Boolean(mode)}
        mode={mode}
        licenses={licenses}
        selectedLicenseIdx={selectedLicenseIdx}
        selectedProduct={selectedProduct}
        initOrderDetails={initOrderDetails}
        handleClose={() => {
          setMode(null);
          setSelectedLicenseIdx(null);
        }}
      />
    </div>
  );
}

export default FormBusinessLicenses;
