import { alertActions } from "_actions";
import { handleResponse } from "_helpers";
import React from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          dispatch(alertActions.error("User unauthorized"));
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};
