import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";

import { PRODUCT_PRIORITY } from "variables/Variables";
import { PRIORITY_COLORS } from "views/Chats/constants";
import { customerActions, chatsActions } from "_actions";

import PriorityDropdown from "./PriorityDropdown";

export const items = [
  {
    value: PRODUCT_PRIORITY.unset,
    label: PRODUCT_PRIORITY.unset,
    color: PRIORITY_COLORS[PRODUCT_PRIORITY.unset],
  },
  {
    value: PRODUCT_PRIORITY.high,
    label: PRODUCT_PRIORITY.high,
    color: PRIORITY_COLORS[PRODUCT_PRIORITY.high],
  },
  {
    value: PRODUCT_PRIORITY.medium,
    label: PRODUCT_PRIORITY.medium,
    color: PRIORITY_COLORS[PRODUCT_PRIORITY.medium],
  },
  {
    value: PRODUCT_PRIORITY.low,
    label: PRODUCT_PRIORITY.low,
    color: PRIORITY_COLORS[PRODUCT_PRIORITY.low],
  },
];

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid #edeefd;
  border-radius: 5px;
  background-color: #fff;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const StyledRow = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledCol = styled.div``;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #641ec9;
`;

const StyledName = styled.div`
  font-size: 16px;
  color: #606071;
`;

const StyledCrmOrderId = styled.span`
  cursor: pointer;
  font-size: 16px;
  color: #606071;

  &:hover {
    text-decoration: underline;
  }
`;

export default function ChannelPriorityCard({ channel, orderData }) {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers);
  const { channels } = useSelector((state) => state.chats);
  const loading = customers?.loading || channels?.refetching;
  const { channelUrl, orderId, productId, params } = channel || {};
  const linkedProduct = orderData?.products?.find(({ id }) => id === productId) || {};

  const handleSelect = (priority) => {
    const products = orderData?.products?.filter(({ id }) => id !== productId);
    const newOrderData = cloneDeep(orderData);
    newOrderData.products = [
      ...products,
      { ...linkedProduct, priority: priority === PRODUCT_PRIORITY.unset ? null : priority },
    ];

    if (orderId && linkedProduct) {
      dispatch(
        customerActions.updateorder(orderId, newOrderData, () => {
          dispatch(chatsActions.refetchChannel({ url: channelUrl, userIdForStatuses: params?.userId }));
        })
      );
    }
  };

  return (
    <StyledOuterContainer>
      <StyledInnerContainer>
        <StyledCol>
          <StyledRow>
            <StyledTitle>Chat Name:</StyledTitle>
            <StyledName>{channel?.name || "No channel selected"}</StyledName>
          </StyledRow>
          <StyledRow>
            <StyledTitle>Order ID:</StyledTitle>
            {orderData?.order?.crmOrderId && (
              <Link to={`/admin/orders/${channel?.orderId}/${channel?.productId}`} target="_blank">
                <StyledCrmOrderId>#{orderData?.order?.crmOrderId}</StyledCrmOrderId>
              </Link>
            )}
            {!orderData?.order?.crmOrderId && <StyledName>N/A</StyledName>}
          </StyledRow>
        </StyledCol>
        {channel && (
          <StyledCol>
            <PriorityDropdown
              loading={loading}
              activeValue={linkedProduct?.priority || PRODUCT_PRIORITY.unset}
              items={items}
              onSelect={handleSelect}
            />
          </StyledCol>
        )}
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
}
