import { primaryactivity } from "_reducers/primaryactivity.reducer";
import moment from "moment";

export function checkIsIncorpify(code) {
  return ["INC_LLCIncorporation", "INC_LLCIncorporationV2"].includes(code);
}

export function checkIsIncStates(code) {
  return ["INC_States_LLCIncorporation", "INC_States_LLCIncorporationV2"].includes(code);
}

export function checkIsIncLLCIncorporation(code) {
  return [
    "INC_LLCIncorporation",
    "INC_LLCIncorporationV2",
    "INC_States_LLCIncorporation",
    "INC_States_LLCIncorporationV2",
  ].includes(code);
}

export function checkIsLLCIncorporation(code) {
  return [
    "EIN_LLCIncorporation",
    "INC_LLCIncorporation",
    "INC_LLCIncorporationV2",
    "INC_States_LLCIncorporation",
    "INC_States_LLCIncorporationV2",
  ].includes(code);
}

export function checkIsRA(code) {
  return [
    "INC_RegisteredAgent",
    "INC_States_RegisteredAgent",
    "EIN_RegisteredAgent",
    "INC_ResidentAgent",
    "INC_States_ResidentAgent",
    "EIN_ResidentAgent",
  ].includes(code);
}

export function checkIsAR(code) {
  return ["INC_AnnualReport", "INC_States_AnnualReport", "EIN_AnnualReport"].includes(code);
}

export function checkIsACR(code) {
  return ["INC_AnnualComplianceReport", "INC_States_AnnualComplianceReport", "EIN_AnnualComplianceReport"].includes(
    code
  );
}

export function checkIsSTP(code) {
  return ["INC_SalesTaxPermit", "INC_States_SalesTaxPermit"].includes(code);
}

export function checkIsBOI(code) {
  return ["INC_BOI", "INC_States_BOI", "EIN_BOI"].includes(code);
}

export function checkIsStateIncorporationFee(code) {
  return ["INC_StateIncorporationFee", "INC_States_StateIncorporationFee", "EIN_StateIncorporationFee"].includes(code);
}

export function checkIsRegisterTrademark(code) {
  return [
    "TRADEMARK_RegisterTrademark",
    "TRADEMARK_RegisterTrademarkV2",
    "INC_RegisterTrademark",
    "INC_States_RegisterTrademark",
  ].includes(code);
}

export function checkIsTrademarkMonitoring(code) {
  return ["TRADEMARK_Trademark_Monitoring", "INC_Trademark_Monitoring"].includes(code);
}

export function checkIsUSPTOFilingFee(code) {
  return ["TRADEMARK_USPTOFilingFee", "INC_USPTOFilingFee", "INC_States_USPTOFilingFee"].includes(code);
}

export function checkIsIncEin(code) {
  return ["INC_EIN", "INC_States_EIN"].includes(code);
}

export function checkIsAccounting(code) {
  return [
    "ACCOUNTING_Monthly_bookkeeping",
    "EIN_Monthly_bookkeeping",
    "INC_Monthly_bookkeeping",
    "INC_States_Monthly_bookkeeping",
  ].includes(code);
}

export function checkIsTaxFiling(code) {
  return [
    "ACCOUNTING_Personal_Tax_filing",
    "ACCOUNTING_LLC_Tax_filing",
    "ACCOUNTING_Sole_Proprietor_Tax_filing",
    "ACCOUNTING_S_Corp_tax_filing",
    "ACCOUNTING_Partnership_tax_filing",
    "INC_States_Personal_Tax_filing",
    "INC_States_LLC_Tax_filing",
    "INC_States_Sole_Proprietor_Tax_filing",
    "INC_Personal_Tax_filing",
    "INC_LLC_Tax_filing",
    "INC_Sole_Proprietor_Tax_filing",
    "EIN_Personal_Tax_filing",
    "EIN_LLC_Tax_filing",
    "EIN_Sole_Proprietor_Tax_filing",
  ].includes(code);
}

export function checkIsEinLlc(code) {
  return ["EIN_LLC"].includes(code);
}

export function getProductInitData(codeObj, oldData = {}) {
  let initObj = null;

  switch (codeObj?.code) {
    case "INC_EIN":
    case "INC_States_EIN":
    case "EIN_LLC":
      initObj = {
        legalName: "",
        dbaname: "",
        taxClassification: "Individual",
        owner: {
          firstName: "",
          middleName: "",
          lastName: "",
          suffixName: null,
          title: "",
          ssn: null,
        },
        altAddress: null,
        // {
        //   country: "",
        //   address1: "",
        //   address2: "",
        //   city: "",
        //   state: "",
        //   zip: "",
        //   county: "",
        //   country: "US",
        // },
        corpAddress: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          county: "",
          country: "US",
        },
        organizedState: "",
        startDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        closingMonth: 1,

        activityPrimaryActivity: "started_business",
        activitySpecificProducts: null,
        productDescription: null,
        productExtraQuestion1: null,
        productExtraQuestion2: null,
        productExtraQuestion3: null,

        questionsATF: false,
        questionsAppliedBefore: false,
        questionsExcise: false,
        questionsGambling: false,
        questionsHighway: false,
        questionsHire: false,
        payLessThan4k: false,
        payEmployeesViaPayroll: false,
        annualTaxes: false,
        acceptCreditCardPayments: false,
        husbandAndWife: false,
        multiMember: false,

        numberOfMembers: 1,
        numberOfAgricultureEmployees: 0,
        numberOfHouseholdeEmployees: 0,
        numberOfOtherEmployees: 0,

        previousEINNumberFirst2: null,
        previousEINNumberLast7: null,

        firstDateWages: null,
      };
      break;

    case "EIN_BOI":
    case "INC_BOI":
    case "INC_States_BOI":
      initObj = { dateOfBirth: null, ssn: null, driverLicenceOrID: null };
      break;

    case "EIN_CertifiedCopyStateFee":
    case "EIN_CertifiedCopy":
    case "INC_CertifiedCopyStateFee":
    case "INC_CertifiedCopy":
    case "INC_States_CertifiedCopyStateFee":
    case "INC_States_CertifiedCopy":
    case "INC_States_DBA_State_Fee":
    case "INC_DBA_State_Fee":
    case "EIN_DBA_State_Fee":
    case "INC_States_DBA":
    case "INC_DBA":
    case "EIN_DBA":
    case "INC_CertificateOfGoodStanding":
    case "INC_CertificateOfGoodStandingStateFee":
    case "INC_States_CertificateOfGoodStanding":
    case "INC_States_CertificateOfGoodStandingStateFee":
      initObj = { state: "" };
      break;

    case "EIN_ResidentAgent":
      initObj = {
        state: null,
        webFileNumber: null,
        // processingResult: {
        //   companyName: null,
        //   email: null,
        // },
      };
      break;

    case "TRADEMARK_RegisterTrademark":
    case "TRADEMARK_RegisterTrademarkV2":
    case "INC_RegisterTrademark":
    case "INC_States_RegisterTrademark":
    case "INC_States_RegisterTrademarkV2":
      initObj = {
        trademarkType: "NAME",
        markDesc: null,
        includedName: "OWNER_NAME",
        includedNameValue: null,
        logoColoredVariety: false,
        colorsInLogo: null,
        literalElements: null,
        includeTranslationWords: false,
        translation: null,
        currentTrademark: false,
        firstUseAnywhere: null,
        firstUseInCommerce: null,
        individual: {
          firstName: null,
          middleName: null,
          lastName: null,
          suffixName: null,
          countryOfCitizenship: "US",
          email: null,
          phone: null,
          address: {
            address1: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
            country: "US",
            county: null,
          },
        },
        coOwners: [],
        organization: {
          usBased: true,
          name: "",
          type: "LLC",
          stateOrCountryOfFormation: null,
          position: "Owner",
        },
        classes: [],
        website: null,
        specimenDescription: null,
        // signatureRequestSent: null,
      };
      break;

    case "TRADEMARK_Trademark_Search_Federal_State":
      initObj = {
        searchTermType: "",
        logoImage: null,
        logoWords: null,
        text: "",
      };
      break;

    case "TRADEMARK_Trademark_Monitoring":
      initObj = {
        trademarkName: "",
        owner: null,
      };
      break;

    case "EIN_LLCIncorporation":
      initObj = {
        companyName: "",
        altCompanyName: "",
        organizedState: "",
        firstName: null,
        middleName: null,
        lastName: null,
        email: null,
        phone: null,
        corporateAddress: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "US",
          county: null,
        },
        mailingAddress: null,
        members: [],
        activityPrimaryActivity: "",
        activitySpecificProducts: "",
        productExtraQuestion1: null,
        productExtraQuestion2: null,
        productExtraQuestion3: null,
        productDescription: null,
        principalOfficer: {
          firstName: "",
          middleName: "",
          lastName: "",
          suffixName: null,
          countryOfCitizenship: null,
          email: "",
          phone: "",
          address: null,
        },
        owner: null,
        designator: null,
        managedByManagers: null,
        managedByMembers: null,
        acceptCreditCardPayments: false,
        questionsFirstLLC: false,
        questionsHaveEmployee: false,
        questionsStartedDoingBusiness: false,
        wvFormationQuestions: null,
        incorporatedDate: null,
      };
      break;

    default:
      initObj = {};
      break;
  }

  return { ...initObj, ...oldData, code: codeObj };
}

export function fillProductInitData(initObj, orderDetails) {
  const getFieldsForEinLlc = () => {
    const llcIncorporationProduct = orderDetails?.products?.find(
      (product) => checkIsIncorpify(product?.code?.code) || checkIsIncStates(product?.code?.code)
    );

    if (!llcIncorporationProduct) {
      return {};
    }

    return {
      legalName: llcIncorporationProduct?.companyName || initObj?.legalName,
      acceptCreditCardPayments: llcIncorporationProduct?.acceptCreditCardPayments || initObj?.acceptCreditCardPayments,
      activityPrimaryActivity: llcIncorporationProduct?.activityPrimaryActivity || initObj?.activityPrimaryActivity,
      activitySpecificProducts: llcIncorporationProduct?.activitySpecificProducts || initObj?.activitySpecificProducts,
      productDescription: llcIncorporationProduct?.productDescription || initObj?.productDescription,
      productExtraQuestion1: llcIncorporationProduct?.productExtraQuestion1 || initObj?.productExtraQuestion1,
      productExtraQuestion2: llcIncorporationProduct?.productExtraQuestion2 || initObj?.productExtraQuestion2,
      productExtraQuestion3: llcIncorporationProduct?.productExtraQuestion3 || initObj?.productExtraQuestion3,
      corpAddress: llcIncorporationProduct?.corporateAddress || initObj?.corpAddress,
      altAddress: llcIncorporationProduct?.mailingAddress || initObj?.altAddress,
      numberOfMembers: llcIncorporationProduct?.members?.length || initObj?.numberOfMembers,
      owner: llcIncorporationProduct?.owner || initObj?.owner,
      organizedState: llcIncorporationProduct?.organizedState || initObj?.organizedState,
      startDate: llcIncorporationProduct?.incorporatedDate
        ? moment(llcIncorporationProduct?.incorporatedDate).format("YYYY-MM-DDTHH:mm:ss")
        : initObj?.startDate,
    };
  };

  const getFieldsForEinLlcIncorporation = () => {
    const einMainProduct = orderDetails?.products?.find(
      (product) => product?.code?.main && product?.code?.category === "EIN"
    );

    if (!einMainProduct) {
      return {};
    }

    const principalOfficer = {
      ...initObj?.principalOfficer,
      firstName: einMainProduct?.owner?.firstName || "",
      middleName: einMainProduct?.owner?.middleName || "",
      lastName: einMainProduct?.owner?.lastName || "",
      suffixName: einMainProduct?.owner?.suffixName || null,
    };

    return {
      companyName: einMainProduct?.legalName || initObj?.companyName,
      activityPrimaryActivity: einMainProduct?.activityPrimaryActivity || initObj?.activityPrimaryActivity,
      activitySpecificProducts: einMainProduct?.activitySpecificProducts || initObj?.activitySpecificProducts,
      productDescription: einMainProduct?.productDescription || initObj?.productDescription,
      productExtraQuestion1: einMainProduct?.productExtraQuestion1 || initObj?.productExtraQuestion1,
      productExtraQuestion2: einMainProduct?.productExtraQuestion2 || initObj?.productExtraQuestion2,
      productExtraQuestion3: einMainProduct?.productExtraQuestion3 || initObj?.productExtraQuestion3,
      corporateAddress: einMainProduct?.corpAddress || initObj?.corporateAddress,
      mailingAddress: einMainProduct?.altAddress || initObj?.mailingAddress,
      principalOfficer: principalOfficer,
      owner: einMainProduct?.owner || initObj?.owner,
      organizedState: einMainProduct?.organizedState || initObj?.organizedState,
      incorporatedDate: einMainProduct?.startDate
        ? moment(einMainProduct?.startDate).format("YYYY-MM-DD")
        : initObj?.incorporatedDate,
    };
  };

  const getFieldsForTmMonitoring = () => {
    const einMainProduct = orderDetails?.products?.find(
      (product) => product?.code?.main && product?.code?.category === "EIN"
    );

    if (!einMainProduct) {
      return {};
    }

    return {
      owner: einMainProduct?.owner || initObj?.owner,
    };
  };

  const getFieldsForTmRegister = () => {
    const einMainProduct = orderDetails?.products?.find(
      (product) => product?.code?.main && product?.code?.category === "EIN"
    );

    if (!einMainProduct) {
      return {};
    }
    const initIndividual = initObj?.individual || {};
    const individual = {
      ...initIndividual,
      firstName: einMainProduct?.owner?.firstName || initObj?.individual?.firstName,
      middleName: einMainProduct?.owner?.middleName || initObj?.individual?.middleName,
      lastName: einMainProduct?.owner?.lastName || initObj?.individual?.lastName,
      suffixName: einMainProduct?.owner?.suffixName || initObj?.individual?.suffixName,
    };

    return {
      individual: individual,
    };
  };

  const getFieldsForStateFee = () => {
    const llcIncorporationProduct = orderDetails?.products?.find(
      (product) => checkIsIncorpify(product?.code?.code) || checkIsIncStates(product?.code?.code)
    );

    if (!llcIncorporationProduct) {
      return {};
    }

    return {
      state: llcIncorporationProduct?.organizedState || initObj?.state,
    };
  };

  const getFieldsForEinResidentAgent = () => {
    const llcIncorporationProduct = orderDetails?.products?.find((product) =>
      checkIsLLCIncorporation(product?.code?.code)
    );

    const einLlcProduct = orderDetails?.products?.find((product) => checkIsEinLlc(product?.code?.code));

    if (!llcIncorporationProduct && !einLlcProduct) {
      return {};
    }

    return {
      state: llcIncorporationProduct?.organizedState || einLlcProduct?.organizedState || initObj?.state,
      webFileNumber: initObj?.webFileNumber,
      // processingResult: {
      //   companyName:
      //     llcIncorporationProduct?.companyName || einLlcProduct?.legalName || initObj?.processingResult?.companyName,
      //   email:
      //     llcIncorporationProduct?.principalOfficer?.email ||
      //     einLlcProduct?.principalOfficer?.email ||
      //     initObj?.processingResult?.email,
      // },
    };
  };

  switch (initObj?.code?.code) {
    case "INC_EIN":
    case "INC_States_EIN":
    case "EIN_LLC":
      return { ...initObj, ...getFieldsForEinLlc() };

    case "EIN_StateIncorporationFee":
    case "EIN_CertifiedCopyStateFee":
    case "EIN_CertifiedCopy":
    case "INC_CertifiedCopyStateFee":
    case "INC_CertifiedCopy":
    case "INC_States_CertifiedCopyStateFee":
    case "INC_States_CertifiedCopy":
    case "INC_States_DBA_State_Fee":
    case "INC_DBA_State_Fee":
    case "EIN_DBA_State_Fee":
    case "INC_States_DBA":
    case "INC_DBA":
    case "EIN_DBA":
    case "INC_CertificateOfGoodStanding":
    case "INC_CertificateOfGoodStandingStateFee":
    case "INC_States_CertificateOfGoodStanding":
    case "INC_States_CertificateOfGoodStandingStateFee":
      return { ...initObj, ...getFieldsForStateFee() };

    case "EIN_ResidentAgent":
      return { ...initObj, ...getFieldsForEinResidentAgent() };

    case "EIN_LLCIncorporation":
      return { ...initObj, ...getFieldsForEinLlcIncorporation() };

    case "TRADEMARK_Trademark_Monitoring":
      return { ...initObj, ...getFieldsForTmMonitoring() };

    case "TRADEMARK_RegisterTrademark":
    case "TRADEMARK_RegisterTrademarkV2":
    case "INC_RegisterTrademark":
    case "INC_States_RegisterTrademark":
    case "INC_States_RegisterTrademarkV2":
      return { ...initObj, ...getFieldsForTmRegister() };

    default:
      return initObj;
  }
}

export function getAccountingProductCodes() {
  return [
    "ACCOUNTING_Monthly_bookkeeping",
    "EIN_Monthly_bookkeeping",
    "INC_Monthly_bookkeeping",
    "INC_States_Monthly_bookkeeping",
    "ACCOUNTING_Personal_Tax_filing",
    "ACCOUNTING_LLC_Tax_filing",
    "ACCOUNTING_Sole_Proprietor_Tax_filing",
    "ACCOUNTING_S_Corp_tax_filing",
    "ACCOUNTING_Partnership_tax_filing",
    "INC_States_Personal_Tax_filing",
    "INC_States_LLC_Tax_filing",
    "INC_States_Sole_Proprietor_Tax_filing",
    "INC_Personal_Tax_filing",
    "INC_LLC_Tax_filing",
    "INC_Sole_Proprietor_Tax_filing",
    "EIN_Personal_Tax_filing",
    "EIN_LLC_Tax_filing",
    "EIN_Sole_Proprietor_Tax_filing",
    "ACCOUNTING_Tax_Amendment",
    "EIN_Tax_Amendment",
    "INC_Tax_Amendment",
    "INC_States_Tax_Amendment",
  ];
}

export function getMainProductIndex(orderDetails = {}) {
  const mainProductIndex = orderDetails.products?.findIndex((product) => product.code.main);
  return mainProductIndex >= 0 ? mainProductIndex : undefined;
}

export function getMainProductDetails(orderDetails = {}, parentOrderDetails = {}) {
  const parentProductIndex = getMainProductIndex(parentOrderDetails);
  const productIndex = getMainProductIndex(orderDetails);

  let mainOrderDetails = null;
  let mainProductIndex = null;
  let mainProductDetails = null;
  let isParent = false;

  if (parentProductIndex !== undefined) {
    mainOrderDetails = parentOrderDetails;
    mainProductIndex = parentProductIndex;
    mainProductDetails = parentOrderDetails.products[parentProductIndex];
    isParent = true;
  }

  if (productIndex !== undefined) {
    mainOrderDetails = orderDetails;
    mainProductIndex = productIndex;
    mainProductDetails = orderDetails.products[productIndex];
    isParent = false;
  }

  return { mainOrderDetails, mainProductIndex, mainProductDetails, isParent };
}

export function getProductIndexByCode(orderDetails = {}, codes = []) {
  const productIndex = orderDetails.products?.findIndex((product) => codes.includes(product?.code?.code));
  return productIndex >= 0 ? productIndex : undefined;
}

export function getExtraProductDetailsByCode(orderDetails = {}, parentOrderDetails = {}, codes = []) {
  const parentProductIndex = getProductIndexByCode(parentOrderDetails, codes);
  const productIndex = getProductIndexByCode(orderDetails, codes);

  let extraOrderDetails = null;
  let extraProductIndex = null;
  let extraProductDetails = null;
  let isParent = false;

  if (parentProductIndex !== undefined) {
    extraOrderDetails = parentOrderDetails;
    extraProductIndex = parentProductIndex;
    extraProductDetails = parentOrderDetails.products[parentProductIndex];
    isParent = true;
  }

  if (productIndex !== undefined) {
    extraOrderDetails = orderDetails;
    extraProductIndex = productIndex;
    extraProductDetails = orderDetails.products[productIndex];
    isParent = false;
  }

  return { extraOrderDetails, extraProductIndex, extraProductDetails, isParent };
}
