import { accountingConstants } from "_constants";
import { handleError } from "_helpers";
import { accountingService, customerService } from "_services";
import { alertActions, customerActions } from "./";

export const accountingActions = {
  getFiles,
  downloadFile,
  uploadFile,
  deleteFile,
  refreshFilesFilters,
  getStatements,
  createStatement,
  uploadStatement,
  updateStatement,
  downloadStatement,
  deleteStatement,
  requestStatement,
  refreshStatementsFilters,
  getFileTypes,
  getChannel,
  getChannelOrders,
  getUpcomingDates,
  postUpcomingDate,
  deleteUpcomingDate,
};

function getFiles({ customerId, productId, type, year, page, count }) {
  return (dispatch) => {
    dispatch(request());

    accountingService.getFiles({ customerId, productId, type, year, page, count }).then(
      (files) => dispatch(success(files)),
      (error) => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Loading files error"
          )
        );
      }
    );
  };

  function request() {
    return { type: accountingConstants.GET_FILES_REQUEST };
  }
  function success(payload) {
    return { type: accountingConstants.GET_FILES_SUCCESS, payload };
  }
  function failure() {
    return { type: accountingConstants.GET_FILES_FAILURE };
  }
}

function uploadFile({ customerId, productId, body, startDate, endDate, description, type, year, filters }) {
  const { page, count } = filters || {};

  return (dispatch) => {
    dispatch(request());

    accountingService.uploadFile({ customerId, productId, body, startDate, endDate, description, type, year }).then(
      () => {
        dispatch(getFiles({ customerId, productId, page, count }));

        dispatch(alertActions.success("File uploaded successfully."));
        dispatch(success());
      },
      (error) => {
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Uploading file error"
        );
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: accountingConstants.UPLOAD_FILE_REQUEST };
  }
  function success() {
    return { type: accountingConstants.UPLOAD_FILE_SUCCESS };
  }
  function failure() {
    return { type: accountingConstants.UPLOAD_FILE_FAILURE };
  }
}

function downloadFile({ customerId, productId, fileId, fileName, checkMime }) {
  return (dispatch) => {
    accountingService.downloadFile({ customerId, productId, fileId, fileName, checkMime }).then(
      () => {},
      (error) => {
        dispatch(
          alertActions.error(handleError(error)?.message || error?.message || error?.toString() || "Getting file error")
        );
      }
    );
  };
}

function deleteFile({ customerId, productId, fileId, filters }) {
  const { page, count } = filters || {};

  return (dispatch) => {
    dispatch(request());

    accountingService.deleteFile({ customerId, productId, fileId }).then(
      () => {
        dispatch(getFiles({ customerId, productId, page, count }));

        dispatch(alertActions.success("File deleted successfully."));
        dispatch(success());
      },
      (error) => {
        alertActions.error(handleError(error)?.message || error?.message || error?.toString() || "Deleting file error");
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: accountingConstants.DELETE_FILE_REQUEST };
  }
  function success() {
    return { type: accountingConstants.DELETE_FILE_SUCCESS };
  }
  function failure() {
    return { type: accountingConstants.DELETE_FILE_FAILURE };
  }
}

function refreshFilesFilters(payload) {
  return { type: accountingConstants.REFRESH_FILES_FILTERS, payload };
}

function getStatements({ customerId, productId, type, status, year, page, count }) {
  return (dispatch) => {
    dispatch(request());

    accountingService.getStatements({ customerId, productId, type, status, year, page, count }).then(
      (files) => dispatch(success(files)),
      (error) => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Loading statements error"
          )
        );
      }
    );
  };

  function request() {
    return { type: accountingConstants.GET_STATEMENTS_REQUEST };
  }
  function success(payload) {
    return { type: accountingConstants.GET_STATEMENTS_SUCCESS, payload };
  }
  function failure() {
    return { type: accountingConstants.GET_STATEMENTS_FAILURE };
  }
}

function createStatement({
  customerId,
  productId,
  type,
  status,
  body,
  startDate,
  endDate,
  description,
  year,
  filters,
}) {
  const { page, count } = filters || {};

  return (dispatch) => {
    dispatch(request());

    accountingService
      .createStatement({ customerId, productId, type, status, body, startDate, endDate, description, year })
      .then(
        () => {
          dispatch(getStatements({ customerId, productId, page, count }));

          dispatch(alertActions.success("Statement created successfully."));
          dispatch(success());
        },
        (error) => {
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Creating statement error"
          );
          dispatch(failure());
        }
      );
  };

  function request() {
    return { type: accountingConstants.CREATE_STATEMENT_REQUEST };
  }
  function success() {
    return { type: accountingConstants.CREATE_STATEMENT_SUCCESS };
  }
  function failure() {
    return { type: accountingConstants.CREATE_STATEMENT_FAILURE };
  }
}

function uploadStatement({ customerId, productId, statementId, body, filters }) {
  const { page, count } = filters || {};

  return (dispatch) => {
    dispatch(request());

    accountingService.uploadStatement({ customerId, productId, statementId, body }).then(
      () => {
        dispatch(getStatements({ customerId, productId, page, count }));

        dispatch(alertActions.success("Statement uploaded successfully."));
        dispatch(success());
      },
      (error) => {
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Uploading statement error"
        );
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: accountingConstants.UPLOAD_STATEMENT_REQUEST };
  }
  function success() {
    return { type: accountingConstants.UPLOAD_STATEMENT_SUCCESS };
  }
  function failure() {
    return { type: accountingConstants.UPLOAD_STATEMENT_FAILURE };
  }
}

function updateStatement({ customerId, productId, statementId, body, filters }) {
  const { page, count } = filters || {};

  return (dispatch) => {
    dispatch(request());

    accountingService.updateStatement({ customerId, productId, statementId, body }).then(
      () => {
        dispatch(getStatements({ customerId, productId, page, count }));

        dispatch(alertActions.success("Statement updated successfully."));
        dispatch(success());
      },
      (error) => {
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Updating statement error"
        );
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: accountingConstants.UPDATE_STATEMENT_REQUEST };
  }
  function success() {
    return { type: accountingConstants.UPDATE_STATEMENT_SUCCESS };
  }
  function failure() {
    return { type: accountingConstants.UPDATE_STATEMENT_FAILURE };
  }
}

function downloadStatement({ customerId, productId, statementId, fileName, checkMime }) {
  return (dispatch) => {
    accountingService.downloadStatement({ customerId, productId, statementId, fileName, checkMime }).then(
      () => {},
      (error) => {
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Getting statement error"
          )
        );
      }
    );
  };
}

function deleteStatement({ customerId, productId, statementId, filters }) {
  const { page, count } = filters || {};

  return (dispatch) => {
    dispatch(request());

    accountingService.deleteStatement({ customerId, productId, statementId }).then(
      () => {
        dispatch(getStatements({ customerId, productId, page, count }));

        dispatch(alertActions.success("Statement deleted successfully."));
        dispatch(success());
      },
      (error) => {
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Deleting statement error"
        );
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: accountingConstants.DELETE_STATEMENT_REQUEST };
  }
  function success() {
    return { type: accountingConstants.DELETE_STATEMENT_SUCCESS };
  }
  function failure() {
    return { type: accountingConstants.DELETE_STATEMENT_FAILURE };
  }
}

function requestStatement({ customerId, productId, body, filters }) {
  const { page, count } = filters || {};

  return (dispatch) => {
    dispatch(request());

    accountingService.requestStatement({ customerId, productId, body }).then(
      () => {
        dispatch(getStatements({ customerId, productId, page, count }));

        dispatch(alertActions.success("Statement requested successfully."));
        dispatch(success());
      },
      (error) => {
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Requesting statement error"
        );
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: accountingConstants.REQUEST_STATEMENT_REQUEST };
  }
  function success() {
    return { type: accountingConstants.REQUEST_STATEMENT_SUCCESS };
  }
  function failure() {
    return { type: accountingConstants.REQUEST_STATEMENT_FAILURE };
  }
}

function refreshStatementsFilters(payload) {
  return { type: accountingConstants.REFRESH_STATEMENTS_FILTERS, payload };
}

function getFileTypes({ customerId, productId }) {
  return (dispatch) => {
    dispatch(request());

    accountingService.getFileTypes({ customerId, productId }).then(
      (fileTypes) => dispatch(success(fileTypes)),
      (error) => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Loading file types error"
          )
        );
      }
    );
  };

  function request() {
    return { type: accountingConstants.GET_FILE_TYPES_REQUEST };
  }
  function success(payload) {
    return { type: accountingConstants.GET_FILE_TYPES_SUCCESS, payload };
  }
  function failure() {
    return { type: accountingConstants.GET_FILE_TYPES_FAILURE };
  }
}

function getChannel({ customerId, orderId, productId }) {
  return (dispatch) => {
    dispatch(request());

    accountingService.getChannel({ customerId, productId }).then(
      (data) => {
        dispatch(success(data));
        dispatch(customerActions.getOrder(orderId));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Loading channel error"
          )
        );
      }
    );
  };

  function request() {
    return { type: accountingConstants.GET_CHANNEL_REQUEST };
  }
  function success(payload) {
    return { type: accountingConstants.GET_CHANNEL_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: accountingConstants.GET_CHANNEL_FAILURE, payload };
  }
}

function getChannelOrders(orderIds = []) {
  return (dispatch) => {
    dispatch(request());

    Promise.all(orderIds.map((orderId) => customerService.getOrder(orderId))).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Loading channel error"
          )
        );
      }
    );
  };

  function request() {
    return { type: accountingConstants.GET_CHANNEL_ORDERS_REQUEST };
  }
  function success(payload) {
    return { type: accountingConstants.GET_CHANNEL_ORDERS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: accountingConstants.GET_CHANNEL_ORDERS_FAILURE, payload };
  }
}

function getUpcomingDates({ code } = {}) {
  return (dispatch) => {
    dispatch(request());

    accountingService.getUpcomingDates({ code }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Loading Upcoming Dates error"
          )
        );
      }
    );
  };

  function request() {
    return { type: accountingConstants.GET_UPCOMING_DATES_REQUEST };
  }
  function success(payload) {
    return { type: accountingConstants.GET_UPCOMING_DATES_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: accountingConstants.GET_UPCOMING_DATES_FAILURE, payload };
  }
}

function postUpcomingDate({ body, onSuccess }) {
  return (dispatch) => {
    dispatch(request());

    accountingService.postUpcomingDate({ body }).then(
      () => {
        dispatch(success());
        dispatch(getUpcomingDates());
        onSuccess && onSuccess();
      },
      (error) => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Posting Upcoming Date error"
          )
        );
      }
    );
  };

  function request() {
    return { type: accountingConstants.POST_UPCOMING_DATE_REQUEST };
  }
  function success() {
    return { type: accountingConstants.POST_UPCOMING_DATE_SUCCESS };
  }
  function failure(payload) {
    return { type: accountingConstants.POST_UPCOMING_DATE_FAILURE };
  }
}

function deleteUpcomingDate({ id, onSuccess }) {
  return (dispatch) => {
    dispatch(request());

    accountingService.deleteUpcomingDate({ id }).then(
      () => {
        dispatch(success());
        dispatch(getUpcomingDates());
        onSuccess && onSuccess();
      },
      (error) => {
        dispatch(failure());
        dispatch(
          alertActions.error(
            handleError(error)?.message || error?.message || error?.toString() || "Deleting Upcoming Date error"
          )
        );
      }
    );
  };

  function request() {
    return { type: accountingConstants.DELETE_UPCOMING_DATE_REQUEST };
  }
  function success() {
    return { type: accountingConstants.DELETE_UPCOMING_DATE_SUCCESS };
  }
  function failure(payload) {
    return { type: accountingConstants.DELETE_UPCOMING_DATE_FAILURE };
  }
}
