import { GroupChannelProvider } from "@sendbird/uikit-react/GroupChannel/context";
import React from "react";

import CustomGroupChannelView from "./CustomGroupChannelView";

const CustomGroupChannel = ({ channelUrl, currentMessage, setSearchVisible, setSettingsVisible }) => {
  return (
    <GroupChannelProvider channelUrl={channelUrl}>
      <CustomGroupChannelView
        currentMessage={currentMessage}
        setSearchVisible={setSearchVisible}
        setSettingsVisible={setSettingsVisible}
      />
    </GroupChannelProvider>
  );
};

export default CustomGroupChannel;
