export const SOURCE_CODE = {
  customer: "CUSTOMER",
  xero: "XERO",
  plaid: "PLAID",
};

export const SOURCE_TITLE = {
  [SOURCE_CODE.customer]: "Customer",
  [SOURCE_CODE.xero]: "Xero",
  [SOURCE_CODE.plaid]: "Plaid",
};
