import { customerConstants } from "../_constants";

const initial_state = {
  summaryorders: null,
  loading: 0,
  error: null,
};

export function summaryorders(state = initial_state, action) {
  switch (action.type) {
    case customerConstants.GET_SUMMARY_REQUEST:
      return { ...state, loading: state.loading + 1, summaryorders: null, error: null };
    case customerConstants.GET_SUMMARY_SUCCESS:
      return { ...state, loading: state.loading - 1, summaryorders: action.payload || null, error: null };
    case customerConstants.GET_SUMMARY_FAILED:
      return { ...state, loading: state.loading - 1, summaryorders: null, error: action.payload || null };
    default:
      return state;
  }
}
