import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

import FormContainer from "components/OrderDetails/CreateOrder/FormComponents/FormContainer";

const initProduct = {
  searchTermType: "",
  logoImage: null,
  logoWords: null,
  text: "",
};

const ExtraFieldsContainer = styled.div`
  background-color: #ededed;
  border-left: 2px solid lightgray;
  margin-bottom: 1rem;
  padding: 12px;
`;

const TrademarkSearchFederalState = (props) => {
  const { orderDetails, productDetails, validated, onChange, onError } = props;

  const isLogo = productDetails?.searchTermType === "LOGO";

  const handleProductChange = ({ target: { name, value, values } }) => {
    const newObj = { ...productDetails };

    let exFields = { [name]: value };

    if (name === "searchTermType" && value !== "LOGO") {
      exFields = { ...exFields, logoImage: null, logoWords: null };
    }

    onChange({
      ...newObj,
      ...exFields,
    });
  };

  function handleAddError(error) {
    onError(error);
  }

  return (
    <FormContainer>
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group>
        <Form.Label>Trademark Type:</Form.Label>
        <Form.Control
          as="select"
          size="sm"
          name="searchTermType"
          value={productDetails.searchTermType || ""}
          onChange={handleProductChange}
          onInvalid={() => handleAddError("Trademark Type")}
          required
        >
          <option value="">Select type...</option>
          <option value="NAME">Name</option>
          <option value="SLOGAN">Slogan</option>
          <option value="LOGO">Logo</option>
        </Form.Control>
      </Form.Group>

      {isLogo && (
        <ExtraFieldsContainer>
          <Form.Group>
            <Form.Label>Logo Image:</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="text"
              value={productDetails.logoImage || ""}
              onChange={handleProductChange}
              onInvalid={() => handleAddError("Logo Image")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Logo Words:</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="logoWords"
              value={productDetails.logoWords || ""}
              onChange={handleProductChange}
              onInvalid={() => handleAddError("Logo Words")}
            />
          </Form.Group>
        </ExtraFieldsContainer>
      )}

      <Form.Group>
        <Form.Label>Trademark Description:</Form.Label>
        <Form.Control
          required
          size="sm"
          type="text"
          name="text"
          value={productDetails.text || ""}
          onChange={handleProductChange}
          onInvalid={() => handleAddError("Trademark Description")}
        />
      </Form.Group>
    </FormContainer>
  );
};

export default TrademarkSearchFederalState;
