import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { store, history, getRedirectLocation } from "_helpers";

import AdminLayout from "layouts/Admin";
import Redirect2FA from "views/Redirect2FA";
import { Login } from "views/Login";
import CheckAuth from "views/CheckAuth";
import { PrivateRoute } from "routes/PrivateRoute";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/graph.css";
import "./assets/css/collapse.css";
import "./assets/css/custom.css";

import "./assets/css/index.css";

const user = JSON.parse(localStorage.getItem("user"));
const redirectLocation = getRedirectLocation(user);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Router history={history}>
        <Switch>
          <PrivateRoute path="/admin" component={AdminLayout} />
          <Route path="/login" component={Login} />
          <Route path="/2fa" component={Redirect2FA} />
          <Route path="/check-auth" component={CheckAuth} />
          <Redirect from="*" to={redirectLocation} />
        </Switch>
      </Router>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
