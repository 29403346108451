import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { officeActionStatus, statementOfUseStatus } from "constants/trademarks.constants";

import IndividualView from "./Individual/View";

import StatusView from "../Status/View";

export default function FormTrademarkRevivalFromAbandonment({
  orderDetails,
  selectedProduct,
  onUpdateOrderStatus,
  onChange,
}) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">REVIVAL FROM ABANDOMENT TRADEMARK</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>

      <h6 className="font-weight-bold">Individual Information</h6>
      <IndividualView individual={productDetails.individual} />

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.serialNumber}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.trademarkName}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Trademark owner</h6>
      <IndividualView individual={productDetails.trademarkOwner} />

      <hr />
      {productDetails.officeActionStatus !== "NONE" && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Office action status:
          </Form.Label>
          <Form.Label column sm={7}>
            {officeActionStatus[productDetails.officeActionStatus]}
          </Form.Label>
        </Form.Group>
      )}

      {productDetails.statementOfUseStatus !== "NONE" && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Statement of use status:
          </Form.Label>
          <Form.Label column sm={7}>
            {statementOfUseStatus[productDetails.statementOfUseStatus]}
          </Form.Label>
        </Form.Group>
      )}
    </div>
  );
}
