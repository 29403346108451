import { useSelector } from "react-redux";

const useSummaryData = () => {
  const { loading, summaryorders } = useSelector((state) => state.summaryorders);

  return {
    isLoading: Boolean(loading),
    data: summaryorders || {
      countGroupedByEINStatus: {},
      countGroupedByOrderStatus: {},
    },
  };
};

export default useSummaryData;
