import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import clsx from "clsx";
import LibSelect from "react-select";

import { state_array, country_obj } from "variables/Variables";

export const initOrganization = {
  usBased: true,
  name: "",
  type: "",
  stateOrCountryOfFormation: "",
  position: "",
};

function Organization({ hidden, validated, organization: oldOrganization, onError, onChange }) {
  const [organization, setOrganization] = useState(initOrganization);
  const [stateFormation, setStateFormation] = useState(oldOrganization?.stateOrCountryOfFormation || "");

  const handleOrganizationChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    if (name === "suffixName" && value === "") exFields = { [name]: null };

    if (name === "usBased") {
      const isUSBased = value === "yes";

      if (isUSBased) {
        exFields = { [name]: isUSBased, stateOrCountryOfFormation: stateFormation };
      } else {
        setStateFormation(oldOrganization.stateOrCountryOfFormation);
        exFields = { [name]: isUSBased, stateOrCountryOfFormation: "US" };
      }
    }

    setOrganization({ ...organization, ...exFields });
    onChange({ ...oldOrganization, ...exFields });
  };

  const handleStateOrCountryOfFormationChange = (option) => {
    handleOrganizationChange({ target: { name: "stateOrCountryOfFormation", value: option.value } });
  };

  useEffect(() => {
    if (oldOrganization === true) {
      if (!organization) {
        setOrganization(initOrganization);
        onChange({ ...initOrganization });
      } else {
        onChange(organization);
      }
    } else if (oldOrganization !== null) {
      setOrganization(oldOrganization);
    }
  }, [oldOrganization]);

  if (hidden) return null;

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Formation
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="usBased_r_yes"
            className="mr-3"
            type="radio"
            label="US Based"
            name="usBased"
            value="yes"
            defaultChecked={oldOrganization.usBased}
            onClick={handleOrganizationChange}
          />
          <Form.Check
            id="usBased_r_no"
            type="radio"
            label="Non US Based"
            name="usBased"
            value="no"
            defaultChecked={!oldOrganization.usBased}
            onClick={handleOrganizationChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organization Name
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="name"
            value={oldOrganization.name || ""}
            onChange={handleOrganizationChange}
            onInvalid={() => onError("Organization Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organization Type
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="type"
            value={oldOrganization.type || ""}
            onChange={handleOrganizationChange}
            onInvalid={() => onError("Organization Type")}
          />
        </Col>
      </Form.Group>

      {oldOrganization.usBased && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            State of Formation
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              required
              hidden
              name="stateOrCountryOfFormation"
              value={oldOrganization.stateOrCountryOfFormation || ""}
              onInvalid={() => onError("State of Formation")}
              onChange={() => {}}
            />

            <LibSelect
              className="custom-select-container"
              classNamePrefix={clsx({
                ["custom-select"]: !validated,
                ["custom-select-valid"]: validated && oldOrganization.stateOrCountryOfFormation,
                ["custom-select-invalid"]: validated && !oldOrganization.stateOrCountryOfFormation,
              })}
              value={{
                value: oldOrganization.stateOrCountryOfFormation || "",
                label: state_array[oldOrganization.stateOrCountryOfFormation] || "Select a state...",
              }}
              options={[
                { value: "", label: "Select a state..." },
                ...Object.keys(state_array).reduce((res, key) => {
                  res.push({ value: key, label: state_array[key] });
                  return res;
                }, []),
              ]}
              onChange={handleStateOrCountryOfFormationChange}
            />
          </Col>
        </Form.Group>
      )}

      {!oldOrganization.usBased && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Country of Formation
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              required
              hidden
              name="stateOrCountryOfFormation"
              value={oldOrganization.stateOrCountryOfFormation || ""}
              onInvalid={() => onError("Country of Formation")}
              onChange={() => {}}
            />
            <LibSelect
              className="custom-select-container"
              classNamePrefix={clsx({
                ["custom-select"]: !validated,
                ["custom-select-valid"]: validated && oldOrganization.stateOrCountryOfFormation,
                ["custom-select-invalid"]: validated && !oldOrganization.stateOrCountryOfFormation,
              })}
              value={{
                value: oldOrganization.stateOrCountryOfFormation || "",
                label: country_obj[oldOrganization.stateOrCountryOfFormation] || "Select a country...",
              }}
              options={[
                { value: "", label: "Select a country..." },
                ...Object.keys(country_obj).reduce((res, key) => {
                  res.push({ value: key, label: country_obj[key] });
                  return res;
                }, []),
              ]}
              onChange={handleStateOrCountryOfFormationChange}
            />
          </Col>
        </Form.Group>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Position
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="position"
            value={oldOrganization.position || ""}
            onChange={handleOrganizationChange}
          />
        </Col>
      </Form.Group>
    </>
  );
}

export default Organization;
