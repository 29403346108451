import React, { useEffect, useRef, useState, useMemo } from "react";
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { PRODUCT_TASK_STATUS, PRODUCT_TASK_TYPE } from "variables/Variables";
import { productTasksActions } from "_actions";

import ConfirmationModal from "components/ConfirmationModal";
import TaskDetailsModal from "components/TaskDetailsModal";
import Paginate from "components/Paginate";

const Tasks = ({ orderDetails, selectedProduct }) => {
  const dispatch = useDispatch();
  const orderId = orderDetails?.order?.id;
  const productDetails = orderDetails?.products[selectedProduct];
  const productId = productDetails?.id;
  const productTasks = useSelector(({ productTasks }) => productTasks);
  const { data: tasksData, filters, loading } = productTasks || {};
  const isNoTasks = !tasksData?.elements?.length;

  const [type, setType] = useState(PRODUCT_TASK_TYPE.textRequest);
  const [taskId, setTaskId] = useState(null);
  const [createTaskDetailsShow, setCreateTaskDetailsShow] = useState(false);
  const [updateTaskDetailsShow, setUpdateTaskDetailsShow] = useState(false);
  const [deleteTaskDetailsShow, setDeleteTaskDetailsShow] = useState(false);

  const currentTaskData = useMemo(() => tasksData?.elements?.find(({ id }) => id === taskId), [taskId, tasksData]);

  const handlePageChange = (page) => {
    dispatch(productTasksActions.refreshProductTasksFilters({ page }));
  };

  const handleTypeFilterChange = ({ target: { value } }) => {
    dispatch(productTasksActions.refreshProductTasksFilters({ type: value }));
  };

  const handleStatusFilterChange = ({ target: { value } }) => {
    dispatch(productTasksActions.refreshProductTasksFilters({ status: value }));
  };

  const handleCreateTaskInit = () => {
    setCreateTaskDetailsShow(true);
  };

  const handleCreateTaskComplete = (details = {}) => {
    dispatch(productTasksActions.createProductTask({ orderId, productId, body: { productId, ...details }, filters }));
    setCreateTaskDetailsShow(false);
  };

  const handleCreateTaskClose = () => {
    setCreateTaskDetailsShow(false);
  };

  const handleUpdateTaskInit = (taskId) => () => {
    setTaskId(taskId);
    setUpdateTaskDetailsShow(true);
  };

  const handleUpdateTaskComplete = (details = {}) => {
    dispatch(
      productTasksActions.updateProductTask({ orderId, productId, taskId, body: { productId, ...details }, filters })
    );
    setUpdateTaskDetailsShow(false);
    setTaskId(null);
  };

  const handleUpdateTaskClose = () => {
    setUpdateTaskDetailsShow(false);
    setTaskId(null);
  };

  const handleDeleteTaskInit = (taskId) => () => {
    setTaskId(taskId);
    setDeleteTaskDetailsShow(true);
  };

  const handleDeleteTaskComplete = () => {
    dispatch(productTasksActions.deleteProductTask({ orderId, productId, taskId, filters }));
    setDeleteTaskDetailsShow(false);
    setTaskId(null);
  };

  const handleDeleteTaskClose = () => {
    setDeleteTaskDetailsShow(false);
    setTaskId(null);
  };

  useEffect(() => {
    if (filters) {
      dispatch(productTasksActions.getProductTasks({ orderId, productId, filters }));
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [filters]);

  useEffect(() => {
    if (orderId && productId) {
      dispatch(productTasksActions.getProductTasks({ orderId, productId, filters }));
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [orderId, productId]);

  if (!productDetails) return null;

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Tasks</h6>

      <Row>
        <Col xs={4} />
        <Col xs={4}>
          <Row>
            <Col sm={3} className="d-flex align-items-center">
              <h6 style={{ fontWeight: 400 }}>Type:</h6>
            </Col>
            <Col sm={9} style={{ marginBottom: 8 }}>
              <Form.Control as="select" size="sm" value={filters?.type} onChange={handleTypeFilterChange}>
                <option value="">All</option>
                {Object.values(PRODUCT_TASK_TYPE)?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col sm={3} className="d-flex align-items-center">
              <h6 style={{ fontWeight: 400 }}>Status:</h6>
            </Col>
            <Col sm={9} style={{ marginBottom: 8 }}>
              <Form.Control as="select" size="sm" value={filters?.status} onChange={handleStatusFilterChange}>
                <option value="">All</option>
                {Object.values(PRODUCT_TASK_STATUS)?.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Status</th>
            <th>Subject</th>
            <th>Message</th>
            <th>Response</th>
            <th colSpan={2}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {!isNoTasks && (
            <>
              {tasksData?.elements?.map(({ id, type, status, subject, message, response, updated }) => (
                <tr key={id}>
                  <td>{updated ? moment.utc(updated).local().format("YYYY/MM/DD HH:mm:ss") : "N/A"}</td>
                  <td>{type || "N/A"}</td>
                  <td>{status || "N/A"}</td>
                  <td>{subject || "N/A"}</td>
                  <td>{message || "N/A"}</td>
                  <td>{response || "N/A"}</td>
                  <td>
                    <Button size="sm" variant="success" className="w-100" onClick={handleUpdateTaskInit(id)}>
                      Update
                    </Button>
                  </td>
                  <td>
                    <Button size="sm" variant="danger" className="w-100" onClick={handleDeleteTaskInit(id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </>
          )}
          {isNoTasks && (
            <tr>
              <td>No tasks</td>
            </tr>
          )}
          <tr>
            <td colSpan={3} />
            <td>
              <div className="d-flex justify-content-end">Type:</div>
            </td>
            <td colSpan={2}>
              <Form.Control
                as="select"
                size="sm"
                value={type}
                onChange={({ target: { value } }) => {
                  setType(value);
                }}
              >
                {Object.values(PRODUCT_TASK_TYPE)?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </td>
            <td colSpan={2}>
              <Button variant="info" className="my-1 w-100" size="sm" onClick={handleCreateTaskInit}>
                Create Task
              </Button>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <Paginate
                page={tasksData?.page}
                totalPages={tasksData?.totalPages}
                loading={loading}
                handlePageChange={handlePageChange}
              />
            </td>
            <td colSpan={5} />
          </tr>
        </tbody>
      </Table>

      <TaskDetailsModal
        show={createTaskDetailsShow}
        initialData={{ type }}
        handleCancel={handleCreateTaskClose}
        handleConfirm={handleCreateTaskComplete}
      />

      <TaskDetailsModal
        show={updateTaskDetailsShow}
        initialData={currentTaskData}
        handleCancel={handleUpdateTaskClose}
        handleConfirm={handleUpdateTaskComplete}
      />

      <ConfirmationModal
        show={deleteTaskDetailsShow}
        handleClose={handleDeleteTaskClose}
        handleConfirm={handleDeleteTaskComplete}
        body={
          <div>
            <div>
              Are you sure you want to delete task: <b>{currentTaskData?.subject}</b>?
            </div>
          </div>
        }
      />
    </>
  );
};

export default Tasks;
