import React from "react";
import styled, { css } from "styled-components";
import moment from "moment";

import { PRODUCT_PRIORITY } from "variables/Variables";
import { PRIORITY_COLORS } from "views/Chats/constants";
import { CHANNEL_STATUS_TITLES, CHANNEL_STATUS_COLORS } from "_constants";
import { hexToRGBA } from "_helpers/theme";
import { getCompanyName } from "views/Chats/helpers";

import monexaLogoSrc from "assets/img/monexa-logo.png";
import Loader from "views/Chats/Loader";

function formatTimestamp(timestamp) {
  const messageTimeMs = moment.utc(timestamp).valueOf();
  const currentTimeMs = moment().valueOf();

  const diffTimeMs = currentTimeMs - messageTimeMs;
  const diffTimeMin = diffTimeMs / 1000 / 60;

  if (diffTimeMin < 60) {
    return `${Math.floor(diffTimeMin)}m`;
  }

  if (diffTimeMin < 60 * 24) {
    return `${Math.floor(diffTimeMin / 60)}hr`;
  }

  if (diffTimeMin < 60 * 24 * 7) {
    return `${Math.floor(diffTimeMin / 60 / 24)}d`;
  }

  if (diffTimeMin < 60 * 24 * 30) {
    return `${Math.floor(diffTimeMin / 60 / 24 / 7)}wk`;
  }

  if (diffTimeMin < 60 * 24 * 30 * 12) {
    return `${Math.floor(diffTimeMin / 60 / 24 / 30)}mo`;
  }

  return `${Math.floor(diffTimeMin / 60 / 24 / 30 / 12)}yr`;
}

const StyledOuterContainer = styled.div`
  display: flex;
  padding: 8px;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  gap: 8px;

  width: 100%;
  padding: 12px;
  border: 1px solid #dee3e6;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
  }

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid #edeefd;
      background-color: #edeefd;

      &:hover {
        background-color: #edeefd;
      }
    `}
`;

const StyledAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;

  border: 1px solid #dee3e6;
  border-radius: 50%;
  background-color: #fff;

  .sendbird-avatar {
    .sendbird-avatar-img {
      top: 0;
    }

    .sendbird-avatar-img--default {
      background-color: #fff;

      svg {
        path {
          fill: #641ec9;
        }
      }
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid #edeefd;
    `}
`;

const StyledMessageContainer = styled.div`
  flex-grow: 1;
`;

const StyledCompanyName = styled.div`
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 18px;
  font-weight: 700;
  color: #641ec9;
`;

const StyledCrmOrderId = styled.div`
  font-size: 12px;
  color: #606071;
  margin-bottom: 16px;
`;

const StyledMessageBody = styled.div`
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #606071;
`;

const StyledMetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

const StyledMessageInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const StyledMessageBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  padding: 4px;
  background-color: #641ec9;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  color: #ffffff;
`;

const StyledPriorityContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex-grow: 1;

  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}
`;

const StyledPriorityBullet = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 2px;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

const StyledPriorityTitle = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: #606071;
`;

const StyledStatusBadge = styled.div`
  padding: 6px;
  border-radius: 4px;

  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;

  ${({ color }) =>
    color &&
    css`
      background-color: ${hexToRGBA(color, 0.1)};
      color: ${color};
    `}
`;

const StyledMessageTimestamp = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: #606071;
`;

export default function ChannelListItem({ index, style, data }) {
  const { elements, currentChannel, orders, isItemLoaded, onSelect } = data || {};
  const channel = elements[index];
  const { orderId, priority } = channel || {};
  const orderData = orders[orderId] || {};
  const crmOrderId = orderData?.order?.crmOrderId;

  const handleSelect = () => {
    onSelect && onSelect(channel);
  };

  let content = <Loader />;

  if (isItemLoaded(index)) {
    content = (
      <StyledInnerContainer
        selected={isItemLoaded(index) && channel?.channelUrl === currentChannel?.channelUrl}
        onClick={handleSelect}
      >
        <StyledAvatarContainer selected={isItemLoaded(index) && channel?.channelUrl === currentChannel?.channelUrl}>
          <img src={monexaLogoSrc} alt="Monexa Logo" />
        </StyledAvatarContainer>

        <StyledMessageContainer>
          <StyledCompanyName title={getCompanyName(orderData)}>{getCompanyName(orderData)}</StyledCompanyName>
          <StyledCrmOrderId>#{crmOrderId}</StyledCrmOrderId>
          <StyledMessageBody title={channel?.lastMessage}>{channel?.lastMessage}</StyledMessageBody>
        </StyledMessageContainer>

        <StyledMetaContainer>
          <StyledMessageInfo>
            {Boolean(channel?.countOfUnreadMessages) && (
              <StyledMessageBadge>{channel?.countOfUnreadMessages}</StyledMessageBadge>
            )}
            <StyledMessageTimestamp>{formatTimestamp(channel?.lastMessageTimestamp)}</StyledMessageTimestamp>
          </StyledMessageInfo>
          <StyledPriorityContainer opacity={priority ? 1 : 0.5}>
            <StyledPriorityBullet color={PRIORITY_COLORS[priority || PRODUCT_PRIORITY.unset]} />
            <StyledPriorityTitle>{priority || PRODUCT_PRIORITY.unset}</StyledPriorityTitle>
          </StyledPriorityContainer>
          <StyledStatusBadge color={CHANNEL_STATUS_COLORS[channel?.status]}>
            {CHANNEL_STATUS_TITLES[channel?.status] || channel?.status}
          </StyledStatusBadge>
        </StyledMetaContainer>
      </StyledInnerContainer>
    );
  }

  return <StyledOuterContainer style={{ ...style }}>{content}</StyledOuterContainer>;
}
