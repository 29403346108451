import { useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";

export default function ActivityIncEdit({ productDetails, onError, onProductChange }) {
  const { primaryactivity } = useSelector((state) => state);

  if (!primaryactivity.length) return null;

  const isEinLLC = productDetails?.code?.code === "EIN_LLCIncorporation";

  const findElement = (element) => element.code === productDetails.activityPrimaryActivity;

  const productActivity = primaryactivity.find(findElement) || {};

  const getExtraQuestion = (object, value) => {
    for (let i = 0; i < object.length; i++) {
      const item = object[i];

      if (item.code === value) return item;
    }

    return null;
  };

  const extraQuestions = (data, depth) => {
    if (!data) return null;

    return (
      <>
        {data.labelForDescriptionField && (
          <>
            <Form.Label column sm={5}>
              {data.labelForDescriptionField}
            </Form.Label>
            <Form.Label column sm={7}>
              <Form.Control
                required
                size="sm"
                name="productDescription"
                value={productDetails.productDescription || ""}
                onChange={onProductChange}
                onInvalid={() => onError(data.labelForDescriptionField)}
              />
            </Form.Label>
          </>
        )}
        {data.extraQuestionOptions.length > 0 && (
          <>
            <Form.Label column sm={5}>
              {data.extraQuestion}
            </Form.Label>
            <Form.Label column sm={7}>
              <Form.Control
                required
                as="select"
                size="sm"
                name={"productExtraQuestion" + depth}
                value={productDetails["productExtraQuestion" + depth] || ""}
                onChange={onProductChange}
              >
                <option value="">Please select</option>
                {data.extraQuestionOptions.map((item, key) => (
                  <option key={key} value={item.code}>
                    {item.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Label>
            {extraQuestions(
              getExtraQuestion(data.extraQuestionOptions, productDetails["productExtraQuestion" + depth]),
              Number(depth + 1)
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Primary Activity
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={!isEinLLC}
            as="select"
            size="sm"
            name="activityPrimaryActivity"
            value={productDetails.activityPrimaryActivity || ""}
            onChange={onProductChange}
          >
            <option value="">Please select</option>
            {primaryactivity.map((item, key) => (
              <option key={key} value={item.code}>
                {item.label}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        {productActivity?.labelForDescriptionField && (
          <>
            <Form.Label column sm={5}>
              {productActivity?.labelForDescriptionField}
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                name="productDescription"
                value={productDetails.productDescription || ""}
                onChange={onProductChange}
              />
            </Col>
          </>
        )}
        {productActivity?.specificProducts?.length > 0 && (
          <>
            <Form.Label column sm={5}>
              {productActivity?.specificProductsLabel || "Specific Products/Services"}
            </Form.Label>
            <Form.Label column sm={7}>
              <Form.Control
                required
                as="select"
                size="sm"
                name="activitySpecificProducts"
                value={productDetails.activitySpecificProducts || ""}
                onChange={onProductChange}
              >
                <option value="">Please select</option>
                {productActivity?.specificProducts.map((item, key) => (
                  <option key={key} value={item.code}>
                    {item.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Label>

            {productDetails.activitySpecificProducts &&
              extraQuestions(
                getExtraQuestion(productActivity?.specificProducts, productDetails.activitySpecificProducts),
                1
              )}
          </>
        )}
      </Form.Group>
    </>
  );
}
