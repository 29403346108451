import React from "react";
import styled, { css } from "styled-components";
import { hexToRGBA } from "_helpers/theme";

const StyledButton = styled.button`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  background-color: unset;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  text-decoration: none;

  ${({ secondary }) =>
    secondary &&
    css`
      border: 1px solid #dee3e6;
    `}

  ${({ color }) =>
    color &&
    css`
      border: 1px solid ${color};
      background-color: ${color};
      color: #ffffff;
    `}

    ${({ color, disabled }) =>
    color &&
    disabled &&
    css`
      border: 1px solid ${hexToRGBA("#606071", 0.1)};
      background-color: ${hexToRGBA("#606071", 0.4)};
    `}
`;

export default function Button({ secondary, color, ...rest }) {
  return <StyledButton secondary={secondary} color={color} {...rest} />;
}
