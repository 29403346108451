import React from "react";
import { Col, Form } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";

import { includedName } from "constants/trademarks.constants";
import FormContainer from "components/OrderDetails/CreateOrder/FormComponents/FormContainer";
import CoOwnerListEdit from "components/OrderDetails/ProductDetails/TrademarkForms/CoOwnerList/Edit";
import IndividualEdit from "components/OrderDetails/ProductDetails/TrademarkForms/Individual/Edit";
import OrganizationEdit from "components/OrderDetails/ProductDetails/TrademarkForms/Organization/Edit";
import TrademarkClassesListEdit from "components/OrderDetails/ProductDetails/TrademarkForms/TrademarkClassesList/EditAsync";

const initProduct = {
  trademarkType: "NAME",
  markDesc: "",
  logoColoredVariety: false,
  colorsInLogo: "",
  literalElements: "",
  includedName: "NONE",
  includedNameValue: "",
  includeTranslationWords: false,
  translation: "",
  currentTrademark: false,
  firstUseAnywhere: "",
  firstUseInCommerce: "",
  specimenDescription: "",
  website: "",
  organization: false,
};

const ExtraFieldsContainer = styled.div`
  background-color: #ededed;
  border-left: 2px solid lightgray;
  margin-bottom: 1rem;
  padding: 12px;
`;

const TrademarkRegister = (props) => {
  const { orderDetails, productDetails, validated, onChange, onError } = props;

  const isLogo = productDetails?.trademarkType === "LOGO";
  const isOrganization = Boolean(productDetails?.organization);

  const handleProductChange = ({ target: { name, value, values } }) => {
    const newObj = { ...productDetails };

    let exFields = { [name]: value };

    if (name === "includedName" && value === "NONE") exFields = { ...exFields, includedNameValue: "" };

    if (name === "trademarkType" && value !== "LOGO")
      exFields = { ...exFields, logoColoredVariety: false, colorsInLogo: "", literalElements: "" };

    if (name === "logoColoredVariety") {
      exFields = { [name]: value === "yes" };

      if (value === "yes") exFields = { ...exFields, colorsInLogo: "" };
    }

    if (name === "includeTranslationWords") {
      exFields = { [name]: value === "yes" };

      if (value !== "yes") exFields = { ...exFields, translation: "" };
    }

    if (name === "currentTrademark") {
      exFields = { [name]: value === "yes" };

      if (value !== "yes") {
        exFields = { ...exFields, firstUseAnywhere: null, firstUseInCommerce: null };
      }
    }

    if (name === "identifyOwner") {
      exFields = { organization: value === "Organization" ? true : null };

      if (value === "Organization") exFields = { ...exFields, coOwners: [] };
    }

    onChange({
      ...newObj,
      ...exFields,
    });
  };

  // const handleProductOwnerChange = ({ target: { name, value } }) =>
  //   handleProductChange({
  //     target: {
  //       name: "owner",
  //       value: {
  //         ...productDetails?.owner,
  //         [name]: ["suffixName", "ssn"].includes(name) && value === "" ? null : value,
  //       },
  //     },
  //   });

  const handleProductCoOwnersChange = (value) => handleProductChange({ target: { name: "coOwners", value } });
  const handleProductClassesChange = (value) => handleProductChange({ target: { name: "classes", value } });
  const handleProductOrganizationChange = (value) => handleProductChange({ target: { name: "organization", value } });
  const handleProductIndividualChange = (value) => handleProductChange({ target: { name: "individual", value } });

  function handleAddError(error) {
    onError(error);
  }

  return (
    <FormContainer>
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group>
        <Form.Label>Trademark Type:</Form.Label>
        <Form.Control
          as="select"
          size="sm"
          name="trademarkType"
          value={productDetails.trademarkType || ""}
          onChange={handleProductChange}
        >
          <option value="NAME">Name</option>
          <option value="SLOGAN">Slogan</option>
          <option value="LOGO">Logo</option>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Trademark Description:</Form.Label>
        <Form.Control
          required
          size="sm"
          type="text"
          name="markDesc"
          value={productDetails.markDesc || ""}
          onChange={handleProductChange}
          onInvalid={() => handleAddError("Trademark Description")}
        />
      </Form.Group>

      {isLogo && (
        <ExtraFieldsContainer>
          <Form.Group>
            <Form.Label>Do you want to be able to protect your logo in a variety of colors?</Form.Label>
            <Col className="d-flex align-items-center">
              <Form.Check
                id="logoColoredVariety_r_no"
                type="radio"
                label="No"
                name="logoColoredVariety"
                className="mr-3"
                disabled={!isLogo}
                value="no"
                checked={!productDetails.logoColoredVariety}
                onChange={handleProductChange}
              />
              <Form.Check
                id="logoColoredVariety_r_yes"
                type="radio"
                label="Yes"
                name="logoColoredVariety"
                disabled={!isLogo}
                value="yes"
                checked={productDetails.logoColoredVariety}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>

          <Form.Group>
            <Form.Label>Colors list:</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="colorsInLogo"
              disabled={!isLogo || productDetails.logoColoredVariety}
              value={productDetails.colorsInLogo || ""}
              onChange={handleProductChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Logo:</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="literalElements"
              disabled={!isLogo}
              value={productDetails.literalElements || ""}
              onChange={handleProductChange}
              onInvalid={() => handleAddError("Logo")}
            />
          </Form.Group>
        </ExtraFieldsContainer>
      )}

      <Form.Group>
        <Form.Label>Include Name:</Form.Label>
        <Form.Control
          as="select"
          size="sm"
          name="includedName"
          value={productDetails.includedName}
          onChange={handleProductChange}
        >
          {Object.keys(includedName).map((key) => (
            <option key={key} value={key}>
              {includedName[key]}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Name:</Form.Label>
        <Form.Control
          required
          size="sm"
          type="text"
          name="includedNameValue"
          disabled={productDetails.includedName === "NONE"}
          value={productDetails.includedNameValue || ""}
          onChange={handleProductChange}
          onInvalid={() => handleAddError("Included Name")}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Does your mark include any words other than English or non-Latin characters?</Form.Label>
        <Col className="d-flex align-items-center">
          <Form.Check
            id="includeTranslationWords_r_no"
            className="mr-3"
            type="radio"
            label="No"
            name="includeTranslationWords"
            value="no"
            defaultChecked={!productDetails.includeTranslationWords}
            onChange={handleProductChange}
          />
          <Form.Check
            id="includeTranslationWords_r_yes"
            type="radio"
            label="Yes"
            name="includeTranslationWords"
            value="yes"
            defaultChecked={productDetails.includeTranslationWords}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      {productDetails?.includeTranslationWords && (
        <ExtraFieldsContainer>
          <Form.Group>
            <Form.Label>English translation:</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="translation"
              disabled={!productDetails.includeTranslationWords}
              value={productDetails.translation || ""}
              onChange={handleProductChange}
            />
          </Form.Group>
        </ExtraFieldsContainer>
      )}

      <Form.Group>
        <Form.Label>Are you currently using this trademark in your business?</Form.Label>
        <Col className="d-flex align-items-center">
          <Form.Check
            id="currentTrademark_r_no"
            className="mr-3"
            type="radio"
            label="No"
            name="currentTrademark"
            value="no"
            defaultChecked={!productDetails.currentTrademark}
            onChange={handleProductChange}
          />
          <Form.Check
            id="currentTrademark_r_yes"
            type="radio"
            label="Yes"
            name="currentTrademark"
            value="yes"
            defaultChecked={productDetails.currentTrademark}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      {productDetails?.currentTrademark && (
        <ExtraFieldsContainer>
          <Form.Group>
            <Form.Label>First use anywhere:</Form.Label>
            <Form.Control
              type="date"
              name="firstUseAnywhere"
              max={moment().format("YYYY-MM-DD")}
              disabled={!productDetails.currentTrademark}
              value={productDetails.firstUseAnywhere || ""}
              onChange={handleProductChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>First use in commerce:</Form.Label>
            <Form.Control
              type="date"
              name="firstUseInCommerce"
              max={moment().format("YYYY-MM-DD")}
              disabled={!productDetails.currentTrademark}
              value={productDetails.firstUseInCommerce || ""}
              onChange={handleProductChange}
            />
          </Form.Group>
        </ExtraFieldsContainer>
      )}

      <Form.Group>
        <Form.Label>Website:</Form.Label>
        <Form.Control
          size="sm"
          type="text"
          name="website"
          value={productDetails.website || ""}
          onChange={handleProductChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Specimen Description:</Form.Label>
        <Form.Control
          size="sm"
          type="text"
          name="specimenDescription"
          value={productDetails.specimenDescription || ""}
          onChange={handleProductChange}
        />
      </Form.Group>

      <hr />
      <Form.Group>
        <Form.Label>Identify the owner of the trademark</Form.Label>
        <Col className="d-flex align-items-center">
          <Form.Check
            id="identifyOwner_r_no"
            className="mr-3"
            type="radio"
            label="Individual"
            name="identifyOwner"
            value="Individual"
            defaultChecked={!productDetails.organization}
            onChange={handleProductChange}
          />
          <Form.Check
            id="identifyOwner_r_yes"
            type="radio"
            label="Organization"
            name="identifyOwner"
            value="Organization"
            defaultChecked={productDetails.organization}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      {isOrganization && (
        <ExtraFieldsContainer>
          <h6>Organization</h6>
          <OrganizationEdit
            hidden={!isOrganization}
            validated={validated}
            organization={productDetails.organization}
            onError={handleAddError}
            onChange={handleProductOrganizationChange}
          />
        </ExtraFieldsContainer>
      )}

      <ExtraFieldsContainer>
        <h6>Individual</h6>
        <IndividualEdit
          validated={validated}
          isOrganization={isOrganization}
          individual={productDetails.individual}
          onError={handleAddError}
          onChange={handleProductIndividualChange}
        />
      </ExtraFieldsContainer>

      {!isOrganization && (
        <ExtraFieldsContainer>
          {/* <h6>Co-Owners List</h6> */}
          <CoOwnerListEdit
            isCompact
            hidden={isOrganization}
            validated={validated}
            coOwners={productDetails.coOwners}
            onError={handleAddError}
            onChange={handleProductCoOwnersChange}
          />
        </ExtraFieldsContainer>
      )}

      <ExtraFieldsContainer>
        {/* <h6>Trademark Classes List</h6> */}
        <TrademarkClassesListEdit
          isCompact
          validated={validated}
          label="Trademark Classes"
          classes={productDetails.classes}
          onError={handleAddError}
          onChange={handleProductClassesChange}
        />
      </ExtraFieldsContainer>
    </FormContainer>
  );
};

export default TrademarkRegister;
