import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useChannelSettingsContext } from "@sendbird/uikit-react/ChannelSettings/context";
import { Header } from "@sendbird/uikit-react/ui/Header";
import { IconColors, IconTypes } from "@sendbird/uikit-react/ui/Icon";

import { parseChannelData } from "views/Chats/helpers";
import { chatsActions, notificationsActions } from "_actions";

import UserInfo from "./UserInfo";
import MembersInfo from "./MembersInfo";
import InviteUsersPannel from "./InviteUsersPannel";
import CloseChannelPannel from "./CloseChannelPannel";
import LeaveChannelPannel from "./LeaveChannelPannel";
import Button from "views/Chats/ui/Button";

const StyledOuterContainer = styled.div`
  overflow-x: hidden;
`;

const StyledInnerContainer = styled.div`
  position: relative;
`;

const StyledBody = styled.div``;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 8px;
`;

const StyledLeaveLink = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledFrontDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 1000;

  @keyframes slideIn {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  animation: ease-out;
  animation-name: slideIn;
  animation-duration: 300ms;
`;

const LeaveIcon = () => (
  <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.4338 14.1723C30.8243 13.7818 30.8243 13.1486 30.4338 12.7581L24.0698 6.39414C23.6793 6.00362 23.0461 6.00362 22.6556 6.39414C22.2651 6.78467 22.2651 7.41783 22.6556 7.80836L28.3125 13.4652L22.6556 19.1221C22.2651 19.5126 22.2651 20.1458 22.6556 20.5363C23.0461 20.9268 23.6793 20.9268 24.0698 20.5363L30.4338 14.1723ZM7.72668 14.4652L29.7267 14.4652V12.4652L7.72668 12.4652V14.4652Z"
      fill="#C04F4F"
    />
    <path
      d="M17.7267 1.46521H3.72668C2.62212 1.46521 1.72668 2.36064 1.72668 3.46521V23.4652C1.72668 24.5698 2.62212 25.4652 3.72668 25.4652H17.7267"
      stroke="#C04F4F"
      stroke-width="2"
    />
  </svg>
);

const CustomChannelSettingsView = ({ setSettingsVisible }) => {
  const dispatch = useDispatch();
  const { channel } = useChannelSettingsContext();
  const { orderId, productId } = parseChannelData(channel?.data) || {};
  const user = useSelector((state) => state.authentication.user);
  const { channels: allChannels, users } = useSelector((state) => state.chats);
  const { closedChannels } = useSelector((state) => state.notifications);
  const isChannelClosed = Boolean(closedChannels?.data?.elements?.length);
  const [inviteUsersPannelVisible, setInviteUsersPannelVisible] = useState(false);
  const [closeChannelPannelVisible, setCloseChannelPannelVisible] = useState(false);
  const [leaveChannelPannelVisible, setLeaveChannelPannelVisible] = useState(false);

  const handleClose = () => {
    setSettingsVisible(false);
  };

  const handleInviteUsersPannelClose = () => {
    setInviteUsersPannelVisible(false);
  };

  const handleInviteUsersPannelOpen = () => {
    setInviteUsersPannelVisible(true);
  };

  const handleCloseChannelPannelClose = () => {
    setCloseChannelPannelVisible(false);
    setSettingsVisible(false);
  };

  const handleCloseChannelPannelOpen = () => {
    setCloseChannelPannelVisible(true);
  };

  const handleLeaveChannelPannelClose = () => {
    setLeaveChannelPannelVisible(false);
    setSettingsVisible(false);
  };

  const handleLeaveChannelPannelOpen = () => {
    setLeaveChannelPannelVisible(true);
  };

  const handleMembersAdd = (userIds) => {
    dispatch(
      chatsActions.addMembers({
        userIds,
        channelUrl: channel?.url,
        cb: () => {
          dispatch(chatsActions.resetUsers());
          dispatch(chatsActions.getUsers({ ...users?.filters, page: 1 }));
        },
      })
    );
    setInviteUsersPannelVisible(false);
  };

  const handleChannelClose = () => {
    dispatch(
      notificationsActions.closeChannel({
        userId: user?.id,
        orderId,
        productId,
        cb: () => {
          dispatch(chatsActions.resetAllChannels());
          dispatch(chatsActions.getAllChannels({ ...allChannels?.filters, page: 1 }));
        },
      })
    );
    setCloseChannelPannelVisible(false);
  };

  const handleChannelLeave = () => {
    dispatch(
      notificationsActions.leaveChannel({
        userId: user?.id,
        orderId,
        channelUrl: channel?.url,
        cb: () => {
          dispatch(chatsActions.resetAllChannels());
          dispatch(chatsActions.getAllChannels({ ...allChannels?.filters, page: 1 }));
        },
      })
    );
    setLeaveChannelPannelVisible(false);
    setSettingsVisible(false);
  };

  return (
    <StyledOuterContainer>
      <StyledInnerContainer>
        {inviteUsersPannelVisible && (
          <StyledFrontDrop>
            <InviteUsersPannel onClose={handleInviteUsersPannelClose} onConfirm={handleMembersAdd} />
          </StyledFrontDrop>
        )}
        {closeChannelPannelVisible && (
          <StyledFrontDrop>
            <CloseChannelPannel onClose={handleCloseChannelPannelClose} onConfirm={handleChannelClose} />
          </StyledFrontDrop>
        )}
        {leaveChannelPannelVisible && (
          <StyledFrontDrop>
            <LeaveChannelPannel onClose={handleLeaveChannelPannelClose} onConfirm={handleChannelLeave} />
          </StyledFrontDrop>
        )}
        <Header
          className="sendbird-channel-settings__header"
          renderMiddle={() => <Header.Title title="Channel Information" />}
          renderRight={() => (
            <Header.IconButton
              className="sendbird-channel-settings__header__close-button"
              onClick={handleClose}
              type={IconTypes.CLOSE}
              color={IconColors.ON_BACKGROUND_1}
            />
          )}
        />
        <StyledBody>
          <UserInfo channel={channel} />
          <MembersInfo channel={channel} />
        </StyledBody>
        <StyledFooter>
          <Button secondary onClick={handleInviteUsersPannelOpen}>
            Invite Users
          </Button>
          <Button color="#D13E27" disabled={isChannelClosed} onClick={handleCloseChannelPannelOpen}>
            Close channel
          </Button>
          <StyledLeaveLink onClick={handleLeaveChannelPannelOpen}>
            <LeaveIcon />
            <span>Leave Channel</span>
          </StyledLeaveLink>
        </StyledFooter>
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default CustomChannelSettingsView;
