import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Container, Row, Col, Card, Pagination, Form, Modal } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import styled, { css } from "styled-components";

import { userActions, alertActions, variantsActions } from "_actions";
import { getIndicator } from "_helpers";

import { ReactComponent as EmailSVG } from "assets/img/Email.svg";
import { ReactComponent as EmailTemplateSVG } from "assets/img/EmailTemplate.svg";
import { ReactComponent as PhoneSVG } from "assets/img/Phone.svg";

import Spinner from "components/Spinner";
import Button from "components/Button";

const ColorIndicator = styled.td`
  padding: 0 !important;

  ${({ status }) => {
    switch (status) {
      case "disabled":
        return css`
          background-color: #bcbcbc;
        `;
      case "account-manager":
        return css`
          background-color: #a5d6a7;
        `;
      case "user":
        return css`
          background-color: #b3e5fc;
        `;
      case "legal-team":
        return css`
          background-color: #b39ddb;
        `;
      case "admin":
        return css`
          background-color: #ef9a9a;
        `;
      case "developer":
        return css`
          background-color: #ffe082;
        `;
      default:
        return css`
          background-color: #ffffff;
        `;
    }
  }}
`;

const StyledTextButton = styled.span`
  background-color: #a5d6a777;
  border-radius: 12px;
  padding: 0px 4px;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ROLES = {
  USER: "User",
  ADMIN: "Admin",
  ATTORNEY: "Legal Team",
  DEVELOPER: "Developer",
  BOOKKEEPER: "Bookkeeper",
  TAX_PREPARER: "Tax Preparer",
};

const initUser = {
  id: "",
  name: "",
  email: "",
  username: "",
  password: "",
  status: "Active",
  role: "USER",
  availableCategories: [],
  managedCategories: [],
  phone: "",
  accountManager: false,
  requires2FA: false,
  welcomeEmailTemplates: {},
  supportEmails: {},
};

const USERS_ON_PAGE = 25;

const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const variants = useSelector((state) => state.variants);
  const [page, setPage] = useState(1);
  const [editing, setEditing] = useState(false);
  const [searchByName, setSearchByName] = useState("");
  const [searchByRole, setSearchByRole] = useState("");
  const [includeDisabled, setIncludeDisabled] = useState("");
  const [onlyAccountManager, setOnlyAccountManager] = useState(false);
  const [showSupportDetailsModal, setShowSupportDetailsModal] = useState(null);
  const [user, setUser] = useState(initUser);

  const isLoading = users.loading > 0;

  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  };

  const cleanUnavailableEmails = (managedCategoriesList) => {
    if (!managedCategoriesList.length) {
      setUser((prev) => ({
        ...prev,
        supportEmails: {},
        welcomeEmailTemplates: {},
      }));
      return;
    }

    const allowedManagedCategoriesArray = managedCategoriesList.map((item) => item.value);

    const supportEmails = user.supportEmails;
    const welcomeEmailTemplates = user.welcomeEmailTemplates;

    Object.keys(supportEmails).forEach((item) => {
      if (!allowedManagedCategoriesArray.includes(item)) {
        delete supportEmails[item];
      }
    });

    Object.keys(welcomeEmailTemplates).forEach((item) => {
      if (!allowedManagedCategoriesArray.includes(item)) {
        delete welcomeEmailTemplates[item];
      }
    });

    setUser((prev) => ({
      ...prev,
      supportEmails,
      welcomeEmailTemplates,
    }));
  };

  const clearUnavailableManagedCategories = (availableCategoriesList) => {
    const allowedCategoriesArray = availableCategoriesList.map((item) => item.value);
    const filteredManagedCategories = user.managedCategories.filter((category) =>
      allowedCategoriesArray.includes(category.value)
    );

    cleanUnavailableEmails(filteredManagedCategories);

    setUser((prev) => ({
      ...prev,
      managedCategories: filteredManagedCategories,
    }));
  };

  const handleAvailableCategoriesChange = (value, event) => {
    clearUnavailableManagedCategories(value);
    setUser((prev) => ({ ...prev, [event.name]: value }));
  };

  const handleManagedCategoriesChange = (value, event) => {
    const categories = [...value];
    const selectedCategory = event.option.value;
    const isSelectAction = event.action === "select-option";

    const sortedCategories = categories.sort((a, b) => a.value.localeCompare(b.value));

    cleanUnavailableEmails(sortedCategories);

    setUser((prev) => ({ ...prev, [event.name]: sortedCategories }));

    if (isSelectAction) {
      if (!Object.keys(user.welcomeEmailTemplates).includes(selectedCategory)) {
        setUser((prev) => ({
          ...prev,
          welcomeEmailTemplates: {
            ...prev.welcomeEmailTemplates,
            [selectedCategory]: {
              template: "",
              emailSystemType: "SENDGRID",
            },
          },
        }));
      }

      if (!Object.keys(user.supportEmails).includes(selectedCategory)) {
        setUser((prev) => ({
          ...prev,
          supportEmails: {
            ...prev.supportEmails,
            [selectedCategory]: "",
          },
        }));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type, dataset } = e.target;
    const { category } = dataset;

    if (name === "supportEmail" && Boolean(category)) {
      setUser((prev) => ({
        ...prev,
        supportEmails: {
          ...prev.supportEmails,
          [category]: value,
        },
      }));
      return;
    }

    if (name === "welcomeEmailTemplate" && Boolean(category)) {
      setUser((prev) => ({
        ...prev,
        welcomeEmailTemplates: {
          ...prev.welcomeEmailTemplates,
          [category]: {
            template: value,
            emailSystemType: prev.welcomeEmailTemplates[category]?.emailSystemType || "SENDGRID",
          },
        },
      }));
      return;
    }

    if (name === "emailSystemType" && Boolean(category)) {
      setUser((prev) => ({
        ...prev,
        welcomeEmailTemplates: {
          ...prev.welcomeEmailTemplates,
          [category]: {
            template: prev.welcomeEmailTemplates[category]?.template || "",
            emailSystemType: value,
          },
        },
      }));
      return;
    }

    if (name === "accountManager" && checked) {
      clearUnavailableManagedCategories(user?.availableCategories || []);
    }

    if (type === "checkbox") {
      setUser((prev) => ({ ...prev, [name]: checked }));
    } else {
      setUser((prev) => ({ ...prev, [name]: value }));
    }
  };

  const canceledit = () => {
    setEditing(false);
    setUser(initUser);
  };

  const edituser = (userData) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const availableCategoriesList = userData?.availableCategories.map((cat) => ({ label: cat, value: cat }));
    const managedCategoriesList = userData?.managedCategories.map((cat) => ({ label: cat, value: cat }));

    setEditing(true);
    setUser({
      id: userData.id,
      name: userData.name,
      email: userData.email,
      username: userData.username,
      password: "",
      status: userData.status,
      role: userData.role,
      managedCategories: managedCategoriesList,
      availableCategories: availableCategoriesList,
      phone: userData.phone || "",
      accountManager: userData.accountManager || false,
      requires2FA: userData.requires2FA || false,
      welcomeEmailTemplates: userData.welcomeEmailTemplates || {},
      supportEmails: userData.supportEmails || {},
    });
  };

  const deleteuser = (userData) => {
    dispatch(
      userActions.delete({
        user: userData,
        filters: {
          pagecount: USERS_ON_PAGE,
          searchByName,
          searchByRole,
          includeDisabled,
          onlyAccountManager,
        },
      })
    );
  };

  const saveuser = (e) => {
    e.preventDefault();
    let userdata = { ...user };

    const availableCategories = userdata.availableCategories.map((item) => item.value);
    userdata.availableCategories = availableCategories;

    if (availableCategories.length === 0) {
      dispatch(alertActions.error("At least one Available Category should be selected"));
      return;
    }

    if (userdata.accountManager) {
      const managedCategories = userdata.managedCategories.map((item) => item.value);

      if (managedCategories.length === 0) {
        dispatch(alertActions.error("At least one Managed Category should be selected"));
        return;
      }

      userdata.managedCategories = managedCategories;

      const supportEmailCategories = Object.keys(userdata?.supportEmails || {});
      if (supportEmailCategories.length) {
        supportEmailCategories.forEach((category) => {
          if (!userdata.managedCategories.includes(category) || !userdata.supportEmails[category]?.trim()) {
            delete userdata.supportEmails[category];
          }
        });
      }

      const welcomeEmailTemplateCategories = Object.keys(userdata?.welcomeEmailTemplates || {});
      if (welcomeEmailTemplateCategories.length) {
        welcomeEmailTemplateCategories.forEach((category) => {
          if (
            !userdata.managedCategories.includes(category) ||
            !userdata.welcomeEmailTemplates[category]?.template?.trim()
          ) {
            delete userdata.welcomeEmailTemplates[category];
          }
        });
      }
    } else {
      userdata.managedCategories = [];
      userdata.supportEmails = {};
      userdata.welcomeEmailTemplates = {};
    }

    if (editing) {
      dispatch(
        userActions.updateuser({
          user: userdata,
          filters: {
            pagecount: USERS_ON_PAGE,
            searchByName,
            searchByRole,
            includeDisabled,
            onlyAccountManager,
          },
        })
      );
    } else {
      dispatch(
        userActions.saveuser({
          user: userdata,
          filters: {
            pagecount: USERS_ON_PAGE,
            searchByName,
            searchByRole,
            includeDisabled,
            onlyAccountManager,
          },
        })
      );
    }

    setEditing(false);
    setUser(initUser);
  };

  const pageChanged = (e) => {
    const newPage = Number(e.target.text);
    setPage(newPage);
    dispatch(
      userActions.getAll({
        page: newPage,
        count: USERS_ON_PAGE,
        search: searchByName,
        role: searchByRole,
        includeDisabled: includeDisabled,
      })
    );
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      dispatch(
        userActions.getAll({
          page: page - 1,
          count: USERS_ON_PAGE,
          search: searchByName,
          role: searchByRole,
          includeDisabled: includeDisabled,
        })
      );
    }
  };

  const nextPage = () => {
    if (page < users.items.totalPages) {
      setPage(page + 1);
      dispatch(
        userActions.getAll({
          page: page + 1,
          count: USERS_ON_PAGE,
          search: searchByName,
          role: searchByRole,
          includeDisabled: includeDisabled,
        })
      );
    }
  };

  const firstPage = () => {
    setPage(1);
    dispatch(
      userActions.getAll({
        page: 1,
        count: USERS_ON_PAGE,
        search: searchByName,
        role: searchByRole,
        includeDisabled: includeDisabled,
      })
    );
  };

  const lastPage = () => {
    setPage(users.items.totalPages);
    dispatch(
      userActions.getAll({
        page: users.items.totalPages,
        count: USERS_ON_PAGE,
        search: searchByName,
        role: searchByRole,
        includeDisabled: includeDisabled,
      })
    );
  };

  const filter = () => {
    setPage(1);
    setEditing(false);
    setUser(initUser);
    dispatch(
      userActions.getAll({
        page: 1,
        count: USERS_ON_PAGE,
        search: searchByName,
        role: searchByRole,
        accountManager: onlyAccountManager,
        includeDisabled: includeDisabled,
      })
    );
  };

  const showSupportDetailsModalHandler = (userData) => {
    setShowSupportDetailsModal(userData);
  };

  const hideSupportDetailsModal = () => {
    setShowSupportDetailsModal(null);
  };

  const categories = variants?.categories?.values || [];
  let categories_array = categories.map(({ value, code }) => ({ label: value, value: code }));

  let items = [];
  let userdata = [];
  let totalpages = 1;
  let totalresults = 0;
  let currentpage = 1;

  if (users?.items?.elements) {
    userdata = users.items.elements;
    totalpages = users.items.totalPages;
    currentpage = users.items.page;
    totalresults = users.items.totalResults;

    for (let i = 1; i <= totalpages; i++) {
      items.push(
        <Pagination.Item key={i} active={currentpage === i} onClick={currentpage !== i ? pageChanged : null}>
          {i}
        </Pagination.Item>
      );
    }
  }

  useEffect(() => {
    dispatch(userActions.getAll({ page: 1, count: USERS_ON_PAGE }));
    dispatch(variantsActions.getProductCategories());
  }, [dispatch]);

  return (
    <Container fluid className="py-5 my-3">
      <Row className="mt-3">
        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
          <Card className="px-2 pb-2">
            <Form onSubmit={saveuser} autoComplete="off">
              {editing ? <h3>Edit User</h3> : <h3>Add New User</h3>}

              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Name"
                  name="name"
                  value={user.name || ""}
                  autoComplete="new-name"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  size="sm"
                  name="email"
                  value={user.email || ""}
                  autoComplete="new-email"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="Username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  size="sm"
                  name="username"
                  value={user.username || ""}
                  autoComplete="new-username"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="Password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  size="sm"
                  name="password"
                  value={user.password || ""}
                  autoComplete="new-password"
                  onChange={handleChange}
                  required={!editing}
                />
              </Form.Group>
              <Form.Group controlId="status">
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" name="status" size="sm" value={user.status || ""} onChange={handleChange}>
                  <option value="Active">Active</option>
                  <option value="Disabled">Disabled</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="role">
                <Form.Label>Available Categories</Form.Label>
                <ReactMultiSelectCheckboxes
                  placeholderButtonLabel="Categories"
                  name="availableCategories"
                  value={user.availableCategories || []}
                  options={categories_array}
                  onChange={handleAvailableCategoriesChange}
                  getDropdownButtonLabel={getDropdownButtonLabel}
                  className="reactMultiSelectCheckboxes"
                />
              </Form.Group>

              <Form.Group controlId="Category">
                <Form.Label>Role</Form.Label>
                <Form.Control as="select" name="role" size="sm" value={user.role || ""} onChange={handleChange}>
                  <option value="USER">{ROLES["USER"]}</option>
                  <option value="ADMIN">{ROLES["ADMIN"]}</option>
                  <option value="ATTORNEY">{ROLES["ATTORNEY"]}</option>
                  {user.role === "DEVELOPER" && <option value="DEVELOPER">{ROLES["DEVELOPER"]}</option>}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  size="sm"
                  placeholder="Phone"
                  name="phone"
                  value={user.phone || ""}
                  autoComplete="new-phone"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="requires2FA">
                <Form.Check
                  id="requires2FA"
                  className="text-dark"
                  type="checkbox"
                  label="Requires 2FA"
                  name="requires2FA"
                  checked={Boolean(user.requires2FA)}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="accountManager">
                <Form.Check
                  id="accountManager"
                  className="text-dark"
                  type="checkbox"
                  label="Account Manager"
                  name="accountManager"
                  checked={Boolean(user.accountManager)}
                  onChange={handleChange}
                />
              </Form.Group>
              {user.accountManager && (
                <>
                  <Form.Group controlId="role">
                    <Form.Label>Managed Categories</Form.Label>
                    <ReactMultiSelectCheckboxes
                      placeholderButtonLabel="Categories"
                      name="managedCategories"
                      value={user.managedCategories || []}
                      options={user.availableCategories || []}
                      onChange={handleManagedCategoriesChange}
                      getDropdownButtonLabel={getDropdownButtonLabel}
                      className="reactMultiSelectCheckboxes"
                    />
                  </Form.Group>

                  {user.managedCategories.map((item, idx) => (
                    <Row key={`${idx}-supportemail-${item.value}`}>
                      <Col sm={12} className="pr-5">
                        <div className="p-2" style={{ backgroundColor: "#f1f1f1", borderRadius: "4px" }}>
                          <Form.Label className="font-weight-bold">
                            {item.label || item.value || "<Unknown>"}
                          </Form.Label>

                          <Form.Group>
                            <Form.Label>Support Email</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Support Email"
                              size="sm"
                              name="supportEmail"
                              data-category={item.value}
                              value={user.supportEmails[item.value] || ""}
                              autoComplete="new--support-email"
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Welcome Email Template</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Welcome Email Template"
                              size="sm"
                              name="welcomeEmailTemplate"
                              data-category={item.value}
                              value={user.welcomeEmailTemplates[item.value]?.template || ""}
                              autoComplete="new-welcome-email-template"
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Email System Type</Form.Label>
                            <Form.Control
                              as="select"
                              name="emailSystemType"
                              data-category={item.value}
                              size="sm"
                              value={user.welcomeEmailTemplates[item.value]?.emailSystemType || ""}
                              onChange={handleChange}
                            >
                              <option value="SENDGRID">SENDGRID</option>
                              <option value="MAILGUN">MAILGUN</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </>
              )}

              {editing ? (
                <div>
                  <Button variant="success" type="submit" className="btn-sm pull-right ml-1" disabled={isLoading}>
                    Update
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    className="btn-sm pull-right"
                    disabled={isLoading}
                    onClick={canceledit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button variant="success" type="submit" className="btn-sm pull-right" disabled={isLoading}>
                  Save
                </Button>
              )}
            </Form>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={9} lg={9} xl={9}>
          <Card>
            <h3 className="ml-2">All Users</h3>
            <Form
              className="p-2"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                filter();
              }}
            >
              <Form.Row>
                <Form.Group md={3} sm={12} as={Col}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    size="sm"
                    name="searchByName"
                    value={searchByName || ""}
                    onChange={(e) => setSearchByName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group md={2} sm={12} as={Col}>
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    name="searchByRole"
                    value={searchByRole || ""}
                    onChange={(e) => setSearchByRole(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="USER">{ROLES["USER"]}</option>
                    <option value="ATTORNEY">{ROLES["ATTORNEY"]}</option>
                    <option value="ADMIN">{ROLES["ADMIN"]}</option>
                    <option value="DEVELOPER">{ROLES["DEVELOPER"]}</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group md={2} sm={12} as={Col}>
                  <Form.Label>Only Account Managers</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    name="onlyAccountManager"
                    value={onlyAccountManager || ""}
                    onChange={(e) => setOnlyAccountManager(e.target.value === "true")}
                  >
                    <option value="">No</option>
                    <option value="true">Yes</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group md={2} sm={12} as={Col}>
                  <Form.Label>Show Disabled</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    name="includeDisabled"
                    value={includeDisabled || ""}
                    onChange={(e) => setIncludeDisabled(e.target.value)}
                  >
                    <option value="">No</option>
                    <option value="true">Yes</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  className="d-flex align-items-end justify-content-md-start justify-content-end"
                  as={Col}
                  sm={12}
                  md={3}
                >
                  <Button variant="primary" type="submit" className="btn-sm" isLoading={isLoading}>
                    Filter
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
            {!isLoading && (
              <>
                <Table responsive bordered striped hover>
                  <thead>
                    <tr>
                      <th className="px-1">#</th>
                      <th>Name</th>
                      <th style={{ minWidth: "210px" }}>User Info</th>
                      <th>Username</th>
                      <th>Status</th>
                      <th>2FA</th>
                      <th>Categories</th>
                      <th>Role</th>
                      <th style={{ minWidth: "175px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userdata.map((prop, key) => {
                      const isAccountManager = Boolean(prop?.accountManager);
                      const hasSupportDetails =
                        (prop.supportEmails && Object.keys(prop.supportEmails).length > 0) ||
                        (prop.welcomeEmailTemplates && Object.keys(prop.welcomeEmailTemplates).length > 0);

                      return (
                        <tr key={key}>
                          <ColorIndicator size="12px" status={getIndicator(prop)} title={getIndicator(prop)} />
                          <td>{prop.name}</td>
                          <td style={{ maxWidth: "375px" }}>
                            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                              <div title="E-mail">
                                <EmailSVG width="18px" height="18px" />
                              </div>
                              <div>{prop.email ? prop.email : <span>—</span>}</div>
                            </div>
                            <div style={{ borderTop: "1px dashed #afafaf" }} />
                            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                              <div title="Phone">
                                <PhoneSVG width="18px" height="18px" />
                              </div>
                              <div>{prop.phone ? prop.phone : <span>—</span>}</div>
                            </div>
                            {isAccountManager && hasSupportDetails && (
                              <>
                                <div style={{ borderTop: "1px dashed #afafaf" }} />
                                <div style={{ display: "flex", gap: "4px", alignItems: "center", marginTop: "2px" }}>
                                  <div title="Welcome E-mail Template">
                                    <EmailTemplateSVG width="18px" height="18px" />
                                  </div>
                                  <StyledTextButton onClick={() => showSupportDetailsModalHandler(prop)}>
                                    Show support details
                                  </StyledTextButton>
                                </div>
                              </>
                            )}
                          </td>
                          <td>{prop.username}</td>
                          <td>{prop.status}</td>
                          <td>{prop.requires2FA ? "Yes" : "No"}</td>
                          <td>{prop.availableCategories.join(", ")}</td>
                          <td>{ROLES[prop.role]}</td>
                          <td>
                            <Button variant="warning" className="btn btn-sm mr-2" onClick={() => edituser(prop)}>
                              Edit <i className="fa fa-edit"></i>{" "}
                            </Button>
                            <Button variant="danger" className="btn btn-sm mt-1" onClick={() => deleteuser(prop)}>
                              Delete <i className="fa fa-trash"></i>{" "}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                {totalresults > 0 && (
                  <Pagination className="ml-2">
                    <Pagination.First disabled={currentpage === 1} onClick={firstPage} />
                    <Pagination.Prev disabled={currentpage === 1} onClick={prevPage} />
                    {items}
                    <Pagination.Next disabled={currentpage === totalpages} onClick={nextPage} />
                    <Pagination.Last disabled={currentpage === totalpages} onClick={lastPage} />
                  </Pagination>
                )}
              </>
            )}

            {!isLoading && totalresults === 0 && <div className="ml-2 mb-2">No results</div>}
            {isLoading && <Spinner />}
          </Card>
        </Col>
      </Row>

      <Modal show={Boolean(showSupportDetailsModal)} onHide={hideSupportDetailsModal}>
        <Modal.Header closeButton>Support Details</Modal.Header>
        <Modal.Body>
          {showSupportDetailsModal?.managedCategories?.map((item, idx) => (
            <div key={`support-details-category-${item}-${idx}`} className="mb-4">
              <p style={{ fontWeight: "bold", margin: 0 }}>{item}</p>
              <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                <div style={{ flex: 6 }}>Support E-mail:</div>
                <div style={{ flex: 6 }}>{showSupportDetailsModal?.supportEmails[item] || "—"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                <div style={{ flex: 6 }}>Welcome E-Mail Template:</div>
                <div style={{ flex: 6 }}>{showSupportDetailsModal?.welcomeEmailTemplates[item]?.template || "—"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                <div style={{ flex: 6 }}>Email System Type:</div>
                <div style={{ flex: 6 }}>
                  {showSupportDetailsModal?.welcomeEmailTemplates[item]?.emailSystemType || "—"}
                </div>
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideSupportDetailsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Users;
