import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";

import FormContainer from "components/OrderDetails/CreateOrder/FormComponents/FormContainer";
import { phoneCleaner, phoneNumRegexp } from "_helpers";
import { designatorsLLC, state_array, suffixArray } from "variables/Variables";
import clsx from "clsx";
import InputMask from "react-input-mask";
import ActivityIncEdit from "components/OrderDetails/ProductDetails/Incorporation/Activity/Edit";
import ExtraLabel from "components/OrderDetails/CreateOrder/FormComponents/ExtraLabel";

// const initProduct = {
//   companyName: "",
//   altCompanyName: "",
//   organizedState: "",
//   firstName: null,
//   middleName: null,
//   lastName: null,
//   email: null,
//   phone: null,
//   corporateAddress: {
//     address1: "",
//     address2: "",
//     city: "",
//     state: "",
//     zip: "",
//     country: "US",
//     county: null,
//   },
//   mailingAddress: null,
//   members: [],
//   activityPrimaryActivity: "",
//   activitySpecificProducts: "",
//   productExtraQuestion1: null,
//   productExtraQuestion2: null,
//   productExtraQuestion3: null,
//   productDescription: null,
//   principalOfficer: {
//     firstName: "",
//     middleName: "",
//     lastName: "",
//     suffixName: null,
//     countryOfCitizenship: null,
//     email: "",
//     phone: "",
//     address: null,
//   },
//   owner: null,
//   designator: null,
//   managedByManagers: null,
//   managedByMembers: null,
//   acceptCreditCardPayments: false,
//   questionsFirstLLC: false,
//   questionsHaveEmployee: false,
//   questionsStartedDoingBusiness: false,
//   boiWasDeclinedByCustomer: null,
//   wvFormationQuestions: null,
// };

const ExtraFieldsContainer = styled.div`
  background-color: #ededed;
  border-left: 2px solid lightgray;
  margin-bottom: 1rem;
  padding: 12px;
`;

const getYearOptions = () => {
  const startYear = new Date().getFullYear();

  return Array.from(new Array(20), (_, i) => {
    return { value: startYear + i, label: `${startYear + i}` };
  });
};

const getMonthOptions = () => [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const parseBooleanValue = (value) => {
  if (value === undefined || value === null) {
    return "";
  }

  return value ? "yes" : "no";
};

const EinLLCIncorporation = (props) => {
  const { orderDetails, productDetails, validated, onChange, onError } = props;

  const llcEINProductIndex = orderDetails?.products?.findIndex((p) => checkIsIncEin(p.code.code));
  const hasLlcEINProduct = Boolean(llcEINProductIndex >= 0);

  const hasMailingAddressFilled = Boolean(
    productDetails?.mailingAddress?.address1 ||
      productDetails?.mailingAddress?.city ||
      productDetails?.mailingAddress?.state ||
      productDetails?.mailingAddress?.zip
  );

  function handleAddError(error) {
    onError(error);
  }

  const handleProductChange = ({ target: { name, value, values } }) => {
    const newObj = { ...productDetails };

    let exFields = { [name]: value };

    if (name === "activityPrimaryActivity") {
      exFields.activitySpecificProducts = null;
      exFields.productDescription = null;
      exFields.productExtraQuestion1 = null;
      exFields.productExtraQuestion2 = null;
      exFields.productExtraQuestion3 = null;
    }

    if (name === "activitySpecificProducts") {
      exFields.productDescription = null;
      exFields.productExtraQuestion1 = null;
      exFields.productExtraQuestion2 = null;
      exFields.productExtraQuestion3 = null;
    }

    if (name === "companyName" && hasLlcEINProduct) {
      exFields.legalName = value;
    }

    if (name === "ssn") {
      exFields.owner = { ...exFields.owner, ssn: value };
    }

    onChange({
      ...newObj,
      ...exFields,
    });
  };

  const handlePrincipalOfficerChange = ({ target: { name, value } }) => {
    const newObj = { ...productDetails };

    let newData = value;

    if (name === "phone") {
      newData = phoneCleaner(newData);
    }

    if (value === "") {
      newData = null;
    }

    onChange({ ...newObj, principalOfficer: { ...newObj.principalOfficer, [name]: newData } });
  };

  const handleCorporateAddressChange = ({ target: { name, value } }) => {
    const newObj = { ...productDetails };

    let newData = value;

    if (["zip"].includes(name)) {
      newData = newData.replace(/\D/gi, "");
    }

    if (value === "") {
      newData = null;
    }

    onChange({ ...newObj, corporateAddress: { ...newObj.corporateAddress, [name]: newData } });
  };

  const handleMailingAddressChange = ({ target: { name, value } }) => {
    const newObj = { ...productDetails };

    let newData = value;

    if (["zip"].includes(name)) {
      newData = newData.replace(/\D/gi, "");
    }

    if (value === "") {
      newData = null;
    }

    onChange({ ...newObj, mailingAddress: { ...newObj.mailingAddress, [name]: newData } });
  };

  const handleWVFormationQuestionsChange = ({ target: { name, value } }) => {
    const newObj = { ...productDetails };

    let newData = value;

    if (value === "yes") {
      newData = true;
    }

    if (value === "no") {
      newData = false;
    }

    if (value === "") {
      newData = null;
    }

    onChange({ ...newObj, wvFormationQuestions: { ...newObj.wvFormationQuestions, [name]: newData } });
  };

  return (
    <FormContainer>
      <h6 className="font-weight-bold">LLC Incorporation Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Limited Liability Company Name: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="companyName"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$" // \x22 is "
            value={productDetails.companyName || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Company Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Alternate Company Name: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="altCompanyName"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$" // \x22 is "
            value={productDetails.altCompanyName || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Alternate Company Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Designator: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            as="select"
            name="designator"
            value={productDetails?.designator || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Designator")}
          >
            <option value="">Select a designator</option>
            {designatorsLLC.map((value, idx) => (
              <option key={`${idx}-${value}`} value={value}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organized State: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            as="select"
            name="organizedState"
            value={productDetails?.organizedState || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Organized State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className="form-control form-control-sm"
            mask="999-99-9999"
            maskChar=" "
            name="ssn"
            placeholder="999-99-9999"
            pattern="^\d{3}-\d{2}-\d{4}$"
            value={productDetails?.owner?.ssn || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Social Security Number")}
          />
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Activity</h6>
      <ActivityIncEdit productDetails={productDetails} onError={handleAddError} onProductChange={handleProductChange} />

      {productDetails?.organizedState === "WV" && (
        <>
          <hr />
          <h6 className="font-weight-bold">West Virginia Questions</h6>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              What is your estimate of the annual gross income from this business location?
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                as="select"
                name="annualGrossIncome"
                value={productDetails?.wvFormationQuestions?.annualGrossIncome || ""}
                onChange={handleWVFormationQuestionsChange}
              >
                <option value="">Please select</option>
                <option value="MV_0_20000">$0 - $20,000</option>
                <option value="MV_20000_MAX">Over $20,000</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Counting all business income for the previous year at any location, has this business grossed over $4,000?
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                as="select"
                name="grossOver4000"
                value={parseBooleanValue(productDetails?.wvFormationQuestions?.grossOver4000)}
                onChange={handleWVFormationQuestionsChange}
              >
                <option value="">Please select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Does this business have a location in WV from which you will make retail sales or do any service
              maintenance work?
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                as="select"
                name="locationInWV"
                value={parseBooleanValue(productDetails?.wvFormationQuestions?.locationInWV)}
                onChange={handleWVFormationQuestionsChange}
              >
                <option value="">Please select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Will this business sell at either wholesale or retail, or consume for business operations any of the
              following products?
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                as="select"
                name="retailProduct"
                value={productDetails?.wvFormationQuestions?.retailProduct || ""}
                onChange={handleWVFormationQuestionsChange}
              >
                <option value="">Please select</option>
                <option value="ALCOHOL">Alcoholic beverages</option>
                <option value="TOBACCO">Tobacco products</option>
                <option value="MOTOR_FUEL">Motor fuel</option>
                <option value="SOFT_DRINKS">Soft drinks</option>
                <option value="NONE">None of the above</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Do any of these categories relate to the activity of your business?
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                as="select"
                name="businessActivity"
                value={productDetails?.wvFormationQuestions?.businessActivity || ""}
                onChange={handleWVFormationQuestionsChange}
              >
                <option value="">Please select</option>
                <option value="HEALTH_CARE">Health care</option>
                <option value="NATURAL_GAS">Natural gas</option>
                <option value="NATURAL_RESOURCES">Natural resources</option>
                <option value="PUBLIC_UTILITIES">Public utilities</option>
                <option value="TELECOMMUNICATION">Telecommunications</option>
                <option value="TRANSPORTATION">Transportation</option>
                <option value="NONE">None of the above</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              What is your taxable year-end for federal tax purposes?
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                as="select"
                name="taxableEndYearMonth"
                value={productDetails?.wvFormationQuestions?.taxableEndYearMonth || ""}
                onChange={handleWVFormationQuestionsChange}
              >
                <option value="">Please select</option>
                {getMonthOptions().map(({ label, value }) => (
                  <option key={`taxableEndYearMonth-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Do you have employees?
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                as="select"
                name="haveEmployees"
                value={parseBooleanValue(productDetails?.wvFormationQuestions?.haveEmployees)}
                onChange={handleWVFormationQuestionsChange}
              >
                <option value="">Please select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Col>
          </Form.Group>

          {productDetails?.wvFormationQuestions?.haveEmployees && (
            <>
              <hr />
              <h6 className="font-weight-bold">Employee Questions</h6>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  How many employees do you have working in WV?
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    type="number"
                    min="0"
                    name="countOfEmployees"
                    value={productDetails?.wvFormationQuestions?.countOfEmployees || ""}
                    onChange={handleWVFormationQuestionsChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Will you pay wages to employees performing services in WV?
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    as="select"
                    name="payWagesInWV"
                    value={parseBooleanValue(productDetails?.wvFormationQuestions?.payWagesInWV)}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Will you have only casual employees—meaning three or fewer workers working not more than 10 days in
                  any quarter?
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    as="select"
                    name="onlyCasualEmployees"
                    value={parseBooleanValue(productDetails?.wvFormationQuestions?.onlyCasualEmployees)}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  What date did the first employee start working in WV?
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    type="date"
                    name="firstEmployeeStartWork"
                    value={productDetails?.wvFormationQuestions?.firstEmployeeStartWork || ""}
                    onChange={handleWVFormationQuestionsChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Enter the date that the first employee was or will be paid:
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    type="date"
                    name="firstEmployeeWasPaid"
                    value={productDetails?.wvFormationQuestions?.firstEmployeeWasPaid || ""}
                    onChange={handleWVFormationQuestionsChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  What is the estimated gross wages paid in WV in the first calendar quarter of operations?
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    type="number"
                    min="0"
                    name="grossWagesInWVFirstQuarter"
                    value={productDetails?.wvFormationQuestions?.grossWagesInWVFirstQuarter || ""}
                    onChange={handleWVFormationQuestionsChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Do you have or expect to have soon a quarterly payroll of $1,500 in WV?
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    as="select"
                    name="willPayrollBe1500"
                    value={parseBooleanValue(productDetails?.wvFormationQuestions?.willPayrollBe1500)}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Will you employ at least one WV worker in 20 different calendar weeks during a calendar year?
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    as="select"
                    name="willEmployWVWorkersIn20Weeks"
                    value={parseBooleanValue(productDetails?.wvFormationQuestions?.willEmployWVWorkersIn20Weeks)}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Will you withhold more than $250 of WV income tax per month?
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    as="select"
                    name="willWithholdMoreThan250Tax"
                    value={parseBooleanValue(productDetails?.wvFormationQuestions?.willWithholdMoreThan250Tax)}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  In what earliest quarter and year will the quarterly payroll reach or exceed $1,500?
                </Form.Label>
                <Col sm={7} />
                <Form.Label column sm={5}>
                  Quarter:
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    className="mb-1"
                    as="select"
                    name="earliestQuarterPayroll1500"
                    value={productDetails?.wvFormationQuestions?.earliestQuarterPayroll1500 || ""}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    <option value={1}>Q1: January – March</option>
                    <option value={2}>Q2: April – June </option>
                    <option value={3}>Q3: July – September</option>
                    <option value={4}>Q4: October – December</option>
                  </Form.Control>
                </Col>
                <Form.Label column sm={5}>
                  Year:
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    as="select"
                    name="earliestYearPayroll1500"
                    value={productDetails?.wvFormationQuestions?.earliestYearPayroll1500 || ""}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    {getYearOptions().map(({ label, value }) => (
                      <option key={`earliestYearPayroll1500-${value}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  In what earliest quarter and year will at least one worker be employed in 20 different calendar weeks
                  during a calendar year?
                </Form.Label>
                <Col sm={7} />
                <Form.Label column sm={5}>
                  Quarter:
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    className="mb-1"
                    as="select"
                    name="earliestQuarterWorkerEmployedIn20Weeks"
                    value={productDetails?.wvFormationQuestions?.earliestQuarterWorkerEmployedIn20Weeks || ""}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    <option value={1}>Q1: January – March</option>
                    <option value={2}>Q2: April – June </option>
                    <option value={3}>Q3: July – September</option>
                    <option value={4}>Q4: October – December</option>
                  </Form.Control>
                </Col>
                <Form.Label column sm={5}>
                  Year:
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    as="select"
                    name="earliestYearWorkerEmployedIn20Weeks"
                    value={productDetails?.wvFormationQuestions?.earliestYearWorkerEmployedIn20Weeks || ""}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    {getYearOptions().map(({ label, value }) => (
                      <option key={`earliestYearPayroll1500-${value}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Is this business subject to federal unemployment tax?
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    as="select"
                    name="businessSubjectToFederalTax"
                    value={parseBooleanValue(productDetails?.wvFormationQuestions?.businessSubjectToFederalTax)}
                    onChange={handleWVFormationQuestionsChange}
                  >
                    <option value="">Please select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </>
          )}
        </>
      )}

      <hr />
      <h6 className="font-weight-bold">General questions</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is this your first LLC?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="questionsFirstLLC_r_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="questionsFirstLLC"
            value={true}
            defaultChecked={productDetails.questionsFirstLLC}
            onChange={handleProductChange}
          />
          <Form.Check
            id="questionsFirstLLC_r_no"
            type="radio"
            label="No"
            name="questionsFirstLLC"
            value={false}
            defaultChecked={!productDetails.questionsFirstLLC}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Will you have employees?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="questionsHaveEmployee_r_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="questionsHaveEmployee"
            value={true}
            defaultChecked={productDetails.questionsHaveEmployee}
            onChange={handleProductChange}
          />
          <Form.Check
            id="questionsHaveEmployee_r_no"
            type="radio"
            label="No"
            name="questionsHaveEmployee"
            value={false}
            defaultChecked={!productDetails.questionsHaveEmployee}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Have you started doing business?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="questionsStartedDoingBusiness_r_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="questionsStartedDoingBusiness"
            value={true}
            defaultChecked={productDetails.questionsStartedDoingBusiness}
            onChange={handleProductChange}
          />
          <Form.Check
            id="questionsStartedDoingBusiness_r_no"
            type="radio"
            label="No"
            name="questionsStartedDoingBusiness"
            value={false}
            defaultChecked={!productDetails.questionsStartedDoingBusiness}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Will you accept Credit Card Payments?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="acceptCreditCardPayments_r_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="acceptCreditCardPayments"
            value={true}
            defaultChecked={productDetails.acceptCreditCardPayments}
            onChange={handleProductChange}
          />
          <Form.Check
            id="acceptCreditCardPayments_r_no"
            type="radio"
            label="No"
            name="acceptCreditCardPayments"
            value={false}
            defaultChecked={!productDetails.acceptCreditCardPayments}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <h6 className="font-weight-bold mt-4">Primary Officer</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="firstName"
            value={productDetails.principalOfficer?.firstName || ""}
            onChange={handlePrincipalOfficerChange}
            onInvalid={() => handleAddError("Primary Officer – First Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="middleName"
            value={productDetails.principalOfficer?.middleName || ""}
            onChange={handlePrincipalOfficerChange}
            onInvalid={() => handleAddError("Primary Officer – Middle Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="lastName"
            value={productDetails.principalOfficer?.lastName || ""}
            onChange={handlePrincipalOfficerChange}
            onInvalid={() => handleAddError("Primary Officer – Last Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            name="suffixName"
            value={productDetails?.principalOfficer?.suffixName || ""}
            onChange={handlePrincipalOfficerChange}
          >
            <option value="">Please select</option>
            {suffixArray.map((prop, key) => (
              <option key={key} value={prop}>
                {prop}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <InputMask
            required
            className={clsx("form-control", "form-control-sm", {
              "is-invalid":
                productDetails?.principalOfficer?.phone &&
                !phoneNumRegexp.test(productDetails?.principalOfficer?.phone),
            })}
            mask="(999) 999-9999"
            maskChar=" "
            name="phone"
            placeholder="(999) 999-9999"
            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
            value={productDetails?.principalOfficer?.phone || ""}
            onChange={handlePrincipalOfficerChange}
            onInvalid={() => handleAddError("Primary Officer – Phone")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          E-mail: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="email"
            value={productDetails.principalOfficer?.email || ""}
            onChange={handlePrincipalOfficerChange}
            onInvalid={() => handleAddError("Primary Officer – Email")}
          />
        </Col>
      </Form.Group>

      <h6 className="font-weight-bold mt-4">Corporate Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address1: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="address1"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
            maxLength={30}
            value={productDetails.corporateAddress?.address1 || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate Address")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address2: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            name="address2"
            type="text"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
            maxLength={30}
            value={productDetails.corporateAddress?.address2 || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate Address2")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="city"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            value={productDetails.corporateAddress?.city || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate City")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            required
            as="select"
            name="state"
            value={productDetails.corporateAddress?.state || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="zip"
            maxLength={5}
            pattern="^\d{5}$"
            value={productDetails.corporateAddress?.zip || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate Zip")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="county"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            value={productDetails.corporateAddress?.county || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate County")}
          />
        </Col>
      </Form.Group>

      <h6 className="font-weight-bold mt-4">Mailing Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address1: <ExtraLabel $required={hasMailingAddressFilled} $optional={!hasMailingAddressFilled} />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={hasMailingAddressFilled}
            size="sm"
            type="text"
            name="address1"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
            maxLength={30}
            value={productDetails.mailingAddress?.address1 || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing Address")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address2: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            name="address2"
            type="text"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
            maxLength={30}
            value={productDetails.mailingAddress?.address2 || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing Address2")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City: <ExtraLabel $required={hasMailingAddressFilled} $optional={!hasMailingAddressFilled} />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={hasMailingAddressFilled}
            size="sm"
            type="text"
            name="city"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            value={productDetails.mailingAddress?.city || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing City")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State: <ExtraLabel $required={hasMailingAddressFilled} $optional={!hasMailingAddressFilled} />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={hasMailingAddressFilled}
            size="sm"
            as="select"
            name="state"
            value={productDetails.mailingAddress?.state || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code: <ExtraLabel $required={hasMailingAddressFilled} $optional={!hasMailingAddressFilled} />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={hasMailingAddressFilled}
            size="sm"
            type="text"
            name="zip"
            maxLength={5}
            pattern="^\d{5}$"
            value={productDetails.mailingAddress?.zip || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing Zip")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="county"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            value={productDetails.mailingAddress?.county || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing County")}
          />
        </Col>
      </Form.Group>
    </FormContainer>
  );
};

export default EinLLCIncorporation;
