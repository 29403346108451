import { useGroupChannelContext } from "@sendbird/uikit-react/GroupChannel/context";
import React from "react";

import MessageSearchPannel from "./LibMessageSearch";

const CustomSearchPannelView = ({ setSearchVisible, setCurrentMessage }) => {
  const { channelUrl } = useGroupChannelContext();

  const handleResultClick = (message) => {
    setCurrentMessage(message);
  };

  const handleResetClick = () => {
    setCurrentMessage(null);
  };

  const handleCloseClick = () => {
    setSearchVisible(false);
    setCurrentMessage(null);
  };

  return (
    <MessageSearchPannel
      channelUrl={channelUrl}
      onResultClick={handleResultClick}
      onResetClick={handleResetClick}
      onCloseClick={handleCloseClick}
    />
  );
};

export default CustomSearchPannelView;
