import { accountingConstants } from "_constants";

const initialState = {
  files: {
    filters: {
      type: "",
      year: "",
      page: 1,
      count: 10,
    },
    loading: 0,
    data: {},
    error: null,
  },
  statements: {
    filters: {
      type: "",
      status: "",
      year: "",
      page: 1,
      count: 10,
    },
    loading: 0,
    data: {},
    error: null,
  },
  fileTypes: {
    loading: 0,
    data: [],
    error: null,
  },
  channel: {
    loading: 0,
    data: {},
    error: null,
    availableOrders: {
      loading: 0,
      data: [],
      error: null,
    },
  },
  upcomingDates: {
    posting: false,
    deleting: false,
    loading: 0,
    data: [],
    error: null,
  },
};

export function accounting(state = initialState, action) {
  const { type, payload, error } = action;

  switch (type) {
    case accountingConstants.GET_FILES_REQUEST:
      return { ...state, files: { ...state.files, loading: state.files.loading + 1 } };
    case accountingConstants.GET_FILES_SUCCESS:
      return { ...state, files: { ...state.files, loading: state.files.loading - 1, data: payload } };
    case accountingConstants.GET_FILES_FAILURE:
      return { ...state, files: { ...state.files, loading: state.files.loading - 1, error: payload } };

    case accountingConstants.UPLOAD_FILE_REQUEST:
      return { ...state, files: { ...state.files, loading: state.files.loading + 1 } };
    case accountingConstants.UPLOAD_FILE_SUCCESS:
      return { ...state, files: { ...state.files, loading: state.files.loading - 1 } };
    case accountingConstants.UPLOAD_FILE_FAILURE:
      return { ...state, files: { ...state.files, loading: state.files.loading - 1, error: payload } };

    case accountingConstants.DOWNLOAD_FILE_REQUEST:
      return { ...state, files: { ...state.files, loading: state.files.loading + 1 } };
    case accountingConstants.DOWNLOAD_FILE_SUCCESS:
      return { ...state, files: { ...state.files, loading: state.files.loading - 1 } };
    case accountingConstants.DOWNLOAD_FILE_FAILURE:
      return { ...state, files: { ...state.files, loading: state.files.loading - 1, error: payload } };

    case accountingConstants.DELETE_FILE_REQUEST:
      return { ...state, files: { ...state.files, loading: state.files.loading + 1 } };
    case accountingConstants.DELETE_FILE_SUCCESS:
      return { ...state, files: { ...state.files, loading: state.files.loading - 1 } };
    case accountingConstants.DELETE_FILE_FAILURE:
      return { ...state, files: { ...state.files, loading: state.files.loading - 1, error: payload } };

    case accountingConstants.REFRESH_FILES_FILTERS:
      return { ...state, files: { ...state.files, filters: { ...state.files.filters, ...payload } } };

    case accountingConstants.GET_STATEMENTS_REQUEST:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading + 1 } };
    case accountingConstants.GET_STATEMENTS_SUCCESS:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1, data: payload } };
    case accountingConstants.GET_STATEMENTS_FAILURE:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1, error: payload } };

    case accountingConstants.CREATE_STATEMENT_REQUEST:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading + 1 } };
    case accountingConstants.CREATE_STATEMENT_SUCCESS:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1 } };
    case accountingConstants.CREATE_STATEMENT_FAILURE:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1, error: payload } };

    case accountingConstants.UPLOAD_STATEMENT_REQUEST:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading + 1 } };
    case accountingConstants.UPLOAD_STATEMENT_SUCCESS:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1 } };
    case accountingConstants.UPDATE_STATEMENT_FAILURE:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1, error: payload } };

    case accountingConstants.UPDATE_STATEMENT_REQUEST:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading + 1 } };
    case accountingConstants.UPDATE_STATEMENT_SUCCESS:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1 } };
    case accountingConstants.UPDATE_STATEMENT_FAILURE:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1, error: payload } };

    case accountingConstants.DOWNLOAD_STATEMENT_REQUEST:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading + 1 } };
    case accountingConstants.DOWNLOAD_STATEMENT_SUCCESS:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1 } };
    case accountingConstants.DOWNLOAD_STATEMENT_FAILURE:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1, error: payload } };

    case accountingConstants.DELETE_STATEMENT_REQUEST:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading + 1 } };
    case accountingConstants.DELETE_STATEMENT_SUCCESS:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1 } };
    case accountingConstants.DELETE_STATEMENT_FAILURE:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1, error: payload } };

    case accountingConstants.REQUEST_STATEMENT_REQUEST:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading + 1 } };
    case accountingConstants.REQUEST_STATEMENT_SUCCESS:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1 } };
    case accountingConstants.REQUEST_STATEMENT_FAILURE:
      return { ...state, statements: { ...state.statements, loading: state.statements.loading - 1, error: payload } };

    case accountingConstants.REFRESH_STATEMENTS_FILTERS:
      return { ...state, statements: { ...state.statements, filters: { ...state.statements.filters, ...payload } } };

    case accountingConstants.GET_FILE_TYPES_REQUEST:
      return { ...state, fileTypes: { ...state.fileTypes, loading: state.fileTypes.loading + 1 } };
    case accountingConstants.GET_FILE_TYPES_SUCCESS:
      return { ...state, fileTypes: { ...state.fileTypes, loading: state.fileTypes.loading - 1, data: payload } };
    case accountingConstants.GET_FILE_TYPES_FAILURE:
      return { ...state, fileTypes: { ...state.fileTypes, loading: state.fileTypes.loading - 1, error: payload } };

    case accountingConstants.GET_CHANNEL_REQUEST:
      return { ...state, channel: { ...state.channel, loading: state.channel.loading + 1 } };
    case accountingConstants.GET_CHANNEL_SUCCESS:
      return { ...state, channel: { ...state.channel, loading: state.channel.loading - 1, data: payload } };
    case accountingConstants.GET_CHANNEL_FAILURE:
      return { ...state, channel: { ...state.channel, loading: state.channel.loading - 1, error: payload } };

    case accountingConstants.GET_CHANNEL_ORDERS_REQUEST:
      return {
        ...state,
        channel: {
          ...state.channel,
          availableOrders: { ...state.channel.availableOrders, loading: state.channel.availableOrders.loading + 1 },
        },
      };
    case accountingConstants.GET_CHANNEL_ORDERS_SUCCESS:
      return {
        ...state,
        channel: {
          ...state.channel,
          availableOrders: {
            ...state.channel.availableOrders,
            loading: state.channel.availableOrders.loading - 1,
            data: payload,
          },
        },
      };
    case accountingConstants.GET_CHANNEL_ORDERS_FAILURE:
      return {
        ...state,
        channel: {
          ...state.channel,
          availableOrders: {
            ...state.channel.availableOrders,
            loading: state.channel.availableOrders.loading - 1,
            error: payload,
          },
        },
      };

    case accountingConstants.RESET_CHANNEL_ORDERS:
      return {
        ...state,
        channel: {
          ...state.channel,
          availableOrders: { ...state.channel.availableOrders, loading: 0, data: [], error: null },
        },
      };

    case accountingConstants.GET_UPCOMING_DATES_REQUEST:
      return { ...state, upcomingDates: { ...state.upcomingDates, loading: state.upcomingDates.loading + 1 } };
    case accountingConstants.GET_UPCOMING_DATES_SUCCESS:
      return {
        ...state,
        upcomingDates: { ...state.upcomingDates, loading: state.upcomingDates.loading - 1, data: payload },
      };
    case accountingConstants.GET_UPCOMING_DATES_FAILURE:
      return {
        ...state,
        upcomingDates: { ...state.upcomingDates, loading: state.upcomingDates.loading - 1, error: payload },
      };

    case accountingConstants.POST_UPCOMING_DATE_REQUEST:
      return { ...state, upcomingDates: { ...state.upcomingDates, posting: true } };
    case accountingConstants.POST_UPCOMING_DATE_SUCCESS:
      return { ...state, upcomingDates: { ...state.upcomingDates, posting: false } };
    case accountingConstants.POST_UPCOMING_DATE_FAILURE:
      return { ...state, upcomingDates: { ...state.upcomingDates, posting: false } };

    case accountingConstants.DELETE_UPCOMING_DATE_REQUEST:
      return { ...state, upcomingDates: { ...state.upcomingDates, deleting: true } };
    case accountingConstants.DELETE_UPCOMING_DATE_SUCCESS:
      return { ...state, upcomingDates: { ...state.upcomingDates, deleting: false } };
    case accountingConstants.DELETE_UPCOMING_DATE_FAILURE:
      return { ...state, upcomingDates: { ...state.upcomingDates, deleting: false } };

    default:
      return state;
  }
}
