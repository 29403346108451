import { config } from "_config";
import { authHeader, handleResponse, mappingQueryParams } from "_helpers";

export const variantsService = {
  getProductCategories,
  getproductcodes,
  getprocessing_status,
  getprocessing_status_2,
  getProductProcessingStatus,
  getProcessingError,
  getprocessing_error_status,
  get_order_status,
  getproductcodesforsale,
  getStates,
  getInternationalCodes,
  getUSCodes,
  getNationalities,
  getProductFileTypes,
  getProcessingOptions,
  getActivities,
  getUSPTOCategories,
  getPrices,
  calculatePrice,
};

function getProductCategories() {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.apiUrl1}/category`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getproductcodes(category) {
  const requestOptions = {
    method: "GET",
  };

  let url = `${config.apiUrl1}/category/products/codes`;

  if (category && category.toLowerCase() !== "all" && category.toLowerCase() !== "accounting") {
    url += `?category=${category}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getproductcodesforsale() {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.apiUrl1}/category/products/codes?forSale=true`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getprocessing_status(category) {
  const requestOptions = {
    method: "GET",
  };

  let selectedCategory = category;

  if (category.toLowerCase() === "all") {
    selectedCategory = "EIN";
  }

  if (category.toLowerCase() === "accounting") {
    selectedCategory = "INC";
  }

  const url = `${config.apiUrl1}/category/${selectedCategory}/processing-statuses`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getprocessing_status_2(category) {
  const requestOptions = {
    method: "GET",
  };

  let selectedCategory = category;

  if (category.toLowerCase() === "all") {
    selectedCategory = "EIN";
  }

  if (category.toLowerCase() === "accounting") {
    selectedCategory = "INC";
  }

  const url = `${config.apiUrl1}/category/${selectedCategory}/processing-statuses-2`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getProductProcessingStatus(category, productCode) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const selectedCategory = category.toLowerCase() === "all" ? "EIN" : category;

  const url = `${config.apiUrl1}/category/${selectedCategory}/products/${productCode}/processing-statuses`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getProcessingError(category) {
  const requestOptions = {
    method: "GET",
  };

  let selectedCategory = category;

  if (category.toLowerCase() === "all") {
    selectedCategory = "EIN";
  }

  if (category.toLowerCase() === "accounting") {
    selectedCategory = "INC";
  }

  const url = `${config.apiUrl1}/category/${selectedCategory}/processing-errors`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getprocessing_error_status(category) {
  const requestOptions = {
    method: "GET",
  };

  let selectedCategory = category;

  if (category.toLowerCase() === "all") {
    selectedCategory = "EIN";
  }

  if (category.toLowerCase() === "accounting") {
    selectedCategory = "INC";
  }

  const url = `${config.apiUrl1}/category/${selectedCategory}/processing-error-statuses`;

  return fetch(url, requestOptions).then(handleResponse);
}

function get_order_status(category) {
  const requestOptions = {
    method: "GET",
  };

  let selectedCategory = category;

  if (category.toLowerCase() === "all") {
    selectedCategory = "EIN";
  }

  if (category.toLowerCase() === "accounting") {
    selectedCategory = "INC";
  }

  const url = `${config.apiUrl1}/category/${selectedCategory}/order-statuses`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getStates(category) {
  const requestOptions = {
    method: "GET",
  };

  let selectedCategory = category;

  if (category.toLowerCase() === "all") {
    selectedCategory = "EIN";
  }

  if (category.toLowerCase() === "accounting") {
    selectedCategory = "INC";
  }

  const url = `${config.apiUrl1}/category/${selectedCategory}/states`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getInternationalCodes() {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.trademarksUrl}/trademarks/international-codes`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getUSCodes() {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.trademarksUrl}/trademarks/us-codes`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getNationalities() {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.trademarksUrl}/trademarks/nationalities`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getProductFileTypes(code) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/category/products/${code}/file-types`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getProcessingOptions(category) {
  const requestOptions = {
    method: "GET",
  };

  const selectedCategory = category.toLowerCase() === "all" ? "EIN" : category;

  const url = `${config.apiUrl1}/category/${selectedCategory}/processing-options`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getActivities(category = "all") {
  const requestOptions = {
    method: "GET",
  };

  const selectedCategory = category.toLowerCase() === "all" ? "EIN" : category;

  const url = `${config.apiUrl1}/category/${selectedCategory}/products/primary-activity`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getUSPTOCategories(params) {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.trademarksUrl}/trademarks/categories${mappingQueryParams(params)}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getPrices(category, options) {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.apiUrl1}/category/${category}/products/prices${mappingQueryParams(options)}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function calculatePrice({ state, code }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ state, code }),
  };

  const url = `${config.apiUrl1}/category/products/calculate-price`;

  return fetch(url, requestOptions).then(handleResponse);
}
