import { GroupChannelProvider } from "@sendbird/uikit-react/GroupChannel/context";
import React from "react";

import CustomSearchPannelView from "./CustomSearchPannelView";

const CustomSearchPannel = ({ channelUrl, setSearchVisible, setCurrentMessage }) => {
  return (
    <GroupChannelProvider channelUrl={channelUrl}>
      <CustomSearchPannelView setSearchVisible={setSearchVisible} setCurrentMessage={setCurrentMessage} />
    </GroupChannelProvider>
  );
};

export default CustomSearchPannel;
