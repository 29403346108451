import { GroupChannelListProvider } from "@sendbird/uikit-react/GroupChannelList/context";
import React from "react";

import CustomGroupChannelListView from "./CustomGroupChannelListView";

const CustomGroupChannelList = ({ orderDetails, productDetails, currentChannelUrl, setCurrentChannelUrl }) => {
  return (
    <GroupChannelListProvider selectedChannelUrl={currentChannelUrl || productDetails?.messageChannelUrl}>
      <CustomGroupChannelListView
        orderDetails={orderDetails}
        productDetails={productDetails}
        setCurrentChannelUrl={setCurrentChannelUrl}
      />
    </GroupChannelListProvider>
  );
};

export default CustomGroupChannelList;
