import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { state_array } from "variables/Variables";

const initAddress = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  country: "",
};

function Address({ address, onError, onChange }) {
  const handleAddAddress = () => onChange(initAddress);

  const handleDeleteAddress = () => onChange(null);

  const handleChangeAddress = ({ target: { name, value } }) => onChange({ ...address, [name]: value });

  if (!address)
    return (
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <Button variant="success" onClick={handleAddAddress}>
            Add address
          </Button>
        </Form.Label>
      </Form.Group>
    );

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address1
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="address1"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={address.address1 || ""}
            onChange={handleChangeAddress}
            onInvalid={() => onError("Address")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address2
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            name="address2"
            type="text"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={address.address2 || ""}
            onChange={handleChangeAddress}
            onInvalid={() => onError("Address2")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="city"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={address.city || ""}
            onChange={handleChangeAddress}
            onInvalid={() => onError("City")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            as="select"
            size="sm"
            name="state"
            value={address.state || ""}
            onChange={handleChangeAddress}
            onInvalid={() => onError("State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="zip"
            maxLength={5}
            pattern="^\d{5}$"
            value={address.zip || ""}
            onChange={handleChangeAddress}
            onInvalid={() => onError("Zip")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="county"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={address.county || ""}
            onChange={handleChangeAddress}
            onInvalid={() => onError("County")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <Button variant="danger" onClick={handleDeleteAddress}>
            Delete address
          </Button>
        </Form.Label>
      </Form.Group>
    </>
  );
}

export default Address;
