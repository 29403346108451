export const accountingConstants = {
  GET_FILES_REQUEST: "ACCOUNTING_GET_FILES_REQUEST",
  GET_FILES_SUCCESS: "ACCOUNTING_GET_FILES_SUCCESS",
  GET_FILES_FAILURE: "ACCOUNTING_GET_FILES_FAILURE",

  UPLOAD_FILE_REQUEST: "ACCOUNTING_UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "ACCOUNTING_UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FAILURE: "ACCOUNTING_UPLOAD_FILE_FAILURE",

  DOWNLOAD_FILE_REQUEST: "ACCOUNTING_DOWNLOAD_FILE_REQUEST",
  DOWNLOAD_FILE_SUCCESS: "ACCOUNTING_DOWNLOAD_FILE_SUCCESS",
  DOWNLOAD_FILE_FAILURE: "ACCOUNTING_DOWNLOAD_FILE_FAILURE",

  DELETE_FILE_REQUEST: "ACCOUNTING_DELETE_FILE_REQUEST",
  DELETE_FILE_SUCCESS: "ACCOUNTING_DELETE_FILE_SUCCESS",
  DELETE_FILE_FAILURE: "ACCOUNTING_DELETE_FILE_FAILURE",

  REFRESH_FILES_FILTERS: "ACCOUNTING_REFRESH_FILES_FILTERS",

  GET_STATEMENTS_REQUEST: "ACCOUNTING_GET_STATEMENTS_REQUEST",
  GET_STATEMENTS_SUCCESS: "ACCOUNTING_GET_STATEMENTS_SUCCESS",
  GET_STATEMENTS_FAILURE: "ACCOUNTING_GET_STATEMENTS_FAILURE",

  CREATE_STATEMENT_REQUEST: "ACCOUNTING_CREATE_STATEMENT_REQUEST",
  CREATE_STATEMENT_SUCCESS: "ACCOUNTING_CREATE_STATEMENT_SUCCESS",
  CREATE_STATEMENT_FAILURE: "ACCOUNTING_CREATE_STATEMENT_FAILURE",

  UPLOAD_STATEMENT_REQUEST: "ACCOUNTING_UPLOAD_STATEMENT_REQUEST",
  UPLOAD_STATEMENT_SUCCESS: "ACCOUNTING_UPLOAD_STATEMENT_SUCCESS",
  UPLOAD_STATEMENT_FAILURE: "ACCOUNTING_UPLOAD_STATEMENT_FAILURE",

  UPDATE_STATEMENT_REQUEST: "ACCOUNTING_UPDATE_STATEMENT_REQUEST",
  UPDATE_STATEMENT_SUCCESS: "ACCOUNTING_UPDATE_STATEMENT_SUCCESS",
  UPDATE_STATEMENT_FAILURE: "ACCOUNTING_UPDATE_STATEMENT_FAILURE",

  DOWNLOAD_STATEMENT_REQUEST: "ACCOUNTING_DOWNLOAD_STATEMENT_REQUEST",
  DOWNLOAD_STATEMENT_SUCCESS: "ACCOUNTING_DOWNLOAD_STATEMENT_SUCCESS",
  DOWNLOAD_STATEMENT_FAILURE: "ACCOUNTING_DOWNLOAD_STATEMENT_FAILURE",

  DELETE_STATEMENT_REQUEST: "ACCOUNTING_DELETE_STATEMENT_REQUEST",
  DELETE_STATEMENT_SUCCESS: "ACCOUNTING_DELETE_STATEMENT_SUCCESS",
  DELETE_STATEMENT_FAILURE: "ACCOUNTING_DELETE_STATEMENT_FAILURE",

  REQUEST_STATEMENT_REQUEST: "ACCOUNTING_REQUEST_STATEMENT_REQUEST",
  REQUEST_STATEMENT_SUCCESS: "ACCOUNTING_REQUEST_STATEMENT_SUCCESS",
  REQUEST_STATEMENT_FAILURE: "ACCOUNTING_REQUEST_STATEMENT_FAILURE",

  REFRESH_STATEMENTS_FILTERS: "ACCOUNTING_REFRESH_STATEMENTS_FILTERS",

  GET_FILE_TYPES_REQUEST: "ACCOUNTING_GET_FILE_TYPES_REQUEST",
  GET_FILE_TYPES_SUCCESS: "ACCOUNTING_GET_FILE_TYPES_SUCCESS",
  GET_FILE_TYPES_FAILURE: "ACCOUNTING_GET_FILE_TYPES_FAILURE",

  GET_CHANNEL_REQUEST: "ACCOUNTING_GET_CHANNEL_REQUEST",
  GET_CHANNEL_SUCCESS: "ACCOUNTING_GET_CHANNEL_SUCCESS",
  GET_CHANNEL_FAILURE: "ACCOUNTING_GET_CHANNEL_FAILURE",

  GET_CHANNEL_ORDERS_REQUEST: "ACCOUNTING_GET_CHANNEL_ORDERS_REQUEST",
  GET_CHANNEL_ORDERS_SUCCESS: "ACCOUNTING_GET_CHANNEL_ORDERS_SUCCESS",
  GET_CHANNEL_ORDERS_FAILURE: "ACCOUNTING_GET_CHANNEL_ORDERS_FAILURE",

  RESET_CHANNEL_ORDERS: "ACCOUNTING_RESET_CHANNEL_ORDERS",

  GET_UPCOMING_DATES_REQUEST: "ACCOUNTING_GET_UPCOMING_DATES_REQUEST",
  GET_UPCOMING_DATES_SUCCESS: "ACCOUNTING_GET_UPCOMING_DATES_SUCCESS",
  GET_UPCOMING_DATES_FAILURE: "ACCOUNTING_GET_UPCOMING_DATES_FAILURE",

  POST_UPCOMING_DATE_REQUEST: "ACCOUNTING_POST_UPCOMING_DATE_REQUEST",
  POST_UPCOMING_DATE_SUCCESS: "ACCOUNTING_POST_UPCOMING_DATE_SUCCESS",
  POST_UPCOMING_DATE_FAILURE: "ACCOUNTING_POST_UPCOMING_DATE_FAILURE",

  DELETE_UPCOMING_DATE_REQUEST: "ACCOUNTING_DELETE_UPCOMING_DATE_REQUEST",
  DELETE_UPCOMING_DATE_SUCCESS: "ACCOUNTING_DELETE_UPCOMING_DATE_SUCCESS",
  DELETE_UPCOMING_DATE_FAILURE: "ACCOUNTING_DELETE_UPCOMING_DATE_FAILURE",
};

export const ACCOUNTING_DOCUMENT_STATUSES = {
  requested: "Requested",
  underReview: "UnderReview",
  confirmed: "Confirmed",
};
