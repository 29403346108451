import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";

import { asyncOptionsList } from "_helpers";

export default function UserFilter({ clearable, activeOption, onSelect }) {
  return (
    <AsyncPaginate
      isClearable={clearable}
      placeholder="Select User..."
      additional={{ page: 1 }}
      value={activeOption}
      onChange={onSelect}
      loadOptions={asyncOptionsList["loadUserOptions"]}
    />
  );
}
