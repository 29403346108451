import React from "react";
import styled from "styled-components";
import { Header } from "@sendbird/uikit-react/ui/Header";
import { IconColors, IconTypes } from "@sendbird/uikit-react/ui/Icon";

import Button from "views/Chats/ui/Button";

const StyledContainer = styled.div``;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px;
`;

export default function LeaveChannelPannel({ onClose, onConfirm }) {
  return (
    <StyledContainer>
      <Header
        className="sendbird-channel-settings__header"
        renderMiddle={() => <Header.Title title="Leave this channel?" />}
        renderRight={() => (
          <Header.IconButton
            className="sendbird-channel-settings__header__close-button"
            onClick={onClose}
            type={IconTypes.CLOSE}
            color={IconColors.ON_BACKGROUND_1}
          />
        )}
      />
      <StyledFooter>
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
        <Button color="#D13E27" onClick={onConfirm}>
          Leave
        </Button>
      </StyledFooter>
    </StyledContainer>
  );
}
