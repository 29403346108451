import React from "react";

import { config } from "_config";
import logo from "assets/img/reactlogo.png";

const LogoLink = ({ url, logo }) => (
  <a href={url} className="simple-text logo-mini">
    <div className="logo-img">
      <img src={logo} alt="App logo" />
    </div>
  </a>
);

const AppTitle = ({ url }) => (
  <a href={url} className="simple-text logo-normal">
    InnovaLab CRM
  </a>
);

const SidebarHeader = ({ image, hasImage }) => (
  <>
    {hasImage && <div className="sidebar-background" style={{ backgroundImage: `url(${image})` }} />}

    <div className="logo">
      <LogoLink url={config.publicUrl} logo={logo} />
      <AppTitle url={config.publicUrl} />
    </div>
  </>
);

export default SidebarHeader;
