import { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { customerActions, alertActions } from "_actions";

const useSidebarLogic = (initialRoutes, authentication) => {
  const dispatch = useDispatch();

  const [expandedRoutes, setExpandedRoutes] = useState({});

  const initializeExpandedRoutes = useCallback(() => {
    const routesConfig = initialRoutes.reduce((acc, route) => {
      if (route?.collapse !== undefined) {
        acc[`${route.path}-${route.name}`] = route.collapse;
      }

      if (!["ADMIN", "DEVELOPER"].includes(authentication?.user?.role)) {
        const ordersRoute = initialRoutes.find((r) => r.name === "Orders");
        if (ordersRoute) {
          acc[`${ordersRoute.path}-${ordersRoute.name}`] = true;
        }
      }
      return acc;
    }, {});

    setExpandedRoutes(routesConfig);
  }, [initialRoutes, authentication]);

  useEffect(() => {
    dispatch(customerActions.getsummary());
    initializeExpandedRoutes();
  }, [dispatch, initializeExpandedRoutes]);

  const expandRoute = useCallback((item) => {
    setExpandedRoutes((prev) => ({
      ...prev,
      [`${item.path}-${item.name}`]: !prev[`${item.path}-${item.name}`],
    }));
  }, []);

  return { expandedRoutes, expandRoute };
};

export default useSidebarLogic;
