import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import ActivityView from "./Activity/View";
import AllQuestionView from "./GeneralQuestion/View/All";
import AltAddressView from "./AltAddress/View";
import CorpAddressView from "./CorpAddress/View";
import DateView from "./Date/View";

import StatusView from "../Status/View";

import { EIN_title_array } from "variables/Variables";

export default function FormProfit({ orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">NON-PROFIT ORGANIZATION</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Non-profit Organization</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Legal Name of the Non-profit Organization:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.legalName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          DBA of the Non-Profit:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.dbaname}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Type of Non-Profit:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.nonProfitType}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Responsible Party Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.responsibleParty.firstName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.responsibleParty.middleName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.responsibleParty.lastName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.responsibleParty.suffixName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Title:
        </Form.Label>
        <Form.Label column sm={7}>
          {EIN_title_array[productDetails.responsibleParty.title]}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.responsibleParty.ssn}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Activity</h6>

      <ActivityView productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">General questions</h6>

      <AllQuestionView productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Corporate Address (PO Boxes are not authorized)</h6>
      <CorpAddressView productDetails={productDetails} />

      <AltAddressView productDetails={productDetails} />

      <DateView productDetails={productDetails} />
    </div>
  );
}
