import * as XLSX from "xlsx";
import * as CSV from "csv-string";

import { config } from "_config";
import { authHeader, mappingQueryParams, handleResponse, saveFile } from "_helpers";

export const customerService = {
  loginCustomer,
  // getAll,
  searchcustomer,
  exportCustomer,
  getOrder,
  deleteOrder,
  getAvailableStatuses,
  downloadPDF,
  downloadProductResultPDF,
  uploadPDF,
  uploadProductPDF,
  deleteProductPDF,
  updateorder,
  addNote,
  addRelatedOrder,
  refunds,
  refundLastTransaction,
  chargeUSPTOFee,
  chargeAmendment,
  setPassword,
  resendEmailByKey,
  sendReceipt,
  sendhardcopy,
  getsummary,
  getprimaryactivity,
  automationLock,
  getrelatedOrders,
  getdashboardreport,
  getTrackingInfo,
  downloadAllProductFiles,
  postUnsubscribe,
  getPaymentMethods,
  addPaymentMethod,
  setPaymentDetails,
  getSubscriptionDetails,
  rerunSubscription,
  chargeAnnualComplianceReport,
  chargeSOUFee,
  chargeExtensionFee,
  chargeRevivalFromAbandoment,
  chargeSalesTaxPermitStateFee,
  chargeRenewalFee,
  dissolveOrder,
  clearCustomerInfo,
  createDelayedUpsell,
  chargeOfficeAction,
  unlockAutomation,
  changeEmail,
  getProductTasks,
  createProductTask,
  updateProductTask,
  deleteProductTask,
};

function loginCustomer(username, category) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/users/oauth2/customers-token?username=${encodeURIComponent(username)}`;

  if (category) {
    url += `&category=${category}`;
  }

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((customer) => {
      return customer;
    });
}

// function getAll(options) {
//   const { category, page, count, sale } = options;
//   const { orderStatus, einErrorStatus, lastday, firstday } = options;

//   const requestOptions = {
//     method: "GET",
//     headers: authHeader(),
//   };

//   let url = `${config.apiUrl1}/orders` + `?category=${category}&page=${page}&count=${count}`;

//   orderStatus.map(({ label }) => {
//     url += `&orderStatus=${label}`;
//   });

//   einErrorStatus.map(({ name }) => {
//     url += `&EINErrorStatus=${name}`;
//   });

//   if (lastday) {
//     url += `&to=${lastday}`;
//   }

//   if (firstday) {
//     url += `&from=${firstday}`;
//   }

//   if (sale) {
//     url += `&salePage=${sale}`;
//   }

//   return fetch(url, requestOptions).then(handleResponse);
// }

function getprimaryactivity(category = "all") {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let selectedCategory = category;

  if (category.toLowerCase() === "all") {
    selectedCategory = "EIN";
  }

  if (category.toLowerCase() === "accounting") {
    selectedCategory = "INC";
  }

  let url = `${config.apiUrl1}/category/${selectedCategory}/products/primary-activity`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getsummary() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/report`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getOrder(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/` + `${id}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteOrder(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/` + `${id}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getAvailableStatuses(category, productId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const selectedCategory = category.toLowerCase() === "all" ? "EIN" : category;

  const url = `${config.apiUrl1}/category/${selectedCategory}/products/${productId}/available-processing-statuses`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getrelatedOrders(id, includeTest, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/?customerUid=${id}&includeTest=${includeTest}&page=${page}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function refunds({ orderId, productId, amount }) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/`;

  if (productId) {
    url += `products/${productId}/refund?amount=${amount}`;
  } else {
    url += "refund";
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function refundLastTransaction(orderId, productId) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/refund-last-transaction`;

  return fetch(url, requestOptions).then(handleResponse);
}

function chargeUSPTOFee(id, amount) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${id}/charge-uspto-fee`;

  if (amount && amount !== "0.00") {
    url += `?amount=${amount}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function chargeRenewalFee(id, amount) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${id}/charge-renewal-fee`;

  if (amount && amount !== "0.00") {
    url += `?amount=${amount}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function chargeAmendment(id, amount) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${id}/charge-amendment`;

  if (amount && amount !== "0.00") {
    url += `?amount=${amount}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function setPassword(customerId, password, category = null) {
  const requestOptions = {
    method: "post",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ password, category }),
  };

  const url = `${config.trademarksUrl}/customers/${customerId}/set-password`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getPaymentMethods(customerId) {
  const requestOptions = {
    method: "get",
    headers: authHeader(),
  };

  const url = `${config.trademarksUrl}/customers/${customerId}/payment-methods`;

  return fetch(url, requestOptions).then(handleResponse);
}

function addRelatedOrder(orderId, data) {
  const requestOptions = {
    method: "post",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/create-delayed-upsale`;

  return fetch(url, requestOptions).then(handleResponse);
}

function addPaymentMethod(customerId, cardData) {
  const requestOptions = {
    method: "post",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(cardData),
  };

  const url = `${config.trademarksUrl}/customers/${customerId}/payment-methods`;

  return fetch(url, requestOptions).then(handleResponse);
}

function setPaymentDetails(orderId, paymentDetails) {
  const requestOptions = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(paymentDetails),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/payment-details`;

  return fetch(url, requestOptions).then(handleResponse);
}

function addNote(orderId, { message, customerContactRequired }) {
  const requestOptions = {
    method: "post",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ message }),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/notes`;

  if (customerContactRequired) {
    url += `?customerContactRequired=${customerContactRequired}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function updateorder(id, data) {
  if (data?.children) delete data?.children;

  const requestOptions = {
    method: "put",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = `${config.apiUrl1}/orders/` + `${id}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function downloadProductResultPDF(orderId, productId, fileType, name, checkMime = false) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/result-PDF${mappingQueryParams({ fileType })}`;

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 200) {
      saveFile(response, name, true, checkMime);
    } else {
      return handleResponse(response);
    }
  });
}

function downloadPDF(orderId, productId, name, checkMime = false) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/result-PDF`;
  const filename = name + "_result.pdf";

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 200) {
      saveFile(response, filename, true, checkMime);
    } else {
      return handleResponse(response);
    }
  });
}

function resendEmailByKey(orderId, productId, emailKey) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/emails/${emailKey}/resend-email`;

  return fetch(url, requestOptions).then(handleResponse);
}

function sendReceipt(orderId) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/send-order-receipt`;

  return fetch(url, requestOptions).then(handleResponse);
}

function sendhardcopy(id) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${id}/send-hard-copy`;

  return fetch(url, requestOptions).then(handleResponse);
}

function uploadPDF(id, ein_number, form) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: form,
  };

  const url = `${config.apiUrl1}/orders/${id}/ein-number/${ein_number}/EINPDF`;

  return fetch(url, requestOptions).then(handleResponse);
}

function uploadProductPDF(orderId, productId, fileType, body, fileNote) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body,
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/result-PDF${mappingQueryParams({
    fileType,
    fileNote,
  })}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteProductPDF(orderId, productId, fileType) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/result-PDF${mappingQueryParams({ fileType })}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function searchcustomer(options = {}) {
  const { category, sale, page, count, productType, firstName, lastName, email, phone, includeTest } = options;
  const {
    orderId,
    orderStatus,
    einErrorStatus,
    einError,
    einStatus,
    fillingTime,
    lastday,
    firstday,
    primaryActivity,
    incState,
    processingOption,
    assigneeType,
    assigneeUser,
    accountManager,
    onlyCustomerContactRequired,
    llcName,
    serialNumber,
    orderHasProduct,
    subStatuses,
  } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders?page=${page}&count=${count}`;

  if (category && category.toLowerCase() !== "all") {
    url += `&category=${category}`;
  }

  if (productType) {
    url += `&productCode=${productType}`;
  }

  if (firstName) {
    url += `&firstName=${firstName}`;
  }

  if (lastName) {
    url += `&lastName=${lastName}`;
  }

  if (email) {
    url += `&email=${encodeURIComponent(email)}`;
  }

  if (phone) {
    url += `&phone=${phone}`;
  }

  if (includeTest) {
    url += `&includeTest=${includeTest}`;
  }

  if (orderHasProduct) {
    const products = orderHasProduct?.filter((item) => item.value !== "*") || [];

    products.forEach((item) => {
      url += `&orderHasProduct=${item.value}`;
    });
  }

  if (orderId != "") {
    url += `&orderId=${orderId}`;
  }

  if (orderStatus != "") {
    orderStatus.map((item) => {
      url += `&orderStatus=${item.value}`;
    });
  }

  if (einErrorStatus != "") {
    einErrorStatus.map(({ value }) => {
      url += `&EINErrorStatus=${value}`;
    });
  }

  if (einError != "") {
    einError.map(({ value }) => {
      url += `&EINError=${value}`;
    });
  }

  if (einStatus != "") {
    url += `&EINStatus=${einStatus}`;
  }

  if (category === "ein" && fillingTime != "") {
    url += `&processingOption=${fillingTime}`;
  }

  if (lastday) {
    url += `&to=${lastday}`;
  }

  if (firstday) {
    url += `&from=${options.firstday}`;
  }

  if (primaryActivity) {
    url += `&primaryActivity=${primaryActivity}`;
  }

  if (incState) {
    url += `&incState=${incState}`;
  }

  if (processingOption) {
    url += `&processingOption=${processingOption}`;
  }

  if (assigneeType) {
    url += `&assigneeType=${assigneeType}`;
  }

  if (assigneeUser?.value) {
    url += `&assigneeUser=${assigneeUser.value}`;
  }

  if (accountManager?.value) {
    url += `&accountManager=${accountManager.value}`;
  }

  if (onlyCustomerContactRequired) {
    url += `&onlyCustomerContactRequired=${onlyCustomerContactRequired}`;
  }

  if (productType || einStatus || einErrorStatus?.length || einError?.length) {
    url += `&linePerProduct=true`;
  }

  if (llcName) {
    url += `&llcName=${encodeURIComponent(llcName)}`;
  }

  if (sale) {
    url += `&salePage=${sale}`;
  }

  if (serialNumber) {
    url += `&serialNumber=${serialNumber}`;
  }

  if (subStatuses) {
    url += `&subStatuses=${subStatuses}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function automationLock(orderId, productId) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  const url = `${config.apiUrl2}/orders/${orderId}/products/${productId}/process`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getdashboardreport({ startDate, endDate, category, zone, includeRecurring }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/dashboard-report${mappingQueryParams({
    from: startDate,
    to: endDate,
    category,
    zone,
    includeRecurring: includeRecurring.toString(),
  })}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getTrackingInfo(orderId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/tracking-info`;

  return fetch(url, requestOptions).then(handleResponse);
}

function downloadAllProductFiles(orderId, productId, name) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/zip" },
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/result-files`;
  const filename = name + ".zip";

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 200) {
      saveFile(response, filename);
    } else {
      return handleResponse(response);
    }
  });
}

function postUnsubscribe(orderId, productId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/cancel-subscription`;

  return fetch(url, requestOptions).then(handleResponse);
}

function convertCsvToExcelBuffer(csvString, fileName) {
  const arrayOfArrayCsv = CSV.parse(csvString);
  // const arrayOfArrayCsv = csvString.split("\n").map((row) => {
  //   return row.split(",");
  // });
  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
  XLSX.utils.book_append_sheet(wb, newWs);
  XLSX.writeFile(wb, fileName);
}

function getFileHandle(fileName = "report", format = "csv") {
  return async function (response) {
    let rejected = null;

    await response.text().then((csvString) => {
      if (!response?.ok) {
        const data = csvString && JSON.parse(csvString);
        const error =
          (data && data.message) || (Array.isArray(data?.errors) && { errors: data.errors }) || response.statusText;

        rejected = error;
        return false;
      }

      const file_name = `${fileName}.${format}`;

      if (format === "xlsx") {
        convertCsvToExcelBuffer(csvString, file_name);
      } else {
        const url = window.URL.createObjectURL(new Blob([csvString]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    });

    if (rejected) {
      return Promise.reject(rejected);
    }

    return true;
  };
}

function exportCustomer(options = {}) {
  const { category, sale, page, count, productType, firstName, lastName, email, phone, includeTest } = options;
  const {
    orderId,
    orderStatus,
    einErrorStatus,
    einError,
    einStatus,
    fillingTime,
    lastday,
    firstday,
    primaryActivity,
    incState,
    processingOption,
    assigneeType,
    assigneeUser,
    accountManager,
    onlyCustomerContactRequired,
    llcName,
    serialNumber,
    orderHasProduct,
    subStatuses,
    fileType,
  } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/csv?page=${page}&count=${count}`;

  if (category && category.toLowerCase() !== "all") {
    url += `&category=${category}`;
  }

  if (productType) {
    url += `&productCode=${productType}`;
  }

  if (firstName) {
    url += `&firstName=${firstName}`;
  }

  if (lastName) {
    url += `&lastName=${lastName}`;
  }

  if (email) {
    url += `&email=${encodeURIComponent(email)}`;
  }

  if (phone) {
    url += `&phone=${phone}`;
  }

  if (includeTest) {
    url += `&includeTest=${includeTest}`;
  }

  if (orderHasProduct) {
    const products = orderHasProduct?.filter((item) => item.value !== "*") || [];

    products.forEach((item) => {
      url += `&orderHasProduct=${item.value}`;
    });
  }

  if (orderId != "") {
    url += `&orderId=${orderId}`;
  }

  if (orderStatus != "") {
    orderStatus.map((item) => {
      url += `&orderStatus=${item.value}`;
    });
  }

  if (einErrorStatus != "") {
    einErrorStatus.map(({ value }) => {
      url += `&EINErrorStatus=${value}`;
    });
  }

  if (einError != "") {
    einError.map(({ value }) => {
      url += `&EINError=${value}`;
    });
  }

  if (einStatus != "") {
    url += `&EINStatus=${einStatus}`;
  }

  if (category === "ein" && fillingTime != "") {
    url += `&processingOption=${fillingTime}`;
  }

  if (lastday) {
    url += `&to=${lastday}`;
  }

  if (firstday) {
    url += `&from=${options.firstday}`;
  }

  if (primaryActivity) {
    url += `&primaryActivity=${primaryActivity}`;
  }

  if (incState) {
    url += `&incState=${incState}`;
  }

  if (processingOption) {
    url += `&processingOption=${processingOption}`;
  }

  if (assigneeType) {
    url += `&assigneeType=${assigneeType}`;
  }

  if (assigneeUser?.value) {
    url += `&assigneeUser=${assigneeUser.value}`;
  }

  if (accountManager?.value) {
    url += `&accountManager=${accountManager.value}`;
  }

  if (onlyCustomerContactRequired) {
    url += `&onlyCustomerContactRequired=${onlyCustomerContactRequired}`;
  }

  if (productType || einStatus || einErrorStatus?.length || einError?.length) {
    url += `&linePerProduct=true`;
  }

  if (llcName) {
    url += `&llcName=${encodeURIComponent(llcName)}`;
  }

  if (sale) {
    url += `&salePage=${sale}`;
  }

  if (serialNumber) {
    url += `&serialNumber=${serialNumber}`;
  }

  if (subStatuses) {
    url += `&subStatuses=${subStatuses}`;
  }

  return fetch(url, requestOptions).then(getFileHandle(`${category}-orders-report`, fileType));
}

function getSubscriptionDetails(orderId, productId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/subscription-details`;

  return fetch(url, requestOptions).then(handleResponse);
}

function rerunSubscription(orderId, productId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/rerun-subscription`;

  return fetch(url, requestOptions).then(handleResponse);
}

function chargeAnnualComplianceReport(orderId, amount) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/charge-annual-compliance-report`;

  if (amount && amount !== "0.00") {
    url += `?amount=${amount}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function chargeSOUFee(orderId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/charge-sou-fee`;

  return fetch(url, requestOptions).then(handleResponse);
}

function chargeExtensionFee(orderId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/charge-extension-fee`;

  return fetch(url, requestOptions).then(handleResponse);
}

function chargeOfficeAction(orderId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/charge-office-action`;

  return fetch(url, requestOptions).then(handleResponse);
}

function chargeRevivalFromAbandoment(orderId, numberOfClasses) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/charge-revival-fee`;

  if (numberOfClasses && Number(numberOfClasses) !== 0) {
    url += `?classes=${numberOfClasses}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function chargeSalesTaxPermitStateFee(orderId, amount) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/charge-sales-tax-permit-state-fee`;

  if (amount && amount !== "0.00") {
    url += `?amount=${amount}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function dissolveOrder(orderId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/dissolve`;

  return fetch(url, requestOptions).then(handleResponse);
}

function clearCustomerInfo(customerId) {
  const requestOptions = {
    method: "post",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/customers/${customerId}/clear-personal-info`;

  return fetch(url, requestOptions).then(handleResponse);
}

function createDelayedUpsell({ orderId, body }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/create-delayed-upsale`;

  return fetch(url, requestOptions).then(handleResponse);
}

function unlockAutomation(orderId, productId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/unlock-product`;

  return fetch(url, requestOptions).then(handleResponse);
}

function changeEmail(orderId, email) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/change-email?email=${email}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getProductTasks({ orderId, productId, page, count, type, status }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/tasks?page=${page}&count=${count}`;

  if (type) {
    url += `&type=${type}`;
  }

  if (status) {
    url += `&status=${status}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function createProductTask({ orderId, productId, body }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/tasks`;

  return fetch(url, requestOptions).then(handleResponse);
}

function updateProductTask({ orderId, productId, taskId, body }) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/tasks/${taskId}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteProductTask({ orderId, productId, taskId }) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/tasks/${taskId}`;

  return fetch(url, requestOptions).then(handleResponse);
}
