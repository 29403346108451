const reducer = (accumulator, currentValue) => {
  if (Array.isArray(currentValue)) {
    return [...accumulator, ...currentValue];
  } else {
    accumulator.push(currentValue);
    return accumulator;
  }
};

export const getClassName = (classNames) =>
  Array.isArray(classNames) ? classNames.reduce(reducer, []).join(" ") : classNames;

export function debounce(cb, timeout = 0) {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      cb(...args);
    }, timeout);
  };
}
