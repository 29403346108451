import { GroupChannelProvider } from "@sendbird/uikit-react/GroupChannel/context";
import React from "react";

import CustomSearchPannelView from "./CustomSearchPannelView";

const CustomSearchPannel = ({ productDetails, currentChannelUrl, setSearchVisible, setCurrentMessage }) => {
  return (
    <GroupChannelProvider channelUrl={currentChannelUrl || productDetails?.messageChannelUrl}>
      <CustomSearchPannelView setSearchVisible={setSearchVisible} setCurrentMessage={setCurrentMessage} />
    </GroupChannelProvider>
  );
};

export default CustomSearchPannel;
