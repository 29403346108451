import React, { useCallback, useMemo } from "react";
import { Pagination } from "react-bootstrap";

export default function Paginate({ page, totalPages, loading, handlePageChange }) {
  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = useCallback(() => {
    handlePageChange(totalPages);
  }, [totalPages]);

  const handlePrevPage = () => {
    handlePageChange(page - 1);
  };

  const handleNextPage = () => {
    handlePageChange(page + 1);
  };

  const handleActivePage = (newPage) => () => {
    handlePageChange(newPage);
  };

  const paginationItems = useMemo(() => {
    const prevPage = page - 1;
    const multiplicator = Math.floor(prevPage / 5);
    const fromPage = page <= 5 ? 1 : multiplicator * 5 + 1;
    const visiblePages = totalPages - multiplicator * 5 < 5 ? totalPages - multiplicator * 5 : 5;

    return (
      <>
        {page > visiblePages && <Pagination.Ellipsis onClick={handleActivePage(fromPage - 5)} />}
        {Array.from({ length: visiblePages }, (_, i) => {
          return (
            <Pagination.Item
              key={fromPage + i}
              disabled={loading}
              active={page === fromPage + i}
              onClick={handleActivePage(fromPage + i)}
            >
              {fromPage + i}
            </Pagination.Item>
          );
        })}
        {visiblePages === 5 && (multiplicator + 1) * 5 < totalPages && (
          <Pagination.Ellipsis onClick={handleActivePage(fromPage + 5)} />
        )}
      </>
    );
  }, [page, totalPages, loading]);

  if (!totalPages) {
    return null;
  }

  return (
    <Pagination className="ml-2">
      <Pagination.First disabled={page === 1 || loading} onClick={handleFirstPage} />
      <Pagination.Prev disabled={page === 1 || loading} onClick={handlePrevPage} />
      {paginationItems}
      <Pagination.Next disabled={page === totalPages || loading} onClick={handleNextPage} />
      <Pagination.Last disabled={page === totalPages || loading} onClick={handleLastPage} />
    </Pagination>
  );
}
