import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Form } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { AsyncPaginate } from "react-select-async-paginate";
import styled from "styled-components";

import { usePrevious } from "_hooks";
import { asyncOptionsList } from "_helpers";

import { fillingTimeArray } from "variables/Variables.jsx";
import Button from "components/Button";

const StyledGroup = styled(Form.Group)`
  position: relative;
  padding: 12px;
  margin-bottom: 24px;

  & label {
    font-size: 14px;
    text-transform: unset;
    color: black;
  }

  &:hover {
    .filter-title {
      /* background-color: #cce4ff; */
      color: #505050;
    }
  }
`;

const StyledTitle = styled.div`
  position: absolute;
  top: -10px;
  left: 12px;
  color: gray;
  font-size: 12px;
  min-width: 130px;
  width: max-content;
  padding: 0 4px;
  margin-bottom: 8px;
  transition: all 0.1s;
`;

const shortCategories = {
  EIN: "EIN",
  EIN_INC: "EIN_INC",
  INC: "INC",
  INC_States: "INC_St",
  VITAL: "VITAL",
  TRADEMARKS: "TM",
};

function OrdersFilters(props) {
  const {
    filtersPreset,
    filters,
    totalResults,
    currentPage,
    currentCategory,
    handlers,
    handleResetFilters,
    handleChangeMyQueueFilters,
    handleSaveMyQueueFilters,
    handleSearch,
    handleExport,
  } = props;

  const [isAssigneeTypeLoading, setIsAssigneeTypeLoading] = useState(false);
  const prevIsAssigneeTypeLoading = usePrevious(isAssigneeTypeLoading);
  const prevFilters = usePrevious(filters);

  const searchLoading = useSelector(({ customers }) => customers.loading);
  const exportLoading = useSelector(({ customers: { exportLoading } }) => exportLoading);

  const einStatusArray = useSelector(({ variants: { processingStatus } }) => processingStatus);
  const einErrorStatusArray = useSelector(({ variants: { processingErrorStatus } }) =>
    processingErrorStatus.map(({ value, code, ...item }) => ({
      ...item,
      value: code,
      label: value,
    }))
  );
  const einErrorArray = useSelector(({ variants: { processingErrors } }) =>
    processingErrors.map(({ value, code, ...item }) => ({
      ...item,
      value: code,
      label: value,
    }))
  );
  const orderStatusArray = useSelector(({ variants: { orderStatus } }) =>
    orderStatus.map(({ value, code, ...props }) => ({
      ...props,
      value: code,
      label: value,
    }))
  );
  const productCodeArray = useSelector(({ variants: { productCodes } }) =>
    productCodes.map(({ code, customerName }) => ({ code, value: customerName }))
  );
  const processingSpeedOptions = useSelector(({ variants: { productCodes } }) => {
    return productCodes
      .filter((item) => {
        return item?.code?.includes("Same_Day_Processing") || item?.code?.includes("Rush_Processing");
      })
      ?.map(({ code, customerName, category }) => {
        const label = `[${shortCategories[category] || "N/A"}] ${customerName}`;

        return {
          code,
          label,
          value: code,
        };
      });
  });
  const primaryActivityOptions = useSelector(({ primaryactivity }) =>
    primaryactivity.map(({ code, label }) => ({ code, value: label }))
  );
  const states = useSelector(({ variants: { states } }) => states);
  const processingOptions = useSelector(({ variants: { processingOptions } }) => processingOptions);

  const subStatusOptions = useSelector(({ variants: { processingSubStatusOpt, allProcessingSubstatusesOpt } }) => {
    if (currentCategory?.toLowerCase() === "all") {
      return Object.keys(allProcessingSubstatusesOpt).reduce((options, code) => {
        options.push({ code, value: allProcessingSubstatusesOpt[code] });
        return options;
      }, []);
    } else {
      const subStatusesOpt = Object.values(processingSubStatusOpt).reduce((acc, subSatuses) => {
        return { ...acc, ...subSatuses };
      }, {});

      return Object.keys(subStatusesOpt).reduce((options, code) => {
        options.push({ code, value: subStatusesOpt[code] });
        return options;
      }, []);
    }
  });

  const [moreOptions, setMoreOptions] = useState(false);
  const [hasMoreOptions, setHasMoreOptions] = useState(false);

  const binarySwitchArray = [
    { code: false, value: "No" },
    { code: true, value: "Yes" },
  ];

  const assigneeTypeOptions = [
    { code: "TEAM_USER", value: "Team User" },
    { code: "TEAM_ATTORNEY", value: "Team Legal" },
    { code: "SYSTEM", value: "System" },
    { code: "CUSTOMER", value: "Customer" },
  ];

  const optionsList = {
    einStatusArray: [{ code: "", value: "Choose..." }, ...einStatusArray],
    einErrorStatusArray,
    einErrorArray,
    orderStatusArray,
    productCodeArray: [{ code: "", value: "Choose..." }, ...productCodeArray],
    primaryActivityOptions: [{ code: "", value: "--" }, ...primaryActivityOptions],
    fillingTimeArray: [{ code: "", value: "Choose..." }, ...fillingTimeArray.map((el) => ({ code: el, value: el }))],
    processingSpeedOptionsProducts: [{ value: "*", label: "All" }, ...processingSpeedOptions],
    binarySwitchArray,
    incStates: [{ code: "", value: "All States" }, ...states],
    processingOptions: [{ code: "", value: "Choose..." }, ...processingOptions],
    assigneeTypeOptions: [{ code: "", value: "Choose..." }, ...assigneeTypeOptions],
    subStatusOptions: [{ code: "", value: "Choose..." }, ...subStatusOptions],
  };

  const handleMoreOptions = () => setMoreOptions(!moreOptions);

  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value?.length || 0} selected`;
    }
  };

  const filtersRowRenderer = ({ type, filters, title }, idx) => {
    switch (type) {
      case "static":
        return (
          <StyledGroup key={idx} className="card">
            <StyledTitle className="card filter-title">{title}</StyledTitle>
            <Form.Row key={idx} className="md-2">
              {filters.map(filtersGroupRenderer)}
            </Form.Row>
          </StyledGroup>
        );
      case "expanded":
        return (
          <StyledGroup key={idx} className={`card ${moreOptions ? "d-flex" : "d-none"}`}>
            <StyledTitle className="card filter-title">{title}</StyledTitle>
            <Form.Row>{filters.map(filtersGroupRenderer)}</Form.Row>
          </StyledGroup>
        );
    }
  };

  useEffect(() => {
    if (filters?.assigneeUser?.value && prevFilters?.assigneeUser?.value !== filters?.assigneeUser?.value) {
      (async function () {
        try {
          setIsAssigneeTypeLoading(true);

          const { role } = await userService.getById(filters?.assigneeUser?.value);
          const assigneeType = assigneeTypeOptions.find(({ code }) => code.includes(role))?.code || "";
          handlers?.multiselect(assigneeType, { name: "assigneeType" });

          setIsAssigneeTypeLoading(false);
        } catch (e) {
          setIsAssigneeTypeLoading(false);
        }
      })();
    }
  }, [prevFilters?.assigneeUser?.value, filters?.assigneeUser?.value, handlers]);

  useEffect(() => {
    if (prevIsAssigneeTypeLoading && !isAssigneeTypeLoading) {
      handleSaveMyQueueFilters && handleSaveMyQueueFilters();
    }
  }, [prevIsAssigneeTypeLoading, isAssigneeTypeLoading]);

  useEffect(() => {
    setHasMoreOptions(filtersPreset.some(({ type }) => type === "expanded"));
  }, []);

  const filtersGroupRenderer = ({ label, type, name, placeholder, optionList, changeAction, asyncOptionList }) => {
    switch (type) {
      case "text":
      case "date":
      case "email": {
        return (
          <Form.Group key={name} as={Col} xs={12} sm={6} md={4} lg={4} xl={4}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              size="sm"
              placeholder={placeholder}
              type={type}
              name={name}
              value={filters[name]}
              onChange={handlers[changeAction]}
            />
          </Form.Group>
        );
      }
      case "checkbox": {
        const [filterName, filterValue] = name.split("--");

        return (
          <Form.Group key={name} as={Col} className="d-flex align-items-end" xs={12} sm={6} md={4} lg={4} xl={4}>
            <Form.Label htmlFor={name}>
              <Form.Check
                type={type}
                id={name}
                name={name}
                label={label}
                checked={Boolean(filters[filterName] === filterValue)}
                onChange={handlers[changeAction]}
              />
            </Form.Label>
          </Form.Group>
        );
      }
      case "select": {
        return (
          <Form.Group key={name} as={Col} xs={12} sm={6} md={4} lg={4} xl={4}>
            <Form.Label>{label}</Form.Label>
            <Form.Control size="sm" as="select" name={name} value={filters[name]} onChange={handlers[changeAction]}>
              {isAssigneeTypeLoading && name === "assigneeType" && (
                <option hidden selected>
                  Loading...
                </option>
              )}
              {((!isAssigneeTypeLoading && name === "assigneeType") || name !== "assigneeType") &&
                optionsList[optionList].map((prop, key) => (
                  <option value={prop.code} key={key}>
                    {prop.value}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        );
      }
      case "multiselect": {
        return (
          <Form.Group key={name} as={Col} xs={12} sm={6} md={4} lg={4} xl={4}>
            <Form.Label>{label}</Form.Label>
            <ReactMultiSelectCheckboxes
              className="reactMultiSelectCheckboxes"
              placeholderButtonLabel={placeholder}
              name={name}
              value={filters[name]}
              options={optionsList[optionList]}
              onChange={(values, event) => {
                handlers[changeAction](values, event, optionsList[optionList]);
              }}
              getDropdownButtonLabel={getDropdownButtonLabel}
            />
          </Form.Group>
        );
      }
      case "asyncselect": {
        return (
          <Form.Group key={name} as={Col} xs={12} sm={6} md={4} lg={4} xl={4}>
            <Form.Label>{label}</Form.Label>
            <AsyncPaginate
              className="async-select-container"
              classNamePrefix="async-select"
              placeholder="Search..."
              isClearable={!!filters[name]?.value}
              additional={{ page: 1 }}
              value={filters[name]}
              onChange={handlers[changeAction](name)}
              loadOptions={asyncOptionsList[asyncOptionList]}
            />
          </Form.Group>
        );
      }
    }
  };

  return (
    <div className="search-box my-3" key="orders-">
      <Form>
        {filtersPreset.map(filtersRowRenderer)}
        <Form.Row>
          <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4}>
            <Form.Label>Saved Filter</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              className="my-1 mr-sm-2"
              id="savedfilter"
              onChange={handlers.chooseSavedFilter}
              value={filters.savedFilter}
              name="savedFilter"
            >
              <option value="0">Choose...</option>
              <option value="1">Unprocessed Rush Orders</option>
              <option value="2">Errors Needing Attention</option>
              <option value="3">Unprocessed &gt; 2 Days Old</option>
            </Form.Control>

            <div className="mt-4">
              <Form.Label style={{ disaply: "inline-block" }}>
                Results Found: {totalResults > 1000 ? "1000+" : totalResults}
              </Form.Label>

              <Form.Label className="ml-4">Current Page: {currentPage}</Form.Label>
            </div>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <div className="ml-3">
            <Button
              size="sm"
              variant="primary"
              className="btn-sm mr-2 mb-2"
              type="button"
              disabled={searchLoading || exportLoading}
              onClick={handleResetFilters}
            >
              Clear
            </Button>

            <Button
              size="sm"
              variant="primary"
              className="btn-sm mr-2 mb-2"
              type="button"
              disabled={isAssigneeTypeLoading || exportLoading}
              isLoading={isAssigneeTypeLoading || searchLoading}
              onClick={handleSearch}
            >
              Search
            </Button>

            <Button
              size="sm"
              variant="primary"
              className="btn-sm mr-2 mb-2"
              type="button"
              disabled={isAssigneeTypeLoading}
              onClick={handleChangeMyQueueFilters}
            >
              My Queue
            </Button>

            <Button
              size="sm"
              variant="primary"
              className="btn-sm mr-2 mb-2"
              type="button"
              isLoading={exportLoading}
              disabled={!totalResults || searchLoading}
              onClick={() => handleExport("csv")}
            >
              Export CSV
            </Button>

            <Button
              size="sm"
              variant="primary"
              className="btn-sm mr-2 mb-2"
              type="button"
              isLoading={exportLoading}
              disabled={!totalResults || searchLoading}
              onClick={() => handleExport("xlsx")}
            >
              Export XLSX
            </Button>

            {hasMoreOptions && (
              <Button size="sm" className="btn-sm bold mb-2" onClick={handleMoreOptions} variant="outline-primary">
                <strong>{moreOptions ? <span>&#8722; Less</span> : <span>&#43; More</span>} Options</strong>
              </Button>
            )}
          </div>
        </Form.Row>
      </Form>
    </div>
  );
}

export default OrdersFilters;
