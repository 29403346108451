// Submenu.jsx
import React from "react";
import { checkRoleAccess, checkCategoryAccess } from "_helpers/access";
import SubmenuItem from "./SubmenuItem";

const Submenu = ({ items, role, loading, availableCategories, currentPath }) => (
  <ul className="nav submenu">
    {items.map((item) => {
      const hasAccess = checkRoleAccess(item, role);
      const categoryAllowed = checkCategoryAccess(item, availableCategories);
      const isActive = currentPath === item.layout + item.path;

      return hasAccess && categoryAllowed && item.submenu ? (
        <SubmenuItem key={item.path} item={item} loading={loading} isActive={isActive} />
      ) : null;
    })}
  </ul>
);

export default Submenu;
