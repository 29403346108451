import React, { useEffect, useRef, useState, useMemo } from "react";
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { getFileExtensionsString, getFileExtension, checkIfFileAllowed, sanitizeFileName } from "_helpers";
import { accountingActions, alertActions } from "_actions";

import ConfirmationModal from "components/ConfirmationModal";
import DocumentDetailsModal from "components/DocumentDetailsModal";
import Paginate from "components/Paginate";

const getYearOptions = () => {
  const startYear = new Date().getFullYear();

  return Array.from(new Array(30), (_, i) => {
    return { value: `${startYear - i}`, label: `${startYear - i}` };
  });
};

const AccountingDocuments = ({ productDetails, isDirty, onShowConfirmation }) => {
  if (!productDetails) return null;

  const dispatch = useDispatch();
  const customerId = useSelector(({ customers: { orderdata } }) => orderdata?.customer?.id);

  const fileTypes = useSelector(({ accounting }) => accounting?.fileTypes?.data);
  const files = useSelector(({ accounting }) => accounting?.files);
  const { data: filesData, filters, loading } = files || {};
  const isNoFiles = !filesData?.elements?.length;

  const pdfEl = useRef(null);
  const productId = productDetails?.id;

  const { productsFileTypesObj } = useSelector(({ variants }) => variants);

  const [fileToDelete, setFileToDelete] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [type, setType] = useState("");
  const [documentDetailsShow, setDocumentDetailsShow] = useState(false);

  const productFileTypesObj = productsFileTypesObj["INC_LLCIncorporation"] || {};
  const extensionsFile = getFileExtensionsString(productFileTypesObj["INC_STATE_FORM"]?.allowedExtensions);
  const selectedFileSize = selectedFile?.size;
  const selectedFileExt = getFileExtension(selectedFile?.name);
  const isExtensionAllowed = checkIfFileAllowed(
    selectedFileExt,
    productFileTypesObj["INC_STATE_FORM"]?.allowedExtensions
  );

  const fileTypeTitles = useMemo(
    () =>
      fileTypes.reduce((res, { code, value }) => {
        res[code] = value;
        return res;
      }, {}),
    [fileTypes]
  );

  const handleDocumentChange = (event) => {
    if (!event.target.files.length) {
      setSelectedFile(null);
    } else {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleDocumentDownload = (fileId, fileName) =>
    dispatch(accountingActions.downloadFile({ customerId, productId, fileId, fileName }));

  const handleDocumentDelete = (fileId) =>
    dispatch(accountingActions.deleteFile({ customerId, productId, fileId, filters }));

  const handleDocumentUploadInit = () => {
    if (!isExtensionAllowed) {
      dispatch(alertActions.error("Please select file with correct extension: " + (extensionsFile || "any type")));
    } else if (selectedFileSize && selectedFileSize / 1024 / 1024 > 5) {
      dispatch(alertActions.error("File size limit is 5MB"));
    } else if (selectedFile === null) {
      dispatch(alertActions.error("Please select file to upload"));
    } else {
      setDocumentDetailsShow(true);
    }
  };

  const handleDocumentUploadComplete = (details) => {
    const uploadFunction = () => {
      const { startDate, endDate, description, year } = details || {};
      const body = new FormData();

      body.append("file", selectedFile, sanitizeFileName(selectedFile?.name));

      dispatch(
        accountingActions.uploadFile({
          customerId,
          productId,
          body,
          startDate,
          endDate,
          description,
          type,
          year,
          filters,
        })
      );

      setSelectedFile(null);
      setDocumentDetailsShow(false);
      if (pdfEl.current) pdfEl.current.value = null;
    };

    if (isDirty) {
      onShowConfirmation(uploadFunction);
    } else {
      uploadFunction();
    }
  };

  const handleDocumentDetailsClose = () => {
    setDocumentDetailsShow(false);
  };

  const handlePageChange = (page) => {
    dispatch(accountingActions.refreshFilesFilters({ page }));
  };

  const handleTypeFilterChange = ({ target: { value } }) => {
    dispatch(accountingActions.refreshFilesFilters({ type: value }));
  };

  const handleYearFilterChange = ({ target: { value } }) => {
    dispatch(accountingActions.refreshFilesFilters({ year: value }));
  };

  useEffect(() => {
    if (!type && fileTypes?.length) {
      setType(fileTypes[0]?.code || "");
    }
  }, [fileTypes, type]);

  useEffect(() => {
    if (filters) {
      dispatch(accountingActions.getFiles({ customerId, productId, ...filters }));
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [filters]);

  useEffect(() => {
    if (customerId && productId) {
      dispatch(accountingActions.getFiles({ customerId, productId, ...filters }));
    }
  }, [customerId, productId]);

  return (
    <>
      <hr />
      <Row>
        <Col xs={4}>
          <Row>
            <Col sm={3} className="d-flex align-items-center">
              <h6 style={{ fontWeight: 400 }}>Type:</h6>
            </Col>
            <Col sm={9} style={{ marginBottom: 8 }}>
              <Form.Control as="select" size="sm" value={filters?.type} onChange={handleTypeFilterChange}>
                <option value="">All</option>
                {fileTypes?.map(({ code, value }) => (
                  <option key={code} value={code}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col sm={3} className="d-flex align-items-center">
              <h6 style={{ fontWeight: 400 }}>Year:</h6>
            </Col>
            <Col sm={9} style={{ marginBottom: 8 }}>
              <Form.Control as="select" size="sm" value={filters?.year} onChange={handleYearFilterChange}>
                <option value="">All</option>
                {getYearOptions().map(({ label, value }) => (
                  <option key={`year-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table responsive>
        <thead>
          <tr>
            <th>Document</th>
            <th>Year</th>
            <th>Dates</th>
            <th colSpan={2}>Description</th>
            <th colSpan={2}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!isNoFiles && (
            <>
              {filesData?.elements?.map(({ id, fileName, description, startDate, endDate, type, year, updated }) => (
                <tr key={id}>
                  <td>
                    <div>{fileName}</div>
                    <div style={{ color: "gray", fontSize: "12px" }}>Type: {fileTypeTitles[type]}</div>
                    <div style={{ color: "gray", fontSize: "12px" }}>
                      Updated: {moment.utc(updated).local().format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                  </td>
                  <td>
                    <div>{year || "N/A"}</div>
                  </td>
                  <td>
                    <div>From: {startDate ? moment.utc(startDate).local().format("YYYY/MM/DD") : "N/A"}</div>
                    <div>To: {endDate ? moment.utc(endDate).local().format("YYYY/MM/DD") : "N/A"}</div>
                  </td>
                  <td colSpan={2}>{description}</td>
                  <td>
                    <Button
                      size="sm"
                      variant="info"
                      className="w-100"
                      onClick={() => handleDocumentDownload(id, fileName)}
                    >
                      Download
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      variant="danger"
                      className="w-100"
                      onClick={() => setFileToDelete({ id, fileName, description, startDate, endDate, type })}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </>
          )}
          {isNoFiles && (
            <tr>
              <td>No files</td>
            </tr>
          )}
          <tr>
            <td colSpan={2}>
              <input
                style={{ border: "2px solid lightgray", borderRadius: "4px", padding: "8px", cursor: "pointer" }}
                ref={pdfEl}
                className="mb-1"
                type="file"
                accept={extensionsFile}
                onChange={handleDocumentChange}
              />
            </td>
            <td colSpan={2}>
              <Form.Control
                as="select"
                size="sm"
                value={type}
                onChange={({ target: { value } }) => {
                  setType(value);
                }}
              >
                {fileTypes?.map(({ code, value }) => (
                  <option key={code} value={code}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </td>
            <td>
              <Button
                variant="success"
                className="my-1 w-100"
                size="sm"
                disabled={!selectedFile}
                onClick={() => handleDocumentUploadInit()}
              >
                Upload
              </Button>
            </td>
            <td colSpan={2} />
          </tr>
          <tr>
            <td style={{ border: 0, paddingTop: 0 }}>
              <span className="allowed-file-types-label">
                Allowed file types:&nbsp;
                {extensionsFile || (
                  <span title="Allowed file types not provided, try f.e. [.pdf .png .jpg .jpeg]">
                    any type <i style={{ fontSize: "1.35em" }} className="fa fa-info-circle" />
                  </span>
                )}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <Paginate
                page={filesData?.page}
                totalPages={filesData?.totalPages}
                loading={loading}
                handlePageChange={handlePageChange}
              />
            </td>
            <td colSpan={4} />
          </tr>
        </tbody>
      </Table>

      <DocumentDetailsModal
        show={documentDetailsShow}
        handleCancel={handleDocumentDetailsClose}
        handleConfirm={handleDocumentUploadComplete}
      />

      <ConfirmationModal
        show={Boolean(fileToDelete)}
        handleClose={() => {
          setFileToDelete(null);
        }}
        handleConfirm={() => {
          handleDocumentDelete(fileToDelete?.id);
          setFileToDelete(null);
        }}
        body={
          <div>
            <div>{` Are you sure you want to delete "${fileToDelete?.fileName}"?`}</div>
          </div>
        }
      />
    </>
  );
};

export default AccountingDocuments;
