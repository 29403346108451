import { config } from "_config";
import { authHeader, handleResponse } from "_helpers";

export const sendbirdService = {
  getChannel,
  getAllChannels,
  getChannels,
  getClosedChannels,
  getCountOfUnread,
  closeChannel,
  joinChannel,
  leaveChannel,
};

function getChannel(options = {}) {
  const { url: channelUrl, userIdForStatuses } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/users/message-channels/${channelUrl}`;

  if (userIdForStatuses) {
    url += `?userIdForStatuses=${userIdForStatuses}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getAllChannels(options = {}) {
  const { userId, userIdForStatuses, priority, statuses, page, count } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/users/message-channels?page=${page}&count=${count}`;

  if (userId) {
    url += `&userId=${userId}`;
  }

  if (userIdForStatuses) {
    url += `&userIdForStatuses=${userIdForStatuses}`;
  }

  if (priority) {
    url += `&priority=${priority}`;
  }

  statuses?.forEach((s) => {
    url += `&statuses=${s}`;
  });

  return fetch(url, requestOptions).then(handleResponse);
}

function getChannels(options = {}) {
  const { userId, orderUid, statuses } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/users/${userId}/message-channels`;

  if (orderUid || statuses?.length) {
    url += "?";
  }

  if (orderUid) {
    url += `&orderUid=${orderUid}`;
  }

  statuses?.forEach((s) => {
    url += `&statuses=${s}`;
  });

  return fetch(url, requestOptions).then(handleResponse);
}

function getClosedChannels(options = {}) {
  const { userId, orderUid, page, count } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/users/${userId}/closed-message-channels?page=${page}&count=${count}`;

  if (orderUid) {
    url += `&orderUid=${orderUid}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getCountOfUnread(options = {}) {
  const { userId } = options;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/users/${userId}/message-channels/count-of-unread-messages`;

  return fetch(url, requestOptions).then(handleResponse);
}

function closeChannel(options = {}) {
  const { orderId, productId } = options;

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/orders/${orderId}/products/${productId}/close-message-channel`;

  return fetch(url, requestOptions).then(handleResponse);
}

function joinChannel(options = {}) {
  const { userId, channelUrl } = options;

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/users/${userId}/message-channels/${channelUrl}/join`;

  return fetch(url, requestOptions).then(handleResponse);
}

function leaveChannel(options = {}) {
  const { userId, channelUrl } = options;

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/users/${userId}/message-channels/${channelUrl}/leave`;

  return fetch(url, requestOptions).then(handleResponse);
}
