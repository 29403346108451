export const chatsConstants = {
  SET_SCROLL_VISIBLE: "CHATS_SET_SCROLL_VISIBLE",
  SET_SCROLL_HIDDEN: "CHATS_SET_SCROLL_HIDDEN",
  REFRESH_ALL_CHANNELS_FILTERS: "CHATS_REFRESH_ALL_CHANNELS_FILTERS",
  RESET_ALL_CHANNELS: "CHATS_RESET_ALL_CHANNELS",
  GET_ALL_CHANNELS_REQUEST: "CHATS_GET_ALL_CHANNELS_REQUEST",
  GET_ALL_CHANNELS_SUCCESS: "CHATS_GET_ALL_CHANNELS_SUCCESS",
  GET_ALL_CHANNELS_FAILURE: "CHATS_GET_ALL_CHANNELS_FAILURE",
  REFETCH_CHANNEL_REQUEST: "CHATS_REFETCH_CHANNEL_REQUEST",
  REFETCH_CHANNEL_SUCCESS: "CHATS_REFETCH_CHANNEL_SUCCESS",
  REFETCH_CHANNEL_FAILURE: "CHATS_REFETCH_CHANNEL_FAILURE",
  REFRESH_USERS_FILTERS: "CHATS_REFRESH_USERS_FILTERS",
  RESET_USERS: "CHATS_RESET_USERS",
  GET_USERS_REQUEST: "CHATS_GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "CHATS_GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "CHATS_GET_USERS_FAILURE",
  ADD_MEMBERS_REQUEST: "CHATS_ADD_MEMBERS_REQUEST",
  ADD_MEMBERS_SUCCESS: "CHATS_ADD_MEMBERS_SUCCESS",
  ADD_MEMBERS_FAILURE: "CHATS_ADD_MEMBERS_FAILURE",
};

export const CHANNEL_STATUSES = {
  new: "New",
  active: "Active",
  pendingCustomerResponse: "PendingCustomerResponse",
  pendingAgentResponse: "PendingAgentResponse",
  idle: "Idle",
  closed: "Closed",
};

export const CHANNEL_STATUS_TITLES = {
  [CHANNEL_STATUSES.new]: "New",
  [CHANNEL_STATUSES.active]: "Active",
  [CHANNEL_STATUSES.pendingAgentResponse]: "Pending Agent",
  [CHANNEL_STATUSES.pendingCustomerResponse]: "Pending Customer",
  [CHANNEL_STATUSES.idle]: "Idle",
  [CHANNEL_STATUSES.closed]: "Closed",
};

export const CHANNEL_STATUS_COLORS = {
  [CHANNEL_STATUSES.new]: "#17a2b8",
  [CHANNEL_STATUSES.active]: "#28a745",
  [CHANNEL_STATUSES.pendingAgentResponse]: "#dc3545",
  [CHANNEL_STATUSES.pendingCustomerResponse]: "#dc3545",
  [CHANNEL_STATUSES.idle]: "#daa520",
  [CHANNEL_STATUSES.closed]: "#9e9e9e",
};
