import React from "react";
import { Spinner } from "react-bootstrap";

import useSummaryData from "./hooks/useSummaryData.hook";

const statusKeys = [
  { key: "Lead", label: "Leads" },
  { key: "Completed", label: "Completed" },
  { key: "Processing", label: "Processing" },
  { key: "SS4", label: "SS4" },
  { key: "OtherError", label: "Other Error" },
  { key: "Cancelled", label: "Cancelled" },
  { key: "PartialRefund", label: "Partial Refunds" },
  { key: "FullRefund", label: "Full Refunds" },
];

const StatusList = ({ data }) => (
  <ul>
    {statusKeys.map(({ key, label }) => (
      <StatusItem key={key} label={label} value={data?.countGroupedByEINStatus?.[key] || 0} />
    ))}
  </ul>
);

const LoadingSpinner = () => (
  <div className="loading-indicator" style={{ paddingLeft: "40px" }}>
    <Spinner animation="border" variant="light" />
  </div>
);

const StatusItem = ({ label, value }) => (
  <li>
    {label}: <span className="count-value">{value}</span>
  </li>
);

const SummaryOrders = () => {
  const { isLoading, data } = useSummaryData();

  return (
    <div className="summary-counts">
      <p className="title" style={{ paddingLeft: "40px" }}>
        Summary Counts:
      </p>
      {isLoading ? <LoadingSpinner /> : <StatusList data={data} />}
    </div>
  );
};

export default SummaryOrders;
