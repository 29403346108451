import React, { useState } from "react";
import styled from "styled-components";
import { Header } from "@sendbird/uikit-react/ui/Header";
import { IconColors, IconTypes } from "@sendbird/uikit-react/ui/Icon";

import Button from "views/Chats/ui/Button";
import UserList from "./UsersList";

const StyledContainer = styled.div``;

const StyledBody = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px;
  border-top: 1px solid #dee3e6;
`;

export default function InviteUsersPannel({ onClose, onConfirm }) {
  const [userIds, setUserIds] = useState([]);

  const handleSelect = (userId) => {
    setUserIds((prevUserIds) => {
      if (prevUserIds.includes(userId)) {
        return prevUserIds.filter((prevUserId) => prevUserId !== userId);
      }

      return [...prevUserIds, userId];
    });
  };

  const handleConfirm = () => {
    onConfirm && onConfirm(userIds);
  };

  return (
    <StyledContainer>
      <Header
        className="sendbird-channel-settings__header"
        renderMiddle={() => <Header.Title title="Select members" />}
        renderRight={() => (
          <Header.IconButton
            className="sendbird-channel-settings__header__close-button"
            onClick={onClose}
            type={IconTypes.CLOSE}
            color={IconColors.ON_BACKGROUND_1}
          />
        )}
      />
      <StyledBody>
        <UserList selected={userIds} onSelect={handleSelect} />
      </StyledBody>
      <StyledFooter>
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
        <Button color="#641ec9" onClick={handleConfirm}>
          Invite
        </Button>
      </StyledFooter>
    </StyledContainer>
  );
}
