import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import CommonLawTrademarkResultListView from "./CommonLawTrademarkResultList/View";
import FederalSearchResultListView from "./FederalSearchResultList/View";
import GlobalTrademarkResultListView from "./GlobalTrademarkResultList/View";
import StateSearchResultListView from "./StateSearchResultList/View";

import StatusView from "../Status/View";

export default function FormTrademarkSearch({ orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const productDetails = orderDetails.products[selectedProduct];

  const isBasicProduct = productDetails.code.code === "TRADEMARK_Trademark_Search_Basic";
  const isCommonLawProduct = productDetails.code.code === "TRADEMARK_Trademark_Search_Common_Law";
  const isGlobalProduct = productDetails.code.code === "TRADEMARK_Trademark_Search_Global";

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">SEARCH TRADEMARK</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />

      <FederalSearchResultListView federalSearchResults={productDetails.processingResult.federalSearchResults} />

      {!isBasicProduct && (
        <StateSearchResultListView stateSearchResults={productDetails.processingResult.stateSearchResults} />
      )}

      {(isCommonLawProduct || isGlobalProduct) && (
        <CommonLawTrademarkResultListView
          commonLawTrademarkResults={productDetails.processingResult.commonLawResults}
        />
      )}

      {isGlobalProduct && (
        <GlobalTrademarkResultListView
          label="Global search results"
          globalSearchs={productDetails.processingResult.globalSearchResults}
        />
      )}

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Type:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.searchTermType}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Description:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.text}
        </Form.Label>
      </Form.Group>

      {productDetails.searchTermType === "LOGO" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Logo Image:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.logoImage}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Logo Words:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.logoWords}
            </Form.Label>
          </Form.Group>
        </>
      )}
    </div>
  );
}
