import { Modal, Button } from "react-bootstrap";

export default function ConfirmationModal({
  show,
  handleClose = () => {},
  handleConfirm = () => {},
  body,
  header,
  cancelButtonTitle,
  confirmButtonTitle,
  hideCancelButton,
}) {
  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton style={{ border: 0 }}>
        {header}
      </Modal.Header>
      <Modal.Body>
        {typeof body === "function" ? body() : body || "Are you sure you want to do this action?"}
      </Modal.Body>
      <Modal.Footer>
        {!hideCancelButton && (
          <Button variant="light" onClick={handleClose}>
            {cancelButtonTitle || "No"}
          </Button>
        )}
        <Button variant="danger" onClick={handleConfirm}>
          {confirmButtonTitle || "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
