import React from "react";
import Select from "react-select";
import chroma from "chroma-js";

import { PRODUCT_PRIORITY } from "variables/Variables";
import { PRIORITY_COLORS } from "views/Chats/constants";
import { hexToRGBA } from "_helpers/theme";

export const priorityOptions = [
  {
    value: PRODUCT_PRIORITY.high,
    label: PRODUCT_PRIORITY.high,
    color: PRIORITY_COLORS[PRODUCT_PRIORITY.high],
  },
  {
    value: PRODUCT_PRIORITY.medium,
    label: PRODUCT_PRIORITY.medium,
    color: PRIORITY_COLORS[PRODUCT_PRIORITY.medium],
  },
  {
    value: PRODUCT_PRIORITY.low,
    label: PRODUCT_PRIORITY.low,
    color: PRIORITY_COLORS[PRODUCT_PRIORITY.low],
  },
];

const getActiveOption = ({ activeValue }) => {
  return priorityOptions.find(({ value }) => value === activeValue);
};

const bullet = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    content: '" "',
    display: "block",
    marginRight: 8,
    borderRadius: 2,
    height: 10,
    width: 10,
  },
});

const colorStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? hexToRGBA("#606071", 0.5)
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled ? (isSelected ? data.color : color.alpha(0.3).css()) : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles, ...bullet() }),
  placeholder: (styles) => ({ ...styles, ...bullet(hexToRGBA("#606071", 0.5)) }),
  singleValue: (styles, { data }) => ({ ...styles, ...bullet(data.color) }),
};

export default function PriorityFilter({ activeValue, onSelect }) {
  return (
    <Select
      isClearable
      styles={colorStyles}
      placeholder="Select priority..."
      options={priorityOptions}
      value={getActiveOption({ activeValue })}
      onChange={onSelect}
    />
  );
}
