import { useState, useEffect, useReducer, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import moment from "moment-timezone";

import { CATEGORIES_OBJ } from "variables/Variables";

import { customerActions, variantsActions, orderFilterActions, uiActions } from "_actions";

import Card from "components/Card/Card.jsx";

import OrdersTable from "./OrdersTable";
import OrdersFilters from "./OrdersFilters";
import OrdersTableAction from "./OrdersTableAction";

const defaultColumns = [
  "orderId",
  "timestamp",
  "customeName",
  "productType",
  "price",
  // "email/phone",
  "email",
  "phone",
  "orderStatus",
  "einErrorStatus",
];

const defaultINCColumns = [
  "orderId",
  "timestamp",
  "customeName",
  "companyName",
  "productType",
  "price",
  // "email/phone",
  "email",
  "phone",
  "orderStatus",
  "einErrorStatus",
];

function initialFilters(category) {
  return {
    count: 25,
    page: 1,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    productType: "",
    fillingTime: "",
    einStatus: "",
    orderId: "",
    assigneeType: "",
    assigneeUser: {
      label: "Search...",
    },
    accountManager: {
      label: "Search...",
    },
    orderStatus: [
      { label: "Declined", value: "Declined" },
      { label: "Success", value: "Success" },
      { label: "Partial Refund", value: "PartialRefund" },
      { label: "Full Refund", value: "FullRefund" },
    ],
    einErrorStatus: [],
    einError: [],
    includeTest: process.env.REACT_APP_INCLUDE_TEST_FILTER === "true" ? "true" : "false",
    orderHasProduct: [],
    savedFilter: "0",
    firstday: moment("2022-06-01").format("YYYY-MM-DD"),
    lastday: moment().format("YYYY-MM-DD"),
    incState: "",
    llcName: "",
    serialNumber: "",
    subStatuses: "",
    category,
  };
}

function filtersReducer(state, action) {
  const { type, payload, cb } = action;

  switch (type) {
    case "CHOOSE_PRESET_FILTER":
    case "RESET_FILTERS":
    case "SEARCH":
      return { ...state, ...payload };
    case "PAGE_CHANGE": {
      const newFilters = { ...state, page: Number(payload) };

      cb(newFilters);

      return newFilters;
    }
    case "FIRST_PAGE": {
      const newFilters = { ...state, page: 1 };

      cb(newFilters);

      return newFilters;
    }
    case "PREV_PAGE": {
      const newFilters = { ...state, page: Number(state.page) - 1 };

      cb(newFilters);

      return newFilters;
    }
    case "PREV_STEP": {
      const newPage = state.page % 5 === 0 ? (state.page / 5 - 2) * 5 + 1 : (Math.floor(state.page / 5) - 1) * 5 + 1;
      const newFilters = { ...state, page: newPage };

      cb(newFilters);

      return newFilters;
    }
    case "NEXT_STEP": {
      const newPage = state.page % 5 === 0 ? Number(state.page) + 1 : (Math.floor(state.page / 5) + 1) * 5 + 1;
      const newFilters = { ...state, page: newPage };

      cb(newFilters);

      return newFilters;
    }
    case "NEXT_PAGE": {
      const newFilters = { ...state, page: Number(state.page) + 1 };

      cb(newFilters);

      return newFilters;
    }
    case "LAST_PAGE": {
      const newFilters = { ...state, page: payload };

      cb(newFilters);

      return newFilters;
    }
    default:
      return { ...state, [type]: payload };
  }
}

function initColumns(category) {
  const store = localStorage.getItem(`${category}OrderTableColumns`);
  const { selectedDisplayType = "default", customSelectedColumn = [] } = store ? JSON.parse(store) : {};

  const mappingDisplayTypeAndColumn = {
    default: ["accounting", "inc", "ein_inc", "inc_states"].includes(category) ? defaultINCColumns : defaultColumns,
    customerData: ["orderId", "timestamp", "customeName", "email", "phone", "primaryActivity", "secondaryActivity"],
    custom: customSelectedColumn,
  };

  return {
    displayColumnType: selectedDisplayType,
    displayingColumn: mappingDisplayTypeAndColumn[selectedDisplayType],
    displayingColumnOfCustomType: [],
    mappingDisplayTypeAndColumn,
    category,
  };
}

function columnsReducer(state, action) {
  const { type, payload } = action;

  const setLocalStorage = (newSet) =>
    localStorage.setItem(`${state.category}OrderTableColumns`, JSON.stringify(newSet));

  switch (type) {
    case "CHANGE_DISPLAYING_COLUMN_TYPE": {
      const displayingColumn =
        payload === "custom" && state.displayingColumnOfCustomType.length
          ? state.displayingColumnOfCustomType
          : state.mappingDisplayTypeAndColumn[payload];

      setLocalStorage({
        selectedDisplayType: payload,
        customSelectedColumn: state.displayingColumnOfCustomType,
      });

      return { ...state, displayColumnType: payload, displayingColumn };
    }
    case "CHANGE_DISPLAYING_COLUMNS": {
      if (payload.length <= 8) {
        const displayingColumn = payload.map(({ value }) => value);

        setLocalStorage({
          selectedDisplayType: state.displayColumnType,
          customSelectedColumn: displayingColumn,
        });

        return {
          ...state,
          displayingColumn,
          displayingColumnOfCustomType: displayingColumn,
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

export default function OrdersPage(props) {
  const { category, filtersKey, filtersPreset, displayingColumnOrderTable, tableActions } = props;

  const dispatch = useDispatch();
  const loggedUser = useSelector(({ authentication }) => authentication?.user);
  const customers = useSelector(({ customers }) => customers);
  const variants = useSelector(({ variants }) => variants);
  const orderFilters = useSelector(({ orderFilters }) => orderFilters[category]);
  const hash = useSelector(({ ui }) => ui.hash);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const [filters, setFilters] = useReducer(filtersReducer, initialFilters(category));
  const [columnSet, setColumnSet] = useReducer(columnsReducer, initColumns(category));

  const displayColumnOrderTable = useMemo(
    () => [
      { value: "orderId", label: "Order ID" },
      { value: "timestamp", label: "Timestamp" },
      { value: "customeName", label: "Customer Name" },
      { value: "productType", label: "Product Type" },
      { value: "companyName", label: "LLC Name" },
      {
        value: "fillingStatus",
        label: category === "ein" ? "Filling Status" : "Processing Status",
      },
      { value: "price", label: "Price" },
      { value: "email/phone", label: "Email/Phone" },
      { value: "email", label: "E-mail" },
      { value: "phone", label: "Phone" },
      { value: "orderStatus", label: "Order Status" },
      {
        value: "einErrorStatus",
        label: category === "ein" ? "Ein Error Status" : "Processing Error Status",
      },
      { value: "primaryActivity", label: "Primary Activity" },
      { value: "secondaryActivity", label: "Second Activity" },
      { value: "automationTriggered", label: "Automation triggered" },
    ],
    [category]
  );

  const displayColumnOrderTableObj = displayColumnOrderTable.reduce((newObj, val) => {
    return { ...newObj, [val.value]: val.label };
  }, {});

  const handleChangeFilter = ({ target: { name, value } }) => {
    setFilters({ type: name, payload: value });
  };

  const handleChangeCheckboxFilter = ({ target: { name, checked } }) => {
    const [filterName, filterValue] = name.split("--");
    let payload = filterValue;

    if (!checked) {
      payload = "";
    }

    setFilters({ type: filterName, payload });
  };

  const handleChangeSelectFilter = (values, event, optionsList) => {
    const isSelectAllExist = optionsList?.find((item) => item?.value === "*");
    if (isSelectAllExist) {
      if (event.option.value === "*") {
        if (event.action === "select-option") {
          setFilters({ type: event.name, payload: optionsList });
        }

        if (event.action === "deselect-option") {
          setFilters({ type: event.name, payload: [] });
        }
      } else {
        if (event.action === "select-option") {
          if (values.length === optionsList.length - 1) {
            setFilters({ type: event.name, payload: optionsList });
          } else {
            setFilters({ type: event.name, payload: values });
          }
        }

        if (event.action === "deselect-option") {
          setFilters({ type: event.name, payload: values.filter((item) => item?.value !== "*") });
        }
      }
    } else {
      setFilters({ type: event.name, payload: values });
    }
  };

  const handleChangeAsyncSelectFilter = (name) => (item) => {
    setFilters({ type: name, payload: item });
  };

  const chooseSavedFilter = ({ target: { value } }) => {
    let newFilters = {
      ...filters,
      count: 25,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      productType: "",
      orderStatus: "",
      orderId: "",
      page: 1,
      orderStatus: [],
      includeTest: "false",
      orderHasProduct: [],
      savedFilter: value,
      firstday: "",
      serialNumber: "",
    };

    switch (value) {
      case "1": {
        newFilters = {
          ...newFilters,
          einStatus: "Processing",
          fillingTime: "RUSH",
          einErrorStatus: [],
          lastday: "",
        };
        break;
      }
      case "2": {
        const einErrorStatusByCode = variants.processingErrorStatus.map(({ code, value }) => ({
          code,
          label: value,
          value,
        }));

        newFilters = {
          ...newFilters,
          einStatus: "Error",
          fillingTime: "",
          einErrorStatus: einErrorStatusByCode,
          lastday: "",
        };
        break;
      }
      case "3": {
        const lastday = moment().subtract(2, "days").tz("UTC").format("YYYY-MM-DD");

        newFilters = {
          ...newFilters,
          einStatus: "Processing",
          fillingTime: "",
          einErrorStatus: [],
          lastday,
        };
        break;
      }
      default:
        return;
    }

    setFilters({ type: "CHOOSE_PRESET_FILTER", payload: newFilters });

    dispatch(customerActions.searchcustomer(newFilters));
    dispatch(orderFilterActions.saveFilters({ [filtersKey]: newFilters }));
  };

  const handleResetFilters = () => {
    const newFilters = { ...filters, ...initialFilters(category) };

    setFilters({ type: "RESET_FILTERS", payload: newFilters });

    dispatch(customerActions.searchcustomer(newFilters));
    dispatch(orderFilterActions.saveFilters({ [filtersKey]: newFilters }));
  };

  const handleChangeMyQueueFilters = () => {
    const { includeTest, ...restInitialFilters } = initialFilters(category);
    const newFilters = {
      ...filters,
      ...restInitialFilters,
      assigneeType: filters.assigneeType,
      assigneeUser: {
        label: `${loggedUser.username} - ${loggedUser.email}`,
        value: loggedUser.id,
      },
    };

    setFilters({ type: "RESET_FILTERS", payload: newFilters });
  };

  const handleSaveMyQueueFilters = () => {
    dispatch(customerActions.searchcustomer(filters));
    dispatch(orderFilterActions.saveFilters({ [filtersKey]: filters }));
  };

  const handleSearch = () => {
    const newFilters = { ...filters, page: 1 };

    setFilters({ type: "SEARCH", payload: newFilters });

    dispatch(customerActions.searchcustomer(newFilters));
    dispatch(orderFilterActions.saveFilters({ [filtersKey]: newFilters }));
  };

  const handleExport = (fileType) => {
    dispatch(customerActions.exportCustomer({ fileType, ...filters }));
  };

  const updateOnHandlePage = (newFilters) => {
    dispatch(customerActions.searchcustomer(newFilters));
    dispatch(orderFilterActions.saveFilters({ [filtersKey]: newFilters }));
  };

  // Table columns handler
  const handleChangeDisplayColumnType = (payload) => setColumnSet({ type: "CHANGE_DISPLAYING_COLUMN_TYPE", payload });
  const handleSelectDisplayingColumns = (payload) => setColumnSet({ type: "CHANGE_DISPLAYING_COLUMNS", payload });

  // Pagination change events
  const handlePageChange = ({ target: { text } }) =>
    setFilters({ type: "PAGE_CHANGE", payload: text, cb: updateOnHandlePage });
  const handleFirstPage = () => setFilters({ type: "FIRST_PAGE", cb: updateOnHandlePage });
  const handlePrevPage = () => setFilters({ type: "PREV_PAGE", cb: updateOnHandlePage });
  const handlePrevStep = () => setFilters({ type: "PREV_STEP", cb: updateOnHandlePage });
  const handleNextStep = () => setFilters({ type: "NEXT_STEP", cb: updateOnHandlePage });
  const handleNextPage = () => setFilters({ type: "NEXT_PAGE", cb: updateOnHandlePage });
  const handleLastPage = () =>
    setFilters({ type: "LAST_PAGE", payload: customers.orders.totalPages, cb: updateOnHandlePage });

  useEffect(() => {
    dispatch(customerActions.getprimaryactivity(category));
    dispatch(variantsActions.getvariants(category));

    // if (category?.toLowerCase() === "all") {
    const categories = Object.values(CATEGORIES_OBJ).map((item) => {
      return { category: item };
    });

    dispatch(variantsActions.getAllProcessingStatuses(categories));
    dispatch(variantsActions.getAllProcessingStatuses2(categories));
    dispatch(variantsActions.getAllProcessingError(categories));
    // }

    if (!orderFilters) {
      // dispatch(uiActions.checkHash(hash, customerActions.getAll(filters)));
      dispatch(uiActions.checkHash(hash, customerActions.searchcustomer(filters)));
    } else {
      setFilters({ type: "RESET_FILTERS", payload: orderFilters });
      dispatch(uiActions.checkHash(hash, customerActions.searchcustomer({ ...orderFilters, category })));
    }
  }, []);

  useEffect(() => {
    const { orders } = customers;

    if (orders && orders.elements) {
      setCurrentPage(orders.page);
      setTotalResults(orders.totalResults);
    } else {
      setCurrentPage(1);
      setTotalResults(0);
    }
  }, [customers]);

  const ordersTableActionProps = {
    ...columnSet,
    displayingColumnOrderTable,
    handleChangeDisplayColumnType,
    handleSelectDisplayingColumns,
    displayColumnOrderTable,
    displayColumnOrderTableObj,
  };

  const ordersTableProps = {
    category,
    displayingColumn: columnSet.displayingColumn,
    currentPage,
    displayColumnOrderTableObj,
    handlePageChange,
    handleFirstPage,
    handlePrevPage,
    handlePrevStep,
    handleNextStep,
    handleNextPage,
    handleLastPage,
  };

  return (
    <div key={`section-${filtersKey}`} className="content">
      <Container fluid className="mt-4 py-1">
        <OrdersFilters
          filtersPreset={filtersPreset}
          filters={filters}
          totalResults={totalResults}
          currentPage={currentPage}
          currentCategory={category}
          handlers={{
            chooseSavedFilter,
            multiselect: handleChangeSelectFilter,
            asyncselect: handleChangeAsyncSelectFilter,
            checkbox: handleChangeCheckboxFilter,
            default: handleChangeFilter,
          }}
          handleResetFilters={handleResetFilters}
          handleChangeMyQueueFilters={handleChangeMyQueueFilters}
          handleSaveMyQueueFilters={handleSaveMyQueueFilters}
          handleSearch={handleSearch}
          handleExport={handleExport}
        />
        <Card
          ctTableFullWidth
          actions={<OrdersTableAction tableActions={tableActions} {...ordersTableActionProps} />}
          content={<OrdersTable {...ordersTableProps} />}
        />
      </Container>
    </div>
  );
}
