export const userConstants = {
  GET_USER_REQUEST: "USERS_GET_USER_REQUEST",
  GET_USER_SUCCESS: "USERS_GET_USER_SUCCESS",
  GET_USER_FAILURE: "USERS_GET_USER_FAILURE",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  UPDATE_USER_REQUEST: "USERS_UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "USERS_UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "USERS_UPDATE_USER_FAILURE",

  DELETE_USER_REQUEST: "USERS_DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "USERS_DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "USERS_DELETE_USER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETME_SUCCESS: "USERS_GETME_SUCCESS",
  PROFILE_UPDATED: "PROFILE_UPDATED",
};
