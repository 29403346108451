import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import clsx from "clsx";

import { getMainProductDetails, phoneCleaner, phoneNumRegexp } from "_helpers";

import StatusEdit from "../../Status/Edit";

import Address from "components/OrderDetails/ProductDetails/Incorporation/Address/Edit";
import { TAB_CODES } from "variables/Variables";

export default function FormIncorpifyManagerAmendment({
  parentOrderDetails,
  orderDetails,
  selectedProduct,
  onParentChange,
  onChange,
  onError,
  onSelectedProduct,
}) {
  const productDetails = orderDetails?.products[selectedProduct];
  const { mainOrderDetails, mainProductIndex, mainProductDetails, isParent } = getMainProductDetails(
    orderDetails,
    parentOrderDetails
  );

  const handleAddError = (field) => onError(TAB_CODES.amendmentInfo, field);

  // const handleProductChange = ({ target: { name, value } }) => {
  //   let exFields = { [name]: value };

  //   onChange({
  //     ...orderDetails,
  //     products: orderDetails.products.map((product, idx) =>
  //       idx === selectedProduct ? { ...product, ...exFields } : product
  //     ),
  //   });
  // };

  const handleMainProductChange = ({ target: { name, value } }) => {
    const exFields = {};

    if (name === "ssn") {
      exFields.owner = {
        ...mainProductDetails?.owner,
        ssn: value || null,
      };
    }

    const onEvent = isParent ? onParentChange : onChange;

    onEvent({
      ...mainOrderDetails,
      products: mainOrderDetails?.products.map((product, idx) =>
        idx === mainProductIndex ? { ...product, ...exFields } : product
      ),
    });
  };

  const handleNewManagerChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, newManager: { ...product.newManager, ...exFields } } : product
      ),
    });
  };

  const handleProductChangeAddress = (value) => {
    handleNewManagerChange({ target: { name: "address", value } });
  };

  const handlePhoneChange = ({ target: { name, value } }) => {
    handleNewManagerChange({ target: { name, value: phoneCleaner(value) } });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
        onSelectedProduct={onSelectedProduct}
      />

      {mainProductDetails && (
        <>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Social Security Number:
            </Form.Label>
            <Col sm={7}>
              <InputMask
                className="form-control form-control-sm"
                mask="999-99-9999"
                maskChar=" "
                name="ssn"
                placeholder="999-99-9999"
                pattern="^\d{3}-\d{2}-\d{4}$"
                value={mainProductDetails?.owner?.ssn || ""}
                onChange={handleMainProductChange}
                onInvalid={() => handleAddError("Social Security Number")}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <hr />
      <h6 className="font-weight-bold mb-4">Manager Amendment Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="firstName"
            // pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails?.newManager?.firstName || ""}
            onChange={handleNewManagerChange}
            onInvalid={() => handleAddError("First Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="middleName"
            // pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails?.newManager?.middleName || ""}
            onChange={handleNewManagerChange}
            onInvalid={() => handleAddError("Middle Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="lastName"
            // pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails?.newManager?.lastName || ""}
            onChange={handleNewManagerChange}
            onInvalid={() => handleAddError("Last Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone Number:
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className={clsx("form-control", "form-control-sm", {
              "is-invalid":
                productDetails?.newManager?.phone && !phoneNumRegexp.test(productDetails?.newManager?.phone),
            })}
            mask="(999) 999-9999"
            maskChar=" "
            name="phone"
            placeholder="(999) 999-9999"
            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
            value={phoneCleaner(productDetails?.newManager?.phone) || ""}
            onChange={handlePhoneChange}
            onInvalid={() => handleAddError("Phone")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Email Address:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="email"
            // pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            pattern="^[a-zA-Z0-9_.%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
            value={productDetails?.newManager?.email}
            onChange={handleNewManagerChange}
            onInvalid={() => handleAddError("Email")}
          />
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Address</h6>
      <Address
        address={productDetails?.newManager?.address}
        onError={handleAddError}
        onChange={handleProductChangeAddress}
      />
    </div>
  );
}
