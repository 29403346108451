import IndividualEdit from "../Individual/Edit";
import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

export default function FormTrademarkAmendment({
  orderDetails,
  selectedProduct,
  onChange,
  onError,
  onSelectedProduct,
}) {
  const productDetails = orderDetails?.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.amendmentInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handleProductIndividualChange = (value) => handleProductChange({ target: { name: "individual", value } });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
        onSelectedProduct={onSelectedProduct}
      />

      <hr />
      <h6 className="font-weight-bold">Amendment Details</h6>

      <IndividualEdit
        isForceShowing
        individual={productDetails?.individual}
        onError={handleAddError}
        onChange={handleProductIndividualChange}
      />
    </div>
  );
}
