import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { config } from "_config";
import { accountingActions, notificationsActions } from "_actions";

import CustomGroupChannel from "./CustomGroupChannel";
import CustomGroupChannelList from "./CustomGroupChannelList";
import CustomSearchPannel from "./CustomSearchPannel";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledActions = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledChatContainer = styled.div`
  @media (min-width: 767px) {
    .col-md-4 {
      padding-right: 7px;
    }

    .col-md-8 {
      padding-left: 8px;
    }
  }

  .sendbird-channel-list {
    width: 100%;
  }

  .sendbird-conversation {
    flex-grow: 1;
    border: 1px solid #dee2e6;

    .sendbird-conversation__messages {
      .sendbird-conversation__scroll-container {
        .sendbird-conversation__messages-padding {
          padding-left: 16px;
          padding-right: 16px;
        }
      }

      .sendbird-separator {
        padding-top: 16px;
        padding-bottom: 16px;

        .sendbird-separator__text {
          .sendbird-label.sendbird-label--caption-2.sendbird-label--color-onbackground-2 {
            font-weight: 400;
          }
        }
      }

      .sendbird-admin-message {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .sendbird-msg-hoc {
        .sendbird-avatar-img--default {
          background-color: #17a2b8;
        }

        .sendbird-message-content {
          padding-top: 8px;
          padding-bottom: 8px;

          &.selected-search-message {
            background-color: var(--sendbird-highlight-100);
          }
        }

        .sendbird-message-content.outgoing {
          .sendbird-text-message-item-body {
            .sendbird-label {
              color: #ffffff;
            }
          }

          .sendbird-voice-message-item-body {
            .sendbird-voice-message-item-body__status-button {
              .sendbird-icon {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .sendbird-message-content-reactions {
          .sendbird-context-menu {
            .sendbird-emoji-reactions__add-reaction-badge {
              .sendbird-icon {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          .sendbird-reaction-badge__inner__count {
            color: #ffffff;
          }
        }

        &:hover {
          .sendbird-message-content.outgoing {
            .sendbird-voice-message-item-body {
              .sendbird-progress-bar.sendbird-voice-message-item-body__progress-bar.progress-bar-color--primary {
                background-color: #17a2b8;
              }
            }
          }
        }
      }
    }

    .sendbird-conversation__footer {
      position: sticky;
      bottom: 0;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      background-color: #ffffff;

      .sendbird-message-input-wrapper {
        .sendbird-message-input-wrapper__message-input {
          padding-left: 16px;
          padding-right: 16px;
        }

        #sendbird-message-input-text-field {
          /* border-radius: 12px; */
        }

        .sendbird-message-input {
          .sendbird-message-input--textarea {
            overflow-y: auto;
            border-width: 2px;

            &::-webkit-scrollbar {
              width: 12px;
            }

            &::-webkit-scrollbar-track {
              background: #dee2e6 0% 0% no-repeat padding-box;
              border-radius: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background: #808080 0% 0% no-repeat padding-box;
              border-radius: 6px;
            }
          }

          .sendbird-message-input--placeholder {
            top: 20px;
          }

          .sendbird-iconbutton {
            .sendbird-icon {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .sendbird-mention-suggest-list {
          width: auto;
          bottom: -12px;
          margin-left: 16px;
          margin-right: 16px;
          padding: 16px;
          overflow: auto;
          border: 1px solid #808080;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;

          .sendbird-mention-suggest-list__user-item {
            cursor: pointer;

            .sendbird-avatar-img--default {
              background-color: #17a2b8;
            }

            .focused {
              background-color: #dee2e6;
            }

            * {
              font-weight: 400;
            }
          }
        }
      }

      .sendbird-message-input-wrapper--voice-message {
        .sendbird-voice-message-input {
          padding-left: 16px;
          padding-right: 16px;

          .sendbird-voice-message-input__indicator {
            .sendbird-voice-message-input__indicator__progress-bar {
              .sendbird-progress-bar.sendbird-voice-message-input__indicator__progress-bar__bar.progress-bar-color--primary {
                background-color: #808080;

                .sendbird-progress-bar__fill {
                  background-color: #17a2b8;
                }
              }
            }

            .sendbird-voice-message-input__indicator__on-rec {
              margin-right: 4px;
            }

            .sendbird-voice-message-input__indicator__playback-time {
              display: flex;
              align-items: center;
              top: 0;
              bottom: 0;

              .sendbird-label {
                color: #ffffff;
              }
            }
          }

          .sendbird-voice-message-input__controler {
            .sendbird-icon {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .sendbird-message-search-pannel {
    min-width: 300px;

    .sendbird-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sendbird-ui-header {
      &.sendbird-message-search-pannel__header {
        height: auto;
        min-height: unset;
        padding-bottom: 8px;

        .sendbird-ui-header__middle__title.sendbird-label {
          &.sendbird-label--h-2 {
            &.sendbird-label--color-onbackground-1 {
              color: #17a2b8;
            }
          }
        }

        .sendbird-icon {
          &.sendbird-icon-close {
            &.sendbird-icon-color--on-background-1 {
              svg {
                path {
                  fill: #808080;
                }
              }
            }
          }
        }
      }
    }

    .sendbird-message-search-pannel__input__container {
      border: 2px solid #808080 !important;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

      .sendbird-message-search-pannel__input__container__search-icon {
        left: 8px !important;
      }

      .sendbird-message-search-pannel__input__container__input-area {
        top: 10px;
        left: 38px;
        width: calc(100% - 80px);
      }

      .sendbird-message-search-pannel__input__container__reset-input-button {
        top: 10px;
        right: 10px !important;
      }

      .sendbird-message-search-pannel__input__container__spinner {
        top: 10px;
        right: 10px !important;
      }
    }

    .sendbird-message-search {
      overflow-y: auto;

      .sendbird-message-search-item {
        &.sendbird-message-search-item--selected {
          background-color: #e4f8fc;
        }

        .sendbird-message-search-item__left {
          border-inline-start: none;

          .sendbird-avatar-img--default {
            background-color: #17a2b8;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .sendbird-message-search-pannel {
      width: 100%;
    }
  }
`;

const StyledChannelContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

const customColorSet = {
  "--sendbird-light-onlight-01": "#212529",
  "--sendbird-light-onlight-02": "#212529",
  "--sendbird-light-onlight-03": "#808080",
  "--sendbird-light-onlight-04": "#dee2e6",
  "--sendbird-light-background-100": "#f5f6f7",
  "--sendbird-light-background-200": "#f5f6f7",
  "--sendbird-light-background-300": "#f5f6f7",
  "--sendbird-light-primary-100": "#17a2b8",
  "--sendbird-light-primary-300": "#17a2b8",
  "--sendbird-light-primary-400": "#17a2b8",
  "--sendbird-highlight-100": "#fff2b6",
  "--sendbird-message-input-border-active": "#17a2b8",
  "--sendbird-light-shadow-message-input": "unset",
};

const Chat = ({ orderDetails, selectedProduct }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const channel = useSelector(({ accounting }) => accounting?.channel);
  const { channels, closedChannels } = useSelector((state) => state.notifications);
  const productDetails = orderDetails?.products[selectedProduct];
  const [currentChannelUrl, setCurrentChannelUrl] = useState(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);

  const isChannelClosed = Boolean(closedChannels?.data?.elements?.length);

  const isUserJoined = Object.keys(channels?.data || {}).some((key) => {
    return Boolean((channels?.data || {})[key]?.length);
  });

  const handleClose = () => {
    dispatch(
      notificationsActions.closeChannel({
        userId: user?.id,
        orderId: orderDetails?.order?.id,
        productId: productDetails?.id,
      })
    );
  };

  const handleJoin = () => {
    dispatch(
      notificationsActions.joinChannel({
        userId: user?.id,
        orderId: orderDetails?.order?.id,
        channelUrl: productDetails?.messageChannelUrl,
      })
    );
  };

  useEffect(() => {
    if (
      productDetails &&
      !productDetails?.messageChannelUrl &&
      !channel?.loading &&
      !channel?.data?.channelUrl &&
      !channel?.error
    ) {
      dispatch(
        accountingActions.getChannel({
          customerId: orderDetails?.customer?.id,
          orderId: orderDetails?.order?.id,
          productId: productDetails?.id,
        })
      );
    }
  }, [channel, orderDetails, productDetails]);

  useEffect(() => {
    dispatch(notificationsActions.getChannels({ userId: user?.id, orderUid: orderDetails?.order?.id }));
  }, []);

  useEffect(() => {
    ref.current = setTimeout(() => {
      dispatch(notificationsActions.getCountOfUnread({ userId: user?.id }));
    }, 2000);

    return () => {
      clearTimeout(ref.current);
    };
  }, []);

  return (
    <div className="form-border p-3">
      <StyledHeader>
        <h4 className="m-0 font-weight-bold">Mona Chat</h4>
        <StyledActions>
          <Button disabled={isUserJoined} variant="info" onClick={handleJoin}>
            Join
          </Button>
          <Button disabled={isChannelClosed} variant="danger" onClick={handleClose}>
            Close
          </Button>
        </StyledActions>
      </StyledHeader>
      <StyledChatContainer>
        <SendbirdProvider
          appId={config.sendbirdAppId}
          userId={user?.id}
          accessToken={user?.sendBirdAccessToken}
          colorSet={customColorSet}
          uikitOptions={{
            groupChannel: { enableMention: true, enableTypingIndicator: true, enableVoiceMessage: true },
            groupChannelSettings: { enableMessageSearch: true },
          }}
          sdkInitParams={{ localCacheEnabled: false }}
        >
          <Row>
            <Col xs={12} md={4}>
              <CustomGroupChannelList
                orderDetails={orderDetails}
                productDetails={productDetails}
                currentChannelUrl={currentChannelUrl}
                setCurrentChannelUrl={setCurrentChannelUrl}
              />
            </Col>
            <Col xs={12} md={8}>
              <StyledChannelContainer>
                <CustomGroupChannel
                  productDetails={productDetails}
                  currentChannelUrl={currentChannelUrl}
                  currentMessage={currentMessage}
                  setSearchVisible={setSearchVisible}
                />
                {searchVisible && (
                  <CustomSearchPannel
                    productDetails={productDetails}
                    currentChannelUrl={currentChannelUrl}
                    setSearchVisible={setSearchVisible}
                    setCurrentMessage={setCurrentMessage}
                  />
                )}
              </StyledChannelContainer>
            </Col>
          </Row>
        </SendbirdProvider>
      </StyledChatContainer>
    </div>
  );
};

export default Chat;
