import React, { useEffect } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { NOTIFICATION_STATUSES } from "_constants";
import { history } from "_helpers";
import { notificationsActions } from "_actions";
import Spinner from "components/Spinner";

const StyledOuterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
`;

const StyledInnerContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const StyledBackdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.5;
`;

const StyledPayloadContainerDesktop = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  background: #fff;

  @keyframes slideIn {
    0% {
      right: -400px;
    }
    100% {
      right: 0;
    }
  }

  animation: ease-out;
  animation-name: slideIn;
  animation-duration: 300ms;
`;

const StyledPayloadContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #fff;

  @keyframes slideIn {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  animation: ease-out;
  animation-name: slideIn;
  animation-duration: 300ms;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 32px;
  border-bottom: 1px solid #dee2e6;

  @media (max-width: 767px) {
    padding-right: 16px;
  }
`;

const StyledTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 16px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #dee2e6 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #808080 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }

  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 32px;
  border-top: 1px solid #dee2e6;

  ul {
    margin: unset;
  }

  @media (max-width: 767px) {
    padding-right: 16px;
  }
`;

const StyledNotificationItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  gap: 8px;
  padding: 8px;
  border: 1px solid #dee2e6;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
  }
`;

const StyledNotificationItemIconContainer = styled.div`
  padding: 4px;
`;

const StyledNotificationItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

const StyledCrmOrderIdTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const StyledCrmOrderIdPayload = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledChannelTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const StyledChannelName = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
`;

const StyledBadge = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 16px;
  background-color: #dc3545;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
`;

const StyledMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #e4f8fc;
`;

const StyledMessagePayload = styled.div`
  display: flex;

  span {
    display: flex;
    align-items: flex-start;
  }

  span:nth-child(2) {
    display: -webkit-inline-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  span:last-child {
    align-items: flex-end;
  }

  font-size: 14px;
`;

const StyledMessageTimestamp = styled.div`
  font-size: 12px;
  color: #808080;
`;

const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ChatIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.9965 11H16.0054" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.9955 11H12.0045" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.99451 11H8.00349" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const SendbirdNotifications = () => {
  const dispatch = useDispatch();
  const userId = useSelector(({ authentication }) => authentication?.user?.id);
  const { channels } = useSelector((state) => state.notifications);
  const filters = channels?.filters || {};
  const loading = Boolean(channels?.loading);
  const data = channels?.data || {};
  const orders = channels?.orders || {};
  const listOfUnread = data[NOTIFICATION_STATUSES.new] || [];

  const handleHide = () => {
    dispatch(notificationsActions.hideNotifications());
  };

  const handleNotificationClick =
    ({ orderId, productId }) =>
    () => {
      history.push("/admin/orders/" + orderId + "/" + productId);
      dispatch(notificationsActions.hideNotifications());
    };

  useEffect(() => {
    if (userId && filters) {
      dispatch(notificationsActions.getChannels({ userId, ...filters }));
    }
  }, [userId, filters]);

  const StyledPayloadContainer = isMobile ? StyledPayloadContainerMobile : StyledPayloadContainerDesktop;

  return (
    <StyledOuterContainer>
      <StyledInnerContainer>
        <StyledBackdrop />
        <StyledPayloadContainer>
          <StyledHeader>
            <StyledTitle>Notifications</StyledTitle>
            <StyledIconContainer onClick={handleHide}>
              <span>×</span>
            </StyledIconContainer>
          </StyledHeader>
          <StyledBody>
            {loading && (
              <StyledSpinnerContainer>
                <Spinner />
              </StyledSpinnerContainer>
            )}
            {!loading && !listOfUnread.length && (
              <StyledSpinnerContainer>
                <span>No data available</span>
              </StyledSpinnerContainer>
            )}
            {!loading &&
              listOfUnread.map(
                ({
                  orderId,
                  productId,
                  channelUrl,
                  name,
                  lastMessage,
                  lastMessageTimestamp,
                  countOfUnreadMessages,
                }) => (
                  <StyledNotificationItem key={channelUrl} onClick={handleNotificationClick({ orderId, productId })}>
                    {Boolean(countOfUnreadMessages) && (
                      <StyledBadge>{countOfUnreadMessages > 98 ? "99+" : countOfUnreadMessages}</StyledBadge>
                    )}
                    <StyledNotificationItemIconContainer>
                      <ChatIcon color="#212529" />
                    </StyledNotificationItemIconContainer>
                    <StyledNotificationItemContent>
                      <StyledCrmOrderIdTitle>CRM Order ID:</StyledCrmOrderIdTitle>
                      <StyledCrmOrderIdPayload>{orders[orderId]?.order?.crmOrderId}</StyledCrmOrderIdPayload>
                      <StyledChannelTitle>Channel Name:</StyledChannelTitle>
                      <StyledChannelName>{name}</StyledChannelName>
                      <StyledMessageContainer>
                        <StyledMessagePayload>
                          <span>&quot;</span>
                          <span>{lastMessage}</span>
                          <span>&quot;</span>
                        </StyledMessagePayload>
                        <StyledMessageTimestamp>
                          {moment(lastMessageTimestamp).format("MM/DD/YYYY hh:mm:ss")}
                        </StyledMessageTimestamp>
                      </StyledMessageContainer>
                    </StyledNotificationItemContent>
                  </StyledNotificationItem>
                )
              )}
          </StyledBody>
          <StyledFooter>Count of channels with unread messages: {listOfUnread.length}</StyledFooter>
        </StyledPayloadContainer>
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default SendbirdNotifications;
