import { chatsConstants } from "_constants";
import { handleError } from "_helpers";
import { customerService, sendbirdService, userService } from "_services";
import { alertActions } from "./";

export const chatsActions = {
  setScrollVisible,
  setScrollHidden,
  getAllChannels,
  refreshAllChannelsFilters,
  resetAllChannels,
  refetchChannel,
  refreshUsersFilters,
  getUsers,
  resetUsers,
  addMembers,
};
function setScrollVisible() {
  return { type: chatsConstants.SET_SCROLL_VISIBLE };
}

function setScrollHidden() {
  return { type: chatsConstants.SET_SCROLL_HIDDEN };
}

function refreshAllChannelsFilters(payload) {
  return { type: chatsConstants.REFRESH_ALL_CHANNELS_FILTERS, payload };
}

function resetAllChannels() {
  return { type: chatsConstants.RESET_ALL_CHANNELS };
}

function getAllChannels({ userId, userIdForStatuses, priority, statuses, page, count }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const data = await sendbirdService.getAllChannels({ userId, userIdForStatuses, priority, statuses, page, count });

      const orderIds = data?.elements?.map(({ orderId }) => orderId)?.filter((orderId) => !!orderId);

      const rawOrders = await Promise.all(orderIds.map((orderId) => customerService.getOrder(orderId)));

      const parentIds = rawOrders.map(({ order }) => order.parentOrderUid).filter((orderId) => !!orderId);

      const rawParents = await Promise.all(parentIds.map((orderId) => customerService.getOrder(orderId)));

      const orders = rawOrders?.reduce((result, item) => {
        const parent = rawParents?.find(({ order }) => order.id === item.order.parentOrderUid);
        const newItem = { ...item, parent };
        result[item?.order?.id] = newItem;
        return result;
      }, {});

      dispatch(
        success({
          data: {
            ...data,
            elements: data?.elements?.map((elem) => ({
              ...elem,
              params: { userId, userIdForStatuses, priority, statuses, page, count },
            })),
          },
          orders,
        })
      );
    } catch (error) {
      dispatch(failure(error));
      dispatch(
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Loading channels error"
        )
      );
    }
  };

  function request() {
    return { type: chatsConstants.GET_ALL_CHANNELS_REQUEST };
  }
  function success(payload) {
    return { type: chatsConstants.GET_ALL_CHANNELS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: chatsConstants.GET_ALL_CHANNELS_FAILURE, payload };
  }
}

function refetchChannel({ url, userId, userIdForStatuses }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const data = await sendbirdService.getChannel({ url, userId, userIdForStatuses });
      const order = await customerService.getOrder(data?.orderId);
      const orders = { [order?.order?.id]: order };

      dispatch(success({ channel: data, orders }));
    } catch (error) {
      dispatch(failure());
      dispatch(
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Refetching channel error"
        )
      );
    }
  };

  function request() {
    return { type: chatsConstants.REFETCH_CHANNEL_REQUEST };
  }
  function success(payload) {
    return { type: chatsConstants.REFETCH_CHANNEL_SUCCESS, payload };
  }
  function failure() {
    return { type: chatsConstants.REFETCH_CHANNEL_FAILURE };
  }
}

function refreshUsersFilters(payload) {
  return { type: chatsConstants.REFRESH_USERS_FILTERS, payload };
}

function resetUsers() {
  return { type: chatsConstants.RESET_USERS };
}

function getUsers({ page, count }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      const data = await userService.getAll({ page, count });

      dispatch(
        success({
          data: {
            ...data,
            elements: data?.elements?.map((elem) => ({
              ...elem,
              params: { page, count },
            })),
          },
        })
      );
    } catch (error) {
      dispatch(failure(error));
      dispatch(
        alertActions.error(handleError(error)?.message || error?.message || error?.toString() || "Loading users error")
      );
    }
  };

  function request() {
    return { type: chatsConstants.GET_USERS_REQUEST };
  }
  function success(payload) {
    return { type: chatsConstants.GET_USERS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: chatsConstants.GET_USERS_FAILURE, payload };
  }
}

function addMembers({ userIds, channelUrl, cb }) {
  return async (dispatch) => {
    dispatch(request());

    try {
      await Promise.all(userIds.map((userId) => sendbirdService.joinChannel({ userId, channelUrl })));
      dispatch(success());
      dispatch(alertActions.success("Members added successfully"));

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(failure());
      dispatch(
        alertActions.error(
          handleError(error)?.message || error?.message || error?.toString() || "Adding of members failed"
        )
      );
    }
  };

  function request() {
    return { type: chatsConstants.ADD_MEMBERS_REQUEST };
  }
  function success() {
    return { type: chatsConstants.ADD_MEMBERS_SUCCESS };
  }
  function failure() {
    return { type: chatsConstants.ADD_MEMBERS_FAILURE };
  }
}
