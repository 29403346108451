import { variantsConstants } from "_constants";

const initialState = {
  loading: 0,
  categories: {},
  prices: [],
  pricesOpt: [],
  productCodes: [],
  productCodesforsale: [],
  productProcessingStatuses: [],
  productsProcessingStatuses: {},
  productsProcessingStatusesOpt: {},
  productsFileTypes: {},
  productsFileTypesObj: {},
  processingOptions: [],
  processingStatus: [],
  processingStatusOpt: {},
  processingSubStatusByStatusCode: {},
  processingSubStatusOpt: {},
  processingErrors: [],
  processingErrorsOpt: {},
  processingErrorStatus: [],
  processingErrorStatusOpt: {},
  orderStatus: [],
  orderStatusOpt: {},
  states: [],
  activities: [],
  statesObj: {},
  internationalCodes: [],
  internationalCodesOpt: [],
  usCodes: [],
  usCodesOpt: [],
  nationalities: [],
  nationalitiesOpt: [],
  allProcessingOptions: {},
  allProcessingErrorStatuses: {},
  allStates: {},
  allOrderStatuses: {},
  allProcessingErrors: {},
  allProcessingStatuses: {},
  allProcessingStatuses2: {},
  allProcessingSubstatusesOpt: {},
  allProductCodes: {},
  allPrices: {},
};

export function variants(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // PRICES
    case variantsConstants.GET_PRICES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PRICES_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        prices: payload,
        pricesOpt: payload.reduce((acc, currentValue) => {
          if (acc[currentValue.option]) {
            return { ...acc, [currentValue.option]: [...acc[currentValue.option], currentValue.code] };
          } else {
            return { ...acc, [currentValue.option]: [currentValue.code] };
          }
        }, {}),
      };
    case variantsConstants.GET_PRICES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // CATEGORIES
    case variantsConstants.GET_CATEGORY_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_CATEGORY_SUCCESS:
      return { ...state, loading: state.loading - 1, categories: payload };
    case variantsConstants.GET_CATEGORY_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PRODUCT CODE
    case variantsConstants.GET_PRODUCT_CODE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PRODUCT_CODE_SUCCESS:
      return { ...state, loading: state.loading - 1, productCodes: payload };
    case variantsConstants.GET_PRODUCT_CODE_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PRODUCT CODE SALE
    case variantsConstants.GET_PRODUCT_CODE_SALE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PRODUCT_CODE_SALE_SUCCESS:
      return { ...state, loading: state.loading - 1, productCodesforsale: payload };
    case variantsConstants.GET_PRODUCT_CODE_SALE_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING STATUS
    case variantsConstants.GET_PROCESSING_STATUS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PROCESSING_STATUS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        processingStatus: payload.values,
        processingStatusOpt: payload.values.reduce(
          (acc, currentValue) => ({ ...acc, [currentValue.code]: currentValue.value }),
          {}
        ),
      };
    case variantsConstants.GET_PROCESSING_STATUS_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING STATUS 2
    case variantsConstants.GET_PROCESSING_STATUS_2_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PROCESSING_STATUS_2_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        processingSubStatusByStatusCode: payload.reduce(
          (acc, currentValue) => ({
            ...acc,
            [currentValue.statusCode]: currentValue.subStatuses.map(({ subStatusCode, subStatusName }) => ({
              code: subStatusCode,
              value: subStatusName,
            })),
          }),
          {}
        ),
        processingSubStatusOpt: payload.reduce(
          (acc, currentValue) => ({
            ...acc,
            [currentValue.statusCode]: currentValue.subStatuses.reduce(
              (acc, { subStatusCode, subStatusName }) => ({ ...acc, [subStatusCode]: subStatusName }),
              {}
            ),
          }),
          {}
        ),
      };
    case variantsConstants.GET_PROCESSING_STATUS_2_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PRODUCT PROCESSING STATUSES
    case variantsConstants.GET_PRODUCT_PROCESSING_STATUSES_REQUEST:
      return { ...state, productProcessingStatuses: null, loading: state.loading + 1 };
    case variantsConstants.GET_PRODUCT_PROCESSING_STATUSES_SUCCESS: {
      return {
        ...state,
        loading: state.loading - 1,
        productProcessingStatuses: payload?.values || [],
      };
    }
    case variantsConstants.GET_PRODUCT_PROCESSING_STATUSES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PRODUCTS PROCESSING STATUSES
    case variantsConstants.GET_PRODUCTS_PROCESSING_STATUSES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PRODUCTS_PROCESSING_STATUSES_SUCCESS: {
      let productsProcessingStatusesOpt = {};

      Object.keys(payload).forEach((key) =>
        payload[key].forEach(
          ({ code, value }) => (productsProcessingStatusesOpt = { ...productsProcessingStatusesOpt, [code]: value })
        )
      );

      return {
        ...state,
        loading: state.loading - 1,
        productsProcessingStatuses: payload,
        productsProcessingStatusesOpt,
      };
    }
    case variantsConstants.GET_PRODUCTS_PROCESSING_STATUSES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING ERROR
    case variantsConstants.GET_PROCESSING_ERROR_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PROCESSING_ERROR_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        processingErrors: payload.values,
        processingErrorsOpt: payload.values.reduce(
          (acc, currentValue) => ({ ...acc, [currentValue.code]: currentValue.value }),
          {}
        ),
      };
    case variantsConstants.GET_PROCESSING_ERROR_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING ERROR STATUS
    case variantsConstants.GET_PROCESSING_ERROR_STATUS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PROCESSING_ERROR_STATUS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        processingErrorStatus: payload.values,
        processingErrorStatusOpt: payload.values.reduce(
          (acc, currentValue) => ({ ...acc, [currentValue.code]: currentValue.value }),
          {}
        ),
      };
    case variantsConstants.GET_PROCESSING_ERROR_STATUS_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING OPTIONS
    case variantsConstants.GET_PROCESSING_OPTIONS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PROCESSING_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        processingOptions: payload,
      };
    case variantsConstants.GET_PROCESSING_OPTIONS_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // ORDER STATUS
    case variantsConstants.GET_ORDER_STATUS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        orderStatus: payload.values,
        orderStatusOpt: payload.values.reduce(
          (acc, currentValue) => ({ ...acc, [currentValue.code]: currentValue.value }),
          {}
        ),
      };
    case variantsConstants.GET_ORDER_STATUS_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // STATES
    case variantsConstants.GET_STATES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_STATES_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        states: payload.map(({ name, ...props }) => ({ ...props, value: name })),
        statesObj: payload.reduce((acc, { name, code }) => ({ ...acc, [code]: name }), {}),
      };
    case variantsConstants.GET_STATES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // ACTIVITIES
    case variantsConstants.GET_ACTIVITIES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        activities: payload,
      };
    case variantsConstants.GET_ACTIVITIES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // INTERNATIONAL CODES
    case variantsConstants.GET_INTERNATIONAL_CODES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_INTERNATIONAL_CODES_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        internationalCodes: payload.map(({ name, code }) => ({ value: code, label: name })),
        internationalCodesOpt: payload.reduce(
          (acc, currentValue) => ({ ...acc, [currentValue.code]: currentValue.name }),
          {}
        ),
      };
    case variantsConstants.GET_INTERNATIONAL_CODES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // US CODES
    case variantsConstants.GET_US_CODES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_US_CODES_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        usCodes: payload.map(({ name, code }) => ({ value: code, label: name })),
        usCodesOpt: payload.reduce((acc, currentValue) => ({ ...acc, [currentValue.code]: currentValue.name }), {}),
      };
    case variantsConstants.GET_US_CODES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // NATIONALITIES
    case variantsConstants.GET_NATIONALITIES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_NATIONALITIES_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        nationalities: payload.map(({ name, code }) => ({ value: code, label: name })),
        nationalitiesOpt: payload.reduce(
          (acc, currentValue) => ({ ...acc, [currentValue.code]: currentValue.name }),
          {}
        ),
      };
    case variantsConstants.GET_NATIONALITIES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PRODUCTS FILE TYPES
    case variantsConstants.GET_PRODUCT_FILE_TYPES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_PRODUCT_FILE_TYPES_SUCCESS: {
      function fileTypesProcessing(array) {
        return array.reduce((acc, { code, uploadable }) => ({ ...acc, [code]: uploadable }), {});
      }

      const productsFileTypesAllow = Object.keys(payload).reduce(
        (acc, key) => ({ ...acc, [key]: fileTypesProcessing(payload[key]) }),
        {}
      );

      const productsFileTypesObj = Object.keys(payload).reduce(
        (acc, key) => ({
          ...acc,
          [key]: payload[key].reduce((acc, item) => {
            return { ...acc, [item.code]: item };
          }, {}),
        }),
        {}
      );

      return {
        ...state,
        loading: state.loading - 1,
        productsFileTypes: productsFileTypesAllow,
        productsFileTypesObj: productsFileTypesObj,
      };
    }
    case variantsConstants.GET_PRODUCT_FILE_TYPES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // by products

    // PRODUCT CODES
    case variantsConstants.GET_ALL_PRODUCT_CODES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ALL_PRODUCT_CODES_SUCCESS:
      return { ...state, loading: state.loading - 1, allProductCodes: payload };
    case variantsConstants.GET_ALL_PRODUCT_CODES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING STATUSES
    case variantsConstants.GET_ALL_PROCESSING_STATUSES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ALL_PROCESSING_STATUSES_SUCCESS:
      return { ...state, loading: state.loading - 1, allProcessingStatuses: payload };
    case variantsConstants.GET_ALL_PROCESSING_STATUSES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING STATUSES 2
    case variantsConstants.GET_ALL_PROCESSING_STATUSES2_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ALL_PROCESSING_STATUSES2_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        allProcessingStatuses2: payload,
        allProcessingSubstatusesOpt: Object.values(payload || []).reduce((acc, category) => {
          category.forEach((status) => {
            status.subStatuses.forEach((subStatus) => {
              acc[subStatus.subStatusCode] = subStatus.subStatusName;
            });
          });
          return acc;
        }, {}),
      };
    case variantsConstants.GET_ALL_PROCESSING_STATUSES2_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING ERRORS
    case variantsConstants.GET_ALL_PROCESSING_ERRORS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ALL_PROCESSING_ERRORS_SUCCESS:
      return { ...state, loading: state.loading - 1, allProcessingErrors: payload };
    case variantsConstants.GET_ALL_PROCESSING_ERRORS_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // ORDER STATUSES
    case variantsConstants.GET_ALL_ORDER_STATUSES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ALL_ORDER_STATUSES_SUCCESS:
      return { ...state, loading: state.loading - 1, allOrderStatuses: payload };
    case variantsConstants.GET_ALL_ORDER_STATUSES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // STATES
    case variantsConstants.GET_ALL_STATES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ALL_STATES_SUCCESS:
      return { ...state, loading: state.loading - 1, allStates: payload };
    case variantsConstants.GET_ALL_STATES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING ERROR STATUSES
    case variantsConstants.GET_ALL_PROCESSING_ERROR_STATUSES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ALL_PROCESSING_ERROR_STATUSES_SUCCESS:
      return { ...state, loading: state.loading - 1, allProcessingErrorStatuses: payload };
    case variantsConstants.GET_ALL_PROCESSING_ERROR_STATUSES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PROCESSING OPTIONS
    case variantsConstants.GET_ALL_PROCESSING_OPTIONS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ALL_PROCESSING_OPTIONS_SUCCESS:
      return { ...state, loading: state.loading - 1, allProcessingOptions: payload };
    case variantsConstants.GET_ALL_PROCESSING_OPTIONS_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // PRICES
    case variantsConstants.GET_ALL_PRICES_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case variantsConstants.GET_ALL_PRICES_SUCCESS:
      return { ...state, loading: state.loading - 1, allPrices: payload };
    case variantsConstants.GET_ALL_PRICES_FAILURE:
      return { ...state, loading: state.loading - 1 };

    default:
      return state;
  }
}
