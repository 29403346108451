import { useRef, useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import moment from "moment";

import { ACCOUNTING_DOCUMENT_STATUSES } from "_constants";

const ACCOUNTING_DOCUMENT_STATUS_TITLES = {
  [ACCOUNTING_DOCUMENT_STATUSES.requested]: "Requested",
  [ACCOUNTING_DOCUMENT_STATUSES.underReview]: "Under Review",
  [ACCOUNTING_DOCUMENT_STATUSES.confirmed]: "Confirmed",
};

const getYearOptions = () => {
  const startYear = new Date().getFullYear();

  return Array.from(new Array(30), (_, i) => {
    return { value: `${startYear - i}`, label: `${startYear - i}` };
  });
};

const initialDetails = {
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
  description: "",
  status: "",
  year: "",
  file: null,
};

const checkStartDate = (startDate, endDate) => {
  const parsedStartDate = moment(startDate, "YYYY-MM-DD");
  const parsedEndDate = moment(endDate, "YYYY-MM-DD");

  const startDateMsValue = parsedStartDate.valueOf();
  const endDateMsValue = parsedEndDate.valueOf();

  if (startDateMsValue > endDateMsValue) {
    return true;
  }

  return false;
};

const checkEndDate = (startDate, endDate) => {
  const parsedStartDate = moment(startDate, "YYYY-MM-DD");
  const parsedEndDate = moment(endDate, "YYYY-MM-DD");

  const startDateMsValue = parsedStartDate.valueOf();
  const endDateMsValue = parsedEndDate.valueOf();

  if (startDateMsValue > endDateMsValue) {
    return true;
  }

  return false;
};

export default function StatementDetailsModal({
  show,
  updateMode,
  allowedExtensions,
  initialData,
  handleCancel = () => {},
  handleConfirm = () => {},
}) {
  const formEl = useRef(null);
  const fileInputEl = useRef(null);

  const [details, setDetails] = useState(initialDetails);
  const [validated, setValidated] = useState(false);

  const isStartDateInvalid = checkStartDate(details?.startDate, details?.endDate);
  const isEndDateInvalid = checkEndDate(details?.startDate, details?.endDate);

  const handleChange = ({ target: { value, name } }) => {
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event) => {
    if (!event.target.files.length) {
      setDetails((prev) => ({ ...prev, file: null }));
    } else {
      setDetails((prev) => ({ ...prev, file: event.target.files[0] }));
    }
  };

  const handleSubmit = (e) => {
    const form = formEl.current;
    setValidated(true);

    if (form.checkValidity() === false || isStartDateInvalid || isEndDateInvalid) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      handleConfirm({ ...details });
      setDetails(initialDetails);
      setValidated(false);
      if (fileInputEl.current) fileInputEl.current.value = null;
    }
  };

  const handleClose = () => {
    setDetails(initialDetails);
    if (fileInputEl.current) fileInputEl.current.value = null;
    handleCancel();
  };

  useEffect(() => {
    if (initialData) {
      setDetails({
        startDate: initialData.startDate,
        endDate: initialData.endDate,
        description: initialData.description,
        status: initialData.status,
        year: initialData.year,
      });
    }
  }, [initialData]);

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <b>Statement Details</b>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} noValidate ref={formEl}>
          <Row>
            <Form.Group as={Col} sm={12} md={6}>
              <Form.Label>START DATE:</Form.Label>
              <Form.Control
                required
                type="date"
                name="startDate"
                value={details?.startDate || ""}
                isInvalid={isStartDateInvalid}
                onChange={handleChange}
              />
              {isStartDateInvalid && (
                <Form.Control.Feedback type="invalid">Start Date should be before End Date</Form.Control.Feedback>
              )}
              {!isStartDateInvalid && (
                <Form.Control.Feedback type="invalid">Please, select Start Date</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} sm={12} md={6}>
              <Form.Label>END DATE:</Form.Label>
              <Form.Control
                required
                type="date"
                name="endDate"
                value={details?.endDate || ""}
                isInvalid={isEndDateInvalid}
                onChange={handleChange}
              />
              {isEndDateInvalid && (
                <Form.Control.Feedback type="invalid">End Date should be after Start Date</Form.Control.Feedback>
              )}
              {!isEndDateInvalid && (
                <Form.Control.Feedback type="invalid">Please, select End Date</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} sm={12}>
              <Form.Label>DESCRIPTION:</Form.Label>
              <Form.Control
                required
                name="description"
                placeholder="Provide Description"
                value={details?.description || ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">Please, provide Description</Form.Control.Feedback>
            </Form.Group>

            {updateMode && (
              <Form.Group as={Col} sm={12}>
                <Form.Label>STATUS:</Form.Label>
                <Form.Control as="select" name="status" value={details?.status || ""} onChange={handleChange}>
                  <option value={ACCOUNTING_DOCUMENT_STATUSES.requested}>
                    {ACCOUNTING_DOCUMENT_STATUS_TITLES[ACCOUNTING_DOCUMENT_STATUSES.requested]}
                  </option>
                  <option value={ACCOUNTING_DOCUMENT_STATUSES.underReview}>
                    {ACCOUNTING_DOCUMENT_STATUS_TITLES[ACCOUNTING_DOCUMENT_STATUSES.underReview]}
                  </option>
                  <option value={ACCOUNTING_DOCUMENT_STATUSES.confirmed}>
                    {ACCOUNTING_DOCUMENT_STATUS_TITLES[ACCOUNTING_DOCUMENT_STATUSES.confirmed]}
                  </option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please, select Status</Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group as={Col} sm={12}>
              <Form.Label>Tax Year</Form.Label>
              <Form.Control
                required
                as="select"
                name="year"
                placeholder="Year"
                value={details?.year || ""}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                {getYearOptions().map(({ label, value }) => (
                  <option key={`year-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Required Year</Form.Control.Feedback>
            </Form.Group>

            {updateMode && (
              <Form.Group as={Col} sm={12}>
                <Form.Label>FILE:</Form.Label>
                <div>
                  <input
                    style={{ border: "2px solid lightgray", borderRadius: "4px", padding: "8px", cursor: "pointer" }}
                    ref={fileInputEl}
                    className="mb-1"
                    type="file"
                    accept={allowedExtensions}
                    onChange={handleFileChange}
                  />
                </div>
              </Form.Group>
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
