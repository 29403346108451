import { chatsConstants } from "_constants";

const initialState = {
  channels: {
    scroll: false,
    filters: {
      userId: null,
      userIdForStatuses: null,
      priority: null,
      statuses: [],
      page: 1,
      count: 5,
    },
    loading: false,
    refetching: false,
    data: {},
    error: null,
    orders: {},
  },
  users: {
    filters: {
      page: 1,
      count: 5,
    },
    loading: false,
    data: {},
    error: null,
  },
};

export function chats(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case chatsConstants.SET_SCROLL_VISIBLE:
      return { ...state, channels: { ...state.channels, scroll: true } };

    case chatsConstants.SET_SCROLL_HIDDEN:
      return { ...state, channels: { ...state.channels, scroll: false } };

    case chatsConstants.RESET_ALL_CHANNELS:
      return { ...state, channels: { ...state.channels, data: {} } };

    case chatsConstants.REFRESH_ALL_CHANNELS_FILTERS:
      return {
        ...state,
        channels: { ...state.channels, filters: { ...state.channels.filters, ...payload } },
      };

    case chatsConstants.GET_ALL_CHANNELS_REQUEST:
      return { ...state, channels: { ...state.channels, loading: true } };

    case chatsConstants.GET_ALL_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: false,
          data: {
            ...state.channels.data,
            ...payload?.data,
            elements: [
              ...(state.channels.data?.elements || []),
              ...payload?.data?.elements?.reduce((nextElementsArr, elem) => {
                if (state.channels.data?.elements?.find(({ channelUrl }) => channelUrl === elem.channelUrl)) {
                  return nextElementsArr;
                }

                return [...nextElementsArr, elem];
              }, []),
            ],
          },
          orders: { ...state.channels.orders, ...payload?.orders },
        },
      };

    case chatsConstants.GET_ALL_CHANNELS_FAILURE:
      return {
        ...state,
        channels: { ...state.channels, loading: false, error: payload },
      };

    case chatsConstants.REFETCH_CHANNEL_REQUEST:
      return { ...state, channels: { ...state.channels, refetching: true } };

    case chatsConstants.REFETCH_CHANNEL_SUCCESS:
      return {
        ...state,
        channels: {
          ...state.channels,
          refetching: false,
          data: {
            ...state.channels.data,
            elements: [
              ...state.channels.data?.elements?.map((elem) => {
                if (payload?.channel?.channelUrl === elem.channelUrl) {
                  return { ...elem, ...payload?.channel };
                }

                return elem;
              }, []),
            ],
          },
          orders: { ...state.channels.orders, ...payload?.orders },
        },
      };

    case chatsConstants.REFETCH_CHANNEL_FAILURE:
      return {
        ...state,
        channels: { ...state.channels, refetching: false },
      };

    case chatsConstants.REFRESH_USERS_FILTERS:
      return {
        ...state,
        users: { ...state.users, filters: { ...state.users.filters, ...payload } },
      };

    case chatsConstants.GET_USERS_REQUEST:
      return { ...state, users: { ...state.users, loading: true } };

    case chatsConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          data: {
            ...state.users.data,
            ...payload?.data,
            elements: [
              ...(state.users.data?.elements || []),
              ...payload?.data?.elements?.reduce((nextElementsArr, elem) => {
                if (state.users.data?.elements?.find(({ id }) => id === elem.id)) {
                  return nextElementsArr;
                }

                return [...nextElementsArr, elem];
              }, []),
            ],
          },
        },
      };

    case chatsConstants.GET_USERS_FAILURE:
      return {
        ...state,
        users: { ...state.users, loading: false, error: payload },
      };

    default:
      return state;
  }
}
