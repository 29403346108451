import MessageContent from "@sendbird/uikit-react/ui/MessageContent";
import React, { useEffect } from "react";

import { debounce, getClassName } from "./helpers";

// eslint-disable-next-line react/prop-types
const CustomMessageContent = ({ currentMessage, className, ...restProps }) => {
  const selectedSearchMessageClassName =
    currentMessage?.messageId === restProps?.message?.messageId &&
    currentMessage?.createdAt === restProps?.message?.createdAt
      ? "selected-search-message"
      : [];

  useEffect(() => {
    if (currentMessage) {
      debounce(() => {
        const section = document.querySelector(".selected-search-message");

        if (section) {
          section.style.scrollMarginTop = "50px";
          section?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      })();
    }
  }, [currentMessage]);

  return <MessageContent {...restProps} className={getClassName([className, selectedSearchMessageClassName])} />;
};

export default CustomMessageContent;
