import React, { useEffect, useState, forwardRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { chatsActions } from "_actions";

import UserFilter from "./UserFilter";
import PriorityFilter from "./PriorityFilter";
import StatusFilter from "./StatusFilter";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #dee2e6;
`;

const ChannelListFilters = forwardRef(function (_, ref) {
  const dispatch = useDispatch();
  const [activeUserOption, setActiveUserOption] = useState(null);
  const user = useSelector(({ authentication }) => authentication?.user || {});
  const { channels } = useSelector((state) => state.chats);
  const filters = channels?.filters || {};
  const isAdmin = user?.role === "ADMIN" || user?.role === "DEVELOPER";

  const handleUserSelect = (option) => {
    setActiveUserOption(option);

    if (option) {
      dispatch(chatsActions.setScrollHidden());
      dispatch(chatsActions.resetAllChannels());
      dispatch(
        chatsActions.refreshAllChannelsFilters({
          userId: option?.value,
          userIdForStatuses: option?.value,
          page: 1,
          count: 5,
        })
      );
    }
  };

  const handlePrioritySelect = (option) => {
    dispatch(chatsActions.setScrollHidden());
    dispatch(chatsActions.resetAllChannels());
    dispatch(
      chatsActions.refreshAllChannelsFilters({
        priority: option?.value || null,
        page: 1,
        count: 5,
      })
    );
  };

  const handleStatusSelect = (options) => {
    dispatch(chatsActions.setScrollHidden());
    dispatch(chatsActions.resetAllChannels());
    dispatch(
      chatsActions.refreshAllChannelsFilters({
        statuses: options?.map(({ value }) => value),
        page: 1,
        count: 5,
      })
    );
  };

  useEffect(() => {
    if (!activeUserOption && user) {
      setActiveUserOption({ label: `${user.username} - ${user.email}`, value: user.id });
      dispatch(chatsActions.setScrollHidden());
      dispatch(chatsActions.resetAllChannels());
      dispatch(
        chatsActions.refreshAllChannelsFilters({
          userId: user.id,
          userIdForStatuses: user.id,
          page: 1,
          count: 5,
        })
      );
    }
  }, [activeUserOption, user]);

  return (
    <StyledContainer ref={ref}>
      {isAdmin && (
        <UserFilter
          clearable={user?.id !== activeUserOption?.value}
          activeOption={activeUserOption}
          onSelect={handleUserSelect}
        />
      )}
      <PriorityFilter activeValue={filters?.priority} onSelect={handlePrioritySelect} />
      <StatusFilter activeStatuses={filters?.statuses} onSelect={handleStatusSelect} />
    </StyledContainer>
  );
});

export default ChannelListFilters;
