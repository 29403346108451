import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusEdit from "../../Status/Edit";

export default function FormTrademarkRushProcessing({
  orderDetails,
  selectedProduct,
  onChange,
  onSelectedProduct,
  ...props
}) {
  const orderId = orderDetails.order.id;
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">RUSH PROCESSING PRODUCT TRADEMARK</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onSelectedProduct={onSelectedProduct}
      />

      <FormPDF productDetails={productDetails} {...props} />
    </div>
  );
}
