import { Row, Form } from "react-bootstrap";

import { getExtraProductDetailsByCode } from "_helpers";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import IndividualView from "./Individual/View";
import NewAddressView from "./NewAddress/View";

import StatusView from "../Status/View";

import { TRADEMARK_REGISTER_CODES } from "variables/Variables";

export default function FormTrademarkOfficeActionResponse({
  parentOrderDetails,
  orderDetails,
  selectedProduct,
  onUpdateOrderStatus,
  onChange,
}) {
  const productDetails = orderDetails.products[selectedProduct];
  const { extraProductDetails } = getExtraProductDetailsByCode(
    orderDetails,
    parentOrderDetails,
    TRADEMARK_REGISTER_CODES
  );

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">OFFICE ACTION RESPONSE TRADEMARK</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {extraProductDetails
            ? extraProductDetails.processingResult?.serialNumber || "N/A"
            : productDetails?.serialNumber || "N/A"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.trademarkName}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Trademark Owner</h6>
      <IndividualView individual={productDetails.trademarkOwner} />

      <hr />
      <h6 className="font-weight-bold">New Address</h6>
      <NewAddressView newAddress={productDetails.newAddress} />

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Changes to drawing:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.changesToDrowing}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Change to Trademark Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.changesToTrademarkName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          New specimen was uploaded?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.newSpecimenWasUploaded ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Changes to Owner</h6>
      <IndividualView individual={productDetails.changesToOwner} />
    </div>
  );
}
