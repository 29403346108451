import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import isEqual from "lodash.isequal";

import { usePrevious } from "_hooks";
import { chatsActions } from "_actions";

import monexaLogoSrc from "assets/img/monexa-logo.png";
import Loader from "views/Chats/Loader";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  scrollbar-color: #dee2e6 #fff;

  @media (max-width: 767px) {
  }
`;

const StyledLoaderContainer = styled.div`
  padding-top: 8px;
`;

const StyledItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #dee3e6;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledItemAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;

  border: 1px solid #dee3e6;
  border-radius: 50%;
  background-color: #fff;
`;

const StyledBody = styled.div`
  flex-grow: 1;
`;

const StyledUsername = styled.div`
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  font-weight: 700;
`;

const StyledRole = styled.div`
  font-size: 12px;
  color: #606071;
`;

const StyledBullet = styled.div`
  height: 16px;
  width: 16px;
  border: 1px solid #606071;
  border-radius: 2px;

  ${({ active }) =>
    active &&
    css`
      border-color: #641ec9;
      background-color: #641ec9;
    `}
`;

function ChannelListItem({ index, style, data }) {
  const { elements, selected, onSelect, isItemLoaded } = data || {};
  const user = elements[index];

  const handleSelect = (id) => () => {
    onSelect && onSelect(id);
  };

  let content = <Loader />;

  if (isItemLoaded(index)) {
    content = (
      <>
        <StyledItemAvatarContainer>
          <img src={monexaLogoSrc} alt="Monexa Logo" />
        </StyledItemAvatarContainer>
        <StyledBody>
          <StyledUsername>{user?.username}</StyledUsername>
          <StyledRole>
            {user?.role}
            {user?.accountManager ? " (Account Manager)" : ""}
          </StyledRole>
        </StyledBody>
        <StyledBullet active={selected?.includes(user?.id)} />
      </>
    );
  }
  return (
    <StyledItemContainer style={{ ...style }} onClick={handleSelect(user?.id)}>
      {content}
    </StyledItemContainer>
  );
}

export default function UserList({ selected, onSelect }) {
  const infiniteLoaderRef = useRef(null);
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.chats);
  const filters = users?.filters || {};
  const prevFilters = usePrevious(filters);
  const loading = users?.loading;
  const data = users?.data || {};

  const loadNextPage = () => {
    dispatch(chatsActions.refreshUsersFilters({ page: filters.page + 1 }));
  };

  const itemCount = data?.totalPages > data?.page ? data?.elements?.length + 1 : data?.elements?.length;

  const loadMoreItems = loading ? () => {} : loadNextPage;

  const isItemLoaded = (index) => index < data.elements.length;

  useEffect(() => {
    if (!isEqual(prevFilters, filters) && !loading) {
      dispatch(chatsActions.getUsers({ ...filters }));
    }
  }, [prevFilters, filters, loading]);

  if (loading && !users?.data?.elements?.length) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  return (
    <StyledContainer>
      <InfiniteLoader
        ref={infiniteLoaderRef}
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
      >
        {({ onItemsRendered, ref }) => (
          <List
            ref={(list) => {
              ref(list);
              infiniteLoaderRef.current = list;
            }}
            height={256}
            width="100%"
            itemCount={itemCount}
            itemSize={64}
            itemData={{
              elements: data?.elements,
              selected,
              onSelect,
              isItemLoaded,
            }}
            onItemsRendered={onItemsRendered}
          >
            {ChannelListItem}
          </List>
        )}
      </InfiniteLoader>
    </StyledContainer>
  );
}
