// SubmenuItem.jsx
import React from "react";
import { NavLink } from "react-router-dom";

const SubmenuItem = ({ item, loading, isActive }) => (
  <li className={`${isActive ? "active" : ""} ${item.upgrade ? "active-pro" : ""}`}>
    <NavLink
      to={item.layout + item.path}
      className="nav-link"
      style={{ opacity: loading ? 0.5 : 1 }}
      exact
      onClick={(e) => {
        if (loading) {
          e.preventDefault();
          return;
        }
      }}
    >
      <i className={item.icon} />
      <p>{item.name}</p>
    </NavLink>
  </li>
);

export default SubmenuItem;
