import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import clsx from "clsx";

import { phoneCleaner, phoneNumRegexp } from "_helpers";

import StatusEdit from "../../Status/Edit";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { TAB_CODES } from "variables/Variables";

export default function FormTrademarkPrivateContactInfo({
  orderDetails,
  selectedProduct,
  onChange,
  onError,
  onSelectedProduct,
  ...props
}) {
  const productDetails = orderDetails.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    const exFields = { [name]: value };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handlePhoneChange = ({ target: { name, value } }) => {
    const newData = phoneCleaner(value);

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: newData } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">PRIVATE CONTACT INFORMATION</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
        onSelectedProduct={onSelectedProduct}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <h6 className="font-weight-bold">Private Info</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Private Phone Number:
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className={clsx("form-control", "form-control-sm", {
              "is-invalid": productDetails?.phoneNumber && !phoneNumRegexp.test(productDetails?.phoneNumber),
            })}
            mask="(999) 999-9999"
            maskChar=" "
            name="phoneNumber"
            placeholder="(999) 999-9999"
            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
            value={phoneCleaner(productDetails?.phoneNumber) || ""}
            onChange={handlePhoneChange}
            onInvalid={() => handleAddError("Private Phone")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Private Email:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="email"
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            value={productDetails.email}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Private Email")}
          />
        </Col>
      </Form.Group>
    </div>
  );
}
