import { notificationsConstants } from "_constants";

const initialState = {
  visible: false,
  countOfUnread: 0,
  joiningChannel: 0,
  closingChannel: 0,
  channels: {
    filters: {
      orderUid: null,
      statuses: [],
    },
    loading: 0,
    data: {},
    error: null,
    orders: {},
  },
  closedChannels: {
    filters: {
      orderUid: null,
      page: 1,
      count: 10,
    },
    loading: 0,
    data: {},
    error: null,
    orders: {},
  },
};

export function notifications(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case notificationsConstants.SHOW_NOTIFICATIONS:
      return { ...state, visible: true };
    case notificationsConstants.HIDE_NOTIFICATIONS:
      return { ...state, visible: false };

    case notificationsConstants.JOIN_CHANNEL_REQUEST:
      return { ...state, joiningChannel: state.joiningChannel + 1 };
    case notificationsConstants.JOIN_CHANNEL_SUCCESS:
      return { ...state, joiningChannel: state.joiningChannel - 1 };
    case notificationsConstants.JOIN_CHANNEL_FAILURE:
      return { ...state, joiningChannel: state.joiningChannel - 1 };

    case notificationsConstants.CLOSE_CHANNEL_REQUEST:
      return { ...state, closingChannel: state.closingChannel + 1 };
    case notificationsConstants.CLOSE_CHANNEL_SUCCESS:
      return { ...state, closingChannel: state.closingChannel - 1 };
    case notificationsConstants.CLOSE_CHANNEL_FAILURE:
      return { ...state, closingChannel: state.closingChannel - 1 };

    case notificationsConstants.REFRESH_CHANNELS_FILTERS:
      return { ...state, channels: { ...state.channels, filters: { ...state.channels.filters, ...payload } } };

    case notificationsConstants.GET_CHANNELS_REQUEST:
      return { ...state, channels: { ...state.channels, loading: state.channels.loading + 1 } };

    case notificationsConstants.GET_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: state.channels.loading - 1,
          data: payload?.data,
          orders: payload?.orders,
        },
      };

    case notificationsConstants.GET_CHANNELS_FAILURE:
      return { ...state, channels: { ...state.channels, loading: state.channels.loading - 1, error: payload } };

    case notificationsConstants.REFRESH_CLOSED_CHANNELS_FILTERS:
      return {
        ...state,
        closedChannels: { ...state.closedChannels, filters: { ...state.closedChannels.filters, ...payload } },
      };

    case notificationsConstants.GET_CLOSED_CHANNELS_REQUEST:
      return { ...state, closedChannels: { ...state.closedChannels, loading: state.closedChannels.loading + 1 } };

    case notificationsConstants.GET_CLOSED_CHANNELS_SUCCESS:
      return {
        ...state,
        closedChannels: {
          ...state.closedChannels,
          loading: state.closedChannels.loading - 1,
          data: payload?.data,
          orders: payload?.orders,
        },
      };

    case notificationsConstants.GET_CLOSED_CHANNELS_FAILURE:
      return {
        ...state,
        closedChannels: { ...state.closedChannels, loading: state.closedChannels.loading - 1, error: payload },
      };

    case notificationsConstants.GET_COUNT_OF_UNREAD_SUCCESS:
      return { ...state, countOfUnread: payload?.count };

    default:
      return state;
  }
}
