import moment from "moment";

import { alertActions } from "_actions";

export function getAllByProductsHandler({ products, action, dispatch, options, request, success, failure }) {
  dispatch(request());

  let categories = [];

  if (products) {
    categories = [...new Set(products.map((product) => product?.category))];
  } else {
    categories = ["EIN", "EIN_INC", "INC", "INC_States", "VITAL", "TRADEMARKS", "ACCOUNTING", "PASSPORT"];
  }

  const requests = categories.map((category) => action(category, options));

  Promise.all(requests)
    .then((data) => {
      const payload = data.reduce((acc, values, idx) => {
        if (values.values && values.values.length) {
          return { ...acc, [categories[idx]]: values?.values };
        } else {
          return { ...acc, [categories[idx]]: values };
        }
      }, {});

      dispatch(success(payload));
    })
    .catch((error) => {
      dispatch(failure());
      dispatch(alertActions.error(error.toString()));
    });
}

export function combineFromCategories(obj) {
  return Object.values(obj).reduce((newObj, values) => {
    return {
      ...newObj,
      ...values.reduce((statuses, { code, value }) => {
        return { ...statuses, [code]: value };
      }, {}),
    };
  }, {});
}

export function getProductFilesArray(productDetails) {
  if (!productDetails || !productDetails?.processingResult?.files) return [];
  const files = productDetails?.processingResult?.files;
  // const filteredFiles = Object.fromEntries(Object.entries(files)?.filter((item) => [].includes(item[1]?.type)));
  const uploadedFileKeys = Object.keys(files);
  return uploadedFileKeys
    .map((key) => {
      return { objKey: key, orderId: productDetails.orderId, productId: productDetails.id, ...files[key] };
    })
    .sort((a, b) => moment(b.uploaded) - moment(a.uploaded));
}
