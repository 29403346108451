export const parseChannelData = (data) => {
  try {
    const parsedData = JSON.parse(data?.replace(/'/gi, '"'));
    return parsedData || {};
  } catch (e) {
    return data || {};
  }
};

export const getCompanyName = (rawData) => {
  const data = rawData?.parent || rawData;

  let companyNameOrOwner = data?.products?.find(({ companyName }) => companyName)?.companyName;

  if (!companyNameOrOwner) {
    companyNameOrOwner = data?.products?.find(({ legalName }) => legalName)?.legalName;
  }

  if (!companyNameOrOwner) {
    companyNameOrOwner = data?.products?.find(({ dbaname }) => dbaname)?.dbaname;
  }

  if (!companyNameOrOwner) {
    let { firstName, lastName } = data?.products?.find(({ owner }) => owner?.firstName)?.owner || {};
    companyNameOrOwner = firstName ? `${firstName} ${lastName}` : "Company/Owner: not chosen...";
  }

  return companyNameOrOwner;
};
