import { Row, Form } from "react-bootstrap";
import { SOURCE_TITLE } from "./constants";

export default function ViewSource({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Data Source For Transactions</h6>

      <Form.Group as={Row}>
        <Form.Label column sm="5">
          Source:
        </Form.Label>
        <Form.Label column sm="7">
          {SOURCE_TITLE[productDetails?.source] || "N/A"}
        </Form.Label>
      </Form.Group>
    </>
  );
}
