import { Col, Form, Row } from "react-bootstrap";

import { phoneFormatter } from "_helpers";

import StatusView from "../Status/View";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { state_array } from "variables/Variables";

export default function FormPrivacyPackage({ orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">PRIVACY PACKAGE</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Private Info</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Private Phone Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {phoneFormatter(productDetails?.phoneNumber) || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Private Email:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.email || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="12">
          Virtual Address:
        </Form.Label>
        <Col sm="12">
          {productDetails.virtualAddress && (
            <div className="pl-3">
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Address1:
                </Form.Label>
                <Form.Label column sm="9">
                  {productDetails.virtualAddress.address1}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Address2:
                </Form.Label>
                <Form.Label column sm="9">
                  {productDetails.virtualAddress.address2}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  City:
                </Form.Label>
                <Form.Label column sm="9">
                  {productDetails.virtualAddress.city}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  County:
                </Form.Label>
                <Form.Label column sm="9">
                  {productDetails.virtualAddress.county}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  State:
                </Form.Label>
                <Form.Label column sm="9">
                  {state_array[productDetails.virtualAddress.state]}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Zip Code:
                </Form.Label>
                <Form.Label column sm="9">
                  {productDetails.virtualAddress.zip}
                </Form.Label>
              </Form.Group>
            </div>
          )}
        </Col>
      </Form.Group>
    </div>
  );
}
