import { Row, Form } from "react-bootstrap";

import { MONTHLY_VOLUME } from "variables/Variables";
import { getMainProductDetails } from "_helpers";
import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusView from "../Status/View";

function FormCreditCardProcessing({
  parentOrderDetails,
  orderDetails,
  selectedProduct,
  onUpdateOrderStatus,
  onChange,
}) {
  const productDetails = orderDetails?.products[selectedProduct];
  const { mainProductDetails } = getMainProductDetails(orderDetails, parentOrderDetails);

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Credit Card Processing</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {mainProductDetails?.owner?.ssn || productDetails?.ssn || "N/A"}
        </Form.Label>
      </Form.Group>

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6>Credit Card Processing Types</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Online:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.online ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          In-Person:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.inPerson ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Monthly Sales Volume:
        </Form.Label>
        <Form.Label column sm={7}>
          {MONTHLY_VOLUME[productDetails?.monthlyVolume] || "—"}
        </Form.Label>
      </Form.Group>
    </div>
  );
}

export default FormCreditCardProcessing;
