import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { checkRoleAccess } from "_helpers/access";

import Submenu from "./Submenu";

const NavigationItem = ({ route, isExpanded, role, loading, onExpandRoute, availableCategories }) => {
  const location = useLocation();

  const currentPath = location.pathname;
  const hasAccess = checkRoleAccess(route, role);
  const canRender = !route.redirect && !route.child && hasAccess;

  const getFullPath = (route) => route.layout + route.path;
  const isActiveParent = route.views
    ? route.views.some((child) => {
        const childPath = getFullPath(child);
        return currentPath === childPath || currentPath.startsWith(childPath + "/");
      })
    : currentPath === getFullPath(route);

  if (!canRender) return null;

  return (
    <li className={`${isActiveParent ? "active" : ""} ${route.upgrade ? "active-pro" : ""}`}>
      <NavLink
        to={getFullPath(route)}
        className="nav-link"
        exact={!route.views}
        onClick={(e) => {
          if (loading) {
            e.preventDefault();
            return;
          }

          if (route.views) {
            e.preventDefault();
            onExpandRoute(route);
          }
        }}
        isActive={() => isActiveParent}
      >
        <i className={route.icon} />
        <p>{route.name}</p>
      </NavLink>

      {route.views && isExpanded && (
        <Submenu
          items={route.views}
          role={role}
          currentPath={currentPath}
          loading={loading}
          availableCategories={availableCategories}
        />
      )}
    </li>
  );
};

export default NavigationItem;
