import { useGroupChannelListContext } from "@sendbird/uikit-react/GroupChannelList/context";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { VariableSizeList as List } from "react-window";
import { isMobile } from "react-device-detect";

import { usePrevious } from "_hooks";
import { history } from "_helpers";
import { parseChannelData } from "./helpers";
import { accountingActions, notificationsActions } from "_actions";

const StyledGroupChannelListContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledGroupChannelListItemOuterContainer = styled.div`
  padding-bottom: 8px;
`;

const StyledGroupChannelListItemInnerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  gap: 8px;
  padding: 8px;
  border: 1px solid #dee2e6;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
  }

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid #17a2b8;
      background-color: #e4f8fc;

      &:hover {
        background-color: #e4f8fc;
      }
    `}
`;

const StyledGroupChannelListItemIconContainer = styled.div``;

const StyledGroupChannelListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

const StyledCrmOrderIdTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const StyledCrmOrderIdPayload = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
  }

  ${({ active }) =>
    active &&
    css`
      ::after {
        display: block;
        position: absolute;
        top: -8px;
        right: -52px;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 10px;
        background-color: #17a2b8;
        content: "Active";
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }
    `}
`;

const StyledChannelTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const StyledChannelName = styled.div`
  font-size: 14px;
`;

const StyledBadge = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 16px;
  background-color: #dc3545;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
`;

const ChatIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.9965 11H16.0054" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.9955 11H12.0045" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.99451 11H8.00349" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const useResize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};

const ListItem = ({ index, style, data }) => {
  const listItemRef = useRef();
  const {
    productDetails,
    availableOrders,
    customerGroupChannels,
    selectedChannelUrl,
    handleSelect,
    handleOrderIdClick,
    windowSize,
    setItemSize,
  } = data || {};
  const channel = customerGroupChannels[index];
  const { orderId, productId } = parseChannelData(channel?.data);
  const orderData = availableOrders?.data?.find(({ order }) => order.id === orderId);

  useEffect(() => {
    setItemSize && setItemSize({ index, size: listItemRef.current.getBoundingClientRect().height });
  }, [setItemSize, index, windowSize, data]);

  return (
    <StyledGroupChannelListItemOuterContainer style={{ ...style, paddingBottom: 16, paddingRight: 4 }}>
      <StyledGroupChannelListItemInnerContainer
        ref={listItemRef}
        selected={channel?.url === selectedChannelUrl}
        onClick={handleSelect(channel)}
      >
        {Boolean(channel.unreadMessageCount) && (
          <StyledBadge>{channel.unreadMessageCount > 98 ? "99+" : channel.unreadMessageCount}</StyledBadge>
        )}
        <StyledGroupChannelListItemIconContainer>
          <ChatIcon color={productId === productDetails?.id ? "#17a2b8" : "#212529"} />
        </StyledGroupChannelListItemIconContainer>
        <StyledGroupChannelListItemContent>
          <StyledCrmOrderIdTitle>CRM Order ID:</StyledCrmOrderIdTitle>
          <StyledCrmOrderIdPayload
            active={productId === productDetails?.id}
            onClick={handleOrderIdClick({ orderId, productId })}
          >
            {orderData?.order?.crmOrderId}
          </StyledCrmOrderIdPayload>
          <StyledChannelTitle>Channel Name:</StyledChannelTitle>
          <StyledChannelName>{channel.name}</StyledChannelName>
        </StyledGroupChannelListItemContent>
      </StyledGroupChannelListItemInnerContainer>
    </StyledGroupChannelListItemOuterContainer>
  );
};

const CustomGroupChannelListView = ({ orderDetails, productDetails, setCurrentChannelUrl }) => {
  const listRef = useRef();
  const listItemSizeMap = useRef({});
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const availableOrders = useSelector(({ accounting }) => accounting?.channel?.availableOrders);
  const prevAvailableOrders = usePrevious(availableOrders);
  const { groupChannels, selectedChannelUrl } = useGroupChannelListContext();
  const prevGroupChannels = usePrevious(groupChannels);

  const customerGroupChannels = useMemo(() => {
    return groupChannels?.filter((channel) => {
      const { customerId } = parseChannelData(channel?.data);
      return customerId === orderDetails?.customer?.id;
    });
  }, [orderDetails, groupChannels]);

  const setItemSize = useCallback(({ index, size }) => {
    listItemSizeMap.current = { ...listItemSizeMap.current, [index]: size + 16 };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getItemSize = (index) => listItemSizeMap.current[index] || 140;

  const handleSelect = (channel) => () => {
    setCurrentChannelUrl(channel.url);
  };

  const handleOrderIdClick =
    ({ orderId, productId }) =>
    () => {
      history.push("/admin/orders/" + orderId + "/" + productId);
    };

  useEffect(() => {
    if (
      prevGroupChannels?.length !== groupChannels?.length &&
      groupChannels?.length &&
      !availableOrders?.loading &&
      !availableOrders.error
    ) {
      const orderIds = groupChannels.map((channel) => {
        const { orderId } = parseChannelData(channel?.data);
        return orderId;
      });

      dispatch(accountingActions.getChannelOrders(orderIds));
    }
  }, [prevGroupChannels, groupChannels, availableOrders]);

  useEffect(() => {
    if (prevAvailableOrders?.data?.length !== availableOrders?.data?.length) {
      dispatch(
        notificationsActions.getClosedChannels({
          userId: user?.id,
          orderUid: orderDetails?.order?.id,
          page: 1,
          count: 1,
        })
      );
    }
  }, [prevAvailableOrders, availableOrders]);

  const [windowSize] = useResize();

  return (
    <StyledGroupChannelListContainer>
      <List
        ref={listRef}
        height={isMobile ? 260 : 460}
        width="100%"
        itemCount={customerGroupChannels?.length}
        itemSize={getItemSize}
        itemData={{
          productDetails,
          availableOrders,
          customerGroupChannels,
          selectedChannelUrl,
          handleSelect,
          handleOrderIdClick,
          windowSize,
          setItemSize,
        }}
      >
        {ListItem}
      </List>
    </StyledGroupChannelListContainer>
  );
};

export default CustomGroupChannelListView;
