import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Col, Button } from "react-bootstrap";

import { combineFromCategories } from "_helpers";
import { alertActions } from "_actions";

import { reFileAbilityArray, rejectionReasonArray } from "variables/Variables";
import DateView from "./Date/View";

export default function ViewStatus({ orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const dispatch = useDispatch();

  const { processingSubStatusOpt, productsProcessingStatusesOpt, ...variants } = useSelector(
    ({ variants }) => variants
  );

  const processingErrorsOpt = combineFromCategories(variants.allProcessingErrors);
  const processingErrorStatusOpt = combineFromCategories(variants.allProcessingErrorStatuses);

  const productDetails = orderDetails?.products[selectedProduct];

  const currentStatusObj = processingSubStatusOpt[productDetails?.processingResult?.status] || {};
  const currentSubStatus =
    currentStatusObj[productDetails?.processingResult?.subStatus] ||
    productDetails?.processingResult?.subStatus ||
    "N/A";

  const isClaimed = productDetails?.processingResult?.status === "Claimed";

  const handleClaimOrder = () => {
    const newObj = {
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        if (idx !== selectedProduct) return product;
        const newData = { status: "Claimed", errorStatus: null, processingError: null };
        return { ...product, processingResult: { ...product.processingResult, ...newData } };
      }),
    };
    onChange(newObj);
    onUpdateOrderStatus();
    setTimeout(() => {
      dispatch(
        alertActions.info(
          "This order has been claimed for 30 minutes. If the status is not updated within 30 minutes, the order will revert to its previous status."
        )
      );
    }, 2000);
  };

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Status</h6>

      <Form.Group as={Row}>
        <Form.Label column sm="5">
          Filing Status:
        </Form.Label>
        <Form.Label column sm="7">
          {productsProcessingStatusesOpt[productDetails?.processingResult?.status]}
        </Form.Label>
      </Form.Group>

      {productDetails?.processingResult?.status === "Pending" &&
        (productDetails?.processingResult?.pendingReason ||
          productDetails?.processingResult?.dateToChangeStatus ||
          productDetails?.processingResult?.pendingStatus) && (
          <>
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Pending:
              </Form.Label>
              <Form.Label column sm="7">
                {productDetails.processingResult.pendingReason}
              </Form.Label>
            </Form.Group>
            <DateView label="Status Will Change On:" date={productDetails.processingResult.dateToChangeStatus} />
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Status Will Change To:
              </Form.Label>
              <Form.Label column sm="7">
                {productDetails.processingResult.pendingStatus}
              </Form.Label>
            </Form.Group>
          </>
        )}

      {productDetails?.processingResult?.status === "Hold" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Hold Subcategory:
            </Form.Label>
            <Form.Label column sm="7">
              {currentSubStatus}
            </Form.Label>
          </Form.Group>
        </>
      )}

      {productDetails?.processingResult?.status === "ReadyToFile" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Ready To File Subcategory:
            </Form.Label>
            <Form.Label column sm="7">
              {currentSubStatus}
            </Form.Label>
          </Form.Group>
        </>
      )}

      {[
        "INC_RegisteredAgent",
        "INC_States_RegisteredAgent",
        "EIN_RegisteredAgent",
        "INC_ResidentAgent",
        "INC_States_ResidentAgent",
        "EIN_ResidentAgent",
      ].includes(productDetails?.code?.code) &&
        productDetails?.processingResult?.status === "AgentReview" && (
          <>
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Agent Review Subcategory:
              </Form.Label>
              <Form.Label column sm="7">
                {currentSubStatus}
              </Form.Label>
            </Form.Group>
          </>
        )}

      {productDetails?.processingResult?.status === "Error" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              EIN Error:
            </Form.Label>
            <Form.Label column sm="7">
              {processingErrorsOpt[productDetails?.processingResult?.processingError]}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="5">
              EIN Error Status:
            </Form.Label>
            <Form.Label column sm="7">
              {processingErrorStatusOpt[productDetails?.processingResult?.errorStatus]}
            </Form.Label>
          </Form.Group>
        </>
      )}

      {productDetails?.processingResult?.status === "Rejected" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Rejection Reason:
            </Form.Label>
            <Form.Label column sm="7">
              {rejectionReasonArray[productDetails?.rejectionReason]?.title || "–"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Re-File Ability:
            </Form.Label>
            <Form.Label column sm="7">
              {reFileAbilityArray[productDetails?.reFileAbility]?.title || "–"}
            </Form.Label>
          </Form.Group>
        </>
      )}

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm="5">
          Claim Order:
        </Form.Label>
        <Form.Label column sm="7">
          <Button
            size="sm"
            variant="info"
            title="Change product status to Claimed"
            type="button"
            disabled={isClaimed}
            onClick={() => {
              handleClaimOrder();
            }}
          >
            Claim Order
          </Button>
        </Form.Label>
      </Form.Group>
    </>
  );
}
