import { Form, Col } from "react-bootstrap";

export default function OrderProductSelect({ selectedProduct, orderDetails, onChange }) {
  const handleSelectProduct = ({ target: { value } }) => onChange(value);

  return (
    <Col className="d-flex align-items-center" sm="8">
      <Form.Control data-cy="active-product-select" as="select" value={selectedProduct} onChange={handleSelectProduct}>
        {orderDetails?.products.map(({ id, code: { code, customerName } }, idx) => (
          <option key={id} value={idx} hidden={code === "TRADEMARK_Trademark_Rush_Processing"}>
            {customerName}
          </option>
        ))}
      </Form.Control>
    </Col>
  );
}
