import React, { useRef } from "react";
import styled from "styled-components";

import ChannelListFilters from "./ChannelListFilters";
import ChannelListView from "./ChannelListView";

const StyledContainer = styled.div`
  top: 67px;
  position: sticky;
  width: 400px;
  min-width: 400px;
  min-height: calc(100vh - 67px);

  border-right: 1px solid #dee2e6;
  background-color: #fff;

  @media (max-width: 767px) {
    top: unset;
    position: unset;
    width: unset;
    min-width: unset;
    min-height: unset;
  }
`;

export default function ChannelList({ currentChannel, onSelect }) {
  const channelListFiltersRef = useRef(null);

  return (
    <StyledContainer>
      <ChannelListFilters ref={channelListFiltersRef} />
      <ChannelListView
        channelListFiltersRef={channelListFiltersRef}
        currentChannel={currentChannel}
        onSelect={onSelect}
      />
    </StyledContainer>
  );
}
