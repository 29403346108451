import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Card, Form } from "react-bootstrap";
import styled, { css } from "styled-components";
import moment from "moment-timezone";

import { reportsUsersPerformanceActions, variantsActions, alertActions } from "_actions";
import { history, combineFromCategories } from "_helpers";
import { variantsService } from "_services";

import Spinner from "components/Spinner";
import Button from "components/Button";

const StyledCard = styled(Card)`
  height: max-content;

  .table-responsive {
    margin-bottom: 0;
  }
`;

const TableRow = styled.tr`
  background-color: #dfdfdf;

  ${({ selected }) =>
    selected &&
    css`
      background-color: #afafaf;
    `}

  ${({ tableLayout }) =>
    tableLayout &&
    css`
      tablelayout: ${tableLayout};
    `}
`;
const TableMainRow = styled(TableRow)`
  background-color: #ffffff;
`;
const CellHeader = styled.th`
  width: auto;

  ${({ width, minWidth }) => css`
    width: ${width};
    min-width: ${minWidth ? minWidth : 0};
  `}
`;
const MessageContainer = styled.div`
  padding: 16px 8px;
  text-align: center;
`;

export default function UsersPerformance() {
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState("last30days");
  const [selectedRow, setSelectedRow] = useState("");

  const lines = useSelector(({ reportsUsersPerformance: { lines } }) => lines);
  const loading = useSelector(({ reportsUsersPerformance: { loading } }) => loading);
  const processingStatusOpt = useSelector(({ variants: { allProcessingStatuses } }) =>
    Object.keys(allProcessingStatuses) ? combineFromCategories(allProcessingStatuses) : {}
  );
  const changedStatuses = selectedRow && lines?.length ? lines[selectedRow]?.mapOfStatusChangeRequests : {};
  const [date, setDate] = useState({
    from: moment().subtract(30, "days").format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
  });

  const handleGet = () => {
    // if (moment(date.to).diff(date.from, "days") <= 30 && moment(date.to).valueOf() <= moment(new Date()).valueOf()) {
    setSelectedRow("");
    dispatch(reportsUsersPerformanceActions.getAll({ from: date.from, to: date.to }));
    // } else {
    // dispatch(alertActions.error("You can only get results 30 days before today."));
    // }
  };

  const handleChangeDataRange = ({ target: { value } }) => {
    setDateRange(value);

    switch (value) {
      case "today": {
        setDate({ from: moment().format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") });
        break;
      }

      case "yesterday": {
        setDate({
          from: moment().subtract(1, "day").format("YYYY-MM-DD"),
          to: moment().subtract(1, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      case "weektodate": {
        setDate({
          from: moment().startOf("week").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      }

      case "lastweek": {
        setDate({
          from: moment().day(1).subtract(7, "day").format("YYYY-MM-DD"),
          to: moment().day(7).subtract(7, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      case "monthtodate": {
        setDate({ from: moment().startOf("month").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") });
        break;
      }

      case "last30days": {
        setDate({
          from: moment().subtract(30, "days").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      }

      default:
        break;
    }
  };
  const handleChangeDate = ({ target: { name, value } }) => setDate({ ...date, [name]: value });

  useEffect(() => {
    try {
      const user_data = JSON.parse(localStorage.getItem("user"));
      if (!user_data || !["ADMIN", "DEVELOPER"].includes(user_data.role)) {
        history.push("/");
      } else {
        variantsService.getProductCategories().then(({ values }) => {
          dispatch(
            variantsActions.getAllProcessingStatuses(
              values.map((category) => {
                return { ...category, category: category.code };
              })
            )
          );
        });

        handleGet();
      }
    } catch (err) {
      console.error("Caught an exception!", err);
    }
  }, []);

  return (
    <>
      <Container fluid className="mt-5 py-4">
        <StyledCard className="p-2">
          <Form>
            <Form.Row>
              <Form.Group as={Col} sm={3}>
                <Form.Label>Date Range:</Form.Label>
                <Form.Control size="sm" as="select" name="dateRange" value={dateRange} onChange={handleChangeDataRange}>
                  <option value="" hidden></option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="weektodate">Week to Date</option>
                  <option value="lastweek">Last Week</option>
                  <option value="monthtodate">Month to Date</option>
                  <option value="last30days">Last 30 Days</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} sm={3}>
                <Form.Label>Start Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="from"
                  // min={moment().subtract(30, "days").format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                  value={date.from}
                  onChange={handleChangeDate}
                />
              </Form.Group>

              <Form.Group as={Col} sm={3}>
                <Form.Label>End Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="to"
                  // min={moment().subtract(30, "days").format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                  value={date.to}
                  onChange={handleChangeDate}
                />
              </Form.Group>

              <Form.Group as={Col} sm={3} style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  size="sm"
                  className="mr-2"
                  isLoading={loading}
                  disabled={moment(date.from).valueOf() > moment(date.to).valueOf()}
                  onClick={handleGet}
                >
                  Filter
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </StyledCard>

        <Row className="m-0 p-0">
          <StyledCard className="col-md-6 p-0">
            {!!loading && <Spinner />}
            {!loading && !!lines && (
              <Table responsive className="sales-by-domain-table m-0">
                <thead>
                  <TableMainRow tableLayout="fixed">
                    <CellHeader width="auto">Username</CellHeader>
                    <CellHeader width="20%">Orders Updated</CellHeader>
                    <CellHeader width="20%">Statuses Set</CellHeader>
                  </TableMainRow>
                </thead>
                <tbody>
                  {lines.map(({ username, updateOrderRequests, mapOfStatusChangeRequests }, idx) => {
                    return (
                      <Fragment key={`${username}-${idx}`}>
                        <TableRow
                          tableLayout="fixed"
                          selected={`${idx}` === selectedRow}
                          onClick={() => {
                            setSelectedRow(`${idx}`);
                          }}
                        >
                          <td>{username}</td>
                          <td>{updateOrderRequests}</td>
                          <td>{Object.values(mapOfStatusChangeRequests).reduce((sum, count) => (sum += count), 0)}</td>
                        </TableRow>
                      </Fragment>
                    );
                  })}
                  {!lines.length && (
                    <tr>
                      <td>No Results</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </StyledCard>

          <Col className="col p-0" />

          <StyledCard className="col-md-5 p-0">
            {selectedRow !== "" ? (
              <Table responsive className="sales-by-domain-table mb-0">
                <thead>
                  <TableMainRow tableLayout="fixed">
                    <CellHeader width="70%">Status Name</CellHeader>
                    <CellHeader width="30%">Count of Set Statuses</CellHeader>
                  </TableMainRow>
                </thead>
                <tbody>
                  {Object.keys(changedStatuses).length ? (
                    Object.keys(changedStatuses).map((line, idx) => (
                      <tr key={idx}>
                        <td>{processingStatusOpt[line]}</td>
                        <td>{changedStatuses[line]}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No statuses updated from this user</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <MessageContainer>Click on user's row for details</MessageContainer>
            )}
          </StyledCard>
          <Col className="col p-0" />
        </Row>
      </Container>
    </>
  );
}
