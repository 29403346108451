import { productTasksConstants } from "_constants";

const initialState = {
  filters: {
    type: "",
    status: "",
    page: 1,
    count: 10,
  },
  loading: 0,
  data: {},
  error: null,
};

export function productTasks(state = initialState, action) {
  const { type, payload, error } = action;

  switch (type) {
    case productTasksConstants.GET_PRODUCT_TASKS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case productTasksConstants.GET_PRODUCT_TASKS_SUCCESS:
      return { ...state, loading: state.loading - 1, data: payload };
    case productTasksConstants.GET_PRODUCT_TASKS_FAILURE:
      return { ...state, loading: state.loading - 1, error: payload };

    case productTasksConstants.CREATE_PRODUCT_TASK_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case productTasksConstants.CREATE_PRODUCT_TASK_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case productTasksConstants.CREATE_PRODUCT_TASK_FAILURE:
      return { ...state, loading: state.loading - 1, error: payload };

    case productTasksConstants.UPDATE_PRODUCT_TASK_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case productTasksConstants.UPDATE_PRODUCT_TASK_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case productTasksConstants.UPDATE_PRODUCT_TASK_FAILURE:
      return { ...state, loading: state.loading - 1, error: payload };

    case productTasksConstants.DELETE_PRODUCT_TASK_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case productTasksConstants.DELETE_PRODUCT_TASK_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case productTasksConstants.DELETE_PRODUCT_TASK_FAILURE:
      return { ...state, loading: state.loading - 1, error: payload };

    case productTasksConstants.REFRESH_PRODUCT_TASKS_FILTERS:
      return { ...state, filters: { ...state.filters, ...payload } };

    default:
      return state;
  }
}
