import React from "react";
import { ChannelSettingsProvider } from "@sendbird/uikit-react/ChannelSettings/context";

import CustomChannelSettingsView from "./CustomChannelSettingsView";

const CustomChannelSettings = ({ channelUrl, setSettingsVisible }) => {
  return (
    <ChannelSettingsProvider channelUrl={channelUrl}>
      <CustomChannelSettingsView setSettingsVisible={setSettingsVisible} />
    </ChannelSettingsProvider>
  );
};

export default CustomChannelSettings;
