export function validateWVFormationQuestions(wvFormationQuestions) {
  const questionsObj = wvFormationQuestions || {};

  const mainQuestionsKeys = [
    "annualGrossIncome",
    "grossOver4000",
    "locationInWV",
    "retailProduct",
    "businessActivity",
    "taxableEndYearMonth",
    "haveEmployees",
  ];
  const employeesQuestionsKeys = [
    "countOfEmployees",
    "payWagesInWV",
    "onlyCasualEmployees",
    "firstEmployeeStartWork",
    "firstEmployeeWasPaid",
    "grossWagesInWVFirstQuarter",
    "willPayrollBe1500",
    "willEmployWVWorkersIn20Weeks",
    "willWithholdMoreThan250Tax",
    "earliestQuarterPayroll1500",
    "earliestYearPayroll1500",
    "earliestQuarterWorkerEmployedIn20Weeks",
    "earliestYearWorkerEmployedIn20Weeks",
    "businessSubjectToFederalTax",
  ];

  let questionsKeys = [...mainQuestionsKeys];

  if (questionsObj.haveEmployees) {
    questionsKeys = [...mainQuestionsKeys, ...employeesQuestionsKeys];
  }

  return questionsKeys.every(
    (key) => questionsObj[key] !== null && questionsObj[key] !== undefined && questionsObj[key] !== ""
  );
}
