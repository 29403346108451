import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getRedirectLocation, history } from "_helpers";
import { alertActions } from "_actions";
import { userService } from "_services";

import Spinner from "components/Spinner";

const CheckAuth = () => {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));

  const handleInvalidRequest = (message) => {
    dispatch(alertActions.error(message));
    history.push("/login");
  };

  const handleValidRequest = (userDetails) => {
    const redirectLocation = getRedirectLocation(userDetails);

    dispatch(alertActions.error("Unexpected access error was occured"));
    history.push(redirectLocation);
  };

  useEffect(() => {
    const checkAuth = async () => {
      if (!user) {
        handleInvalidRequest("User unauthorized");
        return;
      }

      try {
        const userDetails = await userService.getme();
        handleValidRequest(userDetails);
      } catch (error) {
        console.error(error);
        handleInvalidRequest("Auth data is outdated or invalid");
      }
    };

    checkAuth();
  }, [dispatch, user]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default CheckAuth;
