import { Form, Row, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import { getMainProductDetails } from "_helpers";
import StatusEdit from "../../Status/Edit";

function FormIncRushProcessing({
  parentOrderDetails,
  orderDetails,
  selectedProduct,
  onParentChange,
  onChange,
  onSelectedProduct,
  ...props
}) {
  const productDetails = orderDetails?.products[selectedProduct];
  const { mainOrderDetails, mainProductIndex, mainProductDetails, isParent } = getMainProductDetails(
    orderDetails,
    parentOrderDetails
  );

  const handleMainProductChange = ({ target: { name, value } }) => {
    const exFields = {};

    if (name === "ssn") {
      exFields.owner = {
        ...mainProductDetails?.owner,
        ssn: value || null,
      };
    }

    const onEvent = isParent ? onParentChange : onChange;

    onEvent({
      ...mainOrderDetails,
      products: mainOrderDetails?.products.map((product, idx) =>
        idx === mainProductIndex ? { ...product, ...exFields } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Rush Processing Product INC</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onSelectedProduct={onSelectedProduct}
      />

      {mainProductDetails && (
        <>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Social Security Number:
            </Form.Label>
            <Col sm={7}>
              <InputMask
                className="form-control form-control-sm"
                mask="999-99-9999"
                maskChar=" "
                name="ssn"
                placeholder="999-99-9999"
                pattern="^\d{3}-\d{2}-\d{4}$"
                value={mainProductDetails?.owner?.ssn || ""}
                onChange={handleMainProductChange}
                onInvalid={() => handleAddError("Social Security Number")}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <FormPDF productDetails={productDetails} {...props} />
    </div>
  );
}

export default FormIncRushProcessing;
