/* eslint-disable react/prop-types */
import MessageSearchUI from "@sendbird/uikit-react/MessageSearch/components/MessageSearchUI";
import { MessageSearchProvider } from "@sendbird/uikit-react/MessageSearch/context";
import { Header } from "@sendbird/uikit-react/ui/Header";
import Icon, { IconColors, IconTypes } from "@sendbird/uikit-react/ui/Icon";
import Loader from "@sendbird/uikit-react/ui/Loader";
import React, { useEffect, useState } from "react";

function MessageSearchPannel(props) {
  const {
    channelUrl,
    onResultClick,
    onCloseClick,
    onResetClick,
    messageSearchQuery,
    renderPlaceHolderError,
    renderPlaceHolderLoading,
    renderPlaceHolderNoString,
    renderPlaceHolderEmptyList,
    renderSearchItem,
  } = props;

  const [searchString, setSearchString] = useState("");
  const [inputString, setInputString] = useState("");
  const [loading, setLoading] = useState(false);

  let timeout = null;

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setSearchString(inputString);
      setLoading(true);
      timeout = null;
    }, 500);
  }, [inputString]);

  const handleOnChangeInputString = (e) => {
    setInputString(e.target.value);
  };

  const handleOnResultLoaded = () => {
    setLoading(false);
  };

  const handleOnClickResetStringButton = (e) => {
    e.stopPropagation();
    setInputString("");
    setSearchString("");
    onResetClick && onResetClick(e);
  };

  return (
    <div className="sendbird-message-search-pannel">
      <Header
        className="sendbird-message-search-pannel__header"
        renderMiddle={() => <Header.Title title="Search in channel" />}
        renderRight={() => (
          <Header.IconButton
            className="sendbird-message-search-pannel__header__close-button"
            onClick={onCloseClick}
            type={IconTypes.CLOSE}
            color={IconColors.ON_BACKGROUND_1}
          />
        )}
      />
      <div className="sendbird-message-search-pannel__input">
        <div className="sendbird-message-search-pannel__input__container">
          <Icon
            className="sendbird-message-search-pannel__input__container__search-icon"
            type={IconTypes.SEARCH}
            fillColor={IconColors.ON_BACKGROUND_3}
            width="24px"
            height="24px"
          />
          <input
            className="sendbird-message-search-pannel__input__container__input-area"
            placeholder="Search"
            value={inputString}
            onChange={handleOnChangeInputString}
          />
          {inputString && loading && (
            <Loader className="sendbird-message-search-pannel__input__container__spinner" width="20px" height="20px">
              <Icon type={IconTypes.SPINNER} fillColor={IconColors.PRIMARY} width="20px" height="20px" />
            </Loader>
          )}
          {!loading && inputString && (
            <Icon
              className="sendbird-message-search-pannel__input__container__reset-input-button"
              type={IconTypes.REMOVE}
              fillColor={IconColors.ON_BACKGROUND_3}
              width="20px"
              height="20px"
              onClick={handleOnClickResetStringButton}
            />
          )}
        </div>
      </div>
      <div className="sendbird-message-search-pannel__message-search">
        <MessageSearchProvider
          channelUrl={channelUrl}
          searchString={searchString}
          onResultClick={onResultClick}
          onResultLoaded={handleOnResultLoaded}
          messageSearchQuery={messageSearchQuery}
        >
          <MessageSearchUI
            renderPlaceHolderError={renderPlaceHolderError}
            renderPlaceHolderLoading={renderPlaceHolderLoading}
            renderPlaceHolderNoString={renderPlaceHolderNoString}
            renderPlaceHolderEmptyList={renderPlaceHolderEmptyList}
            renderSearchItem={renderSearchItem}
          />
        </MessageSearchProvider>
      </div>
    </div>
  );
}

export default MessageSearchPannel;
