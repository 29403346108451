import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import moment from "moment-timezone";
import InputMask from "react-input-mask";
import clsx from "clsx";

import { config } from "_config";
import { CATEGORIES_OBJ } from "variables/Variables";
import { customerActions, alertActions, uiActions } from "_actions";
import {
  getFileExtensionsString,
  getFileExtension,
  checkIfFileAllowed,
  sanitizeFileName,
  checkIsIncorpify,
  checkIsIncStates,
  checkIsRA,
  checkIsAR,
  checkIsSTP,
  checkIsBOI,
  checkIsAccounting,
  checkIsLLCIncorporation,
  checkIsIncEin,
  getProtocolAndDomain,
  multiplyPriceToNumber,
} from "_helpers";

import PayForLeadModal from "components/PayForLeadModal";
import ConfirmationModal from "components/ConfirmationModal";
import WebFileMissingModal from "components/WebFileMissingModal";
import { EIN_MAIN_CODES, TRADEMARK_REGISTER_CODES } from "variables/Variables";

import Assignee from "./Assignee";
import Tracking from "./Tracking";

const einPattern = /^\d{2}-\d{7}$/;

const TM_AMENDMENT_TYPES = Object.freeze({
  minor: "TRADEMARK_Amendment_TrademarkMinor",
  intensive: "TRADEMARK_Amendment_TrademarkIntensive",
});

function OrderActions({
  orderDetails,
  selectedProduct,
  isDirty,
  isEditable,
  onChange,
  onValidate,
  onShowConfirmation,
  onDeleteOrder,
}) {
  const dispatch = useDispatch();

  const [chargeAnnualAmount, setChargeAnnualAmount] = useState("0.00");
  const [chargeSalesTaxAmount, setChargeSalesTaxAmount] = useState("0.00");
  const [chargeTmAmendmentAmount, setChargeTmAmendmentAmount] = useState("0.00");
  // const [numberOfClasses, setNumberOfClasses] = useState(0);
  const [tmAmendmentType, setTmAmendmentType] = useState(TM_AMENDMENT_TYPES.minor);
  const [showWebfileMissing, setShowWebfileMissing] = useState(false);
  const [showDissolveModal, setShowDissolveModal] = useState(false);
  const [showPayForLead, setShowPayForLead] = useState(false);

  const hash = useSelector(({ ui }) => ui.hash);
  const { unsubscription } = useSelector(({ customers }) => customers);
  const { productsFileTypesObj } = useSelector(({ variants }) => variants);

  const productDetails = orderDetails.products[selectedProduct];

  if (!productDetails.processingResult.files) return null;

  const isMain = productDetails.code.main;
  const isLead =
    isMain && (productDetails.processingResult.status === "Lead" || productDetails.paymentStatus === "Lead");
  const isEIN = productDetails.code.category === "EIN";
  const isEinIncCategory = productDetails.code.category === "EIN_INC";
  const isIncCategory = productDetails.code.category === "INC";
  const isIncStatesCategory = productDetails.code.category === "INC_States";
  const isEinMain = EIN_MAIN_CODES.includes(productDetails.code.code);
  const isTrademark = productDetails.code.category === "TRADEMARKS";
  const isTrademarkRegister = TRADEMARK_REGISTER_CODES.includes(productDetails.code.code);
  const isEinInc = productDetails.code.code === "EIN_LLCIncorporation";
  const isVITALNewBirthCertificate = productDetails.code.code === "VITAL_NewBirthCertificate";
  const isEINRA = ["EIN_RegisteredAgent", "EIN_ResidentAgent"].includes(productDetails.code.code);
  // const isACR = checkIsACR(productDetails.code.code);
  const isIncorpify = checkIsIncorpify(productDetails.code.code);
  const isIncStates = checkIsIncStates(productDetails.code.code);
  const isRA = (product) => checkIsRA(product.code.code);
  const isAR = checkIsAR(productDetails.code.code);
  const isSTP = checkIsSTP(productDetails.code.code);
  const isAccounting = checkIsAccounting(productDetails.code.code);
  const isLLCIncorporation = checkIsLLCIncorporation(productDetails.code.code);
  const isIncEin = checkIsIncEin(productDetails.code.code);
  const isBoi = checkIsBOI(productDetails.code.code);

  const hasBOI =
    Boolean(
      orderDetails?.products?.find(
        (product) =>
          checkIsBOI(product?.code?.code) &&
          (product?.processingResult?.status !== "Lead" || product?.paymentStatus !== "Declined")
      )
    ) ||
    Boolean(
      orderDetails?.children?.find((order) => {
        // return order?.order?.productCodes?.includes("INC_BOI") || order?.productCodes?.includes("EIN_BOI");
        return order?.products?.find(
          (product) =>
            checkIsBOI(product?.code?.code) &&
            (product?.processingResult?.status !== "Lead" || product?.paymentStatus !== "Declined")
        );
      })
    ) ||
    false;

  const orderId = orderDetails.order.id;
  const productId = productDetails.id;
  const productFileTypes = productsFileTypesObj[productDetails?.code?.code] || {};
  const productDetailsRA = orderDetails?.products?.filter(isRA)?.find(({ id }) => id === productId);

  const productsWithSubscription = useMemo(
    () =>
      orderDetails.products
        ?.filter(
          ({ id, code: { subscription }, processingResult: { status } }) =>
            subscription && status !== "Cancelled" && id === productId
        )
        .map(({ id }) => id),
    [orderDetails, productId]
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [einNumber, setEINNumber] = useState(productDetails.processingResult.einnumber);

  const extensionsEIN = getFileExtensionsString(productFileTypes["EIN_PDF"]?.allowedExtensions);
  const selectedFileSize = selectedFile?.size;
  const selectedFileExt = getFileExtension(selectedFile?.name);
  const isExtensionAllowed = checkIfFileAllowed(selectedFileExt, productFileTypes["EIN_PDF"]?.allowedExtensions);

  const hasEinPdfFile = Boolean(
    Object.values(productDetails?.processingResult?.files || {}).find((file) => file?.type === "EIN_PDF")
  );

  const handleDownloadPDF = () =>
    dispatch(customerActions.downloadPDF(orderId, productId, productDetails.code.category));

  const handleLoginCustomerTrademark = useCallback(() => {
    dispatch(
      customerActions.loginCustomer({
        orderId: orderDetails?.order?.id,
        username: orderDetails?.customer?.username,
        category: CATEGORIES_OBJ.trademarks,
        salePageURL: config?.membershipUrl,
      })
    );
  }, [dispatch, orderDetails]);

  const handleLoginCustomerAccounting = useCallback(() => {
    dispatch(
      customerActions.loginCustomer({
        orderId: orderDetails?.order?.id,
        username: orderDetails?.customer?.monexaUsername || orderDetails?.customer?.username,
        category: CATEGORIES_OBJ.accounting,
        salePageURL: config?.accountingDashboardUrl,
      })
    );
  }, [dispatch, orderDetails]);

  const handleLoginCustomerIncorpify = useCallback(() => {
    dispatch(
      customerActions.loginCustomer({
        orderId: orderDetails?.order?.id,
        username: orderDetails?.customer?.username,
        category: CATEGORIES_OBJ.inc,
        salePageURL: config?.incorpifyUrl,
      })
    );
  }, [dispatch, orderDetails]);

  const handleLoginCustomerIncStates = useCallback(() => {
    dispatch(
      customerActions.loginCustomer({
        orderId: orderDetails?.order?.id,
        username: orderDetails?.customer?.username,
        category: CATEGORIES_OBJ.incStates,
        salePageURL: config?.incStatesMembershipUrl,
      })
    );
  }, [dispatch, orderDetails]);

  const handleLoginCustomerEin = useCallback(() => {
    let url = config?.einMembershipUrl || "";

    if (config?.envName === "prod" && orderDetails?.order?.salePageURL) {
      const baseUrl = getProtocolAndDomain(orderDetails?.order?.salePageURL);

      if (baseUrl) {
        url = baseUrl + "/membership";
      }
    }

    dispatch(
      customerActions.loginCustomer({
        orderId: orderDetails?.order?.id,
        username: orderDetails?.customer?.username,
        category: CATEGORIES_OBJ.ein,
        salePageURL: url,
      })
    );
  }, [dispatch, orderDetails]);

  const handleSendHardCopy = () => dispatch(customerActions.sendhardcopy(orderId));

  const handleRetryAutomation = () => dispatch(customerActions.automationLock(orderId, productId));
  const getAutomationLockStatus = () => {
    if (productDetails && productDetails.automationLock) {
      return moment.utc(productDetails.automationLock).local().format("YYYY/MM/DD HH:mm:ss");
    }

    if (orderDetails && orderDetails.order.automationLock) {
      return moment.utc(orderDetails.order.automationLock).local().format("YYYY/MM/DD HH:mm:ss");
    }

    return "N/A";
  };

  const handleSaveEinNumber = () => {
    if (!hasEinPdfFile) {
      dispatch(alertActions.error("Please upload EIN PDF file before change EIN #"));

      return false;
    }

    if (!einNumber || !einPattern.test(einNumber)) {
      dispatch(alertActions.error("Please enter EIN # in correct format: XX-XXXXXXX"));

      return false;
    }

    const newOrderDetails = {
      ...orderDetails,
      products: [
        ...orderDetails?.products.map((product) => {
          if (product.id === productId) {
            return {
              ...product,
              processingResult: {
                ...product?.processingResult,
                einnumber: einNumber,
              },
            };
          }
          return product;
        }),
      ],
    };

    dispatch(uiActions.checkHash(hash, customerActions.updateorder(orderId, newOrderDetails)));
  };

  const handleFileChange = (event) => {
    if (!event.target.files.length) {
      setSelectedFile(null);
    } else {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileUpload = () => {
    const einPattern = /^\d{2}-\d{7}$/;

    if (!einNumber || !einPattern.test(einNumber)) {
      dispatch(alertActions.error("Please enter EIN # in correct format: XX-XXXXXXX"));

      return false;
    }

    if (selectedFile === null) {
      dispatch(alertActions.error("Please select file to upload"));

      return false;
    }

    if (!isExtensionAllowed) {
      dispatch(alertActions.error("Please select file with correct extension: " + (extensionsEIN || "any type")));

      return false;
    }

    if (selectedFileSize && selectedFileSize / 1024 / 1024 > 5) {
      dispatch(alertActions.error("File size limit is 5MB"));

      return false;
    }

    const uploadFunction = () => {
      const formData = new FormData();

      formData.append("file", selectedFile, sanitizeFileName(selectedFile?.name));
      dispatch(customerActions.uploadPDF(orderId, einNumber, formData));
      setSelectedFile(null);
    };

    if (isDirty) onShowConfirmation(uploadFunction);
    else uploadFunction();
  };

  const handleEINNumberChange = ({ target: { value } }) => {
    setEINNumber(value);
  };

  const handleUnsubscribe = () => dispatch(customerActions.unsubscribeProducts(orderId, productsWithSubscription));

  const handleRerunSubscribe = () => dispatch(customerActions.rerunSubscription(orderId, productDetailsRA?.id));

  const handleChange = (e, handleChangeValue) => {
    const { name, value } = e.target;

    const sanitizedValue = value.split("").reduce((str, char) => {
      if (char === "." && str.includes(char)) {
        return str;
      }

      return (str += char);
    }, "");

    handleChangeValue(sanitizedValue);
  };

  const handleKeyPress = (e) => {
    if (!/^([0-9]{1,})?(\.)?([0-9]{1,})?$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleBlur = (e, handleChangeValue) => {
    const { value } = e.target;

    const parsedValue = parseFloat(value);

    if (value === "." || value === "") {
      handleChangeValue("0.00");
      return;
    }

    if (!isNaN(parsedValue) && value !== parsedValue.toFixed(2)) {
      handleChangeValue(parsedValue.toFixed(2));
    }
  };

  const handleFocus = (e, handleChangeValue) => {
    const { value } = e.target;

    if (value === "0.00") {
      handleChangeValue("");
    }
  };

  const handleChargeAnnualReport = () => {
    if (isIncCategory || isIncStatesCategory || (chargeAnnualAmount && chargeAnnualAmount !== "0.00")) {
      if (productDetailsRA?.state === "TX" && !productDetailsRA?.webFileNumber) {
        setShowWebfileMissing(true);
      } else {
        dispatch(customerActions.chargeAnnualComplianceReport(orderId, chargeAnnualAmount));
      }
    } else {
      dispatch(alertActions.error("Amount value cannot be zero"));
    }
  };

  const handleChargeSalesTaxPermitStateFee = () => {
    if (chargeSalesTaxAmount && chargeSalesTaxAmount !== "0.00") {
      dispatch(customerActions.chargeSalesTaxPermitStateFee(orderId, chargeSalesTaxAmount));
    } else {
      dispatch(alertActions.error("Amount value cannot be zero"));
    }
  };

  const handleChargeTmAmendment = () => {
    const body = {
      order: { processingOption: "UPSELL" },
      products: [],
    };

    if (tmAmendmentType === TM_AMENDMENT_TYPES.intensive) {
      if (!chargeTmAmendmentAmount) {
        dispatch(alertActions.error("Invalid amount value"));
        return;
      }

      body.products.push({ code: { code: TM_AMENDMENT_TYPES.intensive } });
      body.products.push({
        code: {
          code: "TRADEMARK_Amendment_TrademarkIntensiveFee",
        },
        price: multiplyPriceToNumber(chargeTmAmendmentAmount, 1.2),
      });
      dispatch(
        customerActions.createDelayedUpsell({
          orderId,
          body,
          message: "Amendment was charged successfully (with 20% processing fee)",
        })
      );
      return;
    }

    if (tmAmendmentType === TM_AMENDMENT_TYPES.minor) {
      body.products.push({ code: { code: TM_AMENDMENT_TYPES.minor } });
      dispatch(customerActions.createDelayedUpsell({ orderId, body, message: "Amendment was charged successfully" }));
      return;
    }
  };

  const handleChargeSOUFee = () => {
    dispatch(customerActions.chargeSOUFee(orderId));
  };

  const handleChargeExtensionFee = () => {
    dispatch(customerActions.chargeExtensionFee(orderId));
  };

  const handleChargeOfficeAction = () => {
    dispatch(customerActions.chargeOfficeAction(orderId));
  };

  const handleChargeRevivalFromAbandoment = () => {
    dispatch(customerActions.chargeRevivalFromAbandoment(orderId));
    // if (numberOfClasses >= 0) {
    // dispatch(customerActions.chargeRevivalFromAbandoment(orderId, numberOfClasses));
    // } else {
    // dispatch(alertActions.error("Number of Classes value cannot be less then zero"));
    // }
  };

  const handleChargeBOI = () => {
    const body = {
      order: { processingOption: "UPSELL" },
      products: [{ code: { code: "" } }],
    };

    if (isEinInc) {
      body.products[0].code.code = "EIN_BOI";
    }

    if (isIncorpify) {
      body.products[0].code.code = "INC_BOI";
    }

    if (isIncStates) {
      body.products[0].code.code = "INC_States_BOI";
    }

    if (body?.products[0]?.code?.code) {
      dispatch(customerActions.createDelayedUpsell({ orderId, body, message: "BOI upsell was charged successfully" }));
    } else {
      dispatch(alertActions.error("Something went wrong with BOI Upsell..."));
    }
  };

  const handleUnlockAutomation = () => {
    dispatch(customerActions.unlockAutomationAction(orderId, productId));
  };

  useEffect(() => {
    setEINNumber(productDetails.processingResult.einnumber);
  }, [productDetails.processingResult.einnumber]);

  return (
    <div className="order-info form-border">
      <Col sm="12">
        {(isEIN || isIncEin) && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">PDF</span>

            <Form.Label column sm="4">
              EIN #:
            </Form.Label>
            {/* temporary fix for test passing */}
            <Col sm="8" style={{ display: "flex" }}>
              <InputMask
                className={clsx("form-control", "form-control-sm", {
                  "is-invalid":
                    (einNumber && !einPattern.test(einNumber)) ||
                    (!einNumber && selectedFile !== null) ||
                    (!einNumber && productDetails?.processingResult?.einnumber),
                  "is-valid": selectedFile && einNumber && einPattern.test(einNumber),
                })}
                mask="99-9999999"
                maskChar="_"
                name="einnumber"
                placeholder="ex: 12-3456789"
                value={einNumber || ""}
                onChange={handleEINNumberChange}
              />
              {(hasEinPdfFile || productDetails?.processingResult?.einNumber) && (
                <Button className="ml-2 mb-2" size="sm" onClick={handleSaveEinNumber}>
                  Save&nbsp;EIN&nbsp;#
                </Button>
              )}
            </Col>

            <Form.Label column sm="4">
              <div>Upload PDF:</div>
              <div style={{ color: "gray" }}>({extensionsEIN})</div>
            </Form.Label>
            <Col sm="8">
              <input ref={null} type="file" accept={extensionsEIN} onChange={handleFileChange} />
              <Button className="my-1" size="sm" onClick={handleFileUpload}>
                Upload EIN PDF
              </Button>
            </Col>

            <Form.Label column sm="4">
              EIN PDF:
            </Form.Label>
            <Col sm="8">
              {!hasEinPdfFile ? (
                <Form.Label column>There isn't PDF file</Form.Label>
              ) : (
                <div>
                  <Button className="mr-2" variant="primary" size="sm" onClick={handleDownloadPDF}>
                    Download
                  </Button>
                </div>
              )}
            </Col>

            <Form.Label column sm="4">
              Send EIN by Mail:
            </Form.Label>
            <Col sm="8">
              {!hasEinPdfFile ? (
                <Form.Label column>There isn't PDF file</Form.Label>
              ) : (
                <Button variant="primary" size="sm" onClick={handleSendHardCopy}>
                  Send
                </Button>
              )}
            </Col>
          </Form.Group>
        )}

        {(isLLCIncorporation || isBoi) && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Automation</span>
            <Col sm={6}>
              <Button
                className="my-1"
                size="sm"
                variant="danger"
                title={
                  productDetails?.automationLock ? "Product is locked, re-try automation" : "Product is not locked"
                }
                disabled={!productDetails?.automationLock}
                onClick={handleUnlockAutomation}
              >
                Re-try Automation
              </Button>
            </Col>
          </Form.Group>
        )}

        {isTrademark && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Membership</span>
            <Col sm={6}>
              <Button className="my-1" size="sm" onClick={handleLoginCustomerTrademark}>
                Login as Customer
              </Button>
            </Col>
          </Form.Group>
        )}

        {isAccounting && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Dashboard</span>
            <Col sm={6}>
              <Button className="my-1" size="sm" onClick={handleLoginCustomerAccounting}>
                Login as Customer
              </Button>
            </Col>
          </Form.Group>
        )}

        {isEinMain && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Membership</span>
            <Col sm={6}>
              <Button className="my-1" size="sm" onClick={handleLoginCustomerEin}>
                Login as Customer
              </Button>
            </Col>
          </Form.Group>
        )}

        {isTrademark && isLead && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Pay for Trademark Lead Order</span>
            <Col sm={6}>
              <Button
                className="my-1"
                size="sm"
                onClick={() => {
                  setShowPayForLead(true);
                }}
              >
                Pay
              </Button>
            </Col>
            <PayForLeadModal
              show={showPayForLead}
              orderDetails={orderDetails}
              handleClose={() => {
                setShowPayForLead(false);
              }}
              handleConfirm={(paymentDetails) => {
                setShowPayForLead(false);
                dispatch(uiActions.checkHash(hash, customerActions.setPaymentDetails(orderId, paymentDetails)));
              }}
            />
          </Form.Group>
        )}

        {isIncorpify && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Incorpify Membership</span>
            <Col sm={12}>
              <Button className="my-1 mr-2" size="sm" onClick={handleLoginCustomerIncorpify}>
                Login as Customer
              </Button>
              <Button
                className="my-1 mr-2"
                variant="info"
                size="sm"
                onClick={() => {
                  setShowDissolveModal(true);
                }}
              >
                Dissolve Order
              </Button>
            </Col>
            <ConfirmationModal
              show={showDissolveModal}
              body="Are you sure you want to dissolve this business? This will charge $49 fee + state fee."
              handleClose={() => {
                setShowDissolveModal(false);
              }}
              handleConfirm={() => {
                setShowDissolveModal(false);
                dispatch(uiActions.checkHash(hash, customerActions.dissolveOrder(orderId)));
              }}
            />
          </Form.Group>
        )}

        {isIncStates && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">State Filings Membership</span>
            <Col sm={12}>
              <Button className="my-1 mr-2" size="sm" onClick={handleLoginCustomerIncStates}>
                Login as Customer
              </Button>
              <Button
                className="my-1 mr-2"
                variant="info"
                size="sm"
                onClick={() => {
                  setShowDissolveModal(true);
                }}
              >
                Dissolve Order
              </Button>
            </Col>
            <ConfirmationModal
              show={showDissolveModal}
              body="Are you sure you want to dissolve this business? This will charge $49 fee + state fee."
              handleClose={() => {
                setShowDissolveModal(false);
              }}
              handleConfirm={() => {
                setShowDissolveModal(false);
                dispatch(uiActions.checkHash(hash, customerActions.dissolveOrder(orderId)));
              }}
            />
          </Form.Group>
        )}

        {!unsubscription && !!productsWithSubscription?.length && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Subscriptions</span>
            <Col sm="2" className="mt-2">
              <Button size="sm" onClick={handleUnsubscribe}>
                Cancel
              </Button>
            </Col>
            {productDetailsRA && productsWithSubscription.includes(productDetailsRA?.id) && (
              <Col sm="4" className="mt-2">
                <Button variant="info" size="sm" onClick={handleRerunSubscribe}>
                  Force Bill
                </Button>
              </Col>
            )}
          </Form.Group>
        )}

        {(isIncorpify || isIncStates || isEinInc) && !hasBOI && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Beneficial Ownership Information Report (BOI)</span>
            <Col sm="12" md="3" className="mt-2 align-self-end">
              <Button size="sm" onClick={handleChargeBOI}>
                Charge
              </Button>
            </Col>
          </Form.Group>
        )}

        {isSTP && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Sales Tax Permit State Fee</span>
            <Form.Label column sm="8">
              {orderDetails.order.automationLock &&
                moment.utc(orderDetails.order.automationLock).local().format("YYYY/MM/DD HH:mm:ss")}
            </Form.Label>
            <Col sm="12" md="6" className="d-flex pt-3">
              <Form.Control
                size="sm"
                className="mr-2"
                value={chargeSalesTaxAmount || ""}
                onKeyPress={handleKeyPress}
                onBlur={(e) => handleBlur(e, (value) => setChargeSalesTaxAmount(value))}
                onChange={(e) => handleChange(e, (value) => setChargeSalesTaxAmount(value))}
              />
              <Button size="sm" onClick={handleChargeSalesTaxPermitStateFee}>
                Charge
              </Button>
            </Col>
          </Form.Group>
        )}

        {(isAR || isEINRA) && !isEinIncCategory && orderDetails?.products?.length > 1 && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">
              {isIncCategory || isIncStatesCategory ? "Annual Report State Fee" : "Annual Compliance Report"}
            </span>
            {(orderDetails.order.automationLock || isEinIncCategory) && (
              <Form.Label column sm="8">
                {orderDetails.order.automationLock && (
                  <div>{moment.utc(orderDetails.order.automationLock).local().format("YYYY/MM/DD HH:mm:ss")}</div>
                )}
                {isEinIncCategory && <div>Manual State Fee Amount</div>}
              </Form.Label>
            )}
            <Col sm="12" md="6" className="d-flex pt-3">
              {!(isIncCategory || isIncStatesCategory) && (
                <Form.Control
                  size="sm"
                  className="mr-2"
                  value={chargeAnnualAmount || ""}
                  onKeyPress={handleKeyPress}
                  onBlur={(e) => handleBlur(e, (value) => setChargeAnnualAmount(value))}
                  onChange={(e) => handleChange(e, (value) => setChargeAnnualAmount(value))}
                />
              )}
              <Button size="sm" onClick={handleChargeAnnualReport}>
                Charge
              </Button>
            </Col>
            <WebFileMissingModal
              show={showWebfileMissing}
              handleClose={() => {
                setShowWebfileMissing(false);
              }}
              handleConfirm={() => {
                setShowWebfileMissing(false);
                const newObj = {
                  ...orderDetails,
                  products: [
                    ...orderDetails?.products.map((product, idx) => {
                      if (idx === selectedProduct) {
                        return {
                          ...product,
                          processingResult: { ...product?.processingResult, status: "MissingWebFile" },
                        };
                      }
                      return product;
                    }),
                  ],
                };
                dispatch(uiActions.checkHash(hash, customerActions.updateorder(orderId, newObj)));
              }}
            />
          </Form.Group>
        )}

        {isTrademarkRegister &&
          productDetails?.code?.code !== "INC_RegisterTrademark" &&
          productDetails?.code?.code !== "INC_States_RegisterTrademark" && (
            <>
              <Form.Group as={Row} className="sub-form">
                <span className="title">Statement Of Use</span>
                <Form.Label column sm="12">
                  Charge for Service & USPTO Fees Per Class
                </Form.Label>
                <Col sm="12">
                  <Button size="sm" onClick={handleChargeSOUFee}>
                    Charge
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="sub-form">
                <span className="title">Request for Extension</span>
                <Form.Label column sm="12">
                  Charge for Service & USPTO Fees Per Class
                </Form.Label>
                <Col sm="12">
                  <Button size="sm" onClick={handleChargeExtensionFee}>
                    Charge
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="sub-form">
                <span className="title">Office Action</span>
                <Form.Label column sm="12">
                  Charge for Service
                </Form.Label>
                <Col sm="12">
                  <Button size="sm" onClick={handleChargeOfficeAction}>
                    Charge
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="sub-form">
                <span className="title">Trademark Amendment</span>
                <Form.Label column sm="4" className="mt-2">
                  Amendment Type:
                </Form.Label>
                <Col sm="8" className="mt-2">
                  <Form.Control
                    as="select"
                    size="sm"
                    name="tmAmendmentType"
                    value={tmAmendmentType || ""}
                    onChange={(e) => {
                      setTmAmendmentType(e.target.value);
                    }}
                  >
                    <option value={TM_AMENDMENT_TYPES.minor}>Minor</option>
                    <option value={TM_AMENDMENT_TYPES.intensive}>Intensive</option>
                  </Form.Control>
                </Col>
                {tmAmendmentType === TM_AMENDMENT_TYPES.intensive && (
                  <>
                    <Form.Label column sm="4" className="mt-2">
                      Amount Legal Team Specified:
                    </Form.Label>
                    <Col sm="8" className="mt-2">
                      <div>
                        <Form.Control
                          size="sm"
                          name="chargeAmendmentAmount"
                          value={chargeTmAmendmentAmount}
                          onKeyPress={handleKeyPress}
                          onFocus={(e) => handleFocus(e, (value) => setChargeTmAmendmentAmount(value))}
                          onBlur={(e) => handleBlur(e, (value) => setChargeTmAmendmentAmount(value))}
                          onChange={(e) => handleChange(e, (value) => setChargeTmAmendmentAmount(value))}
                        />
                      </div>
                      <div style={{ color: "gray", fontSize: "0.825rem" }}>
                        With 20% processing fee:&nbsp;
                        {`$${multiplyPriceToNumber(chargeTmAmendmentAmount, 1.2).replace("NaN", "0.00")}`}
                      </div>
                    </Col>
                  </>
                )}
                <Col sm="12" className="mt-2">
                  <Button
                    size="sm"
                    disabled={tmAmendmentType === TM_AMENDMENT_TYPES.intensive && !chargeTmAmendmentAmount}
                    onClick={handleChargeTmAmendment}
                  >
                    Charge
                  </Button>
                </Col>
              </Form.Group>
            </>
          )}

        {["TRADEMARK_Trademark_Extension", "TRADEMARK_Trademark_Statement_Of_Use"].includes(
          productDetails?.code?.code
        ) && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Revival Service</span>
            {/* <Col sm="12" md="9" className="mt-2">
              <Form.Label>
                Number of Classes <span style={{ color: "gray" }}>(optional)</span>
              </Form.Label>
              <Form.Control
                size="sm"
                type="number"
                min={0}
                max={1000}
                value={numberOfClasses}
                onChange={({ target: { value } }) => {
                  if (value >= 0 && value <= 1000) {
                    setNumberOfClasses(Number(value));
                  }
                }}
              />
            </Col> */}
            <Col sm="12" md="3" className="mt-2 align-self-end">
              <Button size="sm" onClick={handleChargeRevivalFromAbandoment}>
                Charge
              </Button>
            </Col>
          </Form.Group>
        )}

        {(isEIN || isVITALNewBirthCertificate) && (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Automation Status</span>
            <Form.Label column sm="4">
              Automation triggered:
            </Form.Label>
            <Form.Label column sm="8">
              {getAutomationLockStatus()}
            </Form.Label>
            <Col sm="12">
              <Button size="sm" onClick={handleRetryAutomation}>
                Retry
              </Button>
            </Col>
          </Form.Group>
        )}

        {isVITALNewBirthCertificate ? (
          <Form.Group as={Row} className="sub-form">
            <span className="title">Tracking Info</span>
            <Tracking orderDetails={orderDetails} />
          </Form.Group>
        ) : null}

        <Form.Group as={Row} className="sub-form">
          <span className="title">Assignee</span>
          <Assignee
            isEditable={isEditable}
            orderDetails={orderDetails}
            selectedProduct={selectedProduct}
            onChange={onChange}
            onValidate={onValidate}
          />
        </Form.Group>

        <Form.Group as={Row} className="sub-form">
          <span className="title">Order</span>
          <Form.Label>
            <Col sm={12}>
              <Button className="my-1" size="sm" variant="danger" onClick={() => onDeleteOrder(true)}>
                Delete Order
              </Button>
            </Col>
          </Form.Label>
        </Form.Group>
      </Col>
    </div>
  );
}

export default OrderActions;
