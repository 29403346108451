import React from "react";
import { useSelector } from "react-redux";

import NavigationItem from "./NavigationItem";

const NavigationList = ({ routes, expandedRoutes, onExpandRoute }) => {
  const { authentication, customers } = useSelector((state) => state);

  return (
    <ul className="nav">
      {routes.map((route) => (
        <NavigationItem
          key={`${route.path}-${route.name}`}
          route={route}
          isExpanded={expandedRoutes[`${route.path}-${route.name}`]}
          role={authentication?.user?.role}
          loading={customers.loading || customers.exportLoading}
          onExpandRoute={onExpandRoute}
          availableCategories={authentication?.user?.availableCategories}
        />
      ))}
    </ul>
  );
};

export default NavigationList;
