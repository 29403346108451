import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { Dropdown } from "react-bootstrap";

import { usePrevious } from "_hooks";
import Loader from "views/Chats/Loader";

const StyledContainer = styled.div`
  .dropdown {
    a {
      svg {
        transform: rotate(0deg);
        transition: all 0.2s ease-out;
      }
    }

    &.show {
      a {
        svg {
          transform: rotate(90deg);
          transition: all 0.2s ease-out;
        }
      }
    }
  }
`;

const StyledFrontDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
  background-color: #ffffff;
  opacity: 0.7;
  z-index: 1;
`;

const StyledLoaderContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
  z-index: 2;
`;

const StyledLink = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: #641ec9 !important;
`;

const StyledMenuContainer = styled.div`
  position: relative;
  padding: 0;
  border: 1px solid #edeefd;
`;

const StyledUl = styled.ul`
  margin: 0;

  .dropdown-item {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px;

    &:hover {
      background-color: #edeefd;
    }

    &.active:hover {
      background-color: #edeefd;
    }

    &.active,
    &:focus {
      background-color: unset;
    }

    &:focus:hover {
      background-color: #edeefd;
    }
  }
`;

const StyledBullet = styled.div`
  height: 10px;
  width: 10px;
  border: 1px solid #606071;
  border-radius: 2px;

  ${({ active }) =>
    active &&
    css`
      border-color: #641ec9;
      background-color: #641ec9;
    `}
`;

const StyledPriorityBullet = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 2px;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

const StyledLabel = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #606071;
`;

const ToggleIcon = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7062 6.80007C13.3729 7.18497 13.3729 8.14722 12.7062 8.53212L1.70535 14.8835C1.03868 15.2684 0.205345 14.7872 0.205345 14.0174V1.31474C0.205345 0.54494 1.03868 0.0638155 1.70535 0.448716L12.7062 6.80007Z"
      fill="#641EC9"
    />
  </svg>
);

const CustomToggle = React.forwardRef(({ show, children, onClick }, ref) => (
  <StyledLink
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &nbsp;&nbsp;
    <ToggleIcon />
  </StyledLink>
));

const CustomMenu = React.forwardRef(({ loading, children, style, className, "aria-labelledby": labeledBy }, ref) => {
  return (
    <StyledMenuContainer ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      {loading && (
        <>
          <StyledFrontDrop />
          <StyledLoaderContainer>
            <Loader />
          </StyledLoaderContainer>
        </>
      )}
      <StyledUl className="list-unstyled">{children}</StyledUl>
    </StyledMenuContainer>
  );
});

export default function PriorityDropdown({ loading, activeValue, items, onSelect }) {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [innerValue, setInnerValue] = useState(null);
  const prevActiveValue = usePrevious(activeValue);

  const handleSelect = (value) => {
    setInnerValue(value);
    onSelect && onSelect(value);
  };

  const handleClickOutsideMenu = (e) => {
    if (ref?.current && !ref?.current?.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (prevActiveValue !== activeValue) {
      setInnerValue(activeValue);
    }
  }, [prevActiveValue, activeValue]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideMenu, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideMenu, true);
    };
  }, []);

  return (
    <StyledContainer>
      <Dropdown ref={ref} show={show} onSelect={handleSelect}>
        <Dropdown.Toggle
          as={CustomToggle}
          show={show}
          onClick={() => {
            setShow((prev) => !prev);
          }}
        >
          Change Priority
        </Dropdown.Toggle>
        <Dropdown.Menu loading={loading} as={CustomMenu}>
          {items?.map(({ value, label, color }) => (
            <Dropdown.Item disabled={loading} eventKey={value}>
              <StyledBullet active={value === innerValue} />
              <StyledPriorityBullet color={color} />
              <StyledLabel>{label}</StyledLabel>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledContainer>
  );
}
