import { Row, Form } from "react-bootstrap";
import moment from "moment-timezone";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { includedName } from "constants/trademarks.constants";

import CoOwnerListView from "./CoOwnerList/View";
import IndividualView from "./Individual/View";
import OrganizationView from "./Organization/View";
import TrademarkClassesListView from "./TrademarkClassesList/View";

import StatusViewTrademark from "../Status/ViewTrademark";

export default function FormTrademarkRegister({
  orderDetails,
  availableProcessingStatus,
  selectedProduct,
  processingErrorsOpt,
  productsProcessingStatusesOpt,
  onUpdateOrderStatus,
  onChange,
}) {
  const orderId = orderDetails.order.id;
  const productDetails = orderDetails.products[selectedProduct];
  const isOrganization = !!productDetails.organization;

  const isLogo = productDetails.trademarkType === "LOGO";

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">REGISTER TRADEMARK</h4>

      <StatusViewTrademark
        orderDetails={orderDetails}
        availableProcessingStatus={availableProcessingStatus}
        selectedProduct={selectedProduct}
        processingErrorsOpt={processingErrorsOpt}
        productsProcessingStatusesOpt={productsProcessingStatusesOpt}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Processing Result</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.processingResult.serialNumber}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Registration number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.processingResult.registrationNumber}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Type:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.trademarkType}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Description:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.markDesc}
        </Form.Label>
      </Form.Group>

      {isLogo && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Do you want to be able to protect your logo in a variety of colors?
            </Form.Label>
            <Form.Label column sm={7} disabled={!isLogo}>
              {isLogo ? (productDetails.logoColoredVariety ? "Yes" : "No") : "—"}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Colors list:
            </Form.Label>
            <Form.Label column sm={7}>
              {isLogo && productDetails.logoColoredVariety ? productDetails.colorsInLogo : "—"}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Logo:
            </Form.Label>
            <Form.Label column sm={7} disabled={!isLogo}>
              {isLogo ? productDetails.literalElements : "—"}
            </Form.Label>
          </Form.Group>
        </>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Include Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {includedName[productDetails.includedName]}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.includedName !== "NONE" ? productDetails.includedNameValue : "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Does your mark include any words other than English or non-Latin characters?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.includeTranslationWords ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          English translation:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.includeTranslationWords ? productDetails.translation : "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Are you currently using this trademark in your business?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.currentTrademark ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First use anywhere:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.currentTrademark
            ? productDetails.firstUseAnywhere && moment.utc(productDetails.firstUseAnywhere).format("YYYY/MM/DD")
            : "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First use in commerce:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.currentTrademark
            ? productDetails.firstUseInCommerce && moment.utc(productDetails.firstUseInCommerce).format("YYYY/MM/DD")
            : "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Website:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.website}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Specimen Description:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.specimenDescription}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">{isOrganization ? "Organization" : "Individual"} Information</h6>

      {isOrganization && <OrganizationView organization={productDetails.organization} />}
      <IndividualView individual={productDetails.individual} isOrganization={isOrganization} />

      <CoOwnerListView hidden={isOrganization} coOwners={productDetails.coOwners} />

      <TrademarkClassesListView label="Trademark Classes" classes={productDetails.classes} />
    </div>
  );
}
