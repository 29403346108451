import React, { useEffect, useState, useMemo } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import cloneDeep from "lodash.clonedeep";
import clsx from "clsx";
import InputMask from "react-input-mask";

import { phoneNumRegexp, phoneCleaner, handleKey } from "_helpers";
import {
  state_array,
  TAB_CODES,
  TAX_REPORT_STATUS,
  TAX_REPORT_STATUS_TITLES,
  RELATIONSHIP_OBJ,
} from "variables/Variables";

const personalTaxReportStatuses = [
  TAX_REPORT_STATUS.previousYearReturnUploaded,
  TAX_REPORT_STATUS.personalInformationConfirmed,
  TAX_REPORT_STATUS.bookkeeperReview,
  TAX_REPORT_STATUS.documentsUploaded,
  TAX_REPORT_STATUS.taxPreparerReview,
  TAX_REPORT_STATUS.sentForSignature,
  TAX_REPORT_STATUS.filed,
];

import DateEdit from "components/OrderDetails/ProductDetails/Incorporation/Date/Edit";
import StatusEdit from "../../Status/Edit";

const marriedFilingSeparatelyValues = {
  separately: true,
  jointly: false,
};

const filingSingleValues = {
  household: false,
  single: true,
};

function getMarriedFilingSeparatelyOptionValue(value) {
  if (value) {
    return "separately";
  }

  return "jointly";
}

function getFilingSingleOptionValue(value) {
  if (value) {
    return "single";
  }

  return "household";
}

const initialPersonInfo = {
  relationship: null,
  firstName: null,
  middleName: null,
  lastName: null,
  suffixName: null,
  countryOfCitizenship: null,
  email: null,
  phone: null,
  address: {
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    country: "US",
    county: null,
    permanent: false,
  },
  ssn: null,
  dateOfBirth: null,
};

function FormPersonalTaxFiling({ orderDetails, selectedProduct, onError, onChange, ...props }) {
  const [dependentsCollapsed, setDependentsCollapsed] = useState(true);
  const productDetails = orderDetails?.products[selectedProduct];
  const taxReports = productDetails?.taxReports || {};
  const tags = Object.keys(taxReports);
  const sortedTags = useMemo(() => [...tags].sort((a, b) => b - a), [tags]);
  const [currentTag, setCurrentTag] = useState(null);
  const currentReport = taxReports[currentTag] || {};
  const taxReturnStatus = currentReport?.status;
  const individualInfo = currentReport?.individual || {};
  const dependents = currentReport?.dependents || [];
  const spouse = currentReport?.spouse;
  const filingSingle = currentReport?.filingSingle;
  const marriedFilingSeparately = currentReport?.marriedFilingSeparately;

  const handleAddError = (field) => onError(TAB_CODES.defaultInfo, field);

  const handleChange = ({ target: { name, value } }) => {
    const parsedValue = name?.includes("phone") ? phoneCleaner(value) : value;

    const newObj = {
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        if (idx === selectedProduct) {
          if (name === "filingSingle") {
            return {
              ...product,
              taxReports: {
                ...taxReports,
                [currentTag]: {
                  ...currentReport,
                  [name]: spouse === null && !!dependents?.length ? filingSingleValues[value] : null,
                },
              },
            };
          }

          if (name === "marriedFilingSeparately") {
            return {
              ...product,
              taxReports: {
                ...taxReports,
                [currentTag]: {
                  ...currentReport,
                  [name]: spouse !== null ? marriedFilingSeparatelyValues[value] : null,
                },
              },
            };
          }

          if (name?.includes("personal")) {
            const actualName = name?.includes("address")
              ? name.replace("personal-address-", "")
              : name.replace("personal-", "");

            return {
              ...product,
              taxReports: {
                ...taxReports,
                [currentTag]: {
                  ...currentReport,
                  individual: name?.includes("address")
                    ? {
                        ...currentReport.individual,
                        address: {
                          ...currentReport.individual.address,
                          [actualName]: parsedValue === "" ? null : parsedValue,
                        },
                      }
                    : { ...currentReport.individual, [actualName]: parsedValue === "" ? null : parsedValue },
                },
              },
            };
          }

          if (name?.includes("spouse")) {
            const actualName = name?.includes("address")
              ? name.replace("spouse-address-", "")
              : name.replace("spouse-", "");

            return {
              ...product,
              taxReports: {
                ...taxReports,
                [currentTag]: {
                  ...currentReport,
                  spouse: name?.includes("address")
                    ? {
                        ...currentReport.spouse,
                        address: {
                          ...currentReport.spouse.address,
                          [actualName]: parsedValue === "" ? null : parsedValue,
                        },
                      }
                    : { ...currentReport.spouse, [actualName]: parsedValue === "" ? null : parsedValue },
                },
              },
            };
          }

          return {
            ...product,
            taxReports: {
              ...taxReports,
              [currentTag]: { ...currentReport, [name]: parsedValue === "" ? null : parsedValue },
            },
          };
        }

        return product;
      }),
    };

    onChange(newObj);
  };

  const handleDateChange = (name) => (value) => {
    handleChange({ target: { name, value } });
  };

  const handleHasSpouseChange = ({ target: { value } }) => {
    const newObj = {
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        if (idx === selectedProduct) {
          return {
            ...product,
            taxReports: {
              ...taxReports,
              [currentTag]: { ...currentReport, spouse: value === "yes" ? { ...initialPersonInfo } : null },
            },
          };
        }

        return product;
      }),
    };

    onChange(newObj);
  };

  const handleDependentDelete = (dependentIdx) => {
    const newObj = {
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        if (idx === selectedProduct) {
          return {
            ...product,
            taxReports: {
              ...taxReports,
              [currentTag]: {
                ...currentReport,
                dependents: currentReport.dependents.filter((_, idx) => idx !== dependentIdx),
              },
            },
          };
        }

        return product;
      }),
    };

    onChange(newObj);
  };

  const handleDependentAdd = (newDependent) => {
    const newObj = {
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        if (idx === selectedProduct) {
          return {
            ...product,
            taxReports: {
              ...taxReports,
              [currentTag]: {
                ...currentReport,
                dependents: [...currentReport.dependents, newDependent],
              },
            },
          };
        }

        return product;
      }),
    };

    onChange(newObj);
  };

  const handleDependentChange =
    (dependentIdx) =>
    ({ target: { name, value } }) => {
      const parsedValue = name?.includes("phone") ? phoneCleaner(value) : value;

      const newObj = {
        ...orderDetails,
        products: orderDetails.products.map((product, idx) => {
          if (idx === selectedProduct) {
            const actualName = name?.includes("address")
              ? name.replace(`dependent${dependentIdx}-address-`, "")
              : name.replace(`dependent${dependentIdx}-`, "");

            const newDependents = cloneDeep(currentReport.dependents);
            const dependent = { ...newDependents[dependentIdx] };
            newDependents[dependentIdx] = name?.includes("address")
              ? {
                  ...dependent,
                  address: { ...dependent.address, [actualName]: parsedValue === "" ? null : parsedValue },
                }
              : { ...dependent, [actualName]: parsedValue === "" ? null : parsedValue };

            return {
              ...product,
              taxReports: {
                ...taxReports,
                [currentTag]: {
                  ...currentReport,
                  dependents: newDependents,
                },
              },
            };
          }

          return product;
        }),
      };

      onChange(newObj);
    };

  const handleDependentDateChange = (name, dependentIdx) => (value) => {
    handleDependentChange(dependentIdx)({ target: { name, value } });
  };

  const handleCurrentTagChange = ({ target: { value } }) => {
    setCurrentTag(value);
  };

  useEffect(() => {
    if (!currentTag && setCurrentTag?.length) {
      setCurrentTag(sortedTags[0]);
    }
  }, [sortedTags, currentTag]);

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">
        {productDetails?.code?.customerName} - {currentTag}
      </h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <hr />
      <h6 className="font-weight-bold">Tax Year</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Tax Year:
        </Form.Label>
        <Col sm={7}>
          <Form.Control as="select" size="sm" value={currentTag || ""} onChange={handleCurrentTagChange}>
            <option value="">Select...</option>
            {sortedTags.map((tag) => (
              <option key={tag} value={tag}>
                {tag}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Tax Return Status</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Tax Return Status:
        </Form.Label>
        <Col sm={7}>
          <Form.Control as="select" size="sm" name="status" value={taxReturnStatus || ""} onChange={handleChange}>
            <option value="">Please select</option>
            {personalTaxReportStatuses.map((value, key) => (
              <option key={key} value={value}>
                {TAX_REPORT_STATUS_TITLES[value]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Personal Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="personal-firstName"
            value={individualInfo?.firstName || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal First Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="personal-lastName"
            value={individualInfo?.lastName || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal Last Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className="form-control form-control-sm"
            mask="999-99-9999"
            maskChar=" "
            name="personal-ssn"
            placeholder="999-99-9999"
            pattern="^\d{3}-\d{2}-\d{4}$"
            value={individualInfo?.ssn || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal Social Security Number")}
          />
        </Col>
      </Form.Group>

      <DateEdit
        label="Birthday:"
        date={individualInfo?.dateOfBirth}
        onChange={handleDateChange("personal-dateOfBirth")}
      />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone:
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className={clsx("form-control", "form-control-sm", {
              "is-invalid": individualInfo?.phone && !phoneNumRegexp.test(individualInfo?.phone),
            })}
            mask="(999) 999-9999"
            maskChar=" "
            name="personal-phone"
            placeholder="(999) 999-9999"
            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
            value={individualInfo?.phone || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal Phone")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          E-mail:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="personal-email"
            value={individualInfo?.email || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal E-mail")}
          />
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold mt-4">Personal Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address1:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="personal-address-address1"
            pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
            maxLength={30}
            value={individualInfo?.address?.address1 || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal Address")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="personal-address-city"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            value={individualInfo?.address?.city || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal City")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            as="select"
            name="personal-address-state"
            value={individualInfo?.address?.state || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="personal-address-zip"
            pattern="^\d{5}$"
            maxLength={5}
            value={individualInfo?.address?.zip || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal Zip")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="personal-address-county"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            value={individualInfo?.address?.county || ""}
            onChange={handleChange}
            onInvalid={() => handleAddError("Personal County")}
          />
        </Col>
      </Form.Group>

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Have a spouse?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="spouse_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="spouse"
            value="yes"
            defaultChecked={!!spouse}
            onChange={handleHasSpouseChange}
          />
          <Form.Check
            id="spouse_no"
            type="radio"
            label="No"
            name="spouse"
            value="no"
            defaultChecked={!spouse}
            onChange={handleHasSpouseChange}
          />
        </Col>
      </Form.Group>

      {spouse && (
        <>
          <hr />
          <h6 className="font-weight-bold mt-4">Spouse Information</h6>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              First Name:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="spouse-firstName"
                value={spouse?.firstName || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse First Name")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Last Name:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="spouse-lastName"
                value={spouse?.lastName || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse Last Name")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Social Security Number:
            </Form.Label>
            <Col sm={7}>
              <InputMask
                className="form-control form-control-sm"
                mask="999-99-9999"
                maskChar=" "
                name="spouse-ssn"
                placeholder="999-99-9999"
                pattern="^\d{3}-\d{2}-\d{4}$"
                value={spouse?.ssn || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse Social Security Number")}
              />
            </Col>
          </Form.Group>

          <DateEdit label="Birthday:" date={spouse?.dateOfBirth} onChange={handleDateChange("spouse-dateOfBirth")} />

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Phone:
            </Form.Label>
            <Col sm={7}>
              <InputMask
                className={clsx("form-control", "form-control-sm", {
                  "is-invalid": spouse?.phone && !phoneNumRegexp.test(spouse?.phone),
                })}
                mask="(999) 999-9999"
                maskChar=" "
                name="spouse-phone"
                placeholder="(999) 999-9999"
                pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                value={spouse.phone || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse Phone")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              E-mail:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="spouse-email"
                value={spouse?.email || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse E-mail")}
              />
            </Col>
          </Form.Group>

          <hr />
          <h6 className="font-weight-bold mt-4">Spouse Address</h6>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Address1:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="spouse-address-address1"
                pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
                maxLength={30}
                value={spouse?.address?.address1 || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse Address")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              City:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="spouse-address-city"
                pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
                maxLength={30}
                value={spouse?.address?.city || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse City")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              State:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                as="select"
                name="spouse-address-state"
                value={spouse?.address?.state || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse State")}
              >
                <option value="">Select a state</option>
                {Object.keys(state_array).map((key) => (
                  <option key={key} value={key}>
                    {state_array[key]}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Zip Code:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="spouse-address-zip"
                pattern="^\d{5}$"
                maxLength={5}
                value={spouse?.address?.zip || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse Zip")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              County:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="spouse-address-county"
                pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
                maxLength={30}
                value={spouse?.address?.county || ""}
                onChange={handleChange}
                onInvalid={() => handleAddError("Spouse County")}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5} style={{ cursor: "pointer" }} onClick={() => setDependentsCollapsed((prev) => !prev)}>
          <i className={`fa fa-caret-${dependentsCollapsed ? "down" : "up"}`} />
          <span className="font-weight-bold mt-4">Dependents:</span>
        </Form.Label>
        <Form.Label column sm={3}>
          <span className="font-weight-bold mt-4">{dependents?.length}</span>
        </Form.Label>
        <Col sm={4} className="d-flex justify-content-end align-items-center">
          <Button
            size="sm"
            type="button"
            className="ml-2 py-0 pull-right"
            onClick={(e) => {
              e.stopPropagation();
              handleDependentAdd({ ...initialPersonInfo });
            }}
          >
            + Add Dependent
          </Button>
        </Col>
      </Form.Group>

      {!dependentsCollapsed &&
        dependents.map((dependent, index) => (
          <React.Fragment key={index}>
            <hr />
            <h6 className="font-weight-bold mt-4">Dependent#{index + 1} Information</h6>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Relationship to You:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  as="select"
                  name={`dependent${index}-relationship`}
                  value={dependent?.relationship || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} Relationship`)}
                >
                  <option value="">Select Relationship</option>
                  {Object.keys(RELATIONSHIP_OBJ).map((key) => (
                    <option key={key} value={key}>
                      {RELATIONSHIP_OBJ[key]}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                First Name:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  name={`dependent${index}-firstName`}
                  value={dependent?.firstName || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} First Name`)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Last Name:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  name={`dependent${index}-lastName`}
                  value={dependent?.lastName || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} Last Name`)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Social Security Number:
              </Form.Label>
              <Col sm={7}>
                <InputMask
                  className="form-control form-control-sm"
                  mask="999-99-9999"
                  maskChar=" "
                  name={`dependent${index}-ssn`}
                  placeholder="999-99-9999"
                  pattern="^\d{3}-\d{2}-\d{4}$"
                  value={dependent?.ssn || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} Social Security Number`)}
                />
              </Col>
            </Form.Group>

            <DateEdit
              label="Birthday:"
              date={dependent?.dateOfBirth}
              onChange={handleDependentDateChange(`dependent${index}-dateOfBirth`, index)}
            />

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Phone:
              </Form.Label>
              <Col sm={7}>
                <InputMask
                  className={clsx("form-control", "form-control-sm", {
                    "is-invalid": dependent?.phone && !phoneNumRegexp.test(dependent?.phone),
                  })}
                  mask="(999) 999-9999"
                  maskChar=" "
                  name={`dependent${index}-phone`}
                  placeholder="(999) 999-9999"
                  pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                  value={dependent.phone || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} Phone`)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                E-mail:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name={`dependent${index}-email`}
                  value={dependent?.email || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} E-mail`)}
                />
              </Col>
            </Form.Group>

            <hr />
            <h6 className="font-weight-bold mt-4">Dependent#{index + 1} Address</h6>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Address1:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  name={`dependent${index}-address-address1`}
                  pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
                  maxLength={30}
                  value={dependent?.address?.address1 || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} Address`)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                City:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  name={`dependent${index}-address-city`}
                  pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
                  maxLength={30}
                  value={dependent?.address?.city || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} City`)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                State:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  as="select"
                  name={`dependent${index}-address-state`}
                  value={dependent?.address?.state || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} State`)}
                >
                  <option value="">Select a state</option>
                  {Object.keys(state_array).map((key) => (
                    <option key={key} value={key}>
                      {state_array[key]}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Zip Code:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  name={`dependent${index}-address-zip`}
                  pattern="^\d{5}$"
                  maxLength={5}
                  value={dependent?.address?.zip || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} Zip`)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                County:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name={`dependent${index}-address-county`}
                  pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
                  maxLength={30}
                  value={dependent?.address?.county || ""}
                  onChange={handleDependentChange(index)}
                  onInvalid={() => handleAddError(`Dependent#${index} County`)}
                />
              </Col>
            </Form.Group>

            <hr />

            <Row>
              <Col xs={6} />
              <Col sm={6} className="d-flex justify-content-end align-items-center">
                <Button
                  size="sm"
                  type="button"
                  variant="danger"
                  className="ml-2 py-0 pull-right"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDependentDelete(index);
                  }}
                >
                  - Remove Dependent#{index + 1}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        ))}

      <hr />

      {!spouse && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Filing Status:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              required
              size="sm"
              as="select"
              name="filingSingle"
              value={getFilingSingleOptionValue(filingSingle) || ""}
              onChange={handleChange}
              onInvalid={() => handleAddError("Filing Status")}
            >
              {/* <option value="">Select Filing Status</option> */}
              <option value="household">Head of Household</option>
              <option value="single">Filing Single</option>
            </Form.Control>
          </Col>
        </Form.Group>
      )}

      {spouse && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Filing Status:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              required
              size="sm"
              as="select"
              name="marriedFilingSeparately"
              value={getMarriedFilingSeparatelyOptionValue(marriedFilingSeparately) || ""}
              onChange={handleChange}
              onInvalid={() => handleAddError("Filing Status")}
            >
              {/* <option value="">Select Filing Status</option> */}
              <option value="jointly">Married Filing Jointly</option>
              <option value="separately">Married Filing Separately</option>
            </Form.Control>
          </Col>
        </Form.Group>
      )}

      {/* <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Filing Single?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center" style={{ gap: 16 }}>
          <Form.Check
            id="filingSingle_single"
            className="mr-3"
            type="radio"
            label="Single"
            name="filingSingle"
            value="single"
            defaultChecked={filingSingle === null ? false : filingSingle}
            onChange={handleChange}
          />
          <Form.Check
            id="filingSingle_household"
            type="radio"
            label="Household"
            name="filingSingle"
            value="household"
            defaultChecked={filingSingle === null ? false : !filingSingle}
            onChange={handleChange}
          />
          <Form.Check
            id="filingSingle_unset"
            type="radio"
            label="Unset"
            name="filingSingle"
            value="unset"
            defaultChecked={filingSingle === null}
            onChange={handleChange}
          />
        </Col>
      </Form.Group> */}

      {/* <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Married Filing Separately?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center" style={{ gap: 16 }}>
          <Form.Check
            id="marriedFilingSeparately_single"
            className="mr-3"
            type="radio"
            label="Jointly"
            name="marriedFilingSeparately"
            value="jointly"
            defaultChecked={marriedFilingSeparately === null ? false : !marriedFilingSeparately}
            onChange={handleChange}
          />
          <Form.Check
            id="marriedFilingSeparately_household"
            type="radio"
            label="Separately"
            name="marriedFilingSeparately"
            value="separately"
            defaultChecked={marriedFilingSeparately === null ? false : marriedFilingSeparately}
            onChange={handleChange}
          />
          <Form.Check
            id="marriedFilingSeparately_unset"
            type="radio"
            label="Unset"
            name="marriedFilingSeparately"
            value="unset"
            defaultChecked={marriedFilingSeparately === null}
            onChange={handleChange}
          />
        </Col>
      </Form.Group> */}
    </div>
  );
}

export default FormPersonalTaxFiling;
