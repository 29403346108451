import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import clsx from "clsx";

import { phoneFormatter } from "_helpers";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import ActivityIncView from "components/OrderDetails/ProductDetails/Incorporation/Activity/View";
import DateView from "components/OrderDetails/ProductDetails/Incorporation/Date/View";

import StatusView from "../Status/View";

import { state_array } from "variables/Variables";

const annualGrossIncomeMap = {
  MV_0_20000: "$0 - $20,000",
  MV_20000_MAX: "Over $20,000",
};

const retailProductMap = {
  ALCOHOL: "Alcoholic beverages",
  TOBACCO: "Tobacco products",
  MOTOR_FUEL: "Motor fuel",
  SOFT_DRINKS: "Soft drinks",
  NONE: "None of the above",
};

const businessActivityMap = {
  HEALTH_CARE: "Health care",
  NATURAL_GAS: "Natural gas",
  NATURAL_RESOURCES: "Natural resources",
  PUBLIC_UTILITIES: "Public utilities",
  TELECOMMUNICATION: "Telecommunications",
  TRANSPORTATION: "Transportation",
  NONE: "None of the above",
};

const taxableEndYearMonthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const quarterMap = {
  1: "Q1: January – March",
  2: "Q2: April – June",
  3: "Q3: July – September",
  4: "Q4: October – December",
};

const parseBooleanValue = (value) => {
  if (value === undefined || value === null) {
    return "—";
  }

  return value ? "Yes" : "No";
};

function FormLLCInc({ orderDetails, selectedProduct, onUpdateOrderStatus, onChange }) {
  const [membersCollapsed, setMembersCollapsed] = useState(true);

  const productDetails = orderDetails?.products[selectedProduct];
  const hasOwners = productDetails?.members?.some(({ firstName }) => !!firstName);

  const getManagedBy = () => {
    if (!productDetails?.managedByManagers && !productDetails?.managedByMembers) return "";
    if (productDetails?.managedByManagers && productDetails?.managedByMembers) return "Members & Managers";
    if (productDetails?.members?.some(({ firstName }) => !!firstName)) return "Owners";
    return productDetails?.managedByMembers ? "Members" : "Managers";
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">LLC Inc Corporation</h4>

      <StatusView
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onUpdateOrderStatus={onUpdateOrderStatus}
        onChange={onChange}
      />

      <FormPDF productDetails={productDetails} />

      <hr />

      <DateView label="Incorporated Date:" date={productDetails?.incorporatedDate} />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Limited Liability Company Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.companyName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Alternate Company Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.altCompanyName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Designator:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.designator || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organized State:
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[productDetails?.organizedState] || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Was BOI offer declined by customer?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.boiWasDeclinedByCustomer ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Was Membership blocked for customer?
        </Form.Label>
        <Form.Label column sm={7} className={clsx({ "text-danger": productDetails?.membershipBlocked })}>
          {productDetails?.membershipBlocked ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Managed By:
        </Form.Label>
        <Form.Label column sm={7}>
          {getManagedBy() || "N/A"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5} style={{ cursor: "pointer" }} onClick={() => setMembersCollapsed(!membersCollapsed)}>
          <i className={`fa fa-caret-${membersCollapsed ? "down" : "up"}`} />
          {!getManagedBy() ? "Members" : getManagedBy()}:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.members?.length}
        </Form.Label>

        {!membersCollapsed && (
          <Form.Label column sm={12}>
            {productDetails.members.map((member, idx) => (
              <div
                key={`${idx}-${hasOwners ? member.firstName : member.name}`}
                className="d-flex mb-2 p-1"
                style={{ border: "1px solid gray", backgroundColor: "#fafafa", borderRadius: "8px" }}
              >
                <div style={{ flex: "1", color: "gray" }}>#{idx + 1}</div>
                <div style={{ flex: "11" }}>
                  {hasOwners
                    ? `${member.firstName || ""} ${member.middleName || ""} ${member.lastName || ""}`
                    : member.name}
                </div>
              </div>
            ))}
          </Form.Label>
        )}
      </Form.Group>

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.ssn || "N/A"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Primary Activity</h6>
      <ActivityIncView productDetails={productDetails} />

      {productDetails?.organizedState === "WV" && (
        <>
          <hr />
          <h6 className="font-weight-bold">West Virginia Questions</h6>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              What is your estimate of the annual gross income from this business location?
            </Form.Label>
            <Form.Label column sm={7}>
              {annualGrossIncomeMap[productDetails?.wvFormationQuestions?.annualGrossIncome] || "—"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Counting all business income for the previous year at any location, has this business grossed over $4,000?
            </Form.Label>
            <Form.Label column sm={7}>
              {parseBooleanValue(productDetails?.wvFormationQuestions?.grossOver4000)}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Does this business have a location in WV from which you will make retail sales or do any service
              maintenance work?
            </Form.Label>
            <Form.Label column sm={7}>
              {parseBooleanValue(productDetails?.wvFormationQuestions?.locationInWV)}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Will this business sell at either wholesale or retail, or consume for business operations any of the
              following products?
            </Form.Label>
            <Form.Label column sm={7}>
              {retailProductMap[productDetails?.wvFormationQuestions?.retailProduct] || "—"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Do any of these categories relate to the activity of your business?
            </Form.Label>
            <Form.Label column sm={7}>
              {businessActivityMap[productDetails?.wvFormationQuestions?.businessActivity] || "—"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              What is your taxable year-end for federal tax purposes?
            </Form.Label>
            <Form.Label column sm={7}>
              {taxableEndYearMonthMap[productDetails?.wvFormationQuestions?.taxableEndYearMonth] || "—"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Do you have employees?
            </Form.Label>
            <Form.Label column sm={7}>
              {parseBooleanValue(productDetails?.wvFormationQuestions?.haveEmployees)}
            </Form.Label>
          </Form.Group>

          {productDetails?.wvFormationQuestions?.haveEmployees && (
            <>
              <hr />
              <h6 className="font-weight-bold">Employee Questions</h6>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  How many employees do you have working in WV?
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails?.wvFormationQuestions?.countOfEmployees || "—"}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Will you pay wages to employees performing services in WV?
                </Form.Label>
                <Form.Label column sm={7}>
                  {parseBooleanValue(productDetails?.wvFormationQuestions?.payWagesInWV)}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Will you have only casual employees—meaning three or fewer workers working not more than 10 days in
                  any quarter?
                </Form.Label>
                <Form.Label column sm={7}>
                  {parseBooleanValue(productDetails?.wvFormationQuestions?.onlyCasualEmployees)}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  What date did the first employee start working in WV?
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails?.wvFormationQuestions?.firstEmployeeStartWork || "—"}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Enter the date that the first employee was or will be paid:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails?.wvFormationQuestions?.firstEmployeeWasPaid || "—"}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  What is the estimated gross wages paid in WV in the first calendar quarter of operations?
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails?.wvFormationQuestions?.grossWagesInWVFirstQuarter || "—"}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Do you have or expect to have soon a quarterly payroll of $1,500 in WV?
                </Form.Label>
                <Form.Label column sm={7}>
                  {parseBooleanValue(productDetails?.wvFormationQuestions?.willPayrollBe1500)}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Will you employ at least one WV worker in 20 different calendar weeks during a calendar year?
                </Form.Label>
                <Form.Label column sm={7}>
                  {parseBooleanValue(productDetails?.wvFormationQuestions?.willEmployWVWorkersIn20Weeks)}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Will you withhold more than $250 of WV income tax per month?
                </Form.Label>
                <Form.Label column sm={7}>
                  {parseBooleanValue(productDetails?.wvFormationQuestions?.willWithholdMoreThan250Tax)}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  In what earliest quarter and year will the quarterly payroll reach or exceed $1,500?
                </Form.Label>
                <Col sm={7} />
                <Form.Label column sm={5}>
                  Quarter:
                </Form.Label>
                <Form.Label column sm={7}>
                  {quarterMap[productDetails?.wvFormationQuestions?.earliestQuarterPayroll1500] || "—"}
                </Form.Label>
                <Form.Label column sm={5}>
                  Year:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails?.wvFormationQuestions?.earliestYearPayroll1500 || "—"}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  In what earliest quarter and year will at least one worker be employed in 20 different calendar weeks
                  during a calendar year?
                </Form.Label>
                <Col sm={7} />
                <Form.Label column sm={5}>
                  Quarter:
                </Form.Label>
                <Form.Label column sm={7}>
                  {quarterMap[productDetails?.wvFormationQuestions?.earliestQuarterWorkerEmployedIn20Weeks] || "—"}
                </Form.Label>
                <Form.Label column sm={5}>
                  Year:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails?.wvFormationQuestions?.earliestYearWorkerEmployedIn20Weeks || "—"}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Is this business subject to federal unemployment tax?
                </Form.Label>
                <Form.Label column sm={7}>
                  {parseBooleanValue(productDetails?.wvFormationQuestions?.businessSubjectToFederalTax)}
                </Form.Label>
              </Form.Group>
            </>
          )}
        </>
      )}

      <hr />
      <h6 className="font-weight-bold">General Questions</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is this your first LLC?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.questionsFirstLLC ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Will you have employees?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.questionsHaveEmployee ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Have you started doing business?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.questionsStartedDoingBusiness ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Will you accept Credit Card Payments?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.acceptCreditCardPayments ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold mt-4">Primary Officer</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.firstName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.middleName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.lastName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.suffixName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone:
        </Form.Label>
        <Form.Label column sm={7}>
          {phoneFormatter(productDetails?.principalOfficer?.phone) || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          E-mail:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.email || "—"}
        </Form.Label>
      </Form.Group>

      {/* <h6 className="font-weight-bold mt-4">Owner (incorpify)</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.firstName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.middleName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.lastName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.suffixName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Title:
        </Form.Label>
        <Form.Label column sm={7}>
          {EIN_title_array[productDetails?.owner?.title] || "—"}
        </Form.Label>
      </Form.Group> 
      
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Previous First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.prevFirstName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Previous Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.prevLastName || "—"}
        </Form.Label>
      </Form.Group>*/}

      <hr />
      <h6 className="font-weight-bold mt-4">Corporate Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.address1 || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address 2:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.address2 || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.city || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State:
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[productDetails?.corporateAddress?.state] || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.zip || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.county || "—"}
        </Form.Label>
      </Form.Group>

      <h6 className="font-weight-bold mt-4">Mailing Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.address1 || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address 2:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.address2 || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.city || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State:
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[productDetails?.mailingAddress?.state] || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.zip || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.county || "—"}
        </Form.Label>
      </Form.Group>
    </div>
  );
}

export default FormLLCInc;
