import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { accountingActions } from "_actions";

import EditSource from "./AccountingSource/Edit";
import AccountingDocuments from "./AccountingDocuments";
import AccountingStatements from "./AccountingStatements";

function EditAccountingInfo({ orderDetails, selectedProduct, onChange, onError }) {
  const [activeTab, setActiveTab] = useState("customer");
  const dispatch = useDispatch();
  const customerId = useSelector(({ customers: { orderdata } }) => orderdata?.customer?.id);
  const productDetails = orderDetails?.products[selectedProduct];
  const productId = productDetails?.id;

  const handleTab = (key) => setActiveTab(key);

  useEffect(() => {
    if (customerId && productId) {
      dispatch(accountingActions.getFileTypes({ customerId, productId }));
    }
  }, [customerId, productId]);

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Bookkeeping Info</h4>
      <EditSource orderDetails={orderDetails} selectedProduct={selectedProduct} onChange={onChange} />
      <Tabs defaultActiveKey="customer" transition={false} activeKey={activeTab} onSelect={handleTab}>
        <Tab eventKey="customer" title="Customer Provided Documents">
          <AccountingStatements productDetails={productDetails} />
        </Tab>
        <Tab eventKey="monexa" title="Monexa Prepared Documents">
          <AccountingDocuments productDetails={productDetails} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default EditAccountingInfo;
