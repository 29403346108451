import SBIcon, { IconColors, IconTypes } from "@sendbird/uikit-react/ui/Icon";
import SBIconButton from "@sendbird/uikit-react/ui/IconButton";
import React from "react";
import styled from "styled-components";

const StyledGroupChannelHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 8px;
  border-bottom: 1px solid #dee2e6;
`;

const StyledGroupChannelHeaderIconContainer = styled.div`
  padding: 8px;
`;

const StyledGroupChannelHeaderContent = styled.div`
  flex-grow: 1;
`;

const StyledGroupChannelHeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const ChatIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.9965 11H16.0054" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.9955 11H12.0045" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.99451 11H8.00349" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CustomGroupChannelHeader = ({ productDetails, currentChannel, showSearchIcon, onSearchClick }) => {
  const isMuted = currentChannel?.myMutedState === "muted";

  return (
    <StyledGroupChannelHeader>
      <StyledGroupChannelHeaderIconContainer>
        <ChatIcon color="#17a2b8" />
      </StyledGroupChannelHeaderIconContainer>
      <StyledGroupChannelHeaderContent>
        <div>{currentChannel?.name}</div>
      </StyledGroupChannelHeaderContent>
      <StyledGroupChannelHeaderActions>
        {isMuted && (
          <SBIcon
            className="sendbird-chat-header__right__mute"
            type={IconTypes.NOTIFICATIONS_OFF_FILLED}
            fillColor={IconColors.ON_BACKGROUND_2}
            width="24px"
            height="24px"
          />
        )}
        {showSearchIcon && !currentChannel?.isEphemeral && (
          <SBIconButton
            className="sendbird-chat-header__right__search"
            width="32px"
            height="32px"
            onClick={onSearchClick}
          >
            <SBIcon type={IconTypes.SEARCH} fillColor={IconColors.PRIMARY} width="24px" height="24px" />
          </SBIconButton>
        )}
      </StyledGroupChannelHeaderActions>
    </StyledGroupChannelHeader>
  );
};

export default CustomGroupChannelHeader;
