import { Col, Row, Form } from "react-bootstrap";

import { SOURCE_CODE, SOURCE_TITLE } from "./constants";

const sourceOptions = [
  {
    code: SOURCE_CODE.customer,
    value: SOURCE_TITLE[SOURCE_CODE.customer],
  },
  {
    code: SOURCE_CODE.xero,
    value: SOURCE_TITLE[SOURCE_CODE.xero],
  },
  {
    code: SOURCE_CODE.plaid,
    value: SOURCE_TITLE[SOURCE_CODE.plaid],
  },
];

export default function EditSource({ orderDetails, selectedProduct, onChange }) {
  const productDetails = orderDetails?.products[selectedProduct];

  const handleSourceChange = ({ target: { name, value } }) => {
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        const newProductData = {};

        if (idx === selectedProduct) {
          newProductData[name] = value;
        }

        return {
          ...product,
          ...newProductData,
        };
      }),
    });
  };

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Data Source For Transactions</h6>

      <Form.Group as={Row}>
        <Form.Label column sm="5">
          Source:
        </Form.Label>
        <Col sm="7">
          <Form.Control size="sm" as="select" name="source" value={productDetails.source} onChange={handleSourceChange}>
            {sourceOptions?.map(({ code, value }) => (
              <option key={code} value={code}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
    </>
  );
}
