import React, { useEffect, useState, useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";

import { phoneFormatter } from "_helpers";
import { state_array, RELATIONSHIP_OBJ } from "variables/Variables";

import DateView from "components/OrderDetails/ProductDetails/Incorporation/Date/View";
import StatusView from "../Status/View";

function FormPersonalTaxFiling({ orderDetails, selectedProduct }) {
  const [dependentsCollapsed, setDependentsCollapsed] = useState(true);
  const productDetails = orderDetails?.products[selectedProduct];
  const taxReports = productDetails?.taxReports || {};
  const tags = Object.keys(taxReports);
  const sortedTags = useMemo(() => [...tags].sort((a, b) => b - a), [tags]);
  const [currentTag, setCurrentTag] = useState(null);
  const currentReport = taxReports[currentTag] || {};
  const taxReturnStatus = currentReport?.status;
  const individualInfo = currentReport?.individual || {};
  const dependents = currentReport?.dependents || [];
  const spouse = currentReport?.spouse;
  const filingSingle = currentReport?.filingSingle;
  const marriedFilingSeparately = currentReport?.marriedFilingSeparately;

  const handleCurrentTagChange = ({ target: { value } }) => {
    setCurrentTag(value);
  };

  useEffect(() => {
    if (!currentTag && setCurrentTag?.length) {
      setCurrentTag(sortedTags[0]);
    }
  }, [sortedTags, currentTag]);

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">
        {productDetails?.code?.customerName} - {currentTag}
      </h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <hr />
      <h6 className="font-weight-bold">Tax Year</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Tax Year:
        </Form.Label>
        <Col sm={7}>
          <Form.Control as="select" size="sm" value={currentTag || ""} onChange={handleCurrentTagChange}>
            <option value="">Select...</option>
            {sortedTags.map((tag) => (
              <option key={tag} value={tag}>
                {tag}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Tax Return Status</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Tax Return Status:
        </Form.Label>
        <Form.Label column sm={7}>
          {taxReturnStatus || "—"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Personal Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {individualInfo?.firstName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {individualInfo?.lastName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {individualInfo?.ssn || "—"}
        </Form.Label>
      </Form.Group>

      <DateView label="Personal Birthday:" date={individualInfo?.dateOfBirth} />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone:
        </Form.Label>
        <Form.Label column sm={7}>
          {phoneFormatter(individualInfo?.phone) || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          E-mail:
        </Form.Label>
        <Form.Label column sm={7}>
          {individualInfo?.email || "—"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold mt-4">Personal Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address:
        </Form.Label>
        <Form.Label column sm={7}>
          {individualInfo?.address?.address1 || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City:
        </Form.Label>
        <Form.Label column sm={7}>
          {individualInfo?.address?.city || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State:
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[individualInfo?.address?.state] || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip:
        </Form.Label>
        <Form.Label column sm={7}>
          {individualInfo?.address?.zip || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County:
        </Form.Label>
        <Form.Label column sm={7}>
          {individualInfo?.address?.county || "—"}
        </Form.Label>
      </Form.Group>

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Have a spouse?
        </Form.Label>
        <Form.Label column sm={7}>
          {spouse ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      {spouse && (
        <>
          <hr />
          <h6 className="font-weight-bold mt-4">Spouse Information</h6>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              First Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {spouse?.firstName || "—"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Last Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {spouse?.lastName || "—"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              SSN:
            </Form.Label>
            <Form.Label column sm={7}>
              {spouse?.ssn || "—"}
            </Form.Label>
          </Form.Group>

          <DateView label="Personal Birthday:" date={spouse?.dateOfBirth} />

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Phone:
            </Form.Label>
            <Form.Label column sm={7}>
              {phoneFormatter(spouse?.phone) || "—"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              E-mail:
            </Form.Label>
            <Form.Label column sm={7}>
              {spouse?.email || "—"}
            </Form.Label>
          </Form.Group>

          <hr />
          <h6 className="font-weight-bold mt-4">Spouse Address</h6>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Address:
            </Form.Label>
            <Form.Label column sm={7}>
              {spouse?.address?.address1 || "—"}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              City:
            </Form.Label>
            <Form.Label column sm={7}>
              {spouse?.address?.city || "—"}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              State:
            </Form.Label>
            <Form.Label column sm={7}>
              {state_array[spouse?.address?.state] || "—"}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Zip:
            </Form.Label>
            <Form.Label column sm={7}>
              {spouse?.address?.zip || "—"}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              County:
            </Form.Label>
            <Form.Label column sm={7}>
              {spouse?.address?.county || "—"}
            </Form.Label>
          </Form.Group>
        </>
      )}

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5} style={{ cursor: "pointer" }} onClick={() => setDependentsCollapsed((prev) => !prev)}>
          <i className={`fa fa-caret-${dependentsCollapsed ? "down" : "up"}`} />
          <span className="font-weight-bold mt-4">Dependents:</span>
        </Form.Label>
        <Form.Label column sm={7}>
          <span className="font-weight-bold mt-4">{dependents?.length}</span>
        </Form.Label>
      </Form.Group>

      {!dependentsCollapsed &&
        dependents.map((dependent, index) => (
          <React.Fragment key={index}>
            <hr />
            <h6 className="font-weight-bold mt-4">Dependent#{index + 1} Information</h6>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Relationship to You:
              </Form.Label>
              <Form.Label column sm={7}>
                {RELATIONSHIP_OBJ[dependent?.relationship] || dependent?.relationship || "—"}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                First Name:
              </Form.Label>
              <Form.Label column sm={7}>
                {dependent?.firstName || "—"}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Last Name:
              </Form.Label>
              <Form.Label column sm={7}>
                {dependent?.lastName || "—"}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                SSN:
              </Form.Label>
              <Form.Label column sm={7}>
                {dependent?.ssn || "—"}
              </Form.Label>
            </Form.Group>

            <DateView label="Personal Birthday:" date={dependent?.dateOfBirth} />

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Phone:
              </Form.Label>
              <Form.Label column sm={7}>
                {phoneFormatter(dependent?.phone) || "—"}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                E-mail:
              </Form.Label>
              <Form.Label column sm={7}>
                {dependent?.email || "—"}
              </Form.Label>
            </Form.Group>

            <hr />
            <h6 className="font-weight-bold mt-4">Dependent#{index + 1} Address</h6>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Address:
              </Form.Label>
              <Form.Label column sm={7}>
                {dependent?.address?.address1 || "—"}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                City:
              </Form.Label>
              <Form.Label column sm={7}>
                {dependent?.address?.city || "—"}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                State:
              </Form.Label>
              <Form.Label column sm={7}>
                {state_array[dependent?.address?.state] || "—"}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Zip:
              </Form.Label>
              <Form.Label column sm={7}>
                {dependent?.address?.zip || "—"}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                County:
              </Form.Label>
              <Form.Label column sm={7}>
                {dependent?.address?.county || "—"}
              </Form.Label>
            </Form.Group>
          </React.Fragment>
        ))}

      <hr />

      {!spouse && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Filing Status:
          </Form.Label>
          <Form.Label column sm={7}>
            {filingSingle === null ? "—" : filingSingle ? "Single" : "Head of Household"}
          </Form.Label>
        </Form.Group>
      )}

      {spouse && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Filing Status:
          </Form.Label>
          <Form.Label column sm={7}>
            {marriedFilingSeparately === null ? "—" : marriedFilingSeparately ? "Separately" : "Jointly"}
          </Form.Label>
        </Form.Group>
      )}
    </div>
  );
}

export default FormPersonalTaxFiling;
