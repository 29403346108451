import React from "react";
import styled from "styled-components";

import monexaLogoSrc from "assets/img/monexa-logo.png";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #dee3e6;
`;

const StyledAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;

  border: 1px solid #dee3e6;
  border-radius: 50%;
  background-color: #fff;
`;

const StyledChannelName = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export default function UserInfo({ channel }) {
  return (
    <StyledContainer>
      <StyledAvatarContainer>
        <img src={monexaLogoSrc} alt="Channel Avatar" />
      </StyledAvatarContainer>
      <StyledChannelName>{channel?._name}</StyledChannelName>
    </StyledContainer>
  );
}
